import deepEqual from "deep-equal";
import {dateStrToDay} from "../date-utils";
import {toBigInt} from "../utils";
import {Changes} from "./Changes";
import type {
  CacheEntry as Entry,
  CacheVal,
  ChangeListener,
  ModelCache,
  ModelDescriptions,
  ModelName,
  MutationDesc,
  OptModelCache,
  OptModelCacheEntry,
  OptModelCacheMap,
} from "./mate-types";
import {getIdAsKey} from "./mate-shared";
import {JsonModel} from "../../cdx-models/utils/model-type-utils";
import {invalidateAfterDelete, invalidateAfterUpdate} from "./invalidation-helpers";

type AddToCacheData = Partial<Record<ModelName, unknown>>;

const hydrateValue = (dbEntry: Record<string, any>, desc: JsonModel) => {
  const translateField = (fieldName: string, value: any) => {
    switch (desc.fields[fieldName]?.type) {
      case "date":
        return value === null ? null : new Date(value);
      case "day":
        return value && dateStrToDay(value);
      case "bigint":
        return toBigInt(value);
      default:
        return value;
    }
  };
  return Object.fromEntries(Object.entries(dbEntry).map(([k, v]) => [k, translateField(k, v)]));
};

const updateExistingValuesAndDeleteStatus = <T extends Record<string, any>>(
  entry: Entry<T>,
  newData: Partial<T>
) => {
  let hasChanged = false;
  const existingData = entry.value;
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const field in newData) {
    const newValue = newData[field]!;
    if (!(field in existingData) || !deepEqual(existingData[field], newValue, {strict: true})) {
      existingData[field] = newValue;
      hasChanged = true;
    }
    delete entry.status[field];
  }
  return hasChanged;
};

function collectFieldDeps(descriptions: ModelDescriptions) {
  // fieldDeps = {Card: {content: {fields: ["title", "tags"], rels: []}}
  type DepMap = Record<
    string,
    {fields: string[]; rels: {modelName: ModelName; relName: string; fk: string}[]}
  >;
  const deps = {} as Record<ModelName, DepMap>;
  for (const [modelName, modelDesc] of Object.entries(descriptions) as [ModelName, JsonModel][]) {
    const modelDeps = (deps[modelName] = {} as DepMap);
    for (const belongsToDesc of Object.values(modelDesc.belongsTo)) {
      // for e.g. deck.account relation, it gets "account.anyDecks", "account.decks" relations

      const {rels} = (modelDeps[belongsToDesc.fk] = modelDeps[belongsToDesc.fk] || {
        fields: [],
        rels: [],
      });
      const counterParts = descriptions[belongsToDesc.model].hasMany;
      // fairly hot loop...
      Object.keys(counterParts)
        .filter((counterRelName) => {
          const hasManyDesc = counterParts[counterRelName];
          return hasManyDesc.model === modelName && hasManyDesc.fk === belongsToDesc.fk;
        })
        .forEach((counterRelName) => {
          rels.push({
            modelName: belongsToDesc.model,
            relName: counterRelName,
            fk: belongsToDesc.fk,
          });
        });
    }
    for (const fieldName of Object.keys(modelDesc.fields)) {
      modelDeps[fieldName] = {fields: [], rels: []};
    }
    for (const [fieldName, fieldDesc] of Object.entries(modelDesc.fields)) {
      for (const dep of fieldDesc.deps) {
        modelDeps[dep].fields.push(fieldName);
      }
    }
    for (const [relName, belongsToDesc] of Object.entries(modelDesc.belongsTo)) {
      for (const dep of belongsToDesc.deps) {
        modelDeps[dep].fields.push(belongsToDesc.fk, relName);
      }
    }
  }
  for (const [modelName, modelDesc] of Object.entries(descriptions) as [ModelName, JsonModel][]) {
    for (const [relName, hasManyDesc] of Object.entries(modelDesc.hasMany)) {
      const relDeps = deps[hasManyDesc.model];
      for (const dep of hasManyDesc.deps) {
        relDeps[dep].rels.push({modelName, relName, fk: hasManyDesc.fk!});
      }
    }
  }
  return deps;
}

const deprecatedFields: Partial<Record<ModelName, Record<string, boolean>>> = {};
const isDeprecatedField = ({modelName, field}: {modelName: ModelName; field: string}) =>
  deprecatedFields[modelName] && deprecatedFields[modelName][field];

function collectHasManyModelDeps(descriptions: ModelDescriptions) {
  // hasManyModelDeps = {attachment: [{model: "card", relName: "attachments", fk: "cardId"}]}
  const modelNameEntries = Object.entries(descriptions) as [ModelName, JsonModel][];
  const deps = Object.fromEntries(
    modelNameEntries.map(([modelName]) => [modelName, [] as any[]])
  ) as Record<ModelName, {model: ModelName; relName: string; fk: string}[]>;
  for (const [modelName, desc] of modelNameEntries) {
    for (const [relName, {model, fk}] of Object.entries(desc.hasMany)) {
      if ((model as any) === "$deprecated") {
        (deprecatedFields[model] = deprecatedFields[model] || {})[relName] = true;
        continue;
      }
      deps[model].push({model: modelName, relName, fk: fk!});
    }
  }
  return deps;
}

function collectBelongsToModelDeps(descriptions: ModelDescriptions) {
  // belongsToModelDeps = {milestone: [{model: "card", relName: "milestone", fk: "cardId"}]}
  const modelNameEntries = Object.entries(descriptions) as [ModelName, JsonModel][];
  const deps = Object.fromEntries(
    modelNameEntries.map(([modelName]) => [modelName, [] as any[]])
  ) as Record<ModelName, {model: ModelName; relName: string; fk: string}[]>;
  for (const [modelName, desc] of modelNameEntries) {
    for (const [relName, {model, fk}] of Object.entries(desc.belongsTo)) {
      deps[model].push({model: modelName, relName, fk});
    }
  }
  return deps;
}

function collectFksToFields(descriptions: ModelDescriptions) {
  const fksToFields = {} as Record<ModelName, Record<string, string>>;
  for (const [modelName, desc] of Object.entries(descriptions) as [ModelName, JsonModel][]) {
    const currMap = (fksToFields[modelName] = {} as Record<string, string>);
    for (const [relName, {fk}] of Object.entries(desc.belongsTo)) {
      currMap[fk] = relName;
    }
  }
  return fksToFields;
}

export const cardAccountSeqToCardId = new Map();

type OnChange = (changes: Changes["changes"] | "all") => void;

export default function createCache(
  descriptions: ModelDescriptions,
  collector: any,
  onChange: OnChange,
  registerOnChangeListener: ChangeListener
) {
  const fieldDeps = collectFieldDeps(descriptions);
  const hasManyModelDeps = collectHasManyModelDeps(descriptions);
  const belongsToModelDeps = collectBelongsToModelDeps(descriptions);
  const fksToFields = collectFksToFields(descriptions);

  const onEntryLoadedHooks: Partial<Record<ModelName, (entry: any) => void>> = {
    card: (entry) => {
      if (entry.accountSeq) {
        cardAccountSeqToCardId.set(entry.accountSeq, entry.cardId);
      }
    },
  };

  const modelCache: ModelCache = {};
  const optModelCache: OptModelCache = {}; // optimistic cache
  // optModelCache = {
  //   card: {
  //     1: {
  //       priority: [{value: 3, mutationId: 2}, {value: 2, mutationId: 4}]
  //     }
  //   }
  // };
  const fieldsAffectingRelations: Partial<
    Record<ModelName, Record<string, Partial<Record<ModelName, Set<string>>>>>
  > = {};
  // {card: {title: {account: Set(["cards({title: 'something'}"])}}

  const relsAffectingRelations: Partial<
    Record<ModelName, Partial<Record<ModelName, Set<string>>>>
  > = {};
  /*
    sample rel:
    account.$meta.find("cards", {handCards: {userId: 3}})

    result in relsAffectingRelations
    {handCards: {account: Set(["cards(...)"])}}

    note that handCard's cardId will be mapped in `fieldsAffectingRelations`

  */

  type PopOptModelCacheCB = (
    opts: Pick<OptModelCacheEntry, "value" | "type" | "onResolve"> & {
      modelName: ModelName;
      id: string;
      field: string;
    }
  ) => void;

  const popFromOptModelCache = (mutationId: number, cb?: PopOptModelCacheCB) => {
    for (const [modelName, optModelMap] of Object.entries(optModelCache) as [
      ModelName,
      OptModelCacheMap,
    ][]) {
      const optModelEntries = Object.entries(optModelMap);
      let deletedModelIds = 0;
      for (const [id, cacheEntry] of optModelEntries) {
        const fieldEntries = Object.entries(cacheEntry);
        let deletedNames = 0;
        for (const [field, fields] of fieldEntries) {
          const deleteIdx: number[] = [];
          let idx = 0;
          for (const {value, mutationId: entryMutationId, type, onResolve} of fields) {
            if (entryMutationId === mutationId) {
              if (cb) cb({modelName, id, field, value, type, onResolve});
              deleteIdx.push(idx);
            }
            idx += 1;
          }
          if (deleteIdx.length > 0) {
            let offset = -1;
            deleteIdx.forEach((i) => fields.splice(i - (offset += 1), 1));
            if (fields.length === 0) {
              delete cacheEntry[field];
              deletedNames += 1;
            }
          }
        }
        if (deletedNames === fieldEntries.length) {
          delete optModelMap[id];
          deletedModelIds += 1;
        }
      }
      if (deletedModelIds === optModelEntries.length) {
        delete optModelCache[modelName];
      }
    }
  };

  type AffectedRelationsCb = (
    parentCacheEntry: Entry,
    relField: string,
    parentModelName: ModelName,
    parentId: string | null
  ) => void;
  const findAffectedRelations = (
    mutationDesc: MutationDesc & {type: "update" | "delete" | "create"},
    data: Record<string, any>,
    cb: AffectedRelationsCb
  ) => {
    const {model: modelName} = mutationDesc;
    const modelDesc = descriptions[modelName];
    const maybeIds = modelDesc.idPropAsArray.map((prop) => data[prop]);
    let idKey = maybeIds.every((val) => val !== undefined) ? getIdAsKey(maybeIds) : null;
    if (!idKey && data.id && modelDesc.idPropAsArray.length === 1) {
      idKey = data.id as string;
    }
    const cacheEntry = (idKey && modelCache[modelName]?.[idKey]) || {
      value: {},
    };
    // hasManyModelDeps = {attachment: [{model: "card", relName: "attachments", fk: "cardId"}]}
    for (const {model: parentModelName, relName, fk} of hasManyModelDeps[modelName]) {
      const parentCacheEntries: {parentCacheEntry: Entry; parentId: string | null}[] = [];
      if (parentModelName === "_root") {
        parentCacheEntries.push({
          parentCacheEntry: modelCache[parentModelName] as any,
          parentId: null,
        });
      } else {
        if (data[fk] === null) continue; // explicitly has no parent
        const parentId: string | undefined = data[fk] || cacheEntry.value[fk] || data.id;
        const ids = parentId ? [parentId] : (data.ids as string[] | undefined);
        if (ids && ids.length) {
          const parentModelCache = modelCache[parentModelName];
          if (parentModelCache) {
            for (const id of ids) {
              const parentCacheEntry = parentModelCache[id];
              if (parentCacheEntry) {
                parentCacheEntries.push({parentCacheEntry, parentId: id});
              }
            }
          }
        }
        if (!parentCacheEntries.length) {
          const entries = modelCache[parentModelName];
          if (entries) {
            for (const [id, parentCacheEntry] of Object.entries(entries)) {
              if (!parentCacheEntry) continue;
              parentCacheEntries.push({parentCacheEntry, parentId: id});
            }
          }
          if (process.env.NODE_ENV !== "production") {
            // eslint-disable-next-line no-console
            console.info(
              `could not find fk "${fk}" within modified "${modelName}" instance or within passed data, therefore invalidating ${parentCacheEntries.length} ${parentModelName} for ${relName}`
            );
          }
        }
      }

      const fieldVariantsRegex = new RegExp(`^(count:|exists:)?${relName}($|\\()`);
      for (const {parentCacheEntry, parentId} of parentCacheEntries) {
        for (const relField of Object.keys(parentCacheEntry.value)) {
          // HINT: could optimize delete by looking if data.id is part of the relation
          if (fieldVariantsRegex.test(relField)) {
            cb(parentCacheEntry, relField, parentModelName, parentId);
          }
        }
      }
    }
  };

  const getModelFromCache = (modelName: ModelName, id: string): Record<string, any> | undefined => {
    const mCache = modelCache[modelName];
    if (!mCache) return undefined;
    const eCache = mCache[id];
    return eCache && eCache.value;
  };

  const cache = {
    modelCache,
    add(data: AddToCacheData) {
      const changes = new Changes();
      for (const [modelName, record] of Object.entries(data) as [ModelName, unknown][]) {
        if (!(modelName in descriptions)) throw new Error(`Don't know model "${modelName}"`);
        if (modelName === "_root") {
          const existing = modelCache[modelName] as any as Entry;
          const entry = record as Record<string, any>;
          if (existing) {
            const hasUpdated = updateExistingValuesAndDeleteStatus(existing, entry);
            if (hasUpdated) {
              changes.add(modelName, null);
            } else {
              changes.add(modelName, null, "touched");
            }
          } else {
            (modelCache[modelName] as any) = {
              value: entry,
              status: {},
            };
          }
        } else {
          const modelEntries = (modelCache[modelName] = modelCache[modelName] || {});
          const hook = onEntryLoadedHooks[modelName];
          const desc = descriptions[modelName];
          for (const [entryId, dbEntry] of Object.entries(record as any) as [
            string,
            Record<string, any>,
          ][]) {
            if (dbEntry === null) {
              if (process.env.NODE_ENV !== "production") {
                console.warn(
                  `trying to add ${modelName}(${entryId}) to cache, but it's deleted/inaccessible`
                );
              }
              changes.add(modelName, entryId);
              modelEntries[entryId] = null;
            } else {
              const hydrated = hydrateValue(dbEntry, desc);
              if (hook) hook(hydrated);
              const existing = modelEntries[entryId];
              if (existing) {
                const hasUpdated = updateExistingValuesAndDeleteStatus(existing, hydrated);
                if (hasUpdated) {
                  changes.add(modelName, entryId);
                } else {
                  changes.add(modelName, entryId, "touched");
                }
              } else {
                changes.add(modelName, entryId);
                modelEntries[entryId] = {
                  value: hydrated,
                  status: {},
                };
              }
            }
          }
        }
      }
      onChange(changes.changes);
    },
    // hint: could also be just inaccessible.
    isDeleted(modelName: ModelName, id: string) {
      const modelEntries = modelCache[modelName];
      if (!modelEntries) return false;
      return modelEntries[id] === null;
    },
    // status: "loaded" | "loading" | "refreshing" | "optimistic" | "notLoaded" | "invalid" | "deleted"
    getAndTellIfLoaded(opts: {
      modelName: ModelName;
      id: string;
      field: string;
      collectIfMissing?: boolean;
    }): CacheVal {
      const {modelName, id, field, collectIfMissing} = opts;
      const cachedVals = modelCache[modelName];
      let cachedEntry: Entry | null | undefined;
      if (modelName === "_root") {
        cachedEntry = cachedVals as any as Entry;
      } else {
        cachedEntry = cachedVals && cachedVals[id];
      }
      const optCachedVals = optModelCache[modelName];
      if (optCachedVals) {
        const optCachedEntry = optCachedVals[id];
        if (optCachedEntry) {
          const fieldVals = optCachedEntry[field];
          if (fieldVals && fieldVals.length) {
            // if val === undefined, api.js told us: we don't know yet. use an existing value, or the default instead
            const val = fieldVals[fieldVals.length - 1].value;
            const useDefaultVal =
              val !== undefined && (!cachedEntry || !(field in cachedEntry.value));
            return {
              val:
                val !== undefined
                  ? val
                  : cachedEntry && field in cachedEntry.value
                    ? cachedEntry.value[field]
                    : undefined,
              isLoaded: false,
              status: "optimistic",
              useDefaultVal,
            };
          }
        }
      }

      if (cachedEntry === null) {
        console.warn(
          `tried to get "${field}" on "${modelName}" with id "${id}" which is a deleted/inaccessible resource. Prevent this via checking for \`$meta.isDeleted()\``
        );
        return {val: null, isLoaded: true, status: "deleted", useDefaultVal: false};
      }
      if (isDeprecatedField({modelName, field})) {
        return {val: null, isLoaded: true, status: "deleted", useDefaultVal: false};
      }
      if (cachedEntry === undefined) {
        if (collectIfMissing) {
          cachedEntry = {
            value: {},
            status: {},
          };
          if (modelName === "_root") {
            (modelCache as any)[modelName] = cachedEntry;
          } else {
            if (!modelCache[modelName]) modelCache[modelName] = {};
            modelCache[modelName][id] = cachedEntry;
          }
          collector.collect([{modelName, id}], field, collectIfMissing);
          cachedEntry.status[field] = "loading";
        }
        return {
          val: undefined,
          isLoaded: false,
          status: collectIfMissing ? "loading" : "notLoaded",
          useDefaultVal: true,
        };
      } else {
        const isFieldPresent = field in cachedEntry.value;
        const status = cachedEntry.status[field];
        const needsToCollect = !isFieldPresent || Boolean(status);

        if (collectIfMissing && needsToCollect && status !== "loading" && status !== "refreshing") {
          const desc = descriptions[modelName];
          if (desc && (desc as any).isFunction) {
            // there's no way to update rows returned from a function. So let's just ignore those
            delete cachedEntry.status[field];
          } else {
            collector.collect([{modelName, id}], field, collectIfMissing, status);
          }
          cachedEntry.status[field] = status === "invalid" ? "refreshing" : "loading";
        }

        return isFieldPresent
          ? {
              val: cachedEntry.value[field],
              isLoaded: !needsToCollect,
              status: cachedEntry.status[field] || "loaded",
              useDefaultVal: false,
            }
          : {
              val: undefined,
              isLoaded: !needsToCollect,
              status: cachedEntry.status[field] || "notLoaded",
              useDefaultVal: true,
            };
      }
    },
    clear({onDone, delayReload}: {onDone: () => void; delayReload?: boolean}) {
      collector.clear();
      if (delayReload) {
        collector.delayCollecting(50, () => onChange("all"));
      }
      for (const key of Object.keys(modelCache) as ModelName[]) {
        delete modelCache[key];
      }
      for (const key of Object.keys(optModelCache) as ModelName[]) {
        delete optModelCache[key];
      }
      if (onDone) {
        const unsub = registerOnChangeListener(() => {
          onDone();
          unsub();
        });
      }
      onChange("all");
    },
    invalidateAll() {
      const invalidateAllFields = ({value, status}: Entry) => {
        for (const field in value) status[field] = "invalid";
      };
      for (const [key, val] of Object.entries(modelCache)) {
        if (key === "_root") {
          invalidateAllFields(val as any as Entry);
        } else {
          for (const model of Object.values(val)) {
            if (model) invalidateAllFields(model);
          }
        }
      }
      onChange("all");
    },
    addOptimisticAction(
      mutationId: number,
      mutationDesc: MutationDesc,
      rawData: Record<string, any>,
      isImplicit = false,
      changes = new Changes()
    ) {
      const {convertDataForOptimistic} = mutationDesc;
      const data = convertDataForOptimistic ? convertDataForOptimistic(rawData) : rawData;
      if (
        mutationDesc.type === "update" ||
        mutationDesc.type === "delete" ||
        mutationDesc.type === "create"
      ) {
        const {model: modelName} = mutationDesc;
        const modelDesc = descriptions[modelName];
        const getIdAndRest = () => {
          const idParts: string[] = [];
          const hasAllParts = modelDesc.idPropAsArray.every((idProp) => {
            const idVal = data[idProp];
            if (idVal) {
              idParts.push(idVal);
              return true;
            }
            return false;
          });

          // TODO: Document why?
          return hasAllParts
            ? {
                ...Object.fromEntries(
                  Object.entries(data).filter(([k]) => !modelDesc.idPropAsArray.includes(k))
                ),
                id: getIdAsKey(idParts),
              }
            : data;
        };

        const {id, ids, ...changedFields} = getIdAndRest();
        if (mutationDesc.type === "update") {
          const changeFieldNames = Object.keys(changedFields);
          if ((id || ids) && changeFieldNames.length) {
            const models = (optModelCache[modelName] = optModelCache[modelName] || {});
            for (const innerId of id ? [id] : ids) {
              const entry = (models[innerId] = models[innerId] || {});
              changes.add(modelName, innerId);
              changeFieldNames.forEach((field) => {
                const fieldName = fksToFields[modelName][field] || field;
                (entry[fieldName] = entry[fieldName] || []).push({
                  value: changedFields[field],
                  mutationId,
                  type: "update",
                  onResolve: mutationDesc.onResolve,
                });
              });
            }
          }
        } else if (mutationDesc.type === "create") {
          const fields = Object.keys(data).filter(
            (field) =>
              field !== "id" &&
              (fksToFields[modelName][field] ||
                modelDesc.fields[field] ||
                modelDesc.belongsTo[field])
          );
          if (fields.length === 0) return;
          const models = (optModelCache[modelName] = optModelCache[modelName] || {});
          const optId = id || `$opt$${mutationId}`;
          const entry = (models[optId] = models[optId] || {});
          fields.forEach((field) => {
            const fieldName = fksToFields[modelName][field] || field;
            (entry[fieldName] = entry[fieldName] || []).push({
              value: data[field],
              mutationId,
              type: "create",
              onResolve: mutationDesc.onResolve,
            });
          });
        } else if (mutationDesc.type === "delete") {
          if (id) {
            findAffectedRelations(
              mutationDesc,
              data,
              (parentCacheEntry, relName, parentModelName) => {
                const parentDesc = descriptions[parentModelName];
                const parentVal = parentCacheEntry.value[relName];
                if (Array.isArray(parentVal)) {
                  const indexOfId = parentVal.indexOf(id);
                  if (indexOfId >= -1) {
                    const parentId = parentCacheEntry.value[parentDesc.idProp as string];
                    const models = (optModelCache[parentModelName] =
                      optModelCache[parentModelName] || {});
                    const entry = (models[parentId] = models[parentId] || {});
                    changes.add(parentModelName, parentId);
                    const fieldVals = (entry[relName] = entry[relName] || []);
                    const latestValue: string[] = fieldVals.length
                      ? fieldVals[fieldVals.length - 1].value
                      : parentVal;
                    fieldVals.push({
                      value: latestValue.filter((relId) => relId !== id),
                      mutationId,
                      type: "deleteFromCollection",
                      onResolve: mutationDesc.onResolve,
                    });
                  }
                } else if (parentVal && parentVal === id) {
                  const parentId = parentCacheEntry.value[parentDesc.idProp as string];
                  const models = (optModelCache[parentModelName] =
                    optModelCache[parentModelName] || {});
                  const entry = (models[parentId] = models[parentId] || {});
                  changes.add(parentModelName, parentId);
                  (entry[relName] = entry[relName] || []).push({
                    value: null,
                    mutationId,
                    type: "deleteFromCollection",
                    onResolve: mutationDesc.onResolve,
                  });
                }
              }
            );
          }
        }
      } else if (mutationDesc.type === "custom") {
        mutationDesc.fn({
          modelCache,
          optModelCache,
          data,
          mutationId,
          addOptimisticAction: cache.addOptimisticAction,
          changes,
          getAndTellIfLoaded: cache.getAndTellIfLoaded,
        });
      } else if (mutationDesc.type === "void") {
        // don't do anything, this is used for implicit updates only
      }
      if (mutationDesc.implicit) {
        const list = mutationDesc.implicit(data, {
          _isOptimistic: true,
          _getModelFromCache: getModelFromCache,
        });
        list.forEach(({desc: implDesc, data: implData}) =>
          cache.addOptimisticAction(mutationId, implDesc, implData, true, changes)
        );
      }
      if (!isImplicit) onChange(changes.changes);
    },
    failedOptimisticAction(mutationId: number) {
      popFromOptModelCache(mutationId);
      onChange("all");
    },
    resolveOptimisticAction(
      mutationId: number,
      mutationDesc: MutationDesc,
      data: Record<string, any>,
      rawRetVal: any,
      changes: Changes
    ) {
      popFromOptModelCache(
        mutationId,
        ({modelName, id, field, value: rawValue, type, onResolve}) => {
          const {value, retVal} = (onResolve &&
            onResolve({id, field, retVal: rawRetVal, value: rawValue})) || {
            value: rawValue,
            retVal: rawRetVal,
          };
          if (type === "update" || type === "deleteFromCollection") {
            const cacheEntry = (modelCache[modelName] = modelCache[modelName] || {})[id];
            changes.add(modelName, id);
            if (!cacheEntry) return;
            if (value === undefined || (typeof value === "string" && value.startsWith("$opt$"))) {
              cacheEntry.status[field] = "invalid";
            } else {
              cacheEntry.value[field] = value;
            }
          } else if (type === "create") {
            if (!retVal || !retVal.id) return;
            const entries = (modelCache[modelName] = modelCache[modelName] || {});
            const cacheEntry = (entries[retVal.id] = entries[retVal.id] || {
              value: {},
              status: {},
            });
            cacheEntry.value[field] = value;
            cacheEntry.status[field] = "invalid";
          }
        }
      );
      return changes;
    },
    invalidate(
      desc: MutationDesc,
      data: Record<string, any>,
      retVal: any,
      isImplicit = false,
      changes = new Changes()
    ) {
      const partialBag = {
        data,
        retVal,
        isImplicit,
        changes,
        descriptions,
        modelCache,
        fieldsAffectingRelations,
        fksToFields,
        fieldDeps,
        belongsToModelDeps,
      };
      if (desc.type === "update") {
        invalidateAfterUpdate({...partialBag, desc});
      } else if (desc.type === "create" || desc.type === "delete") {
        if (desc.type === "delete") {
          invalidateAfterDelete({...partialBag, desc});
        }
        findAffectedRelations(desc, data, (parentCacheEntry, relName, parentModelName, relId) => {
          changes.add(parentModelName, relId);
          parentCacheEntry.status[relName] = "invalid";
        });

        const affectedRels = relsAffectingRelations[desc.model];
        if (affectedRels) {
          for (const [parentModel, relNames] of Object.entries(affectedRels) as [
            ModelName,
            Set<string>,
          ][]) {
            const modelEntries = modelCache[parentModel];
            if (!modelEntries) continue;
            for (const [parentId, entry] of Object.entries(modelEntries)) {
              if (!entry) continue;
              for (const relName of relNames) {
                if (relName in entry.value || relName in entry.status) {
                  entry.status[relName] = "invalid";
                  // it's a change because $isLoaded now returns a different value and the model's cache isn't valid anymore
                  changes.add(parentModel, parentId);
                }
              }
            }
          }
        }
      } else if (desc.type === "custom") {
        desc.fn({modelCache, optModelCache, data});
      } else if (desc.type === "void") {
        // do nothing. This type exists just for implicit updates
      } else {
        throw new Error(`don't know action type "${desc.type}"!`);
      }
      if (desc.implicit) {
        const invalidations = desc.implicit(data, {
          ...retVal,
          _getModelFromCache: getModelFromCache,
        });
        for (const {desc: implDesc, data: implData} of invalidations) {
          cache.invalidate(implDesc, implData, retVal, true, changes);
        }
      }
      if (!isImplicit) onChange(changes.changes);
    },

    addConstraintBasedRelationField({
      parentModel,
      relName,
      targetModel,
      field,
    }: {
      parentModel: ModelName;
      relName: string;
      targetModel: ModelName;
      field: string;
    }) {
      let byModel = fieldsAffectingRelations[targetModel];
      if (!byModel) byModel = fieldsAffectingRelations[targetModel] = {};
      let byField = byModel[field];
      if (!byField) byField = byModel[field] = {};
      let parentSet = byField[parentModel];
      if (!parentSet) parentSet = byField[parentModel] = new Set();
      parentSet.add(relName);
    },

    addConstraintBasedRelationRelation({
      parentModel,
      relName,
      targetModel,
      fk,
    }: {
      parentModel: ModelName;
      relName: string;
      targetModel: ModelName;
      fk: string;
    }) {
      /*
      sample rel:
      account.$meta.find("cards", {handCards: {userId: 3}})

      targetModelDesc: "card"
      parentModel: "account" + relName
      targetModel: "handCard"
      fk: cardId
      */

      // if a handCard's cardId is changed, invalidate the relation
      cache.addConstraintBasedRelationField({parentModel, relName, targetModel, field: fk});

      let byModel = relsAffectingRelations[targetModel];
      if (!byModel) byModel = relsAffectingRelations[targetModel] = {};
      let parentSet = byModel[parentModel];
      if (!parentSet) parentSet = byModel[parentModel] = new Set();
      parentSet.add(relName);
    },
  };
  return cache;
}
