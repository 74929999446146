import {MiniEvent} from "@cdx/common";
import createCollectFn from "./collector";
import createCache from "./cache";
import createMutator from "./mutator";
import createInstancePool from "./instance-pool";
import {mergeChanges} from "./Changes";

const today = new Date();

const defaults = {
  array: [],
  json: {},
  date: today,
  int: 0,
  bigint: 0n,
  day: {year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate()},
  bool: false,
};

function fieldDescFromDesc(desc = {}) {
  return {
    ...{
      type: "default",
      defaultValue: defaults[desc.type] !== undefined ? defaults[desc.type] : "unknown",
      deps: [],
    },
    ...desc,
  };
}

function normalizeDescription(desc) {
  if (desc.name === "_root") {
    desc.idPropAsArray = [];
  } else {
    const {idProp} = desc;
    if (idProp && Array.isArray(idProp)) {
      desc.idPropAsArray = idProp;
      desc.idProp = idProp.length === 1 ? idProp[0] : idProp;
    } else {
      desc.idProp = idProp || "id";
      desc.idPropAsArray = [desc.idProp];
    }
  }

  desc.fields = [...desc.idPropAsArray, ...desc.fields].reduce((fields, field) => {
    if (typeof field === "string") {
      fields[field] = fieldDescFromDesc();
    } else {
      Object.keys(field).forEach((fieldLabel) => {
        const fieldDesc = field[fieldLabel];
        fields[fieldLabel] = fieldDescFromDesc(typeof fieldDesc === "string" ? {} : fieldDesc);
      });
    }
    return fields;
  }, {});

  function hasManyDescFromString(key) {
    return {model: key.replace(/s$/, ""), isSingleton: false, fk: `${desc.name}Id`, deps: []};
  }

  function belongsToDescFromString(key) {
    return {model: key, fk: `${key}Id`, deps: []};
  }

  desc.hasMany = (desc.hasMany || []).reduce((m, entry) => {
    if (typeof entry === "string") {
      m[entry] = hasManyDescFromString(entry);
    } else {
      Object.keys(entry).forEach((relName) => {
        m[relName] = {...hasManyDescFromString(relName), ...entry[relName]};
      });
    }
    return m;
  }, {});

  desc.fkToBelongsTo = {};

  desc.belongsTo = (desc.belongsTo || []).reduce((m, entry) => {
    if (typeof entry === "string") {
      m[entry] = belongsToDescFromString(entry);
      desc.fkToBelongsTo[m[entry].fk] = m[entry];
    } else {
      Object.keys(entry).forEach((relName) => {
        m[relName] = {...belongsToDescFromString(relName), ...entry[relName]};
        desc.fkToBelongsTo[m[relName].fk] = m[relName];
      });
    }
    return m;
  }, {});

  return desc;
}

export default function createApi(descriptionList, fetcher, mutations, dispatcher) {
  let notifyUpdatesTimeoutId = null;
  let apiChangeIndex = 0;
  let collectedChanges = null;

  const descriptions = descriptionList.reduce((m, d) => {
    m[d.name] = normalizeDescription({...d});
    return m;
  }, {});

  const onChangeEvent = new MiniEvent();

  // only fire once per tick by waiting for one tick before updating the listeners
  function onChange(changes) {
    if (!collectedChanges) {
      collectedChanges = changes;
    } else {
      if (collectedChanges === "all" || changes === "all") {
        collectedChanges = "all";
      } else {
        mergeChanges(collectedChanges, changes);
      }
    }
    if (!notifyUpdatesTimeoutId) {
      notifyUpdatesTimeoutId = setTimeout(() => {
        notifyUpdatesTimeoutId = null;
        apiChangeIndex += 1;
        onChangeEvent.emit(apiChangeIndex, collectedChanges || {});
        collectedChanges = null;
      });
    }
  }

  const onNotLoadedEvent = new MiniEvent();

  /* eslint-disable no-use-before-define */
  // TODO: figure out a sane way of who knows about whom how

  const collector = createCollectFn(
    fetcher,
    (...args) => cache.add(...args),
    onNotLoadedEvent.emit,
    descriptions
  );
  const cache = createCache(descriptions, collector, onChange, onChangeEvent.addListener);
  const mutate = createMutator(mutations, dispatcher, cache, onChangeEvent.addListener);
  const instancePool = createInstancePool({
    cache,
    descriptions,
    collect: collector.collect,
    registerOnChangeListener: onChangeEvent.addListener,
    getApiChangeIndex: () => apiChangeIndex,
    notLoadedEvent: onNotLoadedEvent.emit,
  });

  /* eslint-disable no-use-before-define */

  return {
    descriptions,
    cache,
    mutate,
    getModel: instancePool.getInstanceViaId,
    registerOnNotLoadedListener: onNotLoadedEvent.addListener,
    registerOnChangeListener: onChangeEvent.addListener,
    getRoot: instancePool.getRoot,
    getChangeIndex: () => apiChangeIndex,
    isCurrentlyLoading: collector.isCurrentlyLoading,
  };
}
