import {
  cloneElement,
  createContext,
  forwardRef,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {create} from "zustand";
import {
  OverlayPlacer,
  Portal,
  SpawnAnchoredOverlayWithNode,
  springConfigs,
  useClickOutside,
  useGetNodeFromRef,
  useReveal,
  useToggle,
} from ".";

type DropDownStore = {
  openRef: number | null;
  setOpenRef: (ref: number | null) => void;
};

const createStore = () =>
  create<DropDownStore>((set) => ({
    openRef: null,
    setOpenRef: (ref) => set({openRef: ref}),
  }));

const DropDownContentContext = createContext(createStore());

type DropDownRenderOverlayProps = {
  close: () => void;
  onClick: (e: any) => void;
};

export type DropDownRenderOverlay = (
  props: DropDownRenderOverlayProps & Record<string, any>
) => ReactNode;

type DropDownOverlayProps = {
  renderOverlay: DropDownRenderOverlay;
  close: () => void;
  [key: string]: any; // Allow for extra props
};

const DropDownOverlay = ({renderOverlay, close, ...rest}: DropDownOverlayProps) => {
  const [ctxVal] = useState(createStore);
  const clickOutsideHandlers = useClickOutside(close);
  return (
    <DropDownContentContext.Provider value={ctxVal}>
      {renderOverlay({close, ...clickOutsideHandlers, ...rest})}
    </DropDownContentContext.Provider>
  );
};

let nextId = 1;

const useDropDown = (initialOpen: boolean = false) => {
  const useOpenStore = useContext(DropDownContentContext);
  const {openRef, setOpenRef} = useOpenStore();
  const myIdRef = useRef<number>(undefined as any as number);
  if (!myIdRef.current) {
    myIdRef.current = nextId += 1;
  }
  useEffect(() => {
    if (initialOpen) setOpenRef(myIdRef.current);
  }, [initialOpen, setOpenRef]);

  const isOpen = openRef === myIdRef.current;
  const toggle = () => (isOpen ? setOpenRef(null) : setOpenRef(myIdRef.current));
  const close = () => setOpenRef(null);
  return [isOpen, toggle, close] as const;
};

type UseNextDropDownParams = {
  initial?: boolean;
  springConfig?: any;
  overlayProps?: any;
};
export const useNextDropDown = ({
  initial = false,
  springConfig = springConfigs.quick,
  overlayProps,
}: UseNextDropDownParams = {}) => {
  const [isOpen, toggle, close] = useDropDown(initial);
  const [node, setNode] = useState<HTMLElement | null>(null);
  const reveal = useReveal(isOpen, {config: springConfig});

  const overlayElement = reveal((props) => (
    <Portal>
      <OverlayPlacer
        node={node}
        presenceProps={props}
        isOpen={isOpen}
        {...overlayProps}
        renderOverlay={(p: any) => (
          <DropDownOverlay
            close={isOpen ? toggle : () => {}}
            renderOverlay={overlayProps.renderOverlay}
            {...p}
          />
        )}
      />
    </Portal>
  ));

  return {ref: setNode, toggle, overlayElement, isOpen, close, node};
};

type DropDownForChildProps = {
  withHover?: boolean;
  onClose?: () => void;
  initialOpen?: boolean;
  children: ReactElement;
  renderOverlay: DropDownRenderOverlay;
  overlayProps?: {
    distanceFromAnchor?: number;
    placement?: "top" | "bottom" | "left" | "right";
  };
  setChildProps?: (props: {
    isOpen: boolean;
    isClicked: boolean;
    toggle: () => void;
    hovered: boolean;
    close: () => void;
  }) => Record<string, any>;
};

export const DropDownForChild = forwardRef<any, DropDownForChildProps>((props, passedRef) => {
  const {withHover, onClose, initialOpen, children, renderOverlay, overlayProps, setChildProps} =
    props;
  const [hovered, {on, off}] = useToggle();
  const [isClicked, toggle] = useDropDown(initialOpen);
  const {node, ref} = useGetNodeFromRef(passedRef);
  const handlers = {
    ...(withHover && {onMouseEnter: on, onMouseLeave: off}),
    onClick: (e: any) => {
      e?.stopPropagation?.();
      if (isClicked) {
        handleClose();
      } else {
        toggle();
      }
    },
  };
  const handleClose = () => {
    off();
    if (isClicked) toggle();
    if (onClose) return onClose();
  };
  return (
    <SpawnAnchoredOverlayWithNode
      isOpen={isClicked || hovered}
      node={node}
      renderOverlay={(p: any) => (
        <DropDownOverlay close={handleClose} renderOverlay={renderOverlay} {...p} />
      )}
      {...overlayProps}
    >
      {cloneElement(children, {
        ...handlers,
        ...(setChildProps &&
          setChildProps({
            isOpen: isClicked || hovered,
            isClicked,
            toggle,
            hovered,
            close: handleClose,
          })),
        ref,
      })}
    </SpawnAnchoredOverlayWithNode>
  );
});
