const getOffsetParent = (node: HTMLElement | SVGElement): HTMLElement | null => {
  if (!node) return null;
  // svgs don't have an offsetParent!
  let currentNode: HTMLElement | null =
    node.tagName === "svg" ? (node.parentNode as HTMLElement | null) : (node as HTMLElement);
  if (!currentNode) return null;
  const offsetParent = currentNode.offsetParent as HTMLElement | null;
  if (offsetParent) return offsetParent;
  if (currentNode.tagName === "BODY") return null;
  const position = window.getComputedStyle(currentNode).getPropertyValue("position");
  if (position === "fixed") return currentNode.parentNode as HTMLElement;
  return null;
};

export const getZIndex = (node: HTMLElement | SVGElement) => {
  if (!node) return;
  let currentNode: HTMLElement | null = node as HTMLElement;
  let lastZIndex = 0;
  while ((currentNode = getOffsetParent(currentNode))) {
    const zIndexAsString = window.getComputedStyle(currentNode).getPropertyValue("z-index");
    if (zIndexAsString !== "auto") {
      lastZIndex = parseInt(window.getComputedStyle(currentNode).getPropertyValue("z-index"), 10);
    }
  }
  return lastZIndex;
};
