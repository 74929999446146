import {cx} from "@cdx/common";
import {iconStyles} from "@cdx/ds/components/DSIcon/DSIcon.css";
import {cardStateColors} from "@cdx/ds/css/tokens";
import {forwardRef, useLayoutEffect, useRef} from "react";

const clockOutline = (
  <path
    className="stroke"
    d="M12 4.5a8 8 0 1 0 0 16c4 0 8-3 8-8a8 8 0 0 0-8-8Zm0 0v-2M10 2h4"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
);

type AnimatedStopWatchIconProps = {
  size?: keyof (typeof iconStyles)["sizes"] | null;
  start?: Date;
};

const AnimatedStopWatchIcon = ({size, start}: AnimatedStopWatchIconProps) => {
  const pathRef = useRef<SVGPathElement>(null);
  const startTime = start ? start.getTime() : null;

  useLayoutEffect(() => {
    const innerStart = startTime || new Date().getTime();
    const duration = 60000;
    const doIt = () => {
      const elapsed = new Date().getTime() - innerStart;
      const progress = (elapsed % duration) / duration;
      const angle = progress * 360;
      pathRef.current!.setAttribute("transform", `rotate(${angle}, 12, 12)`);
    };
    const intervalId = setInterval(doIt, 1000);
    doIt();
    return () => clearInterval(intervalId);
  }, [startTime]);

  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={cx(size && iconStyles.sizes[size], iconStyles.mode.block, iconStyles.base)}
    >
      {clockOutline}
      <path
        className="stroke minute"
        d="M12 12V7"
        strokeLinecap="round"
        strokeLinejoin="round"
        ref={pathRef}
      />
    </svg>
  );
};

export default AnimatedStopWatchIcon;

type ClockIconWithProgressProps = {
  size?: keyof (typeof iconStyles)["sizes"] | null;
  progress: number;
  className?: string;
};

const centerX = 12;
const centerY = 12.5;
const radius = 6;
export const StopWatchIconWithProgress = forwardRef<SVGSVGElement, ClockIconWithProgressProps>(
  ({size, progress, className, ...rest}, ref) => {
    const startAngle = 0;
    const endAngle = Math.min(Math.max(progress, 0), 1) * Math.PI * 2;

    // Calculate the end point of the arc
    const x = centerX + radius * Math.cos(endAngle - Math.PI / 2);
    const y = centerY + radius * Math.sin(endAngle - Math.PI / 2);
    const lx = centerX + (radius - 0.5) * Math.cos(endAngle - Math.PI / 2);
    const ly = centerY + (radius - 0.5) * Math.sin(endAngle - Math.PI / 2);
    const largeArcFlag = endAngle - startAngle > Math.PI ? 1 : 0;

    const d = `
    M ${centerX} ${centerY}
    L ${centerX} ${centerY - radius}
    A ${radius} ${radius} 0 ${largeArcFlag} 1 ${x} ${y}
    L ${centerX} ${centerY}
  `;

    return (
      <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        {...rest}
        className={cx(
          size && iconStyles.sizes[size],
          iconStyles.mode.block,
          iconStyles.base,
          className
        )}
        ref={ref}
      >
        {clockOutline}
        <path fill={cardStateColors.started100} d={d} />
        <path
          className="stroke minute"
          d={`M${centerX} ${centerY} L ${lx} ${ly}`}
          strokeLinecap="round"
        />
      </svg>
    );
  }
);
