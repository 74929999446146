import {Box, Col, css, DSIconButton, DSIconSmile, Row, Text} from "@cdx/ds";
import {ResolvableEntry, ResolvableEntryId} from "../../../../cdx-models/ResolvableEntry";
import {ResolvableBag} from "./convo-utils";
import Emoji from "../../../../lib/emoji/EmojiRenderer";
import uiClasses from "@cdx/common/xui/ui.css";
import {lazy, useMemo, useRef} from "react";
import {
  ResolvableEntryReaction,
  ResolvableEntryReactionId,
} from "../../../../cdx-models/ResolvableEntryReaction";
import {UserId} from "../../../../cdx-models/User";
import {api} from "../../../../lib/api";
import {useLocalStorageState} from "../../../../lib/storage";
import {WithAllFieldsLoaded} from "../../../../cdx-models/utils/MakeModel";
import {ArrowOverlay, DropDownForChild, TooltipForChild} from "@cdx/common";
import DSAvatar from "../../../../components/DSAvatar/DSAvatar";
import FromNow from "../../../../components/FromNow";

type ReactionValue = WithAllFieldsLoaded<ResolvableEntryReaction>["value"];

const reactionValToKey = (val: ResolvableEntryReaction["value"]): string => val.value!;

const valToIcon = (val: ResolvableEntryReaction["value"]) =>
  val.type === "emoji" ? (
    <Emoji className={css({textType: "label16", width: "1em"})} block withShadow>
      {val.value}
    </Emoji>
  ) : (
    <Box textType="label16" width="1em" />
  );

type ReactionGroup = {
  value: ResolvableEntryReaction["value"];
  reactions: ResolvableEntryReaction[];
  meVotedId: ResolvableEntryReactionId | null;
};

const useReactions = (entry: ResolvableEntry, meId: UserId | null) => {
  return useMemo(() => {
    const counts = new Map<string, ReactionGroup>();
    const myKeys = new Map<string, ResolvableEntryReactionId>();
    for (const reaction of entry.reactions) {
      const meVotedId =
        reaction.$meta.getId("user") === meId ? reaction.$meta.get("id", null) : null;
      const key = reactionValToKey(reaction.value);
      if (!key) continue;
      if (meVotedId) myKeys.set(key, meVotedId);
      const exist = counts.get(key);
      if (!exist) {
        counts.set(key, {value: reaction.value, reactions: [reaction], meVotedId});
      } else {
        exist.reactions.push(reaction);
        if (meVotedId) exist.meVotedId = meVotedId;
      }
    }
    return [[...counts.values()], myKeys] as const;
  }, [entry.reactions, meId]);
};

const ReactionUsers = ({group}: {group: ReactionGroup}) => {
  return (
    <Col sp="4px" pa="8px">
      {group.reactions.map((r) => (
        <Row sp="12px" align="center" key={r.user.id}>
          <Row sp="4px" align="center">
            <DSAvatar user={r.user} size={16} />
            <Text type="label12">{r.user.name}</Text>
          </Row>
          <Text ml="auto" type="label11light" color="secondary" relative top="1px">
            <FromNow date={r.createdAt} />
          </Text>
        </Row>
      ))}
    </Col>
  );
};

const ReactionButton = ({group, entryId}: {group: ReactionGroup; entryId: ResolvableEntryId}) => {
  const handleClick = () => {
    if (group.meVotedId) {
      return api.mutate.resolvables.removeReaction({reactionId: group.meVotedId});
    } else {
      return api.mutate.resolvables.addReaction({entryId, value: group.value});
    }
  };
  return (
    <TooltipForChild delayed tooltip={() => <ReactionUsers group={group} />}>
      <DSIconButton
        variant="secondary"
        size="sm"
        icon={valToIcon(group.value)}
        label={group.reactions.length > 1 ? group.reactions.length.toString() : null}
        active={Boolean(group.meVotedId)}
        onClick={handleClick}
        style={{borderRadius: 8, padding: 6}}
        skipSuccessIcon
      />
    </TooltipForChild>
  );
};

const defaultReactions: ReactionValue[] = [
  {type: "emoji", value: "👍"},
  {type: "emoji", value: "🎉"},
  {type: "emoji", value: "✅"},
];

const deduplicateAndReduceToThree = (list: ReactionValue[]) => {
  const nextSet = new Set<string>();
  const nextUniqueList: ReactionValue[] = [];
  for (const item of list) {
    const key = reactionValToKey(item);
    if (nextSet.has(key)) continue;
    nextSet.add(key);
    nextUniqueList.push(item);
  }
  return nextUniqueList.slice(0, 3);
};

const useLastOptions = () => {
  const [rawVals, setVals] = useLocalStorageState("last-reactions");
  const initialVals = useRef(rawVals ?? []);
  const vals = initialVals.current;
  const shownVals =
    vals.length < 3 ? deduplicateAndReduceToThree([...vals, ...defaultReactions]) : vals;
  const onReaction = (next: ReactionValue) => {
    setVals(deduplicateAndReduceToThree([next, ...vals]));
  };
  return [shownVals, onReaction] as const;
};

const EmojiPicker = lazy(
  () =>
    import(/* webpackChunkName: "EmojiPicker" */ "../../../../pages/project-settings/EmojiPicker")
);

const EmojiPickerOverlay = (props: {overlayProps: any; onChange: (emoji: string) => void}) => {
  const {onChange, overlayProps} = props;
  const handleChange = (color: string) => {
    onChange(color);
    overlayProps.close();
  };

  return (
    <ArrowOverlay bg="white" arrowSize="sm" {...overlayProps}>
      <EmojiPicker value="" onChange={handleChange} />
    </ArrowOverlay>
  );
};

type ReactOptionsProps = {
  entryId: ResolvableEntryId;
  myReactionKeys: Map<string, ResolvableEntryReactionId>;
  nudgeDown?: boolean;
};
const ReactOptions = ({entryId, myReactionKeys, nudgeDown}: ReactOptionsProps) => {
  const [vals, onReaction] = useLastOptions();

  const handleReaction = (value: ReactionValue) => {
    const potentialId = myReactionKeys.get(reactionValToKey(value));
    if (potentialId) {
      return api.mutate.resolvables.removeReaction({reactionId: potentialId});
    } else {
      onReaction(value);
      return api.mutate.resolvables.addReaction({entryId, value});
    }
  };

  return (
    <Row
      ml="auto"
      relative
      style={{marginTop: -30, top: nudgeDown ? 22 : 10}}
      className={uiClasses.hideElement}
      sp="8px"
      bg="foreground"
      colorTheme="gray25"
      rounded={8}
      border="1px"
      px="8px"
      py="4px"
    >
      {vals.map((val) => (
        <DSIconButton
          key={reactionValToKey(val)}
          icon={valToIcon(val)}
          variant="tertiary"
          size="sm"
          negatePadding
          onClick={() => handleReaction(val)}
          style={{padding: 6}}
          skipSuccessIcon
        />
      ))}
      <DropDownForChild
        renderOverlay={(overlayProps: any) => (
          <EmojiPickerOverlay
            overlayProps={overlayProps}
            onChange={(emoji) => handleReaction({type: "emoji", value: emoji})}
          />
        )}
        overlayProps={{placement: "bottom", distanceFromAnchor: 10}}
        setChildProps={({isOpen}) => ({tooltip: isOpen ? null : "Pick an emoji"})}
      >
        <TooltipForChild tooltip={null}>
          <DSIconButton
            icon={<DSIconSmile size={20} style={{margin: -2}} />}
            variant="tertiary"
            size="sm"
            negatePadding
            style={{padding: 6}}
          />
        </TooltipForChild>
      </DropDownForChild>
    </Row>
  );
};

type ReactionAreaProps = {
  resBag: ResolvableBag;
  entry: ResolvableEntry;
};
const ReactionArea = ({resBag, entry}: ReactionAreaProps) => {
  const {root} = resBag;
  const meId = root.loggedInUser.$meta.get("id", null);
  const entryId = entry.$meta.get("entryId", null)!;
  const [reactionsGroups, myReactionKeys] = useReactions(entry, meId);
  return (
    <Row sp="16px" align="start" px="16px">
      {reactionsGroups.length > 0 ? (
        <Row wrap sp="4px" relative style={{marginTop: -12, top: 6}}>
          {reactionsGroups.map((r) => (
            <ReactionButton group={r} entryId={entryId} key={reactionValToKey(r.value)} />
          ))}
        </Row>
      ) : null}
      <ReactOptions
        entryId={entryId}
        myReactionKeys={myReactionKeys}
        nudgeDown={reactionsGroups.length > 0}
      />
    </Row>
  );
};

export default ReactionArea;
