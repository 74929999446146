import {
  $applyNodeReplacement,
  DOMConversionMap,
  DOMExportOutput,
  DecoratorNode,
  LexicalEditor,
  NodeKey,
} from "lexical";
import {useRoot} from "../../../lib/mate/mate-utils";
import {Box, css} from "@cdx/ds";
import dsStyles from "@cdx/ds/css/index.css";
import useLexicalDecoratorNode from "@cdx/ds/components/DSTextEditor/useLexicalDecoratorNode";
import {shrinker} from "@cdx/common";

export class DeckReferenceNode extends DecoratorNode<JSX.Element> {
  __deckSeq: number;

  static getType() {
    return "cdxDeckReference";
  }

  static clone(node: DeckReferenceNode) {
    return new DeckReferenceNode(node.__deckSeq, node.__key);
  }

  static importJSON(serializedNode: any): DeckReferenceNode {
    const node = $createDeckReferenceNode(serializedNode.deckSeq);
    return node;
  }

  constructor(deckSeq: number, key?: NodeKey) {
    super(key);
    this.__deckSeq = deckSeq;
  }

  exportJSON() {
    return {
      type: "cdxDeckReference",
      deckSeq: this.__deckSeq,
      version: 1,
    };
  }

  exportDOM(editor: LexicalEditor): DOMExportOutput {
    const element = document.createElement("span");
    element.setAttribute("data-lexical-cdx-deckref-seq", this.__deckSeq.toString());
    element.innerText = this.getTextContent();
    return {element};
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute("data-lexical-cdx-deckref-seq")) {
          return null;
        }
        return {
          conversion: (innerNode: HTMLElement) => {
            const deckSeq = innerNode.getAttribute("data-lexical-cdx-deckref-seq");
            if (deckSeq) {
              const node = $createDeckReferenceNode(Number(deckSeq));
              return {node};
            } else {
              return null;
            }
          },
          priority: 1,
        };
      },
    };
  }

  createDOM(): HTMLElement {
    const el = document.createElement("span");
    el.className = dsStyles.userSelect.none;
    return el;
  }

  updateDOM(): false {
    return false;
  }

  getTextContent(): string {
    const sib = this.getPreviousSibling();
    const hasSpace = sib && sib.getTextContent().match(/\s$/);
    return `${!sib || hasSpace ? "" : " "}$[deck:${this.__deckSeq}]`;
  }

  decorate(): JSX.Element {
    return <DeckReference deckSeq={this.__deckSeq} nodeKey={this.__key} />;
  }
}

export function $isDeckReferenceNode(node: any): node is DeckReferenceNode {
  return node instanceof DeckReferenceNode;
}

export function $createDeckReferenceNode(deckSeq: number) {
  return $applyNodeReplacement<DeckReferenceNode>(new DeckReferenceNode(deckSeq));
}

const DeckReference = ({deckSeq, nodeKey}: {deckSeq: number; nodeKey: string}) => {
  const root = useRoot();
  const rawDeck = root.account.$meta.find("decks", {
    accountSeq: deckSeq,
  })[0];
  const deck = (rawDeck?.$meta.isDeleted() ? null : rawDeck) ?? null;
  const {selectionClassName} = useLexicalDecoratorNode(nodeKey);

  const title = deck && deck.$meta.get("title", null);
  return (
    <span
      className={css(
        {colorTheme: "purple50", bg: "foreground", color: "primary", rounded: 4},
        selectionClassName
      )}
      title={title ?? undefined}
    >
      <Box weight="normal" as="span">
        $$
      </Box>
      <Box as="span" fontFamily="ebony" style={{fontSize: `${16 / 14}em`}} weight="bold">
        {deck ? (title ? shrinker(title, 21) : "loading") : "Not found"}
      </Box>
    </span>
  );
};
