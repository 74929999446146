import {forwardRef, createElement, Fragment} from "react";
import {XCol, XRow, XText, XPush} from "../xui";
import Ui from "../ui2";
import Avatar from "../Avatar";
import {api} from "../../lib/api";
import {useInstance, useRoot} from "../../lib/mate/mate-utils";
import {IconWithMilestoneTooltipById} from "../../pages/milestones/MilestoneTooltip";
import PieChart from "../PieChart";
import FromNow from "../FromNow";
import {getTotalTimeForTimeTrackingSum} from "../../features/time-tracking/time-tracking-utils";
import {cx, formatMsAsMin, WithTooltip, IconGateClosed, TooltipForChild} from "@cdx/common";
import styles from "./card.css";
import {getChildCards} from "../../features/workflows/workflow-utils";
import {DisplayFile} from "../../features/card-container/sidebars/attachments";
import {OtherTag, ProjectTag} from "../Markdown/CardTags";
import {DependencyOverlay} from "../../features/dependencies/DependencyArea";
import {PotionIcon} from "./PotionIcon";
import {UpvoteTooltip} from "../../features/upvotes/upvote-utils";
import {DueDateCardHintIcon} from "../../features/due-date/DueDateCardHintIcon";
import {getMetaKeyLabel} from "@cdx/common/device-utils";
import {
  Box,
  Col,
  css,
  DSIconAttachment,
  DSIconGhost,
  DSIconPyramid,
  DSShortcutKey,
  Row,
  Text,
} from "@cdx/ds";
import dsStyles from "@cdx/ds/css/index.css";
import uiClasses from "@cdx/common/xui/ui.css";
import {
  sprintLabel,
  ThemedSprintIcon,
  ThemedSprintIconWithTooltipAndMetaClick,
} from "../../features/milestones/sprint-utils";
import {msColorThemes} from "../../pages/milestones/milestone-theme.css";
import {useLazyHandQueueOverlay} from "../../features/card-container/HandQueueOverlay";
import {ThemedMilestoneIcon} from "../../features/milestones/milestone-utils";
import {DeckPickerImage} from "../../features/card-container/DSDeckPicker";
import {getAllCardPos} from "../../features/hand-queue/hand-queue-utils";
import DSAvatar from "../DSAvatar/DSAvatar";
import {StopWatchIconWithProgress} from "../../features/card-container/AnimatedStopWatchIcon";

const ageSteps = {fresh: 3600, medium: 43200};
export const HourGlassTooltip = ({lastUpdateTime}) => (
  <Col pa="8px" colorTheme="active600">
    <Box bold size={12} color="secondary">
      Last updated
    </Box>
    <Box size={12} color="primary">
      <FromNow date={lastUpdateTime} />
    </Box>
  </Col>
);

const SumTime = ({userEntries}) => {
  const msCount = userEntries.reduce((m, ue) => m + ue.time, 0);
  return (
    <Box pt={1}>
      <Row
        sp={2}
        align="center"
        borderWidth={0}
        borderTopWidth={1}
        className={uiClasses.borderColor.active400}
        pt={1}
      >
        <TooltipForChild tooltip="Sum of all users">
          <div
            className={cx(
              dsStyles.textAlign.center,
              dsStyles.width["1rem"],
              uiClasses.color.active200
            )}
          >
            Σ
          </div>
        </TooltipForChild>
        <XText size={1} color="active_l4">
          {formatMsAsMin(msCount)}
        </XText>
      </Row>
    </Box>
  );
};

export const trackedTimeToProgress = (msCount) => {
  return Math.min(0.99, 1 - Math.exp(-msCount / 20000000));
};

export const TrackingTooltip = ({cardId}) => {
  const card = useInstance("card", cardId);
  const getTimes = () => {
    const userTimes = card.$meta.find("userTimeTrackingSums", {$order: "-sumMs"});
    const childCards = getChildCards(card);
    if (!childCards.length) {
      return userTimes.map((ut) => ({user: ut.user, time: getTotalTimeForTimeTrackingSum(ut)}));
    } else {
      const allTimes = [...userTimes];
      childCards.forEach((child) =>
        allTimes.push(...child.$meta.find("userTimeTrackingSums", {$order: "-sumMs"}))
      );
      const byUser = {};
      allTimes.forEach((ut) => {
        const key = ut.user.id;
        const time = getTotalTimeForTimeTrackingSum(ut);
        const exist = byUser[key];
        if (exist) {
          exist.time += time;
        } else {
          byUser[key] = {user: ut.user, time};
        }
      });
      return Object.values(byUser);
    }
  };
  const times = getTimes();
  return (
    <XCol sp={1} px={1} py={0}>
      <XText preset="bold" size={0} color="white">
        Tracked time
      </XText>
      <XCol sp={0}>
        {times.map(({user, time}, i) => (
          <XRow sp={1} align="center" key={user.$meta.isLoaded ? user.id : `_${i}`}>
            <Avatar size={16} user={user} />
            <XText size={1} color="active_l4">
              {formatMsAsMin(time)}
            </XText>
          </XRow>
        ))}
        {times.length > 1 && <SumTime userEntries={times} />}
      </XCol>
    </XCol>
  );
};

export const ProjectTagTooltip = ({tags}) => (
  <XCol sp={1} px={0} py={0}>
    <XText preset="bold" size={0} color="white">
      Project tags
    </XText>
    <XCol sp={0}>
      {tags.map((t, i) =>
        t.isDeprecated ? (
          <OtherTag key={i} tag={t.tag} onDark />
        ) : (
          <ProjectTag key={i} tagObject={t} />
        )
      )}
    </XCol>
  </XCol>
);

export const ProjectTagHint = forwardRef(({tagColors, isSmall, ...rest}, ref) => (
  <XRow align="center" style={{height: isSmall ? 14 : 16}} {...rest} ref={ref}>
    {tagColors.length === 1 ? (
      <div className={styles.singleMasterTag} style={{backgroundColor: tagColors[0]}} />
    ) : (
      <PieChart data={tagColors.map((color) => ({value: 1, color}))} width={10} height={10}>
        {({Comp, props, sliceData, index}) => (
          <Comp key={index} {...props} fill={sliceData.color} />
        )}
      </PieChart>
    )}
  </XRow>
));

const UserLine = ({userId, pos}) => {
  const user = useInstance("user", userId);
  return (
    <Row sp="8px" align="center" key={userId}>
      <DSAvatar size={16} user={user} />
      <Text type="label12" color="primary">
        {user.name}
      </Text>
      <Text type="label12light" color="primary" ml="auto">
        #{pos}
      </Text>
    </Row>
  );
};

const HandQueueTooltip = ({cardId}) => {
  const root = useRoot();
  const cardPosMap = getAllCardPos(cardId, root.account);
  if (!cardPosMap) return <div>Not in any hand</div>;
  return (
    <Col sp="8px" pa="8px">
      <Text type="label12light" color="primary" as="h3">
        In the hand of
      </Text>
      <Col sp="4px">
        {[...cardPosMap].map(([userId, pos], idx) => (
          <UserLine userId={userId} pos={pos} key={idx} />
        ))}
      </Col>
    </Col>
  );
};

/**
 *
 * @param {{
 *  position: number,
 *  type: "self" | "owner" | "other"
 *  coverType?: "white" | "gray" | "dark" | null
 *  cardId: import("../../cdx-models/Card").CardId
 * }} props
 * @returns ReactElement
 */
export const HandPositionHint = ({position, coverType, cardId, type}) => {
  const {modal, setModalOpen} = useLazyHandQueueOverlay(cardId);
  const onDark = coverType && coverType !== "white";
  return (
    <TooltipForChild tooltip={() => <HandQueueTooltip cardId={cardId} />}>
      <Box relative>
        {modal}
        <Ui.Icon.HandSingleCard
          className={cx(
            styles.handIconAsBg.base,
            styles.handIconAsBg.type[onDark ? "dark" : "light"]
          )}
          onClick={(e) => {
            setModalOpen(true);
            e.preventDefault();
            e.stopPropagation();
          }}
        />
        {position <= 7 && (
          <Col absolute className={styles.handPos} pointerEvents="none">
            {position}
          </Col>
        )}
      </Box>
    </TooltipForChild>
  );
};

export const AttachmentTooltip = ({cardId}) => {
  const card = useInstance("card", cardId);
  if (!card) return null;
  return (
    <XCol sp={2} pa={1}>
      {card.attachments.map((att, idx) => (
        <DisplayFile key={att.$meta.get("id", idx)} attachment={att} file={att.file} />
      ))}
    </XCol>
  );
};

const CardDependencyOverlay = ({cardId}) => {
  const card = useInstance("card", cardId);
  return <DependencyOverlay card={card} />;
};

const RecentUpdateHint = ({lastUpdateTime, diffInSeconds, onDark, gotoPanel}) => {
  const props = {
    className: cx(
      styles.bottomHintIcons.md,
      styles.bottomHintIcons.hourglass,
      onDark && styles.bottomHintIcons.hourglassOnDark
    ),
    onClick:
      process.env.REACT_APP_MODE !== "open" &&
      ((e) => {
        gotoPanel("history");
        e.preventDefault();
        e.stopPropagation();
      }),
  };

  return (
    <TooltipForChild tooltip={<HourGlassTooltip lastUpdateTime={lastUpdateTime} />}>
      {diffInSeconds < ageSteps.fresh ? (
        <Ui.Icon.HourglassFull {...props} />
      ) : (
        <Ui.Icon.HourglassHalf style={{opacity: 0.5}} {...props} />
      )}
    </TooltipForChild>
  );
};

export const shouldShowZoneHint = (bag) => {
  const {zoneInfo, milestoneId, sprintId, shownManualOrder} = bag;
  if (!zoneInfo) return false;
  if (!zoneInfo.withZoneIconOnCard) return false;
  if (zoneInfo.milestone && !milestoneId && shownManualOrder !== "milestone") return true;
  if (zoneInfo.sprint && !sprintId && shownManualOrder !== "sprint") return true;
  if (zoneInfo.deck && shownManualOrder !== "deck") return true;
  return false;
};

const ZoneHintTooltip = ({bag}) => {
  const {cardId, zoneInfo, milestoneState, milestoneColor, sprintState, sprintColor} = bag;
  const card = useInstance("card", cardId);
  return (
    <Col pa="8px" sp="8px">
      <Text type="label11Caps" color="secondary">
        Card Zones
      </Text>
      <Col sp="4px">
        {zoneInfo.sprint && card.sprint && (
          <Row sp="4px" align="baseline">
            <Text type="label14" color="primary">
              {zoneInfo.sprint}
            </Text>
            <Text type="label12light" color="primary">
              in{" "}
              <ThemedSprintIcon
                size={16}
                onDark
                theme={msColorThemes[sprintColor] || msColorThemes.blue}
                sprintState={sprintState}
                inline
              />
              {sprintLabel(card.sprint)}
            </Text>
          </Row>
        )}
        {zoneInfo.milestone && card.milestone && (
          <Row sp="4px" align="baseline">
            <Text type="label14" color="primary">
              {zoneInfo.milestone}
            </Text>
            <Text type="label12light" color="primary">
              in{" "}
              <ThemedMilestoneIcon
                size={16}
                onDark
                milestoneState={milestoneState}
                theme={msColorThemes[milestoneColor] || msColorThemes.blue}
                inline
              />
              {card.milestone.name}
            </Text>
          </Row>
        )}
        {zoneInfo.deck && card.deck && (
          <Row sp="4px" align="baseline">
            <Text type="label14" color="primary">
              {zoneInfo.deck}
            </Text>
            <Text type="label12light" color="primary">
              in
            </Text>
            <Row sp="4px" align="baseline">
              <DeckPickerImage
                deck={card.deck}
                className={css({mt: "-4px", position: "relative", top: "4px"})}
              />
              <Text type="label11" color="primary">
                {card.deck.title}
              </Text>
            </Row>
          </Row>
        )}
      </Col>
    </Col>
  );
};

export const ZoneHint = ({bag}) => (
  <TooltipForChild tooltip={<ZoneHintTooltip bag={bag} />}>
    <DSIconPyramid size={16} className={css({color: "secondary"})} />
  </TooltipForChild>
);

export const getBottomHints = (bag) => {
  const {
    cardId,
    isPrivate,
    gotoPanel,
    attachmentCount,
    upvoteInfo,
    milestoneId,
    milestoneColor,
    milestoneState,
    sprintId,
    sprintColor,
    sprintState,
    enrichedProjectTags,
    lastUpdateTime,
    totalTrackingTime,
    handInfo,
    coverInfo,
    depInfo,
    dueDateInfo,
    canModify,
    cardContainerKey,
    zoneInfo,
  } = bag;

  const tagColors = enrichedProjectTags.map((t) => t.color).filter(Boolean);
  const diffInSeconds = (new Date().getTime() - lastUpdateTime.getTime()) / 1000;
  const onDark = coverInfo && coverInfo.type !== "white";

  const lefts = [
    dueDateInfo && (
      <DueDateCardHintIcon
        {...dueDateInfo}
        onClick={
          process.env.REACT_APP_MODE !== "open" &&
          ((e) => {
            gotoPanel("props");
            e.preventDefault();
            e.stopPropagation();
          })
        }
      />
    ),
    isPrivate ? (
      <TooltipForChild tooltip="Not in a deck. Only visible to you.">
        <div>
          <DSIconGhost size={20} style={{margin: -3}} />
        </div>
      </TooltipForChild>
    ) : null,
    handInfo ? (
      <HandPositionHint
        position={handInfo.position}
        cardId={cardId}
        type={handInfo.type}
        coverType={coverInfo?.type}
      />
    ) : null,
    diffInSeconds < ageSteps.medium ? (
      <RecentUpdateHint
        lastUpdateTime={lastUpdateTime}
        diffInSeconds={diffInSeconds}
        onDark={onDark}
        gotoPanel={gotoPanel}
      />
    ) : null,
    totalTrackingTime ? (
      <TooltipForChild tooltip={<TrackingTooltip cardId={cardId} />}>
        <StopWatchIconWithProgress size={20} progress={trackedTimeToProgress(totalTrackingTime)} />
      </TooltipForChild>
    ) : null,
    depInfo ? (
      <TooltipForChild
        tooltip={
          depInfo.dependenciesEnabled ? (
            <CardDependencyOverlay cardId={cardId} />
          ) : (
            "This card has a disabled dependency. Upgrade to the Pro plan to use this feature."
          )
        }
        delayed
        placement="left"
        {...(depInfo.dependenciesEnabled && {bg: "gray700"})}
      >
        <IconGateClosed size="lg" style={depInfo.dependenciesEnabled ? null : {opacity: 0.5}} />
      </TooltipForChild>
    ) : null,
    attachmentCount > 0 ? (
      <TooltipForChild
        tooltip={() => <AttachmentTooltip cardId={cardId} />}
        delayed
        bg="gray600"
        placement="left"
      >
        <DSIconAttachment
          size={16}
          onClick={(e) => {
            gotoPanel("attachments");
            e.preventDefault();
            e.stopPropagation();
          }}
        />
      </TooltipForChild>
    ) : null,
    upvoteInfo.totalCount ? (
      <TooltipForChild
        tooltip={() => (
          <UpvoteTooltip
            card={api.getModel({modelName: "card", id: cardId})}
            upvoteInfo={upvoteInfo}
          />
        )}
        delayed
        bg="gray700"
        placement="bottom"
      >
        <Row style={{marginLeft: -2, marginRight: -2}} align="center">
          <PotionIcon
            size={16}
            active={Boolean(upvoteInfo.myUpvoteId)}
            progress={Math.min(1, upvoteInfo.totalCount / 20)}
          />
          <XText
            preset="bold"
            size={0}
            color={onDark ? "gray100" : "gray600"}
            className={cx(
              coverInfo ? styles.cardTextInner.withCover : styles.cardTextInner.noCover,
              coverInfo && styles.cardTextInner.byCoverType[coverInfo.type]
            )}
          >
            {upvoteInfo.totalCount}
          </XText>
        </Row>
      </TooltipForChild>
    ) : null,
  ];

  const rights = [
    tagColors && tagColors.length > 0 ? (
      <WithTooltip
        tooltip={<ProjectTagTooltip tags={enrichedProjectTags} />}
        options={{bg: "gray600"}}
        as={ProjectTagHint}
        tagColors={tagColors}
      />
    ) : null,
    shouldShowZoneHint(bag) ? <ZoneHint bag={bag} /> : null,
    milestoneId && milestoneColor ? (
      <IconWithMilestoneTooltipById
        milestoneId={milestoneId}
        color={milestoneColor}
        milestoneState={milestoneState}
        onDark={onDark}
        propChangeInfo={canModify && {cardId, cardContainerKey}}
        openMsOnMetaClick={process.env.REACT_APP_MODE !== "open" && {cardId}}
        tooltip={
          process.env.REACT_APP_MODE !== "open" && (
            <>
              <DSShortcutKey>m</DSShortcutKey> to change, {getMetaKeyLabel()} + click to open in
              Milestone view
            </>
          )
        }
        zoneInfo={zoneInfo?.milestone}
      />
    ) : null,
    sprintId && sprintColor ? (
      <ThemedSprintIconWithTooltipAndMetaClick
        theme={msColorThemes[sprintColor] || msColorThemes.blue}
        cardId={cardId}
        sprintId={sprintId}
        onDark={onDark}
        sprintState={sprintState}
        propChangeInfo={canModify && {cardId, cardContainerKey}}
        zoneInfo={zoneInfo?.sprint}
        withShortcutHelp
      />
    ) : null,
  ];
  return [lefts.filter(Boolean), rights.filter(Boolean)];
};

export const CardBottomHints = ({lefts, rights, coverInfo}) => {
  const leftComp = createElement(Fragment, {}, ...lefts.slice(0, 4 - rights.length));
  const rightComp = createElement(Fragment, {}, ...rights);
  return (
    <XRow
      className={cx(
        styles.bottomHints.base,
        coverInfo && styles.bottomHints.withCover,
        coverInfo ? styles.bottomHints.byCoverType[coverInfo.type] : null
      )}
      align="center"
      px={1}
      pb={2}
      sp={1}
      relative
    >
      {leftComp}
      <XPush />
      {rightComp}
    </XRow>
  );
};
