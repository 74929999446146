import effort from "./categories/effort";
import priority from "./categories/priority";
import userCategories from "./categories/user-categories";
import card from "./categories/cards";
import deck from "./categories/deck";
import status from "./categories/status";
import tags from "./categories/tags";
import contents from "./categories/contents";
import milestone from "./categories/milestone";
import project from "./categories/project";
import metaCategories from "./categories/meta";
import sprints from "./categories/sprint";
import spaceCategory from "./categories/space";
import zoneCategory from "./categories/zone";
import queueCategory from "./categories/queue";
import {SearchCategory} from "./search-types";

export const searchCategories = [
  card,
  effort,
  priority,
  status,
  ...tags,
  ...userCategories,
  deck,
  milestone,
  ...sprints,
  project,
  spaceCategory,
  zoneCategory,
  queueCategory,
  ...metaCategories,
  ...contents,
] satisfies SearchCategory<any>[];

export const searchCategoriesByKey = searchCategories.reduce(
  (m, c) => {
    m[c.key] = c;
    return m;
  },
  {} as Record<string, SearchCategory<any>>
);
