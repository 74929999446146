import {CSSProperties, forwardRef} from "react";
import {ReactComponent as ComboIcon} from "./assets/convo-combo-icon.svg";
import cx from "../../utils/cx";
import {iconStyles} from "./DSIcon.css";
import {Box, css} from "../Box/Box";
import {conversationIconStyles as styles} from "./DSConversationIcon.css";

type IconType = keyof typeof styles.type;

type DSConversationIconProps = {
  active?: boolean;
  size: keyof typeof iconStyles.sizes | null;
  hasPastConvos?: boolean;
  convoInfo?: any;
  style?: CSSProperties;
  className?: string;
};

export const DSConversationIcon = forwardRef<HTMLElement, DSConversationIconProps>(
  ({convoInfo, hasPastConvos, size = 24, active = false, className, ...rest}, ref) => {
    const getType = (): IconType => {
      if (!convoInfo) return hasPastConvos ? "hasPast" : "default";
      if (convoInfo.numNewComments > 0) {
        return convoInfo.snoozingResIds.length ? "snoozingUnseen" : "unseen";
      }
      if (convoInfo.snoozingResIds.length) return "snoozing";
      if (convoInfo.notiResIds.length) return "undismissed";
      if (convoInfo.userConvoResIds) return "open";
      if (convoInfo.orgConvoResIds) return "withoutMe";
      return "default";
    };
    const type = getType();

    return (
      <Box
        relative
        className={css(
          {filter: convoInfo?.minSnoozeUntil ? "grayscaleLowContrast" : undefined},
          size && iconStyles.sizes[size],
          styles.type[type][active ? "active" : "default"],
          className
        )}
        ref={ref}
        {...rest}
      >
        <ComboIcon
          className={cx(iconStyles.base, styles.svg)}
          {...{alt: type}} // alt is semantically incorrect (we'd probably want to include a title tag), but the frontend events click event stuff relies on it
        />

        {convoInfo?.numNewComments > 0 && (
          <Box absolute inset="0" textAlign="center" weight="bold" className={styles.count}>
            {convoInfo.numNewComments}
          </Box>
        )}
      </Box>
    );
  }
);
