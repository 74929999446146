import {ReactNode, createContext, useContext, useEffect, useRef, useState} from "react";
import {isMac, isWithMetaKey} from "./device-utils";
import MiniEvent from "./mini-event";
import {isEventWithinInteractiveNode} from "./dom-helpers";

// note withShift and key shouldn't be used together.
// note withAlt and key shouldn't be used together.
// const key = 'a' or 'A'
// const code = 'Digit1'

type Store = {
  setBlocked: (v: boolean) => void;
  onKeyDown: (e: KeyboardEvent) => void;
  registerKey: (data: EventData) => () => void;
};

const KeyPressContext = createContext<Store>(null as any as Store);

export const KeyPressContextProvider = ({children}: {children: ReactNode}) => {
  const [store] = useState(createStore);
  const parentCtx = useContext(KeyPressContext);
  useEffect(() => {
    if (parentCtx) {
      parentCtx.setBlocked(true);
      return () => parentCtx.setBlocked(false);
    }
  }, [parentCtx]);
  useEffect(() => {
    window.addEventListener("keydown", store.onKeyDown);
    return () => window.removeEventListener("keydown", store.onKeyDown);
  }, [store]);
  return <KeyPressContext.Provider value={store}>{children}</KeyPressContext.Provider>;
};

type Fn = (e: KeyboardEvent) => boolean | void;
type KeyOrCode = {key: string; code?: undefined} | {key?: undefined; code: string};
type KeyProps = KeyOrCode & {withAlt?: boolean; withShift?: boolean; withMod?: boolean};
type Prio = "high" | "normal" | "low";
const prios: Prio[] = ["high", "normal", "low"];

const keyPropsToStr = (keyProps: KeyProps) => {
  const {code, key, withAlt, withMod, withShift} = keyProps;
  const mods = [withMod && "_ctrl", withShift && "_shift", withAlt && "_alt"]
    .filter(Boolean)
    .join("");
  return `${code ? `c:${code}` : `k:${key}`}${mods}`;
};

type EventData = {
  keyProps: KeyProps;
  fn: Fn;
  prio: Prio;
  ignoreTarget: boolean;
  debug?: boolean;
};
type PrioMap = Map<Prio, EventData[]>;

const checkIfInInput = (e: KeyboardEvent): boolean => {
  const target = e.target! as HTMLElement;
  const nodeName = target.nodeName;
  if (nodeName === "TEXTAREA" || nodeName === "INPUT" || nodeName === "SELECT") {
    return true;
  }
  if (target.getAttribute("contenteditable")) return true;

  // if focus is on button, the button should be pressed on enter rahter than
  // execiting the other key handler
  if (e.key === "Enter") {
    if (isEventWithinInteractiveNode(e)) return true;
  }
  return false;
};

type UnsubCb = () => void;

type CurrFrameHandler = {
  addToFrame: (fn: () => UnsubCb) => UnsubCb;
};

// We want inner components to be put last onto the fn stack so they fire first
// useEffect fires in the "wrong" order, i.e. inner components are called first
// we thus collect all fns in a queue, reverse the order and execute them in the next frame
const createCurrFrameHandler = (): CurrFrameHandler => {
  let frameQueue: (() => void)[] = [];
  return {
    addToFrame: (fn) => {
      let unsubFn: UnsubCb | null = null;
      if (frameQueue.length === 0) {
        requestAnimationFrame(() => {
          frameQueue.forEach((qFn) => qFn());
          frameQueue = [];
        });
      }
      frameQueue.unshift(() => {
        unsubFn = fn();
      });
      return () => {
        if (unsubFn) {
          unsubFn();
        } else {
          frameQueue.splice(frameQueue.indexOf(fn), 1);
        }
      };
    },
  };
};

const createStore = (): Store => {
  const keyMap = new Map<string, PrioMap>();
  let blocked = false; // needed such that modals block underlying key events
  const currentFrameHandler = createCurrFrameHandler();

  return {
    setBlocked: (next) => {
      blocked = next;
    },
    onKeyDown: (e) => {
      if (blocked) return;
      if (e.repeat) return;
      if (e.key === "Shift") return;
      if (e.key === "Alt") return;
      const mods = {withAlt: e.altKey, withMod: isWithMetaKey(e), withShift: e.shiftKey};
      const prioLists: PrioMap[] = [];
      if (!mods.withAlt) {
        const keyString = keyPropsToStr({key: e.key, withMod: mods.withMod});
        const keyPrioList = keyMap.get(keyString);
        if (keyPrioList) prioLists.push(keyPrioList);
      }
      const codeString = keyPropsToStr({code: e.code, ...mods});
      const codePrioList = keyMap.get(codeString);
      if (codePrioList) prioLists.push(codePrioList);
      if (!prioLists.length) return;
      const isInInput = checkIfInInput(e);
      for (const prio of prios) {
        for (const prioList of prioLists) {
          const eventList = prioList.get(prio);
          if (!eventList) continue;
          for (const evt of eventList) {
            if (!evt.ignoreTarget && isInInput) continue;
            const res = evt.fn(e);
            if (res !== false) {
              e.preventDefault();
              return;
            }
          }
        }
      }
    },
    registerKey: (opts) => {
      return currentFrameHandler.addToFrame(() => {
        const key = keyPropsToStr(opts.keyProps);
        let byPrio = keyMap.get(key);
        if (!byPrio) {
          byPrio = new Map();
          keyMap.set(key, byPrio);
        }
        let list = byPrio.get(opts.prio);
        if (!list) {
          list = [];
          byPrio.set(opts.prio, list);
        }
        list.unshift(opts);
        return () => {
          list!.splice(list!.indexOf(opts), 1);
        };
      });
    },
  };
};

const descriptionEvents = new MiniEvent<CurrentDescriptions>();
type CurrentDescriptions = {[key: string]: {description: string; category: string}[]};
const currentDescriptions: CurrentDescriptions = {
  // N: [{description: "bla", category: "blub"}]
};

type Description = KeyOrCode & {
  description: string;
  category: string;
  withMod?: boolean;
  withShift?: boolean;
  withAlt?: boolean;
};

export const addDescription = ({
  key,
  code,
  description,
  category = "General",
  withMod,
  withShift,
  withAlt,
}: Description) => {
  const obj = {description, category};
  const k = [
    withMod && (isMac() ? "Cmd" : "Ctrl"),
    withAlt && "Alt",
    withShift && "Shift",
    key || (code && code.replace(/^(Digit|Key)/, "").toLowerCase()),
  ]
    .filter(Boolean)
    .join("+");
  (currentDescriptions[k] = currentDescriptions[k] || []).push(obj);
  descriptionEvents.emit(currentDescriptions);
  return () => {
    currentDescriptions[k].splice(currentDescriptions[k].indexOf(obj), 1);
    descriptionEvents.emit(currentDescriptions);
  };
};

export function subscribeToDescriptionEvents(cb: (d: CurrentDescriptions) => void) {
  cb(currentDescriptions);
  return descriptionEvents.addListener(cb);
}

type DescriptionOrNot =
  | {description: string; category: string | null}
  | {description?: undefined; category?: undefined};
export type GlobalKeyPressOpts = KeyOrCode &
  DescriptionOrNot & {
    fn: Fn;
    withMod?: boolean;
    withShift?: boolean;
    withAlt?: boolean;
    disabled?: boolean;
    prio?: Prio;
    ignoreTarget?: boolean;
    debug?: boolean;
  };

export const useGlobalKeyPress = (opts: GlobalKeyPressOpts) => {
  const {key, code, description, category, withMod, withShift, withAlt, disabled} = opts;
  useEffect(() => {
    if (description && !disabled) {
      return addDescription({
        key: key as string,
        code: code as undefined,
        description,
        category,
        withShift,
        withMod,
        withAlt,
      });
    }
  }, [key, code, description, category, withShift, withMod, withAlt, disabled]);

  const store = useContext(KeyPressContext);
  const optsRef = useRef(opts);
  useEffect(() => {
    optsRef.current = opts;
  });

  const keyProps = {
    key: key as string,
    code: code as undefined,
    withAlt,
    withMod,
    withShift,
  };
  const keyString = keyPropsToStr(keyProps);

  useEffect(() => {
    return store.registerKey({
      keyProps: {
        key: optsRef.current.key as string,
        code: optsRef.current.code as undefined,
        withAlt: optsRef.current.withAlt,
        withMod: optsRef.current.withMod,
        withShift: optsRef.current.withShift,
      },
      prio: optsRef.current.prio || "normal",
      fn: (e) => (optsRef.current.disabled || !optsRef.current.fn ? false : optsRef.current.fn(e)),
      ignoreTarget: optsRef.current.ignoreTarget || false,
    });
  }, [keyString, store]);
};

//
// const handlers = useElementKeyPress({
//  onEnter: {fn: !isDisabled && onSubmit, withMod: true}
// });
//

type ElementHandler = {
  fn: undefined | false | Fn;
  withMod?: boolean;
};

export const useElementKeyPress = (handlers: {[keyType: string]: ElementHandler}) => {
  const handlersRef = useRef(handlers);
  useEffect(() => {
    handlersRef.current = handlers;
  });

  const retVal = useRef<{onKeyDown: (e: KeyboardEvent) => void}>();
  if (!retVal.current) {
    retVal.current = {
      onKeyDown: (e) => {
        const handler = handlersRef.current[e.key];
        if (!handler || !handler.fn) return;
        if (Boolean(handler.withMod) !== isWithMetaKey(e)) return;
        if (handler.fn(e) !== false) {
          e.preventDefault();
          e.stopPropagation();
        }
      },
    };
  }
  return retVal.current;
};

type MultiKeyPressOpts = DescriptionOrNot & {
  keys: string[];
  descriptionKey?: string;
  fn: Fn;
  withMod?: boolean;
  disabled?: boolean;
};
export const useMultiGlobalKeyPress = (opts: MultiKeyPressOpts) => {
  const {keys, descriptionKey, description, category, withMod = false, disabled} = opts;
  const store = useContext(KeyPressContext);
  useEffect(() => {
    if (description && !disabled) {
      return addDescription({key: descriptionKey as string, description, category});
    }
  }, [description, category, descriptionKey, disabled]);

  const optsRef = useRef(opts);
  useEffect(() => {
    optsRef.current = opts;
  });

  const subRef = useRef<(() => void)[] | null>();
  if (!subRef.current) {
    subRef.current = keys.map((key) =>
      store.registerKey({
        keyProps: {key: key, withMod},
        prio: "normal",
        fn: (e) =>
          optsRef.current.disabled || !optsRef.current.fn ? false : optsRef.current.fn(e),
        ignoreTarget: false,
      })
    );
  }
  useEffect(() => {
    return () => {
      if (subRef.current) {
        subRef.current.forEach((unsub) => unsub());
        subRef.current = null;
      }
    };
  }, []);
};
