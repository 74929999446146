import {projectsDecks} from "../../../lib/utils";
import {useInstance} from "../../../lib/mate/mate-utils";
import {api} from "../../../lib/api";
import {DeckId} from "../../../cdx-models/Deck";
import {BoxProps, Row} from "@cdx/ds";
import {DeckPickerImage} from "../../card-container/DSDeckPicker";
import {SearchCategory} from "../search-types";

export const DeckOpt = ({id, ...rest}: {id: DeckId} & BoxProps) => {
  const deck = useInstance("deck", id);
  if (!deck) return "No such deck";
  return (
    <Row sp="4px" align="center" {...rest}>
      <DeckPickerImage deck={deck} />
      <div>{deck.title}</div>
    </Row>
  );
};

const deckCategory: SearchCategory<DeckId | null> = {
  key: "deck",
  label: "Deck",
  getSuggestions({input, projectIds, root}) {
    if (input.length < 2) return [];
    return [
      ...projectsDecks({
        root,
        projectIds,
      })
        .filter((d) => d.$meta.get("title", "").toLowerCase().indexOf(input) > -1)
        .map((d) => d.id as DeckId),
      ...("no decks".startsWith(input) ? [null] : []),
    ];
  },
  valueToKey(value) {
    return value;
  },
  valuesToDbQuery(values) {
    return {deckId: values};
  },
  renderSuggestion(value) {
    return value ? <DeckOpt id={value} /> : "No deck";
  },
  savedSearchLabel(value) {
    if (!value) return {prio: -2, label: "w/o deck"};
    const deck = api.getModel({modelName: "deck", id: value});
    return {
      prio: -2,
      label: `in '${deck?.title.slice(0, 16)}'`,
    };
  },
};

export default deckCategory;
