import {api} from "./api";
import {checkIfValid} from "./mate/mate-utils";

/**
 * @template T
 * @param {() => T} getDataFn
 * @returns {Promise<T extends Promise<infer U> ? U : T>}
 */
export const waitForResultPromise = (getDataFn) =>
  new Promise((resolve) => waitForResult(getDataFn, resolve));

/**
 * @template T
 * @param {() => T} getDataFn
 * @param {(result: T) => void} resultFn
 * @returns {undefined | (() => void)}
 */
export const waitForResult = (getDataFn, resultFn) => {
  const {isLoaded, result} = checkIfValid(getDataFn, api);
  if (isLoaded) {
    resultFn(result);
  } else {
    const unsub = api.registerOnChangeListener(() => {
      const testAgain = checkIfValid(getDataFn, api);
      if (testAgain.isLoaded) {
        unsub();
        resultFn(testAgain.result);
      }
    });
    return unsub;
  }
};

// const waitUntilAllSettled = () =>
//   new Promise(finalResolve => {
//     const waitAndCheck = () =>
//       new Promise(resolve => setTimeout(() => resolve(api.isCurrentlyLoading())));
//     waitAndCheck().then(res => {
//       if (res) {
//         finalResolve(true);
//       } else {
//         const unsub = api.registerOnChangeListener(() => {
//           waitAndCheck().then(innerRes => {
//             if (innerRes) {
//               unsub();
//               finalResolve(true);
//             }
//           });
//         });
//       }
//     });
//   });
