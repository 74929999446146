import {useState, useEffect} from "react";
import {debounce} from "../lib/utils";
import {XCol, XText, InputWithLabel} from "@cdx/common";
import ChildCardLane from "../features/workflows/ChildCardLane";
import WorkflowItemLane from "../features/workflows/WorkflowItemLane";
import {referencePattern} from "../features/search/categories/cards";
import {pronounceSafeSeq} from "../lib/sequences";

const createQuery = (query) => {
  if (!query) return null;
  const m = query.match(referencePattern);
  if (m) {
    const accountSeq = pronounceSafeSeq.seqToInt(m[1]);
    return {$or: [{accountSeq}, {title: {op: "contains", value: query}}]};
  } else {
    return {title: {op: "contains", value: query}};
  }
};

const debouncedCardSearchGetter = debounce(
  ({query, limit}) =>
    ({root, filter}) =>
      root.account.$meta.find("cards", {
        ...filter,
        ...createQuery(query),
        $order: "-lastUpdatedAt",
        $limit: limit,
      }),
  150
);

const debouncedWorkflowItemSearchGetter = debounce(
  ({query, limit}) =>
    ({deck, filter}) =>
      deck.$meta.find("workflowItems", {
        ...filter,
        ...(query && {title: {op: "contains", value: query}}),
        $order: "-lastUpdatedAt",
        $limit: limit,
      }),
  150
);

/**
 *
 * @type any
 */
const CardPicker = ({
  root,
  onSelect,
  filter,
  limit = 10,
  emptyMessage,
  label = "Enter card title",
  topBoxProps,
  bottomBoxProps,
  isWorkflowItem,
  deck,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [query, setQuery] = useState("");
  const [suggestionsFn, setSuggestionsFn] = useState(() => () => []);

  const handleInputChange = (val) => {
    setQuery(val);
    setSelectedIndex(0);
  };

  useEffect(() => {
    (isWorkflowItem ? debouncedWorkflowItemSearchGetter : debouncedCardSearchGetter)({
      query,
      limit,
    }).then((fn) => setSuggestionsFn(() => (args) => fn(args)));
  }, [query, limit, root, deck, isWorkflowItem]);
  const cards = suggestionsFn({root, deck, filter});
  const selectedCard = cards.length > 0 && cards[selectedIndex];

  const handleKeyDown = (e) => {
    if (e.which === 38) {
      // UP
      if (selectedIndex > 0) setSelectedIndex(selectedIndex - 1);
    } else if (e.which === 40) {
      // DOWN
      if (selectedIndex < cards.length - 1) setSelectedIndex(selectedIndex + 1);
    } else if (e.which === 13) {
      // Enter
      if (selectedCard) onSelect(selectedCard.id);
    } else {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <XCol>
      <XCol pt={2} {...topBoxProps}>
        <InputWithLabel
          value={query}
          onChange={handleInputChange}
          label={label}
          autoFocus
          onKeyDown={handleKeyDown}
        />
      </XCol>
      <XCol sp={0} py={2} {...bottomBoxProps}>
        {cards.length ? (
          cards.map((c, i) =>
            isWorkflowItem ? (
              <WorkflowItemLane
                key={c.id}
                item={c}
                onClick={() => onSelect(c.id)}
                isShown={i === selectedIndex}
              />
            ) : (
              <ChildCardLane
                key={c.id}
                card={c}
                onClick={() => onSelect(c.id)}
                isShown={i === selectedIndex}
                root={root}
              />
            )
          )
        ) : (
          <XCol sp={1} px={3} py={2} rounded="sm" border="gray400" bg="gray200">
            <XText size={1} color="gray600" preset="bold">
              No cards found
            </XText>
            {emptyMessage && (
              <XText size={1} color="gray600">
                {emptyMessage}
              </XText>
            )}
          </XCol>
        )}
      </XCol>
    </XCol>
  );
};

export default CardPicker;
