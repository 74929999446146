import {Box, Col} from "@cdx/ds";
import {CdxImgWithKnownDimensions as RawCdxImgWithKnownDimensions} from "../../../components/CdxImg";
import {Dispatch, forwardRef, useEffect, useRef, useState} from "react";
import {workflowStyles} from "../unified-card-container.css";
import {setupDragHandlers} from "../../../lib/hooks/use-drag";

const CdxImgWithKnownDimensions = RawCdxImgWithKnownDimensions as any;

const clamp0_100 = (v: number) => Math.min(100, Math.max(0, v));

type BigCoverImageEditorProps = {
  url: string;
  width: number;
  height: number;
  offsetVals: [number, number];
  setOffsetVals: Dispatch<[number, number]>;
  mode: "dark" | "light";
};
const BigCoverImageEditor = forwardRef((props: BigCoverImageEditorProps, ref) => {
  const {url, width, height, offsetVals, setOffsetVals, mode} = props;
  const [isGrabbing, setIsGrabbing] = useState(false);
  const [tmpDiff, setTmpDiff] = useState(0);
  const refs = useRef({tmpDiff, offsetVals, setOffsetVals});
  useEffect(() => {
    refs.current = {tmpDiff, offsetVals, setOffsetVals};
  });

  const imgRatio = width / height;
  const coverRatio = 2.5;

  const isVert = imgRatio > coverRatio;

  const [{handlers, unsubs}] = useState(() =>
    setupDragHandlers(
      (startPos) => {
        let d = 0;
        return {
          onMove: ([x, y]) => {
            d = isVert ? startPos[0] - x : startPos[1] - y;
            setTmpDiff(d);
          },
          onStop: (isCancelled) => {
            if (!isCancelled) {
              const nextVal = clamp0_100(refs.current.offsetVals[isVert ? 0 : 1] + d);
              refs.current.setOffsetVals(isVert ? [nextVal, 0] : [0, nextVal]);
            }
            setTmpDiff(0);
            setIsGrabbing(false);
          },
        };
      },
      () => {
        setIsGrabbing(true);
        return {
          onDone: () => null,
          onCancel: () => {
            setIsGrabbing(false);
          },
        };
      }
    )
  );
  useEffect(() => {
    return () => unsubs.forEach((fn) => fn());
  }, [unsubs]);

  const realOff = clamp0_100(offsetVals[isVert ? 0 : 1] + tmpDiff);

  return (
    <Col
      width="cardWidth"
      height="cardHeight"
      bg="foreground"
      elevation={100}
      rounded="card"
      overflow="hidden"
      relative
    >
      <Box
        relative
        cursor={isGrabbing ? "grabbing" : isVert ? "col-resize" : "row-resize"}
        width="100%"
        style={{aspectRatio: "2.5"}}
      >
        <CdxImgWithKnownDimensions
          src={url}
          width={width}
          height={height}
          maxWidth={500}
          maxHeight={500}
          asBackground
          imgStyle={{objectPosition: isVert ? `${realOff}% 0` : `0 ${realOff}%`}}
          {...handlers}
          ref={ref}
        />
      </Box>
      <Box
        absolute
        top="0"
        left="0"
        right="0"
        bg="background"
        className={mode === "dark" ? workflowStyles.withCoverDark : workflowStyles.withCoverLight}
        pointerEvents="none"
        style={{height: 14}}
      />
      <Col height="20px" bg="background" mt="auto" />
    </Col>
  );
});

export default BigCoverImageEditor;
