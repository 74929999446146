import {cx, RevealSpinner, XCol, XText} from "@cdx/common";
import uiClasses from "@cdx/common/xui/ui.css";
import {useEffect, forwardRef, useState, useLayoutEffect} from "react";
import cdxEnv from "../env";
import {useRoot} from "../lib/mate/mate-utils";
import {useLocalStorageState} from "../lib/storage";

/* eslint-disable jsx-a11y/alt-text */

const useMovingImagePreference = () => {
  const {loggedInUser} = useRoot();
  const [storageVal, setStorage] = useLocalStorageState("disable-moving-image", false);
  const noAnim = loggedInUser ? loggedInUser.$meta.get("disableMovingImages", storageVal) : false;
  useEffect(() => {
    if (noAnim !== storageVal) setStorage(noAnim);
  }, [noAnim, storageVal, setStorage]);
  return noAnim;
};

export const createResizeUrl = ({
  src,
  width,
  height,
  contain,
  webpSupport,
  avifSupport,
  pixelRatio,
  noAnim,
  offset,
}) => {
  const params = [];
  if (width) params.push(`w:${width}`);
  if (height) params.push(`h:${height}`);
  if (pixelRatio && pixelRatio !== 1) params.push(`dpr:${pixelRatio}`);
  if (width && height && !contain) params.push("fit:cover");
  if (webpSupport) params.push("webp");
  if (avifSupport) params.push("avif");
  if (noAnim) params.push("noAnim");
  if (offset) params.push(`offset:${offset[0]}_${offset[1]}`);
  const urlFrag = params.length ? `${params.join(",")}/` : "none/";
  return `${cdxEnv.IMG_RESIZER_HOST}/${urlFrag}${encodeURIComponent(src)}`;
};

const loadedUrlCache = {};
const notLoadedState = {isLoaded: false, error: null, data: null};

export const useImgLoaded = ({node, src}) => {
  const [loadState, setLoadState] = useState(loadedUrlCache[src] || notLoadedState);
  const [checkNode, setCheckNode] = useState(!(src in loadedUrlCache));
  useLayoutEffect(() => {
    const exists = loadedUrlCache[src];
    setLoadState(exists || notLoadedState);
    setCheckNode(!exists);
  }, [src]);
  useLayoutEffect(() => {
    if (node && checkNode) {
      const setAsLoaded = () => {
        const nextLoadState = (loadedUrlCache[src] = {
          isLoaded: true,
          error: null,
          data: getDimensionsFromImage(node),
        });
        setCheckNode(false);
        setLoadState(nextLoadState);
      };
      if (node.complete) {
        setAsLoaded();
      } else {
        setLoadState(loadedUrlCache[src] || notLoadedState);
        node.onload = () => {
          setAsLoaded();
        };
        node.onerror = (e) => {
          setCheckNode(false);
          console.warn(`Couldn't load image '${src}'`, e);
          setLoadState({isLoaded: true, error: "Can't load image", data: null});
        };
        return () => {
          node.onload = null;
          node.onerror = null;
        };
      }
    }
  }, [node, src, checkNode]);
  return loadState;
};

/**
 *
 * @param contain: as opposed to cover (only relevant if both width and height are given)
 * @returns
 */
const RawCdxImg = forwardRef((props, ref) => {
  const {width, height, sharedImgProps, baseImgSrc, className, fullScreenWidth, ...rest} = props;
  if (process.env.NODE_ENV === "development" && baseImgSrc.startsWith("/")) {
    return (
      <picture>
        <img
          width={width}
          height={height}
          ref={ref}
          src={baseImgSrc}
          className={cx(
            className,
            uiClasses.display.block,
            !sharedImgProps.contain && uiClasses.objectFit.cover
          )}
          {...rest}
        />
      </picture>
    );
  }
  const getSrcSet = (args) => {
    if (fullScreenWidth) {
      return [280, 560, 840, 1100, 1650, 2500, 2100, 3100]
        .map(
          (w) =>
            `${createResizeUrl({
              ...sharedImgProps,
              width: args.pixelRatio ? w / args.pixelRatio : w,
              ...args,
            })} ${w}w`
        )
        .join(", ");
    } else {
      return [1, 2]
        .map(
          (pixelRatio) =>
            `${createResizeUrl({...sharedImgProps, ...args, pixelRatio})} ${pixelRatio}x`
        )
        .join(", ");
    }
  };
  return (
    <picture>
      {fullScreenWidth && sharedImgProps.height && (
        <>
          <source
            srcSet={getSrcSet({avifSupport: true, pixelRatio: 2})}
            type="image/avif"
            media="(-webkit-min-device-pixel-ratio: 2)"
          />
          <source
            srcSet={getSrcSet({webpSupport: true, pixelRatio: 2})}
            type="image/webp"
            media="(-webkit-min-device-pixel-ratio: 2)"
          />
        </>
      )}
      <source srcSet={getSrcSet({avifSupport: true})} type="image/avif" />
      <source srcSet={getSrcSet({webpSupport: true})} type="image/webp" />
      <img
        width={width}
        height={height}
        ref={ref}
        src={baseImgSrc}
        srcSet={getSrcSet({})}
        className={cx(
          className,
          uiClasses.display.block,
          !sharedImgProps.contain && uiClasses.objectFit.cover
        )}
        {...rest}
      />
    </picture>
  );
});

const pickFromSizeList = (sizeList, {width, height}) => {
  if (!sizeList) return {width, height};
  return sizeList.find((l) => l.width >= width && l.height >= height) || {width, height};
};

/** @type any */
export const CdxCropImg = forwardRef(
  (
    {
      src,
      isLoadingSrc,
      fallback,
      width,
      height,
      contain,
      className,
      style,
      pickSize,
      children,
      loadingCoverColor,
      imgClassName,
      asBackground,
      loadingClassName,
      fallbackClassName,
      fallbackStyle,
      noSpinner,
      as: Comp = "div",
      fullScreenWidth,
      imgStyle,
      offset,
      ...rest
    },
    ref
  ) => {
    const [imgNode, setImgNode] = useState();

    const noAnim = useMovingImagePreference();
    const sharedImgProps = {
      src,
      contain,
      noAnim,
      offset,
      ...pickFromSizeList(pickSize, {width, height}),
    };
    const baseImgSrc = createResizeUrl(sharedImgProps);
    const {isLoaded, error} = useImgLoaded({node: imgNode, src: baseImgSrc});
    const asBackgroundClasses = [
      uiClasses.position.absolute,
      uiClasses.inset.full,
      uiClasses.width.full,
      uiClasses.height.full,
    ];

    return (
      <Comp
        className={cx(
          uiClasses.noOverflow,
          uiClasses.noShrink,
          className,
          ...(asBackground ? asBackgroundClasses : [uiClasses.position.relative])
        )}
        ref={ref}
        style={style}
      >
        {!noSpinner && (
          <RevealSpinner
            show={isLoadingSrc || (src && !isLoaded)}
            withCover
            coverColor={loadingCoverColor}
            size={Math.min(width, height)}
          />
        )}
        {error ? (
          <XCol
            style={asBackground ? fallbackStyle : {width, height, ...fallbackStyle}}
            className={cx(fallbackClassName, ...(asBackground ? asBackgroundClasses : []))}
            bg="error100"
            border="error200"
            align="center"
            justify="center"
            pa={0}
          >
            <XText preset="bold" color="error600" size={0} align="center">
              {error}
            </XText>
          </XCol>
        ) : src ? (
          <RawCdxImg
            sharedImgProps={sharedImgProps}
            baseImgSrc={baseImgSrc}
            width={width}
            height={height}
            className={cx(imgClassName, ...(asBackground ? asBackgroundClasses : []))}
            fullScreenWidth={fullScreenWidth}
            {...rest}
            style={imgStyle}
            ref={setImgNode}
          />
        ) : isLoadingSrc ? (
          <div
            style={asBackground ? {} : {width, height}}
            className={cx(loadingClassName, asBackground && asBackgroundClasses)}
          />
        ) : fallback ? (
          fallback()
        ) : (
          <div
            style={asBackground ? fallbackStyle : {width, height, ...fallbackStyle}}
            className={cx(fallbackClassName, ...(asBackground ? asBackgroundClasses : []))}
          />
        )}
      </Comp>
    );
  }
);

/**
 * @type {React.FC<{
 *  file: import("../cdx-models/File").File | null | undefined,
 *  width?: number,
 *  height?: number,
 * imgClassName?: string,
 * loadingClassName?: string,
 * fallbackClassName?: string,
 * fallbackStyle?: React.CSSProperties,
 * alt?: string,
 * title?: string,
 * ref?: React.Ref<HTMLElement>,
 * pickSize?: {width: number, height: number}[],
 * className?: string,
 * onClick?: (e: any) => void,
 * style?: React.CSSProperties
 * }>}
 */

export const CdxCropImgByFile = forwardRef(({file, ...rest}, ref) => {
  const src = file && file.$meta.get("url", "loading");
  return (
    <CdxCropImg
      src={src === "loading" ? null : src}
      isLoadingSrc={src === "loading"}
      {...rest}
      ref={ref}
    />
  );
});

export const getWidthAndHeight = ({maxWidth, maxHeight, orgDims}) => {
  if (maxWidth && maxHeight) {
    const origRatio = orgDims.height / orgDims.width;
    const targetRatio = maxHeight / maxWidth;
    if (origRatio < targetRatio) {
      const width = Math.min(maxWidth, orgDims.width);
      const height = Math.round((orgDims.height * width) / orgDims.width);
      return {width, height};
    } else {
      const height = Math.min(maxHeight, orgDims.height);
      const width = Math.round((orgDims.width * height) / orgDims.height);
      return {width, height};
    }
  } else if (maxWidth) {
    const width = Math.min(maxWidth, orgDims.width);
    const height = Math.round((orgDims.height * width) / orgDims.width);
    return {width, height};
  } else if (maxHeight) {
    const height = Math.min(maxHeight, orgDims.height);
    const width = Math.round((orgDims.width * height) / orgDims.height);
    return {width, height};
  }
  throw new Error("Please pass either maxWidth or maxHeight!");
};

export const CdxImgWithKnownDimensions = forwardRef(
  ({src, maxWidth, maxHeight, width, height, ...rest}, ref) => {
    const calcDims = getWidthAndHeight({
      maxWidth,
      maxHeight,
      orgDims: {width, height},
    });
    return (
      <CdxCropImg src={src} width={calcDims.width} height={calcDims.height} {...rest} ref={ref} />
    );
  }
);

/**
 * @type {React.FC<{
 *  file: import("../cdx-models/File").File | null | undefined,
 *  maxWidth?: number,
 *  maxHeight?: number,
 * className?: string,
 * imgClassName?: string,
 * loadingClassName?: string,
 * alt?: string
 * }>}
 */
export const CdxImgByFile = forwardRef(({file, maxWidth, maxHeight, ...rest}, ref) => {
  const src = file && file.$meta.get("url", "loading");
  const meta = file && file.$meta.get("meta", {});
  const {width, height} = getWidthAndHeight({
    maxWidth,
    maxHeight,
    orgDims: {width: 1600, height: 900, ...meta},
  });
  return (
    <CdxCropImg
      src={src === "loading" || meta === "loading" ? null : src}
      isLoadingSrc={src === "loading" || meta === "loading"}
      width={width}
      height={height}
      {...rest}
      ref={ref}
    />
  );
});

const defaultAspect = 16 / 9;

// Image we don't know the dimensions for beforehand that we don't want to crop
/** @type: any */
export const CdxImgForExternalUrl = forwardRef((props, ref) => {
  const {
    src: rawSrc,
    maxWidth,
    maxHeight,
    className,
    style,
    loadingCoverColor,
    imgClassName,
    outerComp: Comp = "div",
    onClick,
    ...rest
  } = props;

  const [imgNode, setImgNode] = useState();

  const src = rawSrc.startsWith("/")
    ? `${cdxEnv.HOST_PATTERN.replace("[SUB]", "web-app-assets")}${rawSrc}`
    : rawSrc;

  const noAnim = useMovingImagePreference();
  const sharedImgProps = {src, noAnim, contain: true, width: maxWidth, height: maxHeight};
  const baseImgSrc =
    process.env.NODE_ENV === "development" && rawSrc.startsWith("/")
      ? src
      : createResizeUrl(sharedImgProps);
  const {isLoaded, data} = useImgLoaded({node: imgNode, src: baseImgSrc});

  const getDims = () => {
    if (!isLoaded) {
      // get estimated dims
      if (maxHeight) {
        return {height: maxHeight, width: maxHeight * defaultAspect};
      }
      if (maxWidth) {
        return {width: maxWidth, height: maxWidth / defaultAspect};
      }
    }
    if (data && data.width && data.height) {
      const aspect = data.width / data.height;
      if (maxWidth) {
        const shownWidth = Math.min(maxWidth, data.width);
        return {width: shownWidth, height: shownWidth / aspect};
      }
      if (maxHeight) {
        const shownHeight = Math.min(maxHeight, data.height);
        return {height: shownHeight, width: shownHeight * aspect};
      }
      return {width: data.width, height: data.height};
    }
    return {};
  };

  return (
    <Comp
      {...rest}
      className={cx(
        uiClasses.noOverflow,
        uiClasses.noShrink,
        uiClasses.position.relative,
        className
      )}
      ref={ref}
      style={style}
    >
      <RevealSpinner
        show={!isLoaded}
        withCover
        coverColor={loadingCoverColor}
        size={Math.min(maxWidth, maxHeight)}
      />
      <RawCdxImg
        sharedImgProps={sharedImgProps}
        baseImgSrc={baseImgSrc}
        className={imgClassName}
        ref={setImgNode}
        onClick={onClick}
        {...getDims()}
      />
    </Comp>
  );
});

const getDimensionsFromImage = (image) => {
  const noNaturalDim = image.naturalWidth === 0 || image.naturalHeight === 0;

  return noNaturalDim
    ? {width: image.clientWidth, height: image.clientHeight}
    : {width: image.naturalWidth, height: image.naturalHeight};
};

export const getImageDimensionsFromUrl = (url) =>
  new Promise((resolve) => {
    const image = new Image();

    image.onload = () => {
      resolve(getDimensionsFromImage(image));
    };

    image.onerror = () => {
      resolve(null);
    };

    image.src = url;
  });
