import {projectsTags, personalTags} from "../../../lib/utils";
import {GetSuggestionContext, SearchCategory} from "../search-types";

type DefaultArg = {
  prop: "tags" | "masterTags";
  getTags: ({input, projects, root}: GetSuggestionContext) => string[];
};

const defaultTagCategories = ({
  prop,
  getTags,
}: DefaultArg): Omit<SearchCategory<string>, "key" | "label"> => ({
  getSuggestions(ctx) {
    const match = ctx.input.match(/^(?:#(\w*)|(\w{3,}))$/);
    if (match) {
      const filtered = getTags(ctx).filter((t) =>
        t.toLowerCase().startsWith(match[1] || match[2] || "")
      );
      return [...new Set(filtered)];
    } else {
      return [];
    }
  },
  valueToKey(tag) {
    return tag;
  },
  valuesToDbQuery(values, {forceOr}) {
    if (values.length === 1) return {[prop]: {op: "has", value: values[0]}};
    return {[forceOr ? "$or" : "$and"]: values.map((tag) => ({[prop]: {op: "has", value: tag}}))};
  },
  renderSuggestion(tag) {
    return `#${tag}`;
  },
  savedSearchLabel(tag) {
    return {
      prio: 1,
      label: `#${tag}`,
    };
  },
});

const tagCategories = [
  {
    label: "Project tag",
    key: "prjct-tags",
    ...defaultTagCategories({
      prop: "masterTags",
      getTags: ({projects}) => projectsTags(projects).map((t) => t.tag),
    }),
  },
  {
    label: "Personal tag",
    key: "prsnl-tags",
    ...defaultTagCategories({
      prop: "tags",
      getTags: ({root}) => personalTags(root.loggedInUser, root.account).map((t) => t.tag),
    }),
  },
  {
    label: "Other tag",
    key: "tags",
    ...defaultTagCategories({
      prop: "tags",
      getTags: ({root}) => personalTags(root.loggedInUser, root.account).map((t) => t.tag),
    }),
    getSuggestions({input}) {
      const match = input.match(/^(?:#(\w+))$/);
      return match ? [match[1]] : [];
    },
  } as SearchCategory<string>,
];

export default tagCategories;
