import {cx} from "@cdx/common";
import {documentStyles as styles} from "./document.css";
import React, {forwardRef} from "react";

type DocumentProps = {
  size?: keyof typeof styles.bySize;
  variant?: keyof typeof styles.variants;
  className?: string;
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>; // Add HTMLAttributes to allow standard HTML attributes

const Document = forwardRef<HTMLDivElement, DocumentProps>(
  ({size = "md", variant = "default", className, children, ...rest}, ref) => (
    <div
      className={cx(className, styles.base, styles.bySize[size], styles.variants[variant])}
      dir="auto"
      {...rest}
      ref={ref}
    >
      {children}
    </div>
  )
);

export default Document;
