import {Card} from "../../../cdx-models/Card";
import {CardOrder} from "../../../cdx-models/CardOrder";
import {Query} from "../../../cdx-models/utils/MakeModel";
import {SearchCategory} from "../search-types";

type ZoneVal = {context: CardOrder["context"]; label: string | null};

const zoneCategory: SearchCategory<ZoneVal> = {
  key: "zone",
  label: "Zone",
  getSuggestions() {
    return [];
  },
  valueToKey({context, label}) {
    return `${context}:${label}`;
  },
  valuesToDbQuery(values) {
    const toConst = ({context, label}: ZoneVal): Query<Card> => {
      if (label !== null) return {cardOrders: {context, label}};
      return {"!cardOrders": {context}};
    };
    if (values.length === 1) {
      return toConst(values[0]);
    } else {
      return {$or: values.map(toConst)};
    }
  },
  renderSuggestion({label}) {
    return label || "Default Zone";
  },
  savedSearchLabel({label}) {
    return {
      prio: -3,
      label: `in '${label || "Default Zone"}'`,
    };
  },
};

export default zoneCategory;
