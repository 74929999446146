import {FunctionComponent, ReactNode} from "react";
import {api} from "../../lib/api";
import {hasPermissionToSeeOnboarding} from "../../lib/permissions";
import {waitForResultPromise as rawWaitForResultPromise} from "../../lib/wait-for-result";
import {
  DSButton,
  DSIconBookmark,
  DSIconCrown,
  DSIconDeck,
  DSIconDeckInbox,
  DSIconDiscord,
  DSIconDoc,
  DSIconGlobe,
  DSIconHand,
  DSIconJourney,
  DSIconPlay,
  DSIconPrioA,
  DSIconPrioB,
  DSIconPrioC,
  DSIconProperties,
  DSIconQuestion,
  DSIconSearch,
  DSIconStopWatch,
  Row,
} from "@cdx/ds";
import dsStyles from "@cdx/ds/css/index.css";
import {checkIfPresent} from "../../lib/mate/mate-utils";

const waitForResultPromise = rawWaitForResultPromise as <T>(fn: () => T) => Promise<T>;

type ChapterName =
  | "basics1"
  | "basics2"
  | "basics3"
  | "hand"
  | "textEditor"
  | "lifeCycle"
  | "advanced"
  | "advanced2"
  | "moreCardTypes"
  | "journeys"
  | "sorting"
  | "timeTracking"
  | "producer"
  | "discord"
  | "openDecks"
  | "bonus";

export type AccountOnboardingStep = {
  key: string;
  title: string;
  description: string | null;
  variants: string;
  chapter: ChapterName;
  sortValue: string;
  status: "active" | "deprecated";
  xp: number;
  createdAt: Date;
  $meta: any;
};

export type StepInfo<Payload = undefined> = {
  startedAt: string;
  completedAt: string | null;
  payload: Payload;
};
export type StepProgress = {[key: string]: StepInfo};

export const ONBOARDING_STEPS = {
  investigateDonePile: "investigateDonePile",
  inviteTeamMember: "inviteTeamMember",
  cardSelection: "cardSelection",
  goToCard: "goToCard",
  dragAndDropCard: "dragAndDropCard",
  visitTimeTrackingReport: "visitTimeTrackingReport",
  applyDefaultOrder: "applyDefaultOrder",
  investigateUpvoteStats: "investigateUpvoteStats",
  investigateUserSettings: "investigateUserSettings",
  investigateMetrics: "investigateMetrics",
  investigateActivityFeed: "investigateActivityFeed",
  investigateOrgSettings: "investigateOrgSettings",
  investigateTeamHands: "investigateTeamHands",
  investigateCardBacklink: "investigateCardBacklink",
  bulkEditPriority: "bulkEditPriority",
  searchForPriority: "searchForPriority",
  showAssignedCards: "showAssignedCards",
  useSorting: "useSorting",
  filterBySwimlaneHeader: "filterBySwimlaneHeader",
  joinCodecksDiscord: "joinCodecksDiscord",
  investigateShortcutPanel: "investigateShortcutPanel",
  addSubCard: "addSubCard",
  applySCMIntegration: "applySCMIntegration",
} as const;

type FrontendKey = keyof typeof ONBOARDING_STEPS;

export type OnboardingInfo = {
  stepProgress: StepProgress;
  availableSteps: AccountOnboardingStep[];
};

const getAvailableAccountOnboardingInfo = (root: any): OnboardingInfo => {
  const accountSteps = root.account.accountOnboarding?.$meta.get(
    "steps",
    null
  ) as StepProgress | null;
  const rawUserSteps = root.loggedInUser.userOnboarding
    ? root.loggedInUser.userOnboarding.$meta.get("steps", null)
    : (false as StepProgress | null | false);
  const userSteps = (rawUserSteps === false ? {} : rawUserSteps) as StepProgress | null;
  const steps = accountSteps && userSteps ? {...accountSteps, ...userSteps} : null;
  const startedStepKeys = steps && Object.keys(steps);
  const variants = root.account.accountOnboarding?.$meta.get("variants", null);
  const availableSteps =
    startedStepKeys && variants
      ? (root.$meta.find("accountOnboardingSteps", {
          $or: [
            {status: "active", variants: {op: "overlaps", value: [...variants, "default"]}},
            // skip this one as it leads to a "new" query being generated everytime a step is completed
            // which leads to `getAvailableAccountOnboardingInfo` to return `null`;
            // ...(startedStepKeys.length > 0
            //   ? [{status: "deprecated", key: Object.keys(steps)}]
            //   : []),
          ],
          $order: ["chapter", "sortValue"],
        }) as AccountOnboardingStep[])
      : [];
  return {stepProgress: steps || {}, availableSteps};
};

export const completeOnboardingStep = async (stepKey: FrontendKey) => {
  const {stepProgress, availableSteps} = await waitForResultPromise(() => {
    const root = api.getRoot();
    if (!hasPermissionToSeeOnboarding(root)) {
      return {stepProgress: {}, availableSteps: []};
    } else {
      return getAvailableAccountOnboardingInfo(api.getRoot());
    }
  });
  if (stepProgress[stepKey]?.completedAt) return;
  const availableStepKeys = new Set(availableSteps.map((a) => a.key));
  if (!availableStepKeys.has(stepKey)) return;
  (api.mutate as any).onboarding.progress({step: stepKey, stepInfo: {completedAt: "now"}});
};

const chapterIconMap: {[key in ChapterName]: ReactNode} = {
  basics1: <DSIconPrioC />,
  basics2: <DSIconPrioB />,
  basics3: <DSIconPrioA />,
  hand: <DSIconHand />,
  textEditor: <DSIconDoc />,
  lifeCycle: <DSIconPlay />,
  advanced: <DSIconSearch />,
  advanced2: <DSIconDeckInbox />,
  moreCardTypes: <DSIconCrown />,
  journeys: <DSIconJourney />,
  sorting: <DSIconDeck />,
  timeTracking: <DSIconStopWatch />,
  producer: <DSIconProperties />,
  discord: <DSIconDiscord />,
  openDecks: <DSIconGlobe />,
  bonus: <DSIconBookmark />,
};

export const getChapterIcon = (chapter: ChapterName): ReactNode => {
  return chapterIconMap[chapter] || <DSIconQuestion />;
};

const XP_PER_LEVEL = 500;

export const getCurrentLevel = (
  args: OnboardingInfo
): null | {level: number; levelStartXp: number; currXp: number; levelEndXp: number} => {
  const {availableSteps, stepProgress} = args;
  let currXp = 0;
  for (const step of availableSteps) {
    const p = stepProgress[step.key];
    if (p?.completedAt) currXp += step.xp;
  }
  const level = Math.floor(currXp / XP_PER_LEVEL) + 1;
  return {
    level,
    levelStartXp: (level - 1) * XP_PER_LEVEL,
    currXp,
    levelEndXp: level * XP_PER_LEVEL,
  };
};

export const toRomanNumeral = (num: number): string => {
  const romanNumerals = [
    {value: 10, numeral: "X"},
    {value: 9, numeral: "IX"},
    {value: 5, numeral: "V"},
    {value: 4, numeral: "IV"},
    {value: 1, numeral: "I"},
  ];

  let result = "";

  for (const numeral of romanNumerals) {
    while (num >= numeral.value) {
      result += numeral.numeral;
      num -= numeral.value;
    }
  }

  return result;
};

export const getOnboardingStepInfoIfLoaded = (root: any): OnboardingInfo | null => {
  if (!root.loggedInUser) return null;
  const {isLoaded, result: onboardingInfo} = checkIfPresent(() => {
    const info = getAvailableAccountOnboardingInfo(root);
    if (info) {
      getCurrentLevel(info);
      if (info.availableSteps.length) {
        const firstStep = info.availableSteps[0];
        firstStep.$meta.get("chapter");
        firstStep.$meta.get("title");
        firstStep.$meta.get("xp");
      }
    }
    return info;
  }, api);
  return isLoaded ? onboardingInfo : null;
};

const JoinCodecksDiscordFooter = ({completed}: {completed: boolean}) => {
  const handleClick = () => {
    return completeOnboardingStep("joinCodecksDiscord");
  };

  return (
    <Row align="center" sp="16px">
      {!completed && (
        <DSButton variant="tertiary" size="sm" onClick={handleClick}>
          Skip
        </DSButton>
      )}
      <DSButton
        size="md"
        className={dsStyles.ml.auto}
        href="https://discord.gg/yCAb26x"
        onClick={handleClick}
      >
        Join
      </DSButton>
    </Row>
  );
};

export const onboardingStepFooters: {[key: string]: FunctionComponent<{completed: boolean}>} = {
  joinCodecksDiscord: JoinCodecksDiscordFooter,
};
