import {useState, useMemo, CSSProperties, ReactNode} from "react";
import {searchActions} from "../search/useSearch";
import {cardSelectionActions, useSelectedCardIds} from "../card-selection/useSelectedCards";
import {formatMsAsMin, TooltipForChild, useDelayedTrigger} from "@cdx/common";
import {effortInfo} from "../../components/props";
import {timeTrackingInfo} from "../time-tracking/time-tracking-utils";
import SelectionIconButton from "../../components/Card/SelectionIcon";
import {completeOnboardingStep, ONBOARDING_STEPS} from "../onboarding/onboarding-utils";
import {Box, Col, css, DSIconEffort, DSIconStopWatch, Row, Text} from "@cdx/ds";
import {Card} from "../../cdx-models/Card";
import {Account} from "../../cdx-models/Account";
import {User} from "../../cdx-models/User";
import DSAvatar from "../../components/DSAvatar/DSAvatar";
import {swimlaneLabelStyles} from "./card-panel.css";
import uiClasses from "@cdx/common/xui/ui.css";

type EffortSummaryProps = {
  cards: Card[];
  noIcon?: boolean;
  onLight?: boolean;
  onHand?: boolean;
  account: Account;
};
export const EffortSummary = ({cards, noIcon, account}: EffortSummaryProps) => {
  const {effortSum, effortlessCards, doneEffort} = effortInfo(cards, account);
  const tooltip = [`This row has cards with a total effort of ${effortSum}`];
  if (doneEffort) tooltip[0] += ` of which an effort of ${doneEffort} is already completed`;
  if (effortlessCards > 0) {
    tooltip.push(
      `${effortlessCards === 1 ? "one card" : `${effortlessCards} cards`} with no assigned effort.`
    );
  }
  return (
    <TooltipForChild tooltip={tooltip.join(" and ")} delayed>
      <Row sp="6px" align="center">
        {noIcon ? (
          <Box size={10} bold textTransform="uppercase" color="secondary" lineHeight="none">
            Total:
          </Box>
        ) : (
          <DSIconEffort size={16} className={css({color: "secondary"})} />
        )}
        <Row align="center" sp="4px">
          <Text type="label10Caps" color="secondary">
            {doneEffort > 0 ? `${doneEffort} / ` : ""}
            {effortSum}
          </Text>
          {effortlessCards > 0 && (
            <Box color="secondary" size={10}>
              ({effortlessCards})
            </Box>
          )}
        </Row>
      </Row>
    </TooltipForChild>
  );
};

type TimeTrackingTooltipProps = {
  byUser: {[userId: string]: {user: User; time: number; isRunning: boolean}};
};
const TimeTrackingTooltip = ({byUser}: TimeTrackingTooltipProps) => {
  return (
    <Col sp="8px" pa="8px">
      <Text type="label11" color="primary">
        Tracked time
      </Text>
      <Col sp="4px">
        {Object.values(byUser).map(({user, time, isRunning}, i) => (
          <Row sp="8px" align="center" key={user.$meta.isLoaded ? user.id : `_${i}`}>
            <DSAvatar size={16} user={user} />
            <Text
              type={isRunning ? "label12" : "label12light"}
              color={isRunning ? "primary" : "secondary"}
            >
              {formatMsAsMin(time)}
            </Text>
          </Row>
        ))}
      </Col>
    </Col>
  );
};

type TimeTrackingSummaryProps = {
  cards: Card[];
};
const TimeTrackingSummary = ({cards}: TimeTrackingSummaryProps) => {
  const {totalTime, getTimeByUser} = timeTrackingInfo(cards);
  if (!totalTime) return null;

  const getTooltip = () => <TimeTrackingTooltip byUser={getTimeByUser()} />;

  return (
    <TooltipForChild tooltip={getTooltip} delayed>
      <Row align="center" sp="6px">
        <DSIconStopWatch size={16} className={css({color: "secondary"})} />
        <Text type="label11" color="secondary">
          {formatMsAsMin(totalTime)}
        </Text>
      </Row>
    </TooltipForChild>
  );
};

type EffortAndTimeTrackingSummaryProps = {
  cards: Card[];
  noIcon?: boolean;
  className?: string;
  style?: CSSProperties;
  root: any;
};

export const EffortAndTimeTrackingSummary = (props: EffortAndTimeTrackingSummaryProps) => {
  const {cards, noIcon, className, style, root} = props;
  return (
    <Row style={style} className={className} align="center" sp="12px">
      <EffortSummary cards={cards} noIcon={noIcon} account={root.account} />
      {root.account.timeTrackingSwimLaneInfo && <TimeTrackingSummary cards={cards} />}
    </Row>
  );
};

type SelectAllIconProps = {
  cards: Card[];
  waitedForSelection: boolean;
  onSelectAll: () => void;
  onDeselectAll: () => void;
  isDragging: boolean;
  onLight?: boolean;
};
export const SelectAllIcon = (props: SelectAllIconProps) => {
  const {cards, waitedForSelection, onSelectAll, onDeselectAll, isDragging, onLight} = props;
  const selectedCardIds = useSelectedCardIds();
  if (!cards.length) return null;
  const showSelectInterface =
    process.env.REACT_APP_MODE !== "open" &&
    !isDragging &&
    (selectedCardIds.size > 0 || waitedForSelection);

  const areAllSelected = cards.every((c) => selectedCardIds.has(c.cardId));
  const prefix = areAllSelected ? "Deselect" : "Select";
  const tooltip = cards.length === 1 ? `${prefix} one card` : `${prefix} all ${cards.length} cards`;

  return (
    <SelectionIconButton
      tooltip={tooltip}
      tooltipProps={{hidden: !showSelectInterface}}
      onClick={areAllSelected ? onDeselectAll : onSelectAll}
      disabled={!showSelectInterface}
      hidden={!showSelectInterface}
      active={areAllSelected}
      onLight={onLight}
    />
  );
};

type SwimlaneLabelProps = {
  children: ReactNode;
  cards: Card[];
  waitedForSelection: boolean;
  orderProp?: string | null;
  isDraggingCardIds: Set<string>;
  searchLabelFilter?: {category: string; value: any} | null;
  onLight?: boolean;
  cardContainerKey: string;
  root: any;
  postfix?: ReactNode;
  prefix?: ReactNode;
  addButton?: ReactNode;
};
const SwimlaneLabel = (props: SwimlaneLabelProps) => {
  const {
    children,
    cards,
    waitedForSelection,
    orderProp,
    isDraggingCardIds,
    searchLabelFilter,
    onLight,
    cardContainerKey,
    root,
    addButton,
    postfix,
    prefix,
  } = props;
  const handleClick = searchLabelFilter
    ? () => {
        completeOnboardingStep(ONBOARDING_STEPS.filterBySwimlaneHeader);
        searchActions.addFilter(searchLabelFilter);
      }
    : undefined;

  const handleSelectAll = () => {
    cardSelectionActions.addSelectedCardIds(
      cards.map((c) => c.cardId),
      cardContainerKey
    );
  };

  const handleDeselectAll = () => {
    cardSelectionActions.removeSelectedCardIds(cards.map((c) => c.cardId));
  };

  const isDragging = isDraggingCardIds.size > 0;
  const selectedCardIds = useSelectedCardIds();
  const showSelectInterface =
    process.env.REACT_APP_MODE !== "open" &&
    !isDragging &&
    (selectedCardIds.size > 0 || waitedForSelection);

  return (
    <Row sp="16px" align="center" relative pt="8px" mt="-8px">
      {prefix}
      <Box style={{minWidth: 200}} relative>
        <div className={swimlaneLabelStyles.bgWithLine} />
        <Row align="center" sp="24px" relative>
          <Box absolute style={{left: -4}}>
            <SelectAllIcon
              onLight={onLight}
              cards={cards}
              waitedForSelection={waitedForSelection}
              onSelectAll={handleSelectAll}
              onDeselectAll={handleDeselectAll}
              isDragging={isDragging}
            />
          </Box>
          <div
            className={css({display: "flex", align: "center", bg: "foreground", pr: "8px"})}
            onClick={handleClick}
            style={{
              cursor: handleClick ? "pointer" : undefined,
              transition: "transform 0.05s ease-out",
              transform: cards.length > 0 && showSelectInterface ? "translateX(16px)" : undefined,
            }}
          >
            {children}
          </div>
          {postfix}
          {addButton && (
            <Box ml="auto" bg="foreground" pl="8px" className={uiClasses.hideElement}>
              {addButton}
            </Box>
          )}
        </Row>
      </Box>
      <EffortAndTimeTrackingSummary cards={cards} noIcon={orderProp === "effort"} root={root} />
    </Row>
  );
};

export const useHoverWaiter = () => {
  const [waited, setWaited] = useState<boolean | null>(null);
  const trigger = useDelayedTrigger();
  const handlers = useMemo(() => {
    return {
      ...(waited === null
        ? {
            onMouseMove: () => {
              setWaited(false);
              trigger.fire(() => setWaited(true), 300);
            },
          }
        : null),
      onMouseEnter: () => trigger.fire(() => setWaited(true), 300),
      onMouseLeave: () => {
        setWaited(false);
        trigger.cancel();
      },
    };
  }, [trigger, waited]);
  return {handlers, waited};
};

export default SwimlaneLabel;
