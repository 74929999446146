import {api} from "../../../lib/api";
import {pronounceSafeSeq} from "../../../lib/sequences";
import {shrinker} from "@cdx/common";
import {isHeroCard} from "../../workflows/workflow-utils";
import {SearchCategory} from "../search-types";
import {Card, CardId} from "../../../cdx-models/Card";
import {Query, QueryContent} from "../../../cdx-models/utils/MakeModel";

export const referencePattern = new RegExp(`\\$([${pronounceSafeSeq.letters}]{3,5})`);

type CardType = (
  | {
      cardSpecific: false;
      toConstraint: () => Query<Card>;
      query: string;
    }
  | {
      cardSpecific: true;
      toConstraint: (id: CardId) => Query<Card>;
      query?: undefined;
    }
) & {
  label: string;
  searchLabel: (title?: string | null) => string;
};

const typeInfo = {
  isHero: {
    cardSpecific: false,
    toConstraint: () => ({
      $or: [
        // either has child cards
        {childCards: {op: "notIn", value: [null, "{}"]}} as any as QueryContent<Card>,
        // or has a deck, but none that allows tasks
        {
          $and: [{"!deck": {allowedCardTypes: {op: "has", value: "task"}}}, {deck: {}}],
        },
      ],
    }),
    label: "Is Hero Card",
    searchLabel: () => `Hero`,
    query: "hero",
  },
  isSub: {
    cardSpecific: false,
    toConstraint: () => ({parentCardId: {op: "neq", value: null}}),
    label: "Is Sub Card",
    searchLabel: () => `Sub`,
    query: "subcard",
  },
  childCards: {
    cardSpecific: true,
    toConstraint: (id) => ({parentCardId: id}),
    label: "Show Sub Cards",
    searchLabel: (title) => `sub cards: ${title}`,
  },
  isLocked: {
    cardSpecific: false,
    toConstraint: () => ({hasBlockingDeps: true}),
    label: "Is locked",
    searchLabel: () => `Locked`,
    query: "locked",
  },
  isNotLocked: {
    cardSpecific: false,
    toConstraint: () => ({hasBlockingDeps: false}),
    label: "Is not locked",
    searchLabel: () => `Not locked`,
    query: "locked",
  },
  withCoverImag: {
    cardSpecific: false,
    toConstraint: () => ({coverFileId: {op: "neq", value: null}}),
    label: "With cover image",
    searchLabel: () => `Cover`,
    query: "coverImage",
  },
  isPrivate: {
    cardSpecific: false,
    toConstraint: () => ({deckId: null}),
    label: "Private Cards",
    searchLabel: () => `Private`,
    query: "private",
  },
  isDoc: {
    cardSpecific: false,
    toConstraint: () => ({isDoc: true}),
    label: "Doc Cards",
    searchLabel: () => `Doc`,
    query: "doc",
  },
  isBeast: {
    cardSpecific: false,
    toConstraint: () => ({
      meta: {op: "jsonSearch", field: "sprintLockedIn.level", jsonOp: "gt", value: 0} as any,
    }),
    label: "Beast Card",
    searchLabel: () => `Beast`,
    query: "beast",
  },
} satisfies Record<string, CardType>;

type Key = keyof typeof typeInfo;

const nonCardSpecEntries = Object.entries(typeInfo).filter(([, t]) => !t.cardSpecific) as [
  Key,
  CardType & {cardSpecific: false},
][];

const cardCategory: SearchCategory<{type: "childCards"; id: CardId} | {type: Key; id?: CardId}> = {
  key: "card",
  label: "Card",
  getSuggestions({input, root}) {
    const match = input.match(referencePattern);
    if (match) {
      const accountSeq = pronounceSafeSeq.seqToInt(match[1]);
      const card = root.account.$meta.find("cards", {accountSeq})[0] || null;
      if (card && isHeroCard(card)) {
        return [{id: card.cardId as CardId, type: "childCards"}];
      }
    } else {
      return nonCardSpecEntries
        .filter(([, {query}]) => query.indexOf(input) === 0)
        .map(([key]) => ({type: key}));
    }
  },
  valueToKey({id, type}) {
    return typeInfo[type].cardSpecific ? `${id}-${type}` : type;
  },
  valuesToDbQuery(values) {
    return values.reduce(
      (m, {id, type}) => ({...m, ...typeInfo[type].toConstraint(id as CardId)}),
      {}
    );
  },
  renderSuggestion({type}) {
    return typeInfo[type].label;
  },
  renderPill({id, type}) {
    const {searchLabel, cardSpecific} = typeInfo[type];
    if (cardSpecific) {
      const card = api.getModel({modelName: "card", id: id as CardId});
      return searchLabel(card?.title);
    } else {
      return searchLabel();
    }
  },
  savedSearchLabel({id, type}) {
    const {searchLabel, cardSpecific} = typeInfo[type];
    const title = cardSpecific
      ? shrinker(api.getModel({modelName: "card", id: id as CardId})?.title || "", 20)
      : null;
    return {prio: 2, label: searchLabel(title)};
  },
};

export default cardCategory;
