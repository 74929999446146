import CdxTypeaheadPlugin, {createNodeHandler} from "./CdxTypeAheadLexicalPlugin";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {TextNode} from "lexical";
import {mergeRegister} from "@lexical/utils";
import {useInstance} from "../../../lib/mate/mate-utils";
import {Box, Row} from "@cdx/ds";
import {waitForResultPromise} from "../../../lib/wait-for-result";
import {api} from "../../../lib/api";
import {useEffect} from "react";
import getSelectedProjects from "../../../lib/hooks/useSelectedProjects";
import {
  addTextTransformer,
  useSlashCommand,
} from "@cdx/ds/components/DSTextEditor/CdxSlashCommandLexicalPlugin";
import {$createDeckReferenceNode} from "./DeckReferenceNode";
import {DeckId} from "../../../cdx-models/Deck";

const Option = ({deckId}: {deckId: DeckId}) => {
  const deck = useInstance("deck", deckId);
  deck?.$meta.get("accountSeq");
  return (
    <Row textType="label11" flex="none" color="primary">
      <Box as="span" color="secondary" textType="label11light">
        $$
      </Box>
      {deck?.title}
      <Box
        as="span"
        color="secondary"
        textType="label11light"
        ml="auto"
        display="inline-block"
        pl="4px"
      >
        in {deck?.project.name}
      </Box>
    </Row>
  );
};

const getOptions =
  () =>
  (word: string): Promise<DeckId[]> =>
    waitForResultPromise(() => {
      const root = api.getRoot();
      const filterProjectIds = getSelectedProjects(root)
        .map((p) => p.$meta.get("id", null)!)
        .filter(Boolean);
      return root.account.$meta
        .find("decks", {
          ...(word && {title: {op: "contains", value: word}}),
          isDeleted: false,
          $order: "title",
          $limit: 15,
          projectId: filterProjectIds,
        })
        .map((c: any) => c.$meta.get("id", null));
    });

const deckRefRegex = /\$\[deck:(\d{1,4})\]/;

const findAndTransformDeckReference = (node: TextNode) => {
  const text = node.getTextContent();
  const m = text.match(deckRefRegex);
  if (!m) return null;
  const i = m.index!;

  let targetNode;
  if (m.index === 0) {
    [targetNode] = node.splitText(i + m[0].length);
  } else {
    [, targetNode] = node.splitText(i, i + m[0].length);
  }

  const reference = $createDeckReferenceNode(Number(m[1]));
  targetNode.replace(reference);
  return reference;
};

const DeckReferencePlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return mergeRegister(
      editor.registerNodeTransform(TextNode, (node) => {
        if (!node.isSimpleText()) return;
        findAndTransformDeckReference(node);
      })
    );
  }, [editor]);

  useSlashCommand({
    key: `deckReference`,
    label: `Reference Deck`,
    keywords: "deck reference $$",
    handleSelect: addTextTransformer("$$", ""),
    groupKey: "references",
  });

  return (
    <CdxTypeaheadPlugin<DeckId>
      triggerFn={(text: string) => {
        const m = text.match(/(\s|\(|^)(\$\$)([\p{Letter}-]*)$/u);
        if (!m) return null;
        const offset = m.index! + m[1].length;
        return {
          offset,
          match: m[3],
          triggerChar: m[2],
        };
      }}
      getOptions={getOptions()}
      handleOptionSelect={createNodeHandler((opt) =>
        $createDeckReferenceNode(api.getModel({modelName: "deck", id: opt})!.accountSeq)
      )}
      optionToKey={(opt) => opt}
      renderOption={(opt) => <Option deckId={opt} />}
    />
  );
};

export default DeckReferencePlugin;
