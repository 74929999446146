import React from "react";

export function isIE11() {
  return window.navigator.userAgent.indexOf("Trident/") >= 0;
}

export function isMac() {
  return window.navigator.platform.toUpperCase().indexOf("MAC") >= 0;
}

export function isFirefox() {
  return window.navigator.userAgent.indexOf("Firefox") >= 0;
}

export function isSafari() {
  // https://stackoverflow.com/a/23522755/616974
  return /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);
}

export function getChromeVersion() {
  // https://stackoverflow.com/a/4900484/616974
  var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
  return raw ? parseInt(raw[2], 10) : false;
}

export function isAndroid() {
  return window.navigator.userAgent.indexOf("Android") >= 0;
}

export function isWithMetaKey(e: React.MouseEvent<any, any> | MouseEvent | KeyboardEvent) {
  return isMac() ? e.metaKey : e.ctrlKey;
}

export function detectScrollDevice(
  event: WheelEvent,
  opts: {isFirst: boolean}
): "mouse" | "touchpad" | null {
  // Browser-specific detection for Firefox
  if (isFirefox()) {
    return event.deltaMode === WheelEvent.DOM_DELTA_LINE ? "mouse" : "touchpad";
  }

  if (isSafari() && "scale" in event && isFinite((event as any).scale)) {
    return "touchpad";
  }

  // Pinch zoom detection (touchpad)
  if (event.ctrlKey && event.deltaY !== 0) {
    return "touchpad";
  }

  // Mac-specific detection based on first wheel delta
  if (opts.isFirst && isMac() && "wheelDeltaY" in event) {
    const wheelDeltaY = Math.abs((event as any).wheelDeltaY);
    // Check for standard mouse wheel delta values
    if (wheelDeltaY === 120 || wheelDeltaY === 12) {
      console.log("HERE");
      return "mouse";
    }
  }

  // Horizontal scrolling without shift is typically touchpad
  if (event.deltaX !== 0 && !event.shiftKey) {
    return "touchpad";
  }

  return null;
}
