import {MODAL_QUERY_PARAM, useModalAdress} from "../ModalRegistry";
import {Link, useLocation} from "react-router-dom";
import {imageStyles} from "./markdown.css";
import {useViewerInfoStore} from "../../features/image-viewer/ImageViewContext";
import {cx} from "@cdx/common";
import {CdxImgForExternalUrl} from "../CdxImg";
import {CSSProperties, useEffect, useState} from "react";
import {getVideoMimeTypeFromUrl} from "../../lib/file-utils";

type RawMarkdownImgProps = {
  src: string;
  alt: string;
  title?: string;
  onClick?: (e: any) => void;
  imageViewerKey?: string;
  imageViewerImgKeyPrefix?: string;
  imgDims?: [number, number];
  imageClassName?: string;
} & React.ImgHTMLAttributes<HTMLImageElement>;

export const RawMarkdownImg = ({
  src,
  onClick,
  alt,
  title = alt,
  imageViewerKey,
  imageViewerImgKeyPrefix,
  imgDims = [500, 182],
  imageClassName,
  ...rest
}: RawMarkdownImgProps) => (
  <CdxImgForExternalUrl
    src={src}
    alt={alt}
    title={title}
    maxWidth={imgDims[0]}
    maxHeight={imgDims[1]}
    imgClassName={cx(imageStyles.base, imageClassName)}
    outerComp="span"
    onClick={onClick}
    {...rest}
  />
);

type PreviewImgProps = {
  imageViewerKey: string;
  src: string;
  imageViewerImgKeyPrefix: string;
  imgKey: string;
  alt: string;
  title?: string;
};

const PreviewImg = ({
  imageViewerKey,
  src,
  imageViewerImgKeyPrefix,
  imgKey,
  ...rest
}: PreviewImgProps) => {
  const [myCount] = useState(() => `${imageViewerImgKeyPrefix}-${imgKey}`);
  const title = rest.title || rest.alt;
  useEffect(() => {
    return useViewerInfoStore.getState().addForKey(imageViewerKey, [myCount, {url: src, title}]);
  }, [imageViewerKey, myCount, src, title]);

  const to = useModalAdress({
    modal: `attachmentViewer.${imageViewerKey}.${myCount}`,
  });
  return (
    <Link to={to} className="img-link" onClick={(e) => e.stopPropagation()}>
      <RawMarkdownImg src={src} {...rest} />
    </Link>
  );
};

type SimplePreviewImgProps = {
  src: string;
  title?: string;
  alt: string;
};

const SimplePreviewImg = ({src, title, ...rest}: SimplePreviewImgProps) => {
  const location = useLocation<any>();
  const getLink = () => {
    const searchParams = new URLSearchParams(location.search);
    if (location.state?.$withinModal) return null;

    // Create a copy of all existing search params
    const newSearchParams = new URLSearchParams(searchParams);
    // Add the modal param
    newSearchParams.set(MODAL_QUERY_PARAM, "imageViewer");

    return {
      ...location,
      search: `?${newSearchParams.toString()}`,
      state: {...location.state, modalState: {url: src}},
    };
  };
  const link = getLink();
  if (link) {
    return (
      <Link to={link} className="img-link">
        <RawMarkdownImg src={src} {...rest} />
      </Link>
    );
  } else {
    return <RawMarkdownImg src={src} {...rest} />;
  }
};

export const Video = ({src, type, style}: {src: string; type: string; style?: CSSProperties}) => (
  <video className={imageStyles.video} controls style={style}>
    <source src={src} type={type} />
  </video>
);

type MarkdownMediaProps = {
  isWithinLink?: boolean;
  imgKey: string;
  imageViewerKey?: string;
  imageViewerImgKeyPrefix?: string;
  src: string;
  alt: string;
  title?: string;
  imgDims?: [number, number];
  imageClassName?: string;
};
export const MarkdownMedia = ({isWithinLink, imgKey, src, ...props}: MarkdownMediaProps) => {
  const videoMime = getVideoMimeTypeFromUrl(src);
  if (videoMime) return <Video src={src} type={videoMime} />;

  return isWithinLink ? (
    <RawMarkdownImg src={src} {...props} />
  ) : props.imageViewerKey ? (
    <PreviewImg src={src} imgKey={imgKey} {...(props as any)} />
  ) : (
    <SimplePreviewImg src={src} {...props} />
  );
};
