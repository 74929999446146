import {Dispatch, MutableRefObject, forwardRef, useEffect, useRef, useState} from "react";
import {
  $getRoot,
  $isElementNode,
  EditorState,
  LexicalEditor as LexicalEditorType,
  LexicalNode,
  $nodesOfType,
  $getSelection,
  $isRangeSelection,
  $createTextNode,
  $createParagraphNode,
} from "lexical";
import LexicalEditor, {$addNodeFromText} from "./LexicalEditor";
import {
  FieldWithLabel as RawFieldWithLabel,
  cx,
  inputStyles,
  readonlyInputStyle,
} from "@cdx/common";
import {$createMentionNode, MentionNode} from "./MentionPlugin";
import dsStyles from "@cdx/ds/css/index.css";
import {UserId} from "../../../cdx-models/User";

const FieldWithLabel = RawFieldWithLabel as any;

const serializer = {
  toJSON: (state: CdxLexicalState) => state.state.ref.current?.editorState.toJSON() ?? null,
  fromJSON: (value: any): null | {type: "json"; value: any} => value && {type: "json", value},
};

export const lexicalSerializer = serializer;

const $someChildrenWithContent = (node: LexicalNode) => {
  if ($isElementNode(node)) {
    for (const child of node.getChildren()) {
      if ($someChildrenWithContent(child)) return true;
    }
  } else if (node.getTextContentSize() > 0) {
    return true;
  }
  return false;
};

const parseChangedContent = {
  allUserIds: (editorState: EditorState) => {
    const userIds = new Set();
    editorState.read(() => {
      for (const node of $nodesOfType(MentionNode)) {
        userIds.add(node.__userId);
      }
    });
    return userIds;
  },
  isEmpty: (editorState: EditorState) => {
    let empty = true;
    editorState.read(() => {
      empty = !$someChildrenWithContent($getRoot());
    });
    return empty;
  },
} as const;

const processChangeListeners = (
  changeListeners: CdxLexicalState["state"]["onChangeListeners"],
  editorState: EditorState
) => {
  for (const [key, listener] of Object.entries(changeListeners)) {
    const parserFn = parseChangedContent[key as keyof typeof parseChangedContent] as any;
    if (parserFn && listener) listener(parserFn(editorState));
  }
};

export const CdxLexicalEditor = forwardRef<any, any>((props, ref) => {
  const {
    onChange,
    value: untypedFieldState,
    showingErrors,
    variant = showingErrors === undefined ? "raw" : "default",
    className,
    onPasteFile,
    maybeProject: project,
    card,
    onCmdEnter,
    onBlur,
    onFocus,
    autoFocus,
    placeholder,
    style,
    onCardChange,
    isAdvanced,
    plugins,
    onMouseDown,
    ...rest
  } = props;

  const fieldState = untypedFieldState as CdxLexicalState["state"];

  const handleChange = (es: EditorState) => {
    if (fieldState.onChangeListeners) {
      processChangeListeners(fieldState.onChangeListeners, es);
    }
  };

  const classNames =
    variant === "raw"
      ? cx(className, dsStyles.flex.auto)
      : cx(
          inputStyles.base,
          inputStyles.bySize.sm,
          showingErrors && inputStyles.error,
          rest.readOnly && readonlyInputStyle,
          className
        );
  const contentKey = fieldState.appliedKey;
  return (
    <LexicalEditor
      fieldState={fieldState}
      onChange={handleChange}
      key={contentKey}
      contentKey={contentKey}
      className={classNames}
      onPasteFile={onPasteFile}
      ref={ref}
      project={project}
      card={card}
      onCmdEnter={onCmdEnter}
      onBlur={onBlur}
      onFocus={onFocus}
      onMouseDown={onMouseDown}
      autoFocus={autoFocus}
      placeholder={placeholder}
      style={style}
      onCardChange={onCardChange}
      isAdvanced={isAdvanced}
      plugins={plugins}
    />
  );
});

export const CdxLexicalEditorWithLabel = forwardRef((props, ref) => (
  <FieldWithLabel as={CdxLexicalEditor} ref={ref} {...props} />
));

export type SelectContent = [start: [number, number], end: [number, number]];

export type CdxLexicalState = {
  state: {
    initialVal:
      | {type: "text"; value: string; selectContent?: SelectContent}
      | {type: "state"; value: EditorState}
      | {type: "json"; value: any}
      | {type: "loading"};
    ref: MutableRefObject<{
      editor: LexicalEditorType;
      editorState: EditorState;
      editorStateKey: string;
    } | null>;
    appliedKey: string | null;
  } & Required<Pick<CdxEditorStateArgs, "onChangeListeners">>;
  getText: () => string;
  setText: (text: string) => void;
  addText: (text: string) => void;
  addUser: (opts: {name: string; id: string}) => void;
  removeUser: (opts: {name: string; id: string}) => void;
};

const editorStateToText = (es: EditorState) => {
  let text = "";
  es.read(() => {
    text = $getRoot().getTextContent();
  });
  return text || "";
};

const getStateFrom = (
  argsRef: MutableRefObject<CdxEditorStateArgs>,
  ref: CdxLexicalState["state"]["ref"]
): CdxLexicalState => {
  const {contentKey, initialContent} = argsRef.current;
  if (ref.current?.editorStateKey && ref.current?.editorStateKey !== contentKey) {
    ref.current = null;
  }
  const getState = (): CdxLexicalState["state"] => {
    const loadingState: CdxLexicalState["state"] = {
      appliedKey: null,
      ref,
      initialVal: {type: "loading"},
      onChangeListeners: {},
    };

    if (!contentKey) return loadingState;
    const val = initialContent();
    if (val === null || val === false) return loadingState;
    const initialVal = typeof val === "string" ? ({type: "text", value: val} as const) : val;
    if (initialVal.type === "text" && (initialVal.value === null || initialVal.value === false)) {
      return loadingState;
    }
    return {
      appliedKey: contentKey,
      ref,
      initialVal: initialVal as any,
      onChangeListeners: {
        allUserIds:
          argsRef.current.onChangeListeners?.allUserIds &&
          ((args) => {
            argsRef.current.onChangeListeners?.allUserIds?.(args);
          }),
        isEmpty:
          argsRef.current.onChangeListeners?.isEmpty &&
          ((args) => {
            argsRef.current.onChangeListeners?.isEmpty?.(args);
          }),
      },
    };
  };
  const state = getState();
  return {
    state,
    getText: () => (ref.current ? editorStateToText(ref.current.editorState) : ""),
    setText: (text) => {
      const e = ref.current?.editor;
      if (!e) return;
      e.update(() => {
        const root = $getRoot();
        root.clear();
        root.append($addNodeFromText(text));
        root.selectEnd();
      });
    },
    addText: (text) => {
      const e = ref.current?.editor;
      if (!e) return;
      e.update(() => {
        const root = $getRoot();
        const lastChild = root.getLastChild();
        if (!lastChild || !$isElementNode(lastChild)) {
          const p = $createParagraphNode();
          p.append($createTextNode(text));
          root.append(p);
        } else {
          lastChild.append($createTextNode(text));
        }
        root.selectEnd();
      });
    },
    addUser: ({id}) => {
      const e = ref.current?.editor;
      if (!e) return;
      e.update(() => {
        const mentionNode = $createMentionNode(id);
        const selection = $getSelection();
        const space = $createTextNode(" ");
        if ($isRangeSelection(selection)) {
          selection.insertNodes([mentionNode, space]);
        } else {
          const root = $getRoot();
          const lastChild = root.getLastChild();
          if (!lastChild || !$isElementNode(lastChild)) {
            const p = $createParagraphNode();
            p.append(mentionNode, space);
            root.append(p);
          } else {
            lastChild.append(mentionNode, space);
          }
        }
        space.select();
      });
    },
    removeUser: ({id}) => {
      const e = ref.current?.editor;
      if (!e) return;
      e.update(() => {
        const visited = new Set<string>();
        for (const node of $nodesOfType(MentionNode)) {
          if (node.__userId !== id || visited.has(node.__key)) continue;
          visited.add(node.__key);
          node.remove();
        }
      });
    },
  };
};

type CdxEditorStateArgs = {
  initialContent: () =>
    | false
    | null
    | string
    | {type: "state"; value: EditorState}
    | {type: "json"; value: any}
    | {type: "text"; value: string | false | null; selectContent?: SelectContent | null};
  contentKey: string | null | false | undefined;
  onChangeListeners?: {
    isEmpty?: Dispatch<boolean>;
    allUserIds?: Dispatch<Set<UserId>>;
  };
};
export const useCdxEditorState = (args: CdxEditorStateArgs): CdxLexicalState => {
  const ref: CdxLexicalState["state"]["ref"] = useRef(null);
  const argsRef = useRef(args);
  useEffect(() => {
    argsRef.current = args;
  });
  const [state, setState] = useState<CdxLexicalState>(() => getStateFrom(argsRef, ref));
  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  });
  useEffect(() => {
    const nextState = getStateFrom(argsRef, ref);
    if (nextState.state.appliedKey !== stateRef.current.state.appliedKey) setState(nextState);
  }, [args.contentKey]);
  return state;
};
