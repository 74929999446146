import {useRef, useEffect} from "react";

const someParent = (node: Node | null, cb: (n: Node) => boolean): boolean => {
  if (!node) return false;
  if (cb(node)) return true;
  return someParent(node.parentNode, cb);
};

let lastFileData: DataTransfer | null = null;

type FilePasterCallback = (files: File[]) => void;

export const useFilePaster = (onReceiveFile: FilePasterCallback) => {
  const fnRef = useRef<FilePasterCallback>(onReceiveFile);
  useEffect(() => {
    fnRef.current = onReceiveFile;
  }, [onReceiveFile]);
  useEffect(() => {
    const handlePaste = (e: ClipboardEvent) => {
      if (lastFileData === e.clipboardData) return;
      if (
        e.target instanceof HTMLElement &&
        (e.target.tagName === "INPUT" || e.target.tagName === "TEXTAREA")
      )
        return;
      if (!fnRef.current) return;
      if (e.defaultPrevented) return;
      if (someParent(e.target as Node, (n) => n instanceof HTMLElement && n.isContentEditable))
        return;
      if (!someParent(e.target as Node, (n) => n === document)) {
        console.warn("Pasting on detached node is forbidden!");
        return;
      }
      if (e.clipboardData?.files) {
        const files = Array.from(e.clipboardData.files);
        if (files.length > 0) {
          fnRef.current(files);
          lastFileData = e.clipboardData;
        }
      }
    };
    document.addEventListener("paste", handlePaste);
    return () => document.removeEventListener("paste", handlePaste);
  }, []);
};

type FilePasteHandlerProps = {
  onReceiveFile: FilePasterCallback;
};

const FilePasteHandler = ({onReceiveFile}: FilePasteHandlerProps) => {
  useFilePaster(onReceiveFile);
  return null;
};

export default FilePasteHandler;
