import {MutableRefObject, useLayoutEffect, useRef, useState} from "react";

export const findScrollableParents = (root: HTMLElement) => {
  const scrollableParents: (HTMLElement | Window)[] = [window];
  const inner = (node: HTMLElement | null) => {
    if (!node) return;

    const style = getComputedStyle(node);
    const overflowRegex = /(auto|scroll|overlay)/;

    if (overflowRegex.test(style.overflowY + style.overflowX)) {
      scrollableParents.push(node);
    }

    inner(node.offsetParent as HTMLElement);
  };
  inner(root);
  return scrollableParents;
};

export const useDSPosition = () => {
  const refs = useRef<{
    unsubscribeCb: null | (() => void);
    cbRef: (node: HTMLElement | null) => void;
  }>();
  const [pos, setPos] = useState<null | {left: number; top: number}>(null);
  if (!refs.current)
    refs.current = {
      unsubscribeCb: null,
      cbRef: (node) => {
        if (refs.current!.unsubscribeCb) refs.current!.unsubscribeCb();
        if (!node) return;
        const updatePos = () => {
          const rect = node.getBoundingClientRect();
          const left = rect.left + window.pageXOffset;
          const top = rect.top + window.pageYOffset;
          setPos((prev) => {
            if (!prev) return {left, top};
            if (prev.left !== left || prev.top !== top) return {left, top};
            return prev;
          });
        };
        updatePos();
        window.addEventListener("resize", updatePos);
        const parents = findScrollableParents(node);
        parents.forEach((parent) => parent.addEventListener("scroll", updatePos));

        refs.current!.unsubscribeCb = () => {
          window.removeEventListener("resize", updatePos);
          parents.forEach((parent) => parent.removeEventListener("scroll", updatePos));
          refs.current!.unsubscribeCb = null;
        };
      },
    };

  return [refs.current.cbRef, pos] as const;
};

export const useDSDimensions = () => {
  const [dims, setDims] = useState<null | {width: number; height: number}>(null);
  const cbRef = useDSDimensionCallback(setDims);
  return [cbRef, dims] as const;
};

// minimizes the number of renders, while also allowing to react to callbacks that change during dimension updates
export const useDSComputeWithDimensions = <T>(
  cb: (dims: null | {width: number; height: number}) => T
) => {
  const [, updateFn] = useState(0);
  const refs = useRef<{lastResult: T; lastDims: null | {width: number; height: number}; cb: any}>(
    null as any
  );
  if (!refs.current) {
    refs.current = {lastResult: undefined as T, lastDims: null, cb};
  }
  refs.current.cb = cb;

  const nodeCbRef = useDSDimensionCallback((dims) => {
    const nextResult = refs.current.cb(dims);
    refs.current.lastDims = dims;
    if (nextResult !== refs.current.lastResult) {
      updateFn((v) => v + 1);
    }
  });
  const result = cb(refs.current.lastDims);
  refs.current.lastResult = result;
  return [nodeCbRef, result] as const;
};

export const useDSDimensionCallback = (cb: (dims: {width: number; height: number}) => void) => {
  const refs = useRef<{
    unsubscribeCb: null | (() => void);
    cbRef: (node: HTMLElement | null) => void;
  }>();
  const cbRef = useRef(cb);
  cbRef.current = cb;
  if (!refs.current) {
    let lastDims: null | {width: number; height: number} = null;
    refs.current = {
      unsubscribeCb: null,
      cbRef: (node) => {
        if (refs.current!.unsubscribeCb) refs.current!.unsubscribeCb();
        if (!node) return;
        const update = () => {
          const rect = node.getBoundingClientRect();
          if (!lastDims || rect.width !== lastDims.width || rect.height !== lastDims.height) {
            cbRef.current({width: rect.width, height: rect.height});
            lastDims = rect;
          }
        };
        const ro = new ResizeObserver(update);
        ro.observe(node);
        update();
        refs.current!.unsubscribeCb = () => {
          refs.current!.unsubscribeCb = null;
          ro.disconnect();
        };
      },
    };
  }

  return refs.current.cbRef;
};

export const useDSDimensionCallbackWithStableRef = (
  nodeRef: MutableRefObject<HTMLElement | null>,
  cb: (dims: {width: number; height: number}) => void
) => {
  const cbRef = useRef(cb);
  cbRef.current = cb;
  useLayoutEffect(() => {
    let lastDims: null | {width: number; height: number} = null;
    const update = () => {
      const rect = nodeRef.current!.getBoundingClientRect();
      if (!lastDims || rect.width !== lastDims.width || rect.height !== lastDims.height) {
        cbRef.current({width: rect.width, height: rect.height});
        lastDims = rect;
      }
    };
    const ro = new ResizeObserver(update);
    ro.observe(nodeRef.current!);
    update();
    return () => {
      ro.disconnect();
    };
  }, [nodeRef]);
};
