export function by<T>(transform: (el: T) => number | string, {reversed = false} = {}) {
  if (reversed) {
    return (a: T, b: T) => {
      const lA = transform(a);
      const lB = transform(b);
      if (lA === lB) return 0;
      if (lA === null) return -1;
      if (lB === null) return 1;
      return lA > lB ? -1 : 1;
    };
  } else {
    return (a: T, b: T) => {
      const lA = transform(a);
      const lB = transform(b);
      if (lA === lB) return 0;
      if (lA === null) return 1;
      if (lB === null) return -1;
      return lA > lB ? 1 : -1;
    };
  }
}

export function byWithFallbacks<T>(transform: (el: T) => (number | string | null)[]) {
  return (a: T, b: T) => {
    const lAs = transform(a);
    const lBs = transform(b);
    let i = 0;
    while (i < lAs.length) {
      const lA = lAs[i];
      const lB = lBs[i];
      if (lA === lB) {
        i += 1;
      } else {
        if (lA === null) return -1;
        if (lB === null) return 1;
        return lA > lB ? 1 : -1;
      }
    }
    return 0;
  };
}

export const sortInlineByLowerCase = (list: string[]) => {
  list.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  return list;
};
