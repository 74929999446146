import {useState, useRef, useEffect, useMemo} from "react";
import {fromEvent as rawFromEvent} from "file-selector";
import {delayedTrigger, isIE11, isSafari} from "@cdx/common";
import {create} from "zustand";

const safariCheck = (e) => {
  if (e.dataTransfer) {
    const types = e.dataTransfer.types;
    for (const keyOrIndex in types) {
      if (types[keyOrIndex] === "Files") {
        return (e.dataTransfer.files || []).length === 0
          ? [{name: "unknown"}]
          : e.dataTransfer.files;
      }
    }
  }
  return [];
};

const fromEvent = isSafari()
  ? (e) => {
      return {then: (cb) => cb(safariCheck(e))};
    }
  : rawFromEvent;

const useDragFileStore = create((set) => ({
  isDragging: false,
  overNodeFn: null,
  setDragging: (val) => set({isDragging: val}),
  setOverNodeFn: (nodeFn) => set({overNodeFn: nodeFn}),
}));

const setDragging = (val) => {
  const {setDragging: innerFn} = useDragFileStore.getState();
  innerFn(val);
};

let _hoverEls = new Set();

const delayedRemover = delayedTrigger();

const handleDocOver = (e) => {
  e.preventDefault();
  delayedRemover.cancel();
};

const handleDocEnter = (e) => {
  e.preventDefault();
  fromEvent(e).then((files) => {
    if (!files.length) return;
    delayedRemover.cancel();
    _hoverEls.add(e.target);
    if (_hoverEls.size === 1) setDragging(true);
  });
};
const handleDocLeave = (e) => {
  e.preventDefault();
  fromEvent(e).then((files) => {
    if (!files.length) return;
    _hoverEls.delete(e.target);
    if (_hoverEls.size === 0) {
      delayedRemover.fire(() => setDragging(false), 25);
    }
  });
};
const handleDocDrop = (e) => {
  const {overNodeFn} = useDragFileStore.getState();
  if (overNodeFn) overNodeFn(e);
  e.preventDefault();
  _hoverEls.clear();
  setDragging(false);
};

const handleExit = (e) => {
  _hoverEls.clear();
  delayedRemover.fire(() => setDragging(false), 25);
};

document.addEventListener("dragover", handleDocOver);
document.addEventListener("dragenter", handleDocEnter);
document.addEventListener("dragleave", handleDocLeave);
document.addEventListener("dragexit", handleExit);
document.addEventListener("drop", handleDocDrop);

export const useIsDraggingFile = () => {
  const isDragging = useDragFileStore((s) => s.isDragging);
  return isDragging;
};

/**
 *
 * @param {Object} opts
 * @param {(files: File[], e: DragEvent) => void} opts.onDrop
 * @returns
 */
export const useDropTarget = ({onDrop}) => {
  const [isOver, setOver] = useState(false);
  const isDragging = useIsDraggingFile();
  const setOverNodeFn = useDragFileStore((s) => s.setOverNodeFn);
  const onDropRef = useRef(onDrop);
  const countRef = useRef(0);
  useEffect(() => {
    onDropRef.current = onDrop;
  }, [onDrop]);

  useEffect(() => {
    if (isOver && !isDragging) {
      countRef.current = 0;
      setOverNodeFn(null);
      setOver(false);
    }
  }, [isDragging, isOver, setOverNodeFn]);

  const dropTargetProps = useMemo(() => {
    const handleDrop = (e) => {
      countRef.current = 0;
      setOverNodeFn(null);
      setOver(false);
      if (onDropRef.current) {
        fromEvent(e).then((files) => {
          if (files.length > 0) onDropRef.current(files, e);
        });
      }
    };

    const handleEnter = (e) => {
      e.preventDefault();
      fromEvent(e).then((files) => {
        if (!files.length) return;
        countRef.current += 1;
        if (countRef.current === 1) {
          setOver(true);
          setOverNodeFn(handleDrop);
        }
      });
    };
    const handleOver = (e) => {
      e.preventDefault();
      if (e.dataTransfer && !isIE11()) {
        e.dataTransfer.dropEffect = "copy";
      }
    };
    const handleLeave = (e) => {
      e.preventDefault();
      fromEvent(e).then((files) => {
        if (!files.length) return;
        countRef.current -= 1;
        if (countRef.current === 0) {
          setOverNodeFn(null);
          setOver(false);
        }
      });
    };
    return {
      onDragEnter: handleEnter,
      onDragLeave: handleLeave,
      onDragOver: handleOver,
    };
  }, [setOverNodeFn]);

  return {isDragging, isOver, dropTargetProps};
};
