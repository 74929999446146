import {File} from "../cdx-models/File";

const imageTypes = new Set([
  "apng",
  "avif",
  "gif",
  "jpg",
  "jpeg",
  "jfif",
  "pjpeg",
  "pjp",
  "png",
  "svg",
  "webp",
  "bmp",
  "ico",
  "tif",
  "tiff",
]);

export const canDisplayAsImage = (file: File) => {
  if (!("type" in file.meta)) return false;
  return file.meta.type && imageTypes.has(file.meta.type.toLowerCase()) && !file.isDeleted;
};

const videoMimeTypesByExtension: Record<string, string> = {
  mp4: "video/mp4",
  webm: "video/webm",
  ogg: "video/ogg",
  mov: "video/quicktime",
  avi: "video/x-msvideo",
  wmv: "video/x-ms-wmv",
  m3u8: "application/x-mpegURL",
  ts: "video/mp2t",
  "3gp": "video/3gpp",
  flv: "video/x-flv",
  mkv: "video/x-matroska",
};

export const getVideoMimeTypeFromUrl = (url: string | null): string | undefined => {
  if (!url) return undefined;
  const fileExtension = url.split(".").pop()?.toLowerCase() || "";
  return videoMimeTypesByExtension[fileExtension];
};
