import {
  Box,
  Col,
  DSIconCard,
  DSIconEffort,
  DSIconFlash,
  DSIconMinus,
  DSIconPrioA,
  DSIconPrioB,
  DSIconPrioC,
  Row,
  Text,
  css,
} from "@cdx/ds";
import {TooltipForChild, cx, uiStyles} from "@cdx/common";
import {useInstance} from "../../../lib/mate/mate-utils";
import {milestoneOverviewStyles, milestoneOverviewStyles as styles} from "./MilestoneOverview.css";
import {cardStatusVariants} from "@cdx/ds/components/DSCard/DSCardTheme.css";
import {pluralize} from "../../../lib/utils";
import dsStyles from "@cdx/ds/css/index.css";
import {cardStateColors} from "@cdx/ds/css/tokens";
import {CardStatus, getStatusForCard} from "../../../lib/card-status-utils";
import {Account} from "../../../cdx-models/Account";
import {cloneElement, CSSProperties} from "react";
import {
  MilestoneProgressField,
  MilestoneStats,
  SprintStats,
} from "../../../cdx-models/utils/extended-fields";
import {UserId} from "../../../cdx-models/User";
import {Milestone} from "../../../cdx-models/Milestone";
import {Card} from "../../../cdx-models/Card";
import {hasChildCards} from "../../../features/workflows/workflow-utils";
import DSAvatar from "../../../components/DSAvatar/DSAvatar";
import {StoreApi, UseBoundStore} from "zustand";
import {transitions} from "@cdx/ds/css/decoration.css";

export type HoverInfo =
  | {type: "prio"; value: "a" | "b" | "c" | "none"}
  | {type: "owner"; value: UserId | null};
export type HoverStore = UseBoundStore<StoreApi<{hoverInfo: null | HoverInfo}>>;

export const VertProgress = ({progress}: {progress: number}) => {
  return (
    <Col
      rounded="full"
      width="3px"
      height="100%"
      className={styles.progressBar.base}
      bg="background"
      overflow="hidden"
      justify="end"
    >
      {progress > 0 && <Box bg="foreground" width="100%" style={{height: `${progress * 100}%`}} />}
    </Col>
  );
};

export const HorProgress = ({progress, style}: {progress: number; style?: CSSProperties}) => {
  return (
    <Box
      rounded="full"
      height="3px"
      className={styles.progressBar.base}
      bg="background"
      overflow="hidden"
      relative
      style={style}
    >
      {progress > 0 && <Box bg="foreground" height="100%" style={{width: `${progress * 100}%`}} />}
    </Box>
  );
};

const prios = ["a", "b", "c", "none"] as const;

type PrioGroup = {
  type: "a" | "b" | "c" | "none";
  total: number;
  done: number;
  progress: number;
};

const getPrioGroups = ({priority}: MilestoneStats, account: Account): PrioGroup[] => {
  const ps = priority || {};
  return prios.map((p) => {
    const vals = ps[p];
    if (!vals) return {total: 0, done: 0, progress: 0, type: p};
    const total = vals.total.effort + (vals.total.noEffort ?? 0) * account.fallbackEffort;
    const done = vals.done.effort + (vals.done.noEffort ?? 0) * account.fallbackEffort;
    return {total, done, progress: done / (total || 1), type: p};
  });
};

const iconByPrio = {
  a: <DSIconPrioA size={16} />,
  b: <DSIconPrioB size={16} />,
  c: <DSIconPrioC size={16} />,
  none: <DSIconMinus size={16} />,
};

const PrioGroupTile = (
  props: PrioGroup & {
    maximum: number;
    active?: boolean;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
  }
) => {
  const {total, progress, done, type, maximum, active, onMouseEnter, onMouseLeave} = props;
  return (
    <div
      className={css({display: "flex", sp: "6px", align: "center"})}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Box relative>
        {cloneElement(iconByPrio[type], {
          className: css(
            {
              color: "secondary",
              opacity: active ? 0 : total ? undefined : 0.25,
            },
            transitions.colors
          ),
        })}
        <Col
          absolute
          inset="0"
          align="center"
          justify="center"
          opacity={active ? 1 : 0}
          pointerEvents={active ? "auto" : "none"}
          zIndex={1}
        >
          <Text color="secondary" type="label10Caps" bg="foreground" px="2px">
            {done === total ? `${done}` : `${done}/${total}`}
          </Text>
        </Col>
      </Box>
      <Col relative flex="auto">
        <HorProgress progress={progress} style={{width: `${((total ?? 0) / maximum) * 100}%`}} />
      </Col>
    </div>
  );
};

type PrioCellProps = {
  stats: MilestoneStats;
  className?: string;
  useLocalMaximum?: boolean;
  account: Account;
  maximum?: number;
  currentHover?: "a" | "b" | "c" | "none" | null;
  onHover?: (prio: "a" | "b" | "c" | "none" | null) => void;
};
export const PrioCell = ({
  stats,
  className,
  useLocalMaximum,
  account,
  maximum: passedMax,
  currentHover,
  onHover,
}: PrioCellProps) => {
  const groups = getPrioGroups(stats, account);
  if (!groups || groups.length === 0) return <div className={className}>-</div>;
  const maximum =
    passedMax || Math.max(useLocalMaximum ? 1 : 100, ...groups.map((g) => g.total ?? 0));
  return (
    <Col sp="2px" className={className}>
      {groups.map((g) => (
        <PrioGroupTile
          key={g.type}
          active={currentHover === g.type}
          {...g}
          maximum={maximum}
          onMouseEnter={onHover ? () => onHover(g.type) : undefined}
          onMouseLeave={onHover ? () => onHover(null) : undefined}
        />
      ))}
    </Col>
  );
};

type OwnerGroup = {
  userId: null | UserId;
  total: number;
  progress: number;
  done: number;
};
const getOwnerGroups = ({assignee}: MilestoneStats, account: Account): OwnerGroup[] => {
  if (!assignee) return [];
  const groups = Object.entries(assignee).map(([userId, vals]) => {
    const total = vals.total?.effort + (vals.total?.noEffort ?? 0) * account.fallbackEffort;
    const done = vals.done?.effort + (vals.done?.noEffort ?? 0) * account.fallbackEffort;
    return {
      userId: userId === "none" ? null : (userId as UserId),
      total,
      progress: done / (total || 1),
      done,
    };
  });
  groups.sort((g1, g2) => g2.total - g1.total);
  return groups;
};

const OwnerGroupTile = (
  props: OwnerGroup & {
    maximum: number;
    active?: boolean;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    isHovering?: boolean;
  }
) => {
  const {total, done, progress, userId, maximum, active, onMouseEnter, onMouseLeave, isHovering} =
    props;
  const user = useInstance("user", userId);
  return (
    <div
      className={css(
        {
          display: "flex",
          flexDir: "column",
          sp: "2px",
          align: "center",
          justify: "end",
        },
        transitions.colors
      )}
      style={{
        height: 50 + 24,
        opacity: !isHovering || active ? 1 : 0.25,
        zIndex: active ? 1 : undefined,
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Row style={{height: ((total ?? 0) / maximum) * 50}} relative>
        <VertProgress progress={progress} />
        <Col
          absolute
          bottom="100%"
          left="0"
          right="0"
          align="center"
          opacity={active ? 1 : 0}
          pointerEvents={active ? "auto" : "none"}
          zIndex={1}
        >
          <Text color="secondary" type="label10Caps" bg="foreground" px="2px">
            {done === total ? `${done}` : `${done}/${total}`}
          </Text>
        </Col>
      </Row>
      <Box style={{width: 0, height: 24}} relative>
        <Box absolute top="0" translateX="-50%">
          {user ? (
            <DSAvatar flat user={user} size={24} />
          ) : (
            <Col
              rounded="full"
              borderWidth={1}
              borderColor="default"
              width="24px"
              height="24px"
              colorTheme="gray50"
              bg="foreground"
              bold
              color="secondary"
              relative
              textAlign="center"
              justify="center"
              align="center"
            >
              -
            </Col>
          )}
        </Box>
      </Box>
    </div>
  );
};

type OwnerCellProps = {
  stats: MilestoneStats;
  account: Account;
  className?: string;
  useLocalMaximum?: boolean;
  maximum?: number;
  currentHover?: UserId | null;
  onHover?: (userId: UserId | null) => void;
};
export const OwnerCell = ({
  stats,
  account,
  className,
  useLocalMaximum,
  maximum: passedMax,
  currentHover,
  onHover,
}: OwnerCellProps) => {
  const groups = getOwnerGroups(stats, account);
  if (!groups || groups.length === 0) {
    return (
      <Box bold size={12} color="secondary">
        No cards
      </Box>
    );
  }
  const maximum =
    passedMax || Math.max(useLocalMaximum ? 1 : 100, ...groups.map((g) => g.total ?? 0));
  return (
    <Row
      style={{justifyContent: "space-between", maxWidth: 16 * groups.length}}
      pr="8px"
      className={className}
    >
      {groups.map((g) => (
        <OwnerGroupTile
          key={g.userId}
          active={currentHover === g.userId}
          isHovering={currentHover !== undefined}
          {...g}
          maximum={maximum}
          onMouseEnter={onHover ? () => onHover(g.userId) : undefined}
          onMouseLeave={onHover ? () => onHover(null) : undefined}
        />
      ))}
    </Row>
  );
};

export const CardCounter = ({
  count,
  type,
}: {
  count: number;
  type: keyof typeof cardStatusVariants;
}) => (
  <Col
    borderWidth={1}
    borderColor="default"
    rounded={2}
    colorTheme="white"
    elevation={200}
    minWidth="20px"
    opacity={!count ? 0.25 : undefined}
  >
    <Col
      flex="auto"
      align="center"
      justify="center"
      bg="foreground"
      size={count >= 100 ? 9 : 12}
      bold
      color="primary"
      height="20px"
      // className={dependencyStyles.counter.content}
    >
      {count || "-"}
    </Col>
    <Box
      className={cx(cardStatusVariants[type], styles.cardCounter.bottom)}
      height="6px"
      bg="foreground"
    />
  </Col>
);

const statusGroups = {
  done: "done",
  review: "review",
  blocked: "blocked",
  assigned: "unassigned",
  unassigned: "unassigned",
  snoozing: "snoozing",
  started: "started",
  hero: "unassigned",
} as const;
const allStatusKeys = Object.keys(statusGroups) as (keyof typeof statusGroups)[];

type CountFields = "done" | "review" | "blocked" | "unassigned" | "snoozing" | "started";

// type CountsByStatus = Partial<{[K in (typeof shownGroups)[number]]: number}>;

type Counts = {
  nullEffort: number;
  sumEffort: number;
  cardCount: number;
  efforts: Partial<Record<CountFields, number>>;
  counts: Partial<Record<CountFields, number>>;
};

const createCounts = (p: MilestoneProgressField | null, fallbackEffort: number): Counts => {
  const counts: Counts = {nullEffort: 0, sumEffort: 0, cardCount: 0, efforts: {}, counts: {}};
  if (!p) return counts;
  const {stats} = p;
  if (stats) {
    const tupleToCount = (t: [number, number, number]) => {
      return t[1] + t[2] * fallbackEffort;
    };
    for (const f of allStatusKeys) {
      const tuple = stats[f];
      if (!tuple) continue;
      const key = statusGroups[f];
      const effort = tupleToCount(tuple);
      counts.efforts[key] = (counts.efforts[key] || 0) + effort;
      counts.counts[key] = (counts.counts[key] || 0) + tuple[0];
      counts.sumEffort += effort;
      counts.nullEffort += tuple[2];
      counts.cardCount += tuple[0];
    }
  } else {
    for (const f of allStatusKeys) {
      const key = statusGroups[f];
      counts.efforts[key] = (counts.efforts[key] || 0) + (p.effort?.[f] ?? 0);
      counts.counts[key] = (counts.counts[key] || 0) + (p.cards?.[f] ?? 0);
      counts.sumEffort += p.effort?.[f] ?? 0;
      counts.cardCount += p.cards?.[f] ?? 0;
    }
    counts.nullEffort = p.noEffortCards;
  }
  return counts;
};

const shownGroups = ["done", "review", "blocked", "snoozing", "started", "unassigned"] as const;

const showPercentage = (percentage: number) => {
  if (percentage > 99 && percentage < 100) {
    return percentage.toFixed(1);
  } else {
    return Math.round(percentage);
  }
};

export const getLastProgress = (model: Milestone) => {
  const finishStats: SprintStats["finishStats"] = (model as any).stats?.finishStats;
  if (finishStats) return {stats: finishStats.progress} as MilestoneProgressField;
  return model.$meta.first("progress", {$order: "-date"})?.progress;
};

const shownStatesWithCols = [
  ["done", cardStateColors.done300],
  ["review", cardStateColors.review300],
  ["blocked", cardStateColors.blocked400],
  ["snoozing", cardStateColors.snoozing300],
  ["started", cardStateColors.started300],
] as const;

const ProgressBar = ({counts, sum}: {sum: number; counts: Counts}) => (
  <Row
    rounded="full"
    height="5px"
    className={milestoneOverviewStyles.progressBar.base}
    bg="background"
    overflow="hidden"
    relative
  >
    {shownStatesWithCols
      .filter(([key]) => Boolean(counts.efforts[key]))
      .map(([key, color]) => (
        <Box
          key={key}
          height="100%"
          style={{
            width: `${(counts.efforts[key]! / sum) * 100}%`,
            backgroundColor: color,
          }}
        />
      ))}
  </Row>
);

const TotalProgressInfo = ({counts, velocity}: {counts: Counts; velocity: null | number}) => {
  const completedRatio = (counts.efforts.done ?? 0) / (counts.sumEffort || 1);
  return (
    <Col sp="4px">
      <Row sp="8px">
        <Box size={12} bold color="primary">
          <DSIconEffort size={16} className={css({color: "secondary"})} inline /> {counts.sumEffort}
        </Box>
        {velocity ? (
          <TooltipForChild tooltip="Velocity of last 7 days accross all Runs of this Run Configuration">
            <Box size={12} bold color="primary">
              <DSIconFlash size={16} inline className={dsStyles.color.secondary} />
              {velocity}
            </Box>
          </TooltipForChild>
        ) : null}
        <Box ml="auto" color="primary" size={12} bold>
          {showPercentage(completedRatio * 100)}%
        </Box>
      </Row>
      <ProgressBar counts={counts} sum={counts.sumEffort || 1} />
      <Row sp="4px" align="baseline">
        <Box color="secondary" bold size={12}>
          <DSIconCard size={16} inline /> {pluralize(counts.cardCount, "Card")}
        </Box>
        {counts.nullEffort > 0 && (
          <Box size={12} color="secondary" colorTheme="warn25">
            <b>{counts.nullEffort}</b> w/o effort
          </Box>
        )}
      </Row>
    </Col>
  );
};

type StatusCellProps = {
  account: Account;
  lastProgress: MilestoneProgressField;
  velocity: number | null;
  className?: string;
};
export const StatusCell = (props: StatusCellProps) => {
  const {account, lastProgress, velocity, className} = props;
  const counts = createCounts(lastProgress, account.fallbackEffort);
  return (
    <Col sp="16px" className={className}>
      <Row sp="4px">
        {shownGroups.map((type) => (
          <CardCounter key={type} type={type} count={counts.counts[type] ?? 0} />
        ))}
      </Row>
      <TotalProgressInfo counts={counts} velocity={velocity} />
    </Col>
  );
};

const ThinPrioGroup = ({total, progress, type, done, maximum}: PrioGroup & {maximum: number}) => (
  <Col sp="4px" className={uiStyles.hideContainer} align="center" minWidth="32px">
    <Col align="center" relative>
      {cloneElement(iconByPrio[type], {
        className: css(
          {color: "secondary"},
          uiStyles.hideElement,
          uiStyles.hideElementInitialOpacities[total ? 1 : 0.25],
          uiStyles.hideElementTargetOpacities[0]
        ),
      })}
      <Col
        size={11}
        bold
        color="secondary"
        absolute
        inset="0"
        align="center"
        justify="center"
        className={uiStyles.hideElement}
      >
        {done}/{total}
      </Col>
    </Col>
    <HorProgress
      progress={progress}
      style={{width: `${Math.max(0.15, total / (maximum || 1)) * 100}%`}}
    />
  </Col>
);

type ThinPrioCellProps = {
  stats: MilestoneStats;
  className?: string;
  account: Account;
};
const ThinPrioCell = ({stats, className, account}: ThinPrioCellProps) => {
  const groups = getPrioGroups(stats, account);
  if (!groups || groups.length === 0) return <div className={className}>-</div>;
  const maximum = Math.max(1, ...groups.map((g) => g.total ?? 0));
  return (
    <Row sp="8px" className={className}>
      {groups.map((g) => (
        <ThinPrioGroup key={g.type} {...g} maximum={maximum} />
      ))}
    </Row>
  );
};

const createEmptyStatField = () => ({
  total: {count: 0, effort: 0, noEffort: 0},
  done: {count: 0, effort: 0, noEffort: 0},
});
export const getStatsAndLastProgressFromCards = (
  cards: Card[]
): {stats: MilestoneStats; lastProgress: MilestoneProgressField} => {
  const stats: MilestoneStats = {
    assignee: {},
    priority: {
      a: createEmptyStatField(),
      b: createEmptyStatField(),
      c: createEmptyStatField(),
      none: createEmptyStatField(),
    },
    trackedTimeSecs: 0,
  };
  const lastProgress: MilestoneProgressField = {cards: {}, effort: {}, noEffortCards: 0, stats: {}};
  const addStat = (map: any, key: any, status: any, effort: number | null, isHero: boolean) => {
    const exist = map[key];
    if (!exist) {
      map[key] = {
        [status]: {
          count: 1,
          effort: effort || 0,
          noEffort: !isHero && effort === null ? 1 : 0,
        },
      };
    } else {
      const counts = exist[status];
      if (!counts) {
        exist[status] = {
          count: 1,
          effort: effort || 0,
          noEffort: !isHero && effort === null ? 1 : 0,
        };
      } else {
        counts.count += 1;
        if (effort === null && !isHero) {
          counts.noEffort += 1;
        } else {
          counts.effort += effort ?? 0;
        }
      }
    }
  };
  const addProgress = (
    counts: MilestoneProgressField["cards"],
    status: CardStatus,
    value: number
  ) => {
    counts[status as "started"] = (counts[status as "started"] || 0) + value;
  };
  const addNewProgress = (status: CardStatus, effort: null | number, isHero: boolean) => {
    const exist = lastProgress.stats![status as "started"];
    if (exist) {
      exist[0] += 1;
      if (effort) exist[1] += effort;
      else if (effort === null && !isHero) exist[2] += 1;
    } else {
      lastProgress.stats![status as "started"] = [
        1,
        effort ?? 0,
        effort === null && !isHero ? 1 : 0,
      ];
    }
  };
  for (const card of cards) {
    const status = getStatusForCard(card);
    const isHero = hasChildCards(card);
    const assigneeId = card.assignee?.$meta.get("id", null) ?? "none";
    const prio = card.$meta.get("priority", null);
    const effort = card.$meta.get("effort", null);
    addStat(stats.priority, prio, status, effort, isHero);
    addStat(stats.priority, prio, "total", effort, isHero);
    addStat(stats.assignee, assigneeId, status, effort, isHero);
    addStat(stats.assignee, assigneeId, "total", effort, isHero);
    addNewProgress(status, effort, isHero);
    addProgress(lastProgress.cards, status, 1);
    if (effort !== null) {
      addProgress(lastProgress.effort, status, effort);
    } else if (!isHero) {
      lastProgress.noEffortCards += 1;
    }
  }
  return {stats, lastProgress};
};

type StatGroupsProps = {
  stats: MilestoneStats;
  account: Account;
  velocity: number | null;
  lastProgress: MilestoneProgressField | null;
  className?: string;
};
export const StatGroups = (props: StatGroupsProps) => {
  const {stats, account, velocity, lastProgress, className} = props;
  const counts = createCounts(lastProgress, account.fallbackEffort);
  return (
    <Col sp="24px" className={className}>
      <Row sp="12px" align="center">
        <Row sp="4px">
          {shownGroups.map((type) => (
            <CardCounter key={type} type={type} count={counts.counts[type] ?? 0} />
          ))}
        </Row>
        <ThinPrioCell stats={stats} className={dsStyles.ml.auto} account={account} />
      </Row>
      <TotalProgressInfo counts={counts} velocity={velocity} />
    </Col>
  );
};
