import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {LexicalComposer, InitialConfigType} from "@lexical/react/LexicalComposer";
import {ContentEditable} from "@lexical/react/LexicalContentEditable";
import {HistoryPlugin} from "@lexical/react/LexicalHistoryPlugin";
import {Suspense, forwardRef, lazy, useEffect, useRef} from "react";
import {
  $createLineBreakNode,
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  BLUR_COMMAND,
  COMMAND_PRIORITY_NORMAL,
  EditorState,
  FOCUS_COMMAND,
  KEY_ENTER_COMMAND,
  RangeSelection,
  $createRangeSelection,
  $setSelection,
  TextNode,
} from "lexical";
import {mergeRegister} from "@lexical/utils";
import dsStyles from "@cdx/ds/css/index.css";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import MentionPlugin, {MentionNode} from "./MentionPlugin";
import {CdxSmartPlainTextCompletionPlugin} from "@cdx/ds/components/DSTextEditor/CdxSmartPlainTextCompletionPlugin/CdxSmartPlainTextCompletionPlugin";
import CdxSlashCommandLexicalPlugin from "@cdx/ds/components/DSTextEditor/CdxSlashCommandLexicalPlugin";
import CdxPasteImagePlugin, {
  SpinnerNode,
} from "@cdx/ds/components/DSTextEditor/CdxPasteImagePlugin";
import {$createImageNode, CdxDisplayMarkdownImagesPlugin, ImageNode} from "./CdxImagePlugin";
import TagPlugin from "./TagPlugin";
import AttachmentPlugin from "./AttachmentPlugin";
import {cx, isWithMetaKey} from "@cdx/common";
import {Box, Col} from "@cdx/ds";
import {CdxFloatingPlainTextFormatterPlugin} from "./CdxFloatingPlainTextFormatterPlugin";
import {CardReferenceNode} from "./CardReferenceNode";
import {DeckReferenceNode} from "./DeckReferenceNode";
import {EmojiNode} from "./EmojiNode";
import {CdxLexicalState} from "./LexicalRichTextProvider";
import {RichTextPlugin} from "@lexical/react/LexicalRichTextPlugin";
import {CdxRichTextOverwritesPlugin} from "@cdx/ds/components/DSTextEditor/CdxRichTextOverwrites";
import {editorStyles} from "./lexical-editor-styles.css";
import DeckReferencePlugin from "./DeckReferencePlugin";

export const $addNodeFromText = (text: string) => {
  const paragraphNode = $createParagraphNode();
  const parts = text.split(/\r?\n/);
  const length = parts.length;
  for (let i = 0; i < length; i++) {
    const part = parts[i];
    if (part !== "") {
      paragraphNode.append($createTextNode(part));
    }
    if (i !== length - 1) {
      paragraphNode.append($createLineBreakNode());
    }
  }
  return paragraphNode;
};

type CdxSetupProps = {
  fieldState: CdxLexicalState["state"];
  onChange?: (es: EditorState) => void;
  autoFocus?: boolean;
  contentKey: string | null;
};

const CdxSetup = (props: CdxSetupProps) => {
  const [editor] = useLexicalComposerContext();
  const refs = useRef(props);
  useEffect(() => {
    refs.current = props;
  });

  const {initialVal} = refs.current.fieldState;

  // note that opening and closing the preview will lead to the editor being re-initialized
  useEffect(() => {
    let scrollToNodeKey: string | null = null;

    editor.update(
      () => {
        const root = $getRoot();
        root.clear();
        const {autoFocus, fieldState} = refs.current;
        let selection: RangeSelection | null = null;

        if (fieldState.ref.current?.editor) {
          const es = fieldState.ref.current.editorState;
          editor.setEditorState(es);
          selection = es._selection as RangeSelection;
        } else {
          if (initialVal.type === "loading") return;

          if (initialVal.type === "state") {
            editor.setEditorState(initialVal.value);
            selection = initialVal.value._selection as RangeSelection;
          } else if (initialVal.type === "json") {
            const parsed = editor.parseEditorState(initialVal.value);
            setTimeout(() => {
              editor.setEditorState(parsed, {tag: "history-merge"});
            });
          } else {
            const {value, selectContent} = initialVal;
            const parts = value.split(/(\r?\n)/);
            const lines: TextNode[] = [];
            const length = parts.length;
            const paragraph = $createParagraphNode();
            root.append(paragraph);
            for (let i = 0; i < length; i++) {
              const part = parts[i];
              if (part === "\n" || part === "\r\n") {
                paragraph.append($createLineBreakNode());
              } else {
                const node = $createTextNode(part);
                lines.push(node);
                paragraph.append(node);
              }
            }
            if (selectContent) {
              const [[startLine, startOffset], [endLine, endOffset]] = selectContent;
              selection = $createRangeSelection();
              selection.setTextNodeRange(
                lines[startLine - 1],
                startOffset - 1,
                lines[endLine - 1],
                endOffset - 1
              );
              scrollToNodeKey = lines[startLine - 1].__key;
              $setSelection(selection);
            }
          }
        }
        refs.current.fieldState.ref.current = {
          editor,
          editorState: editor.getEditorState(),
          editorStateKey: refs.current.contentKey!,
        };
        if (!selection && autoFocus) {
          // wait a frame, otherwise it's gonna cause a sequence of focus -> blur -> focus
          setTimeout(() => {
            editor.update(() => {
              $getRoot().selectEnd();
            });
          });
        }
      },
      {
        onUpdate: () => {
          if (!scrollToNodeKey) return;
          const el = editor._keyToDOMMap.get(scrollToNodeKey);
          // needed as lexical only scrolls into view for collapsed selections by default
          if (el) el.scrollIntoView({block: "center"});
        },
      }
    );

    // note: this does not appear to update `editorState` when selection changes
    return editor.registerUpdateListener(({editorState, dirtyElements, dirtyLeaves}) => {
      if (dirtyElements.size === 0 && dirtyLeaves.size === 0) return;
      const curr = refs.current.fieldState.ref.current;
      if (curr) curr.editorState = editorState;
      if (refs.current.onChange) refs.current.onChange(editorState);
    });
  }, [editor, initialVal]);

  return null;
};

const theme = {
  hashtag: dsStyles.bold.true,
  // Theme styling goes here
  // ...
};

const CmdEnterPlugin = (props: {onCmdEnter: () => void}) => {
  const [editor] = useLexicalComposerContext();
  const refs = useRef(props);
  useEffect(() => {
    refs.current = props;
  });
  useEffect(() => {
    editor.registerCommand<KeyboardEvent | null>(
      KEY_ENTER_COMMAND,
      (event) => {
        if (!event) return false;
        if (isWithMetaKey(event)) {
          event.preventDefault();
          event.stopImmediatePropagation();
          refs.current.onCmdEnter();
          return true;
        }
        return false;
      },
      COMMAND_PRIORITY_NORMAL
    );
  }, [editor]);
  return null;
};

const FocusEmitPlugin = (props: {onFocus?: () => void; onBlur?: () => void}) => {
  const refs = useRef(props);
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    refs.current = props;
  });
  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        FOCUS_COMMAND,
        (event) => {
          if (!event) return false;

          // needs to be in a timeout otherwise there's an infinte loop if multiple editors are open
          setTimeout(() => {
            refs.current.onFocus?.();
          });
          return false;
        },
        COMMAND_PRIORITY_NORMAL
      ),
      editor.registerCommand(
        BLUR_COMMAND,
        (event) => {
          if (!event) return false;
          setTimeout(() => {
            refs.current.onBlur?.();
          });
          return false;
        },
        COMMAND_PRIORITY_NORMAL
      )
    );
  }, [editor]);
  return null;
};

// otherwise circular imports are happening...
const CardReferencePlugin = lazy(() => import("./CardReferencePlugin"));
const EmojiPlugin = lazy(() => import("./EmojiPlugin"));
const CardPropChangePlugin = lazy(() => import("./CardPropChangePlugin"));

const LexicalEditor = forwardRef<HTMLDivElement, any>((props, ref) => {
  const {
    onChange,
    className,
    style,
    fieldState: untypedFieldState,
    placeholder,
    project,
    onPasteFile,
    card,
    onCardChange,
    onCmdEnter,
    onFocus,
    onBlur,
    autoFocus,
    contentKey,
    isAdvanced: forcedAdvanced, // allow to use mention, tags, card references
    plugins,
    onMouseDown,
  } = props;

  const fieldState = untypedFieldState as CdxLexicalState["state"];

  const isAdvanced = forcedAdvanced || Boolean(project || card);

  const initialConfig: InitialConfigType = {
    namespace: "CdxEditor",
    theme,
    onError: (error: any) => console.error(error),
    nodes: [MentionNode, ImageNode, SpinnerNode, CardReferenceNode, DeckReferenceNode, EmojiNode],
    editorState: null,
  };

  return (
    <Col flex="auto" className={className} ref={ref}>
      <Col flex="auto" relative>
        <LexicalComposer initialConfig={initialConfig}>
          <RichTextPlugin
            contentEditable={
              <ContentEditable
                className={cx(
                  editorStyles.base,
                  dsStyles.flex.auto,
                  dsStyles.noFocusOutline.true,
                  dsStyles.fontFamily.mono
                )}
                style={style}
                onMouseDown={onMouseDown}
              />
            }
            placeholder={
              placeholder && (
                <Box
                  absolute
                  top="0"
                  left="0"
                  width="100%"
                  userSelect="none"
                  pointerEvents="none"
                  color="secondary"
                  fontFamily="mono"
                >
                  {placeholder}
                </Box>
              )
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <CdxRichTextOverwritesPlugin />
          <HistoryPlugin />
          <CdxSetup
            fieldState={fieldState}
            onChange={onChange}
            autoFocus={autoFocus}
            contentKey={contentKey}
          />
          {/* needs to be before components using useSlashCommand */}
          <CdxSlashCommandLexicalPlugin />
          {isAdvanced && <MentionPlugin project={project} />}
          {isAdvanced && <TagPlugin project={project} />}
          {onFocus || onBlur ? <FocusEmitPlugin onBlur={onBlur} onFocus={onFocus} /> : null}
          {card && <AttachmentPlugin card={card} />}
          <CdxSmartPlainTextCompletionPlugin />
          {onPasteFile && (
            <CdxPasteImagePlugin onFile={onPasteFile} createNodeFn={$createImageNode} />
          )}
          {onCmdEnter && <CmdEnterPlugin onCmdEnter={onCmdEnter} />}
          <CdxDisplayMarkdownImagesPlugin />
          <CdxFloatingPlainTextFormatterPlugin />

          <Suspense fallback={null}>
            {onCardChange && card && <CardPropChangePlugin card={card} onChange={onCardChange} />}
            <EmojiPlugin />
            {isAdvanced && <CardReferencePlugin card={card} />}
            {isAdvanced && <DeckReferencePlugin />}
            {plugins}
          </Suspense>
        </LexicalComposer>
      </Col>
    </Col>
  );
});

export default LexicalEditor;
