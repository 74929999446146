import {useMemo} from "react";
import {Location, History} from "history";
import qs from "qs";
import {useModalWithData} from "./Modals";
import {Router, useLocation} from "react-router-dom";
import memoizeOne from "memoize-one";
import {Root} from "../cdx-models/Root";

export const MODAL_QUERY_PARAM = "show";

type ModalPrefix =
  | "projectSettings"
  | "accountSettings"
  | "userProfile"
  | "manageUsers"
  | "deckCreation"
  | "deckEdit"
  | "attachmentViewer"
  | "imageViewer"
  | "commentHistory"
  | "releases"
  | "promoteProTrial"
  | "journey";

type ModalName = ModalPrefix | `${ModalPrefix}.${string}`;

const dropLeadingSlash = (s: string) => (s[0] === "/" ? s.slice(1) : s);

const getModalName = memoizeOne((search: string) => {
  const query = qs.parse(search, {ignoreQueryPrefix: true});
  const modalPath = query[MODAL_QUERY_PARAM] as string | null;
  if (!modalPath) return null;
  const m = modalPath.match(/^([^/.]+)([/.].*)/);
  return m
    ? {modalName: m[1], innerPath: m[2], query}
    : {modalName: modalPath, innerPath: "/", query};
});

const transformLocation = (location: Location<any>) => {
  const res = getModalName(location.search);
  return res
    ? {
        pathname: res.innerPath.replace(/\./g, "/") || "/",
        search: "",
        hash: "",
        state: {...location.state?.modalState, $withinModal: true},
      }
    : {pathname: "/", search: "", hash: "", state: null};
};

const getModalHistory = ({
  modalName,
  history: orgHistory,
}: {
  modalName: ModalName;
  history: History;
}) => {
  let passedLocation = orgHistory.location;
  const toModalLocation = (passedPath: string | Location, passedState?: any) => {
    if (typeof passedPath === "string") {
      if (passedPath.match(/^\/?:root:/)) {
        const rootPath = passedPath.replace(/^\/?:root:/, "");
        return passedState ? [rootPath, passedState] : [rootPath];
      }
    } else if (passedPath.search) {
      return [passedPath, passedState];
    } else if (passedPath.pathname && passedPath.state) {
      return toModalLocation(passedPath.pathname, passedPath.state);
    }
    const query = qs.parse(passedLocation.search, {ignoreQueryPrefix: true});
    const pathPart = `${modalName}/${dropLeadingSlash(typeof passedPath === "string" ? passedPath : passedPath.pathname)}`;
    const path = `${passedLocation.pathname}?${qs.stringify({
      ...query,
      [MODAL_QUERY_PARAM]: pathPart.replace(/\//g, "."),
    })}`;
    return passedState ? [path, {modalState: passedState}] : [path];
  };

  const wrapper = {
    get(target: History, key: keyof History) {
      const orgVal = target[key] as any;
      switch (key) {
        case "location":
          return transformLocation(passedLocation);
        case "push":
          return (path: any, state: any) => orgVal(...toModalLocation(path, state));
        case "replace":
          return (path: any, state: any) => orgVal(...toModalLocation(path, state));
        case "createHref":
          return (location: Location) =>
            location.search ? orgVal(location) : toModalLocation(location.pathname)[0];
        case "listen":
          return (fn: (nextLocation: Location) => void) =>
            orgVal((nextLocation: Location) => fn(transformLocation(nextLocation)));
        default:
          return orgVal;
      }
    },
  };

  const wrapped = new Proxy(orgHistory, wrapper);
  return {
    withLocation(location: Location) {
      passedLocation = location;
      return wrapped;
    },
  };
};

type ModalRegistryProps = {
  history: History;
  location: Location;
  root: Root;
  modals: {[key in ModalName]?: {comp: React.FC<any>; defaultProps?: any}};
};
const ModalRegistry = ({history, location, root, modals}: ModalRegistryProps) => {
  const {modalName} = (getModalName(location.search) || {}) as {modalName: ModalName};

  const {comp: ModalComp, defaultProps} = modals[modalName] || {};
  const modalHistory = useMemo(
    () => (ModalComp && modalName ? getModalHistory({modalName, history}) : null),
    [ModalComp, modalName, history]
  );

  const onClose = () => {
    history.push({
      ...location,
      search: qs.stringify({
        [MODAL_QUERY_PARAM]: undefined,
      }),
    });
  };

  const renderModal = useModalWithData(
    ModalComp && {
      comp: ModalComp,
      getWidth: () => defaultProps?.width,
      getBg: () => defaultProps?.bg,
      getFixedHeight: () => defaultProps?.fixedHeight,
      modalHistory: modalHistory!.withLocation(location),
    },
    {
      onClose,
      hideClose: defaultProps?.hideClose,
      width: defaultProps?.width,
      cdxContext: modalName,
      extractKey: (data: any) => data && data.content,
    }
  );
  return renderModal(({comp: Comp, state, modalHistory: currHistory}: any) => (
    <Router history={currHistory}>
      <Comp
        root={root}
        onClose={onClose}
        history={currHistory}
        modalState={state}
        location={currHistory.location}
      />
    </Router>
  ));
};
export const ModalAddress = ({
  modal,
  children,
}: {
  modal: ModalName;
  children: (location: Location) => React.ReactElement;
}) => children(useModalAdress({modal}));

export const getModalUrl = ({
  modal,
  location,
  state,
}: {
  modal: ModalName;
  location: Location;
  state?: any;
}): Location => ({
  ...location,
  search: qs.stringify({
    ...qs.parse(location.search, {ignoreQueryPrefix: true}),
    [MODAL_QUERY_PARAM]: modal,
  }),
  state: {...(location.state || {}), modalState: state},
});

export const useModalAdress = ({modal, state}: {modal: ModalName; state?: any}) => {
  const location = useLocation();
  return getModalUrl({modal, location, state});
};

export default ModalRegistry;
