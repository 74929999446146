import memoizeOne from "memoize-one";
import {userHasPermissionToOwnHand} from "../../lib/permissions";
import {Card, CardId} from "../../cdx-models/Card";
import {Account} from "../../cdx-models/Account";
import {UserId} from "../../cdx-models/User";
import {Root} from "../../cdx-models/Root";
import {sortBy} from "lodash";
import {QueueEntry} from "../../cdx-models/QueueEntry";

const getFullQueueInfo = (account: Account) => {
  const entries = account.$meta.find("queueEntries", {
    cardDoneAt: null,
  });
  return _getAllData(entries);
};

const _getAllData = memoizeOne((entries: QueueEntry[]) => {
  const byCardId = new Map<CardId, Map<UserId, number>>();
  const byUserId = new Map<UserId, Map<CardId, number>>();
  for (const e of entries) {
    const cardId = e.$meta.getId("card", null) as CardId;
    const userId = e.$meta.getId("user", null) as UserId;
    const pos = e.sortIndex + 1;
    if (!byCardId.has(cardId)) byCardId.set(cardId, new Map());
    if (!byUserId.has(userId)) byUserId.set(userId, new Map());
    byCardId.get(cardId)!.set(userId, pos);
    byUserId.get(userId)!.set(cardId, pos);
  }
  return {byCardId, byUserId};
});

export const isCardInUsersHandQueue = ({
  cardId,
  account,
  userId,
}: {
  cardId: CardId | null;
  account: Account;
  userId: UserId | null;
}) => {
  if (!userId || !cardId) return;
  const info = getFullQueueInfo(account);
  return info.byCardId.get(cardId)?.has(userId) ?? false;
};

export const isCardInSomeHandQueue = ({cardId, account}: {cardId: CardId; account: Account}) => {
  const info = getFullQueueInfo(account);
  return Boolean(info.byCardId.get(cardId));
};

export type HandPosInfo = {type: "self" | "owner" | "other"; position: number};

export const getUserQueueCardIds = ({userId, root}: {userId: UserId; root: Root}) => {
  const info = getFullQueueInfo(root.account);
  const queue = info.byUserId.get(userId);
  if (!queue) return [];
  // console.table(
  //   [...queue.entries()].map(([cardId, pos]) => ({
  //     title: api.getModel({modelName: "card", id: cardId}).title,
  //     pos,
  //   }))
  // );
  return sortBy(Array.from(queue.entries()), ([cardId, pos]) => pos).map(([cardId]) => cardId);
};

export const getCardHandInfo = ({card, root}: {card: Card; root: Root}): HandPosInfo | null => {
  const meId = root.loggedInUser?.$meta.get("id", null) ?? null;
  const cardId = card.$meta.get("cardId", null) ?? null;
  if (!cardId) return null;
  const cardInfo = getFullQueueInfo(root.account).byCardId.get(cardId);
  if (!cardInfo) return null;
  if (meId) {
    const meCardPos = cardInfo.get(meId);
    if (meCardPos) return {type: "self", position: meCardPos};
  }
  const userId = card.assignee?.$meta.get("id", null) ?? null;
  if (userId) {
    const ownerCardPos = cardInfo.get(userId);
    if (ownerCardPos) return {type: "owner", position: ownerCardPos};
  }
  const minPos = Math.min(...cardInfo.values());
  return {type: "other", position: minPos};
};

export const getAllCardPos = (cardId: CardId, account: Account) => {
  const info = getFullQueueInfo(account);
  return info.byCardId.get(cardId);
};

export const getBookmarkInfo = ({card, root}: {card: Card; root: Root}) => {
  const {loggedInUser} = root;
  const meId = loggedInUser && loggedInUser.$meta.get("id", null);
  const cardId = card.$meta.get("cardId", null);
  const isActive = meId && cardId && isCardBookmarked({card, root});
  if (!isActive) return {disabled: false, tooltip: "Bookmark card", isActive};

  if (!card.deck) {
    if (card.visibility === "default") {
      return {
        isActive,
        disabled: true,
        tooltip:
          "Can't remove bookmark for non-archived private cards. Archive card or move it to deck for removing the bookmark.",
      };
    } else if (card.visibility === "archived") {
      return {
        isActive,
        disabled: false,
        tooltip:
          "Click to remove from bookmarks. You'll be able to retrieve this card by searching for 'private' and 'archived'.",
      };
    }
  }
  return {isActive, disabled: false, tooltip: "Card is bookmarked"};
};

export const isCardBookmarked = ({card, root}: {card: Card; root: Root}) => {
  const userId = root.loggedInUser && root.loggedInUser.$meta.get("id", null);
  const cardId = card.$meta.get("cardId", null);
  if (!userId || !cardId) return null;
  return root.account.$meta.exists("handCards", {userId, cardId});
};

export const getFreeHandSlotsForUser = (targetUserId: UserId | null, root: Root) => {
  if (!targetUserId) return 0;
  const currCount = root.account.$meta.count("queueEntries", {
    userId: targetUserId,
    cardDoneAt: null,
  });
  return Math.max(0, root.account.maxHandSlotCount - currCount);
};

export const canAddHandToQueueErrors = (
  card: Card,
  targetUserId: UserId | null,
  root: Root,
  {dontCheckFreeSlots}: {dontCheckFreeSlots?: boolean} = {}
) => {
  if (!targetUserId) return null;
  if (card.visibility === "deleted") return "Can't add deleted cards";
  if (card.visibility === "archived" && card.status !== "done") {
    return "Can't add non-done archived cards";
  }
  if (!dontCheckFreeSlots && getFreeHandSlotsForUser(targetUserId, root) === 0) {
    return "User does not have enough free slots";
  }
  if (targetUserId !== (card.creator && card.creator.id)) {
    if (!card.deck) {
      return "Can't add your private card to team member";
    }
  }
  if (targetUserId !== root.loggedInUser.id && card.deck) {
    const hasAccess = card.deck.project.$meta.exists("access", {userId: targetUserId}, true);
    if (!hasAccess) {
      return "Owner can't access this card";
    }
  }
  if (!userHasPermissionToOwnHand({root, userId: targetUserId})) {
    return "Can't add cards to observers";
  }
  return null;
};

export const canDiscardHandFromQueue = (card: Card) => {
  if (card.deck && card.deck.handSyncEnabled) return false;
  if (card.milestone && card.milestone.handSyncEnabled) return false;
  return true;
};

export const canDiscardHandFromQueueErrors = (card: Card) => {
  if (card.deck && card.deck.handSyncEnabled) return "card is in hand sync deck";
  if (card.milestone && card.milestone.handSyncEnabled) return "card is in hand sync milestone";

  return null;
};
