import {useState} from "react";
import StickyBox from "react-sticky-box";
import {ProvideNotificationContext} from "../../features/notifications/useNotifications";
import ArenaMenu from "../../features/arena-menu/ArenaMenu";
import ArenaHeader from "./arena-header";
import {XCol, XRow} from "../../components/xui";
import {CardOrderProvider} from "../../features/card-order/useCardOrder";
import Notifications from "../../features/notifications/notifications";
import TopNudges from "../../features/nudges/TopNudges";
import {arenaStyles} from "./arena.css";
import {Row} from "@cdx/ds";
import {cx} from "@cdx/common";
import {colorThemes} from "@cdx/ds/css/themes/color-overwrites.css";
import dsStyles from "@cdx/ds/css/index.css";
import SmartSidebar from "../../components/SmartSidebar";

export const SetupArena = ({children, root}) => (
  <ProvideNotificationContext root={root}>{children}</ProvideNotificationContext>
);

export const SetupCardPanel = ({
  children,
  orderKey,
  initialOrderProp,
  modelWithPreferredOrder,
  initialOrderMode,
}) => (
  <CardOrderProvider
    orderKey={orderKey}
    initialOrderProp={initialOrderProp}
    modelWithPreferredOrder={modelWithPreferredOrder}
    initialOrderMode={initialOrderMode}
  >
    {children}
  </CardOrderProvider>
);

const activeTabToCdxContext = {
  hand: "Hand",
  decks: "Decks",
  milestones: "Milestones",
};

/** @type: any */
const ArenaLayout = ({
  children,
  root,
  location,
  history,
  bg,
  activeTab,
  searchElement,
  noPadding,
  showNotifications,
  noHorScroll,
  noSelectionHeader,
  scrollNodeRef,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const getTheme = () => {
    if (bg === "purpleDim") return colorThemes.legacyDimPurple;
    return colorThemes[bg];
  };
  return (
    <ArenaMenu
      isOpen={isMenuOpen}
      setOpen={setIsMenuOpen}
      root={root}
      location={location}
      history={history}
    >
      <XCol
        relative
        fillParent
        className={arenaStyles.outer}
        data-cdx-context={activeTabToCdxContext[activeTab]}
      >
        <TopNudges root={root} location={location} />
        <ArenaHeader
          setIsMenuOpen={setIsMenuOpen}
          root={root}
          activeTab={activeTab}
          history={history}
          searchElement={searchElement}
          noSelectionHeader={noSelectionHeader}
        />
        <Row flex="auto" relative>
          <XCol
            scrollable="wide"
            relative
            fillParent
            style={{overflowX: "auto"}}
            ref={scrollNodeRef}
          >
            <XRow
              pl={noPadding ? undefined : 3}
              className={cx(arenaStyles.body, getTheme(), dsStyles.bg.foreground)}
              style={noHorScroll ? {width: "100%"} : null}
            >
              <XRow fillParent sp={3} minWidth>
                {children}
              </XRow>
              {showNotifications && (
                <XCol style={{position: "relative", zIndex: 5}} justify="end">
                  <StickyBox bottom>
                    <Notifications root={root} location={location} />
                  </StickyBox>
                </XCol>
              )}
            </XRow>
          </XCol>
          <SmartSidebar />
        </Row>
      </XCol>
    </ArenaMenu>
  );
};

export const NonCardArenaLayout = ({root, location, history, children, bg = "white"}) => (
  <SetupArena root={root}>
    <ArenaLayout root={root} location={location} history={history} bg={bg} noPadding>
      <XCol fillParent>{children}</XCol>
    </ArenaLayout>
  </SetupArena>
);

export default ArenaLayout;
