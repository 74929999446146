import {useState, useEffect} from "react";
import {useModalWithData} from "./Modals";
import {Section} from "./ui";
import {
  MiniEvent,
  XCol,
  XRow,
  XText,
  subscribeToDescriptionEvents,
  useGlobalKeyPress,
  useMultiGlobalKeyPress,
} from "@cdx/common";
import sortBy from "lodash/sortBy";
import {completeOnboardingStep, ONBOARDING_STEPS} from "../features/onboarding/onboarding-utils";

const keyToLabel = {
  " ": "Space",
};

const ShortcutEntry = ({keys, category}) => (
  <XCol sp={2}>
    <XText preset="bold" size={4}>
      {category}
    </XText>
    <XCol sp={1}>
      {sortBy(keys, (k) => k[0]).map(([k, description]) => (
        <XRow key={k} align="baseline" sp={2}>
          <XText preset="bold" size={2} align="right" style={{minWidth: 80}}>
            {keyToLabel[k] || k}
          </XText>
          <XText size={2}>{description}</XText>
        </XRow>
      ))}
    </XCol>
  </XCol>
);

const ShortcutList = ({currentKeys}) => {
  const categories = {
    General: [["Esc", "Closes active panel"]],
  };
  Object.entries(currentKeys).forEach(([k, v]) => {
    if (v.length > 0) {
      const last = v[v.length - 1];
      const cat = last.category;
      (categories[cat] = categories[cat] || []).push([k, last.description]);
    }
  });
  const sorted = sortBy(Object.keys(categories), (c) => (c === "General" ? "0" : c.toLowerCase()));
  return (
    <XCol sp={4}>
      {sorted.map((c) => (
        <ShortcutEntry key={c} category={c} keys={categories[c]} />
      ))}
    </XCol>
  );
};

const ShortcutModal = ({currentKeys}) => {
  useEffect(() => {
    completeOnboardingStep(ONBOARDING_STEPS.investigateShortcutPanel);
  }, []);
  useMultiGlobalKeyPress({
    keys: [`1`, `2`],
    fn: (e) => {
      switch (e.key) {
        case "1":
          document.body.classList.remove("golos-font");
          break;
        case "2":
          document.body.classList.add("golos-font");
          break;
        default:
          break;
      }
    },
  });

  return (
    <>
      <Section.ModalHeader>
        <XText preset="bold" size={5}>
          Available Shortcuts
        </XText>
      </Section.ModalHeader>
      <XCol sp={4} px={4} py={2}>
        <XCol border="purple500" bg="purple100" rounded="md" px={4} py={2}>
          <XText color="purple600" size={2}>
            This is a list of all available shortcuts for the current screen. Open this menu on a
            different screens to see more shortcuts.
          </XText>
        </XCol>
        <ShortcutList currentKeys={currentKeys} />
      </XCol>
    </>
  );
};

const openEvents = new MiniEvent();
export const setOpen = (val) => openEvents.emit(val);

const ShortcutLister = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentKeys, setKeys] = useState({});

  useEffect(() => {
    return openEvents.addListener(setIsOpen);
  }, []);

  useEffect(() => {
    return subscribeToDescriptionEvents(setKeys);
  }, []);

  useGlobalKeyPress({
    key: "?",
    fn: () => setOpen((prev) => !prev),
    description: "Toggle this shortcut panel",
  });

  const renderModal = useModalWithData(isOpen, {onClose: () => setIsOpen(false), width: 500});
  return renderModal(() => <ShortcutModal currentKeys={currentKeys} />);
};

export default ShortcutLister;
