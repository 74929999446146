import {useInstance, useRoot} from "../../lib/mate/mate-utils";
import {TooltipForChild} from "@cdx/common";
import {DSConversationIcon, Col, css, NonInteractiveDSTag, Row, Text, DSIconBell} from "@cdx/ds";
import memoizeOne from "memoize-one";
import {Card, CardId} from "../../cdx-models/Card";
import {Resolvable, ResolvableId} from "../../cdx-models/Resolvable";
import {ResolvableParticipant} from "../../cdx-models/ResolvableParticipant";
import {Root} from "../../cdx-models/Root";
import {pluralize} from "../../lib/utils";
import {
  ConvoContainer,
  ConvoHeader,
} from "../card-container/sidebars/conversations/ConversationThread";
import {
  ConvoBag,
  createResolvableBag,
  ResolvableBag,
  SnoozeUntilLabel,
} from "../card-container/sidebars/conversations/convo-utils";
import Comment, {ConvoLine} from "../card-container/sidebars/conversations/Comment";
import {ResolvableEntry} from "../../cdx-models/ResolvableEntry";
import {ReactNode} from "react";
import {convoStyles} from "../card-container/sidebars/conversations/convo.css";
import {Notifications} from "../notifications/useNotifications";

const typeToLabel = {
  unseen: "New comments",
  undismissed: "Conversation with open notification",
  snoozing: "Snoozing conversation",
  open: "Ongoing conversation",
  withoutMe: "Ongoing conversation without you",
} as const;

type PreviewType = keyof typeof typeToLabel;

type PreviewEntry =
  | {
      type: "entry";
      key: string;
      item: ResolvableEntry;
      next: "none" | "entry" | "skip";
    }
  | {type: "skip"; item: number; next: null; key: string};
const prepareConvoEntries = (entries: ResolvableEntry[]): PreviewEntry[] => {
  const tooMany = entries.length > 3;
  if (tooMany) {
    return [
      {type: "entry", item: entries[0], next: "skip", key: entries[0].entryId},
      {type: "skip", item: entries.length - 3, next: null, key: "skip"},
      ...entries
        .slice(-2)
        .map((e) => ({type: "entry", item: e, next: "entry", key: e.entryId}) as const),
    ];
  } else {
    return entries.map(
      (e) =>
        ({
          type: "entry",
          item: e,
          next: "entry",
          key: e.entryId,
        }) as const
    );
  }
};

const ConvoEntry = ({
  entry,
  resBag,
  header,
}: {
  entry: PreviewEntry;
  resBag: ResolvableBag;
  header: ReactNode | null;
}) => {
  switch (entry.type) {
    case "entry":
      return (
        <Comment
          entry={entry.item}
          resBag={resBag}
          isPreview
          header={header}
          lineType={entry.next}
        />
      );
    case "skip":
      return (
        <Row sp="12px">
          <Col className={convoStyles.setAccentAsBorder} relative width="24px" flex="none" sp="6px">
            <ConvoLine isDashed />
          </Col>
          <Text type="label12" color="secondary" pb="12px" flex="auto">
            {pluralize(entry.item, "more comment")}
          </Text>
        </Row>
      );
    default:
      return null;
  }
};

type PreviewPaneProps = {
  type: PreviewType;
  card: Card;
  root: Root;
  resolvableId: ResolvableId;
  isFirst: boolean;
  missingConvos: number;
  minSnoozeUntil: Date | null;
};
const PreviewPane = (props: PreviewPaneProps) => {
  const {type, card, resolvableId, root, missingConvos, minSnoozeUntil} = props;
  const label = typeToLabel[type];
  const resolvable = useInstance("resolvable", resolvableId);

  if (!resolvable) return <div>Comments can't be found</div>;

  const fauxConvoBag = {card, root} as ConvoBag;

  const resBag = createResolvableBag(resolvable, fauxConvoBag, true, null);
  const entries = prepareConvoEntries(resolvable.$meta.find("entries", {$order: "createdAt"}));
  const header = <ConvoHeader resBag={resBag} avatarAction={null} isPreview />;

  return (
    <Col width="convoPreviewOverlay" sp="12px">
      <Col sp="8px" align="center">
        {missingConvos > 0 && (
          <NonInteractiveDSTag theme="active">
            +{pluralize(missingConvos, "more conversation")}
          </NonInteractiveDSTag>
        )}
        <Text type="label12" color="primary" align="center">
          {label}
          {minSnoozeUntil && (
            <>
              {" "}
              <DSIconBell inline size={16} /> <SnoozeUntilLabel snoozeUntil={minSnoozeUntil} />
            </>
          )}
        </Text>
      </Col>
      <ConvoContainer resBag={resBag}>
        {entries.map((entry, idx) => (
          <ConvoEntry
            key={entry.key}
            entry={entry}
            resBag={resBag}
            header={idx === 0 ? header : null}
          />
        ))}
      </ConvoContainer>
    </Col>
  );
};

export type ConvoInfo = NonNullable<ReturnType<typeof getConvoPreviewInfo>>;

type ConvoPreviewProps = {
  cardId: CardId;
  convoInfo: ConvoInfo;
};
export const ConvoPreview = ({cardId, convoInfo}: ConvoPreviewProps) => {
  const card = useInstance("card", cardId);
  const root = useRoot();
  if (!card) return <div>Card not found</div>;
  const {withNewCommentsResIds, snoozingResIds, notiResIds, userConvoResIds, orgConvoResIds} =
    convoInfo;
  const shownResIds = new Set();
  const convos: {type: PreviewType; id: ResolvableId}[] = [
    ...withNewCommentsResIds.map((id) => ({type: "unseen" as PreviewType, id})),
    ...snoozingResIds.map((id) => ({type: "snoozing" as PreviewType, id})),
    ...notiResIds.map((id) => ({type: "undismissed" as PreviewType, id})),
    ...(userConvoResIds || []).map((id) => ({type: "open" as PreviewType, id})),
    ...(orgConvoResIds || []).map((id) => ({type: "withoutMe" as PreviewType, id})),
  ];
  const deduplConvos = convos.filter(({id}) => {
    if (shownResIds.has(id)) return false;
    shownResIds.add(id);
    return true;
  });
  return (
    <Row sp="16px" pa="8px" colorTheme="gray50" bg="foreground">
      {deduplConvos.slice(0, 1).map(({id, type}, idx) => (
        <PreviewPane
          key={id}
          type={type}
          card={card}
          root={root}
          resolvableId={id}
          isFirst={idx === 0}
          missingConvos={deduplConvos.length - 1}
          minSnoozeUntil={convoInfo.minSnoozeUntil}
        />
      ))}
    </Row>
  );
};

const getUserConvoCardMapping = memoizeOne((convos: ResolvableParticipant[]) => {
  const map = new Map<CardId, ResolvableId[]>();
  convos.forEach((c) => {
    const cardId = c.resolvable.card.$meta.get("cardId", null) as CardId;
    const resolvableId = c.resolvable.$meta.get("id", null) as ResolvableId;
    const exist = map.get(cardId);
    if (exist) {
      exist.push(resolvableId);
    } else {
      map.set(cardId, [resolvableId]);
    }
  });
  return map;
});

const getOrgConvoCardMapping = memoizeOne((resolvables: Resolvable[]) => {
  const map = new Map<CardId, ResolvableId[]>();
  resolvables.forEach((r) => {
    const cardId = r.card.$meta.get("cardId", null) as CardId;
    const resolvableId = r.$meta.get("id", null) as ResolvableId;
    const exist = map.get(cardId);
    if (exist) {
      exist.push(resolvableId);
    } else {
      map.set(cardId, [resolvableId]);
    }
  });
  return map;
});

type ConvoPreviewInfoArgs = {
  notifications?: Notifications;
  card: Card;
  root: Root;
};
export const getConvoPreviewInfo = ({notifications, card, root}: ConvoPreviewInfoArgs) => {
  if (!notifications) return null;
  const cardId = card.$meta.get("cardId", null);
  const cardResNotis = (cardId && notifications.perCardResolvables[cardId]) || [];
  const participatingNotis = cardResNotis.filter((n: any) => n.isParticipating);
  const accountId = root.account.$meta.get("id", null);
  const userConvos =
    accountId &&
    process.env.REACT_APP_MODE !== "open" &&
    root.account.$meta.isLoaded &&
    getUserConvoCardMapping(
      root.loggedInUser.$meta.find("participations", {
        done: false,
        resolvable: {isClosed: false, card: {accountId}},
      })
    );
  const orgConvos =
    process.env.REACT_APP_MODE !== "open" &&
    getOrgConvoCardMapping(root.account.$meta.find("resolvables", {isClosed: false}));

  const getMinSnoozeUntil = () => {
    let min = null;
    for (const n of participatingNotis) {
      if (!n.snoozeUntil) return null;
      if (min === null || n.snoozeUntil.getTime() < min.getTime()) {
        min = n.snoozeUntil;
      }
    }
    return min;
  };

  const info = {
    notiResIds: participatingNotis
      .map((n: any) => n.resolvable && n.resolvable.$meta.get("id", null))
      .filter(Boolean) as ResolvableId[],
    snoozingResIds: participatingNotis
      .filter((n: any) => n.isSnoozing)
      .map((n: any) => n.resolvable && n.resolvable.$meta.get("id", null))
      .filter(Boolean) as ResolvableId[],
    withNewCommentsResIds: participatingNotis
      .filter((n: any) => n.unseenEntryCount > 0)
      .map((n: any) => n.resolvable && n.resolvable.$meta.get("id", null))
      .filter(Boolean) as ResolvableId[],
    userConvoResIds: userConvos && (userConvos.get(card.cardId as CardId) as ResolvableId[]),
    orgConvoResIds: orgConvos && (orgConvos.get(card.cardId as CardId) as ResolvableId[]),
    numNewComments: participatingNotis.reduce(
      (s: number, n: any) => s + n.unseenEntryCount,
      0
    ) as number,
    minSnoozeUntil: getMinSnoozeUntil(),
  };
  return !participatingNotis.length && !info.userConvoResIds && !info.orgConvoResIds ? null : info;
};

type ConversationHintProps = {
  convoInfo: ConvoInfo;
  gotoPanel?: (panel: string) => void;
  cardId: CardId;
  size?: "sm" | "md";
};
export const ConversationHint = ({
  convoInfo,
  gotoPanel,
  cardId,
  size = "md",
}: ConversationHintProps) => {
  const handleClick = (e: any) => {
    if (gotoPanel) {
      gotoPanel("comments");
      e.preventDefault();
      e.stopPropagation();
    }
  };
  return (
    <TooltipForChild
      tooltip={() => <ConvoPreview cardId={cardId} convoInfo={convoInfo} />}
      delayed={true}
      bg="gray100"
      placement="right"
    >
      <DSConversationIcon
        className={css({pointerEvents: "auto"})}
        convoInfo={convoInfo}
        size={size === "sm" ? 20 : 24}
        {...({onClick: handleClick} as any)}
      />
    </TooltipForChild>
  );
};
