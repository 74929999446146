import {useClickOutside, rules, XForm, ToggleButtonWithoutLabel, isMac} from "@cdx/common";
import {api} from "../../lib/api";
import {ColorSelectWithLabel, defaultColors} from "../../components/ColorSelect";
import useStateIfLoaded from "../../lib/hooks/useStateIfLoaded";
import Helper from "../../components/Helper";
import {
  getProjectAccessTypesForUser,
  ProjectMultiPickerWithLabel,
} from "../../components/ProjectMultiPicker";
import {
  dayToDate,
  getStartOfMonday,
  superShortDate,
  toDateStr,
  weekdayNames,
} from "../../lib/date-utils";
import {createPersistStore} from "../../lib/hooks/usePersistStore";
import {hasPermissionToModifyMilestoneIsGlobal} from "../../lib/permissions";
import {useRoot} from "../../lib/mate/mate-utils";
import {
  Box,
  Col,
  DSButton,
  DSFieldLabel,
  DSIconButton,
  DSIconClose,
  Row,
  css,
  DSSelectionButton,
} from "@cdx/ds";
import dsStyles from "@cdx/ds/css/index.css";
import {
  MsFormButton,
  MsFormProjectTile,
  colorCodeToName,
  getPreselectedProjectIds,
} from "./ms-form-utils";
import {WrappedDSInput, WrappedDSSelect} from "@cdx/common/forms/WrappedDSInput";
import {pluralize} from "../../lib/utils";
import useMutation from "../../lib/hooks/useMutation";
import {msMainColors} from "./milestone-theme.css";
import {forwardRef} from "react";
import {cdxRequest} from "../../lib/request";
import {range} from "lodash";
import {useHistory} from "react-router";
import routes from "../../routes";

const getFormRules = (root, isCreating) => {
  const projectAccess = getProjectAccessTypesForUser(root);
  const manageableProjectIds = new Set(
    projectAccess.filter((pa) => pa.type === "default").map((pa) => pa.project.id)
  );

  return (values) => ({
    sprintConfigName: [rules.isRequired, rules.maxLength(5)],
    color: [rules.isRequired],
    isProjectSpecific: [],
    upcomingSprints: [rules.isRequired],
    sprintDurationWeeks: [rules.isRequired],
    ...(isCreating
      ? {sprintStartDate: [rules.isRequired]}
      : {sprintStartWeekday: [rules.isRequired]}),
    moveUndone: [rules.isRequired],
    moveReview: [rules.isRequired],
    moveBlocked: [rules.isRequired],
    endHour: [rules.isRequired, rules.isDigits],
    endHourTimezone: [rules.isRequired],
    ...(values.isProjectSpecific && {
      projectIds: [
        [
          (ids) => ids.some((id) => manageableProjectIds.has(id)),
          "at least one project you can manage is required",
        ],
      ],
    }),
  });
};

const upcomingSprintNums = [1, 2, 3, 4, 5, 6, 8, 10, 12, 15];
const sprintDurationNums = [1, 2, 3, 4, 5, 6, 7, 8, 10, 12];

const cachedTimezones = [];
/**
 * @returns {Promise<{name: string}[]>}
 */
const getTimezones = async () => {
  if (!cachedTimezones.length) {
    const data = await cdxRequest({
      path: "/services/timezones",
      method: "GET",
    });
    cachedTimezones.push(...data.timezones);
  }
  return cachedTimezones;
};

const TimeZoneField = forwardRef(({value, onChange}, ref) => (
  <DSSelectionButton
    ref={ref}
    getOptions={getTimezones}
    optionToKey={(tz) => tz.name}
    optionToSearchString={(tz) => tz.name}
    renderOption={(tz) => tz.name}
    value={value}
    onSelect={(tz) => onChange(tz)}
    renderButtonContent={(tz) => tz ?? "None"}
    size="md"
    className={css({maxWidth: "120px", textWrap: "nowrap"})}
  />
));

const HourField = forwardRef(({value, onChange}, ref) => (
  <DSSelectionButton
    ref={ref}
    getOptions={() => range(25)}
    optionToKey={(h) => h}
    optionToSearchString={(h) => `${h}:00`}
    renderOption={(h) => `${h}:00`}
    value={value}
    onSelect={(h) => onChange(h)}
    renderButtonContent={(h) => `${h}:00` ?? "---"}
    size="md"
  />
));

const BaseForm = ({
  sprintConfig, // is empty when creating sprint
  buttonLabel,
  onSubmit,
  root,
  values,
  onChange,
  canModifyIsGlobal,
  projectPickerHint,
  isCreating,
}) => {
  const sprintConfigId = sprintConfig?.$meta.get("id", null);
  return (
    <XForm
      onSubmit={onSubmit}
      values={values}
      onChange={onChange}
      rules={getFormRules(root, isCreating)}
    >
      <Col colorTheme="gray50" bg="foreground" px="24px" py="16px" sp="16px">
        <Row sp="8px" align="center">
          <Box textTransform="uppercase" color="primary" bold size={12}>
            Is project-specific
          </Box>
          <Helper heading="What is this?">
            <p>
              For teams with many projects it might be helpful to assign Runs to certain projects
              such that different members of your team will only see Runs that are relevant to them.
            </p>
          </Helper>

          <XForm.Field
            className={dsStyles.ml.auto}
            as={ToggleButtonWithoutLabel}
            name="isProjectSpecific"
            disabled={!canModifyIsGlobal}
            tooltip={!canModifyIsGlobal && "Only admins may change this property"}
          />
        </Row>
        {values.isProjectSpecific && (
          <Col sp="16px">
            <XForm.Field
              as={ProjectMultiPickerWithLabel}
              name="projectIds"
              root={root}
              projectTileComp={MsFormProjectTile}
              projectTileProps={{
                getCardCountForProject:
                  sprintConfigId &&
                  ((projectId) =>
                    root.account.$meta.count("cards", {
                      sprint: {sprintConfigId},
                      deck: {projectId},
                    })),
              }}
            />
            {projectPickerHint && (
              <Box size={12} color="secondary">
                {projectPickerHint}
              </Box>
            )}
          </Col>
        )}
      </Col>
      <Row sp="48px" px="24px" py="16px">
        <Col sp="16px" style={{width: 200}}>
          <XForm.Field
            as={WrappedDSInput}
            name="sprintConfigName"
            label="Short Name"
            autoFocus
            hint="Short 1-5 letter name to allow differentiating multiple parallel Runs"
          />
          <XForm.Field
            as={ColorSelectWithLabel}
            name="color"
            label="Color"
            className={css({pb: "24px"})}
          />
        </Col>
        <Col sp="16px" style={{width: 250}}>
          <XForm.Field
            as={WrappedDSSelect}
            name="sprintDurationWeeks"
            label="Duration"
            options={sprintDurationNums.map((num) => ({
              value: num,
              label: pluralize(num, "Week"),
            }))}
          />
          {isCreating ? (
            <XForm.Field
              as={WrappedDSInput}
              type="date"
              name="sprintStartDate"
              label="Start day"
              min={toDateStr(getStartOfMonday(new Date()))}
            />
          ) : (
            <XForm.Field
              as={WrappedDSSelect}
              name="sprintStartWeekday"
              label="Start day"
              options={weekdayNames.map((n, i) => ({value: (i + 1) % 7, label: n}))}
            />
          )}
          <XForm.Field
            as={WrappedDSSelect}
            name="upcomingSprints"
            label="Number of upcoming Runs to create"
            options={upcomingSprintNums.map((num) => ({
              value: num,
              label: pluralize(num, "Run"),
            }))}
          />
        </Col>
        <Col sp="16px" style={{width: 225}}>
          <Col sp="4px">
            <DSFieldLabel>Finish at</DSFieldLabel>
            <Row sp="8px" align="start" pb="16px">
              <XForm.Field as={HourField} name="endHour" label={null} />
              <XForm.Field as={TimeZoneField} name="endHourTimezone" label={null} />
            </Row>
          </Col>
          <DSFieldLabel>
            At end of a Run, automatically move Cards to the next Run that are:
          </DSFieldLabel>
          <Row sp="8px" align="center">
            <XForm.Field as={ToggleButtonWithoutLabel} name="moveReview" label="In review" />
            <Box size={14} color="primary">
              In review
            </Box>
          </Row>
          <Row sp="8px" align="center">
            <XForm.Field as={ToggleButtonWithoutLabel} name="moveBlocked" label="Blocked" />
            <Box size={14} color="primary">
              Blocked
            </Box>
          </Row>
          <Row sp="8px" align="center">
            <XForm.Field as={ToggleButtonWithoutLabel} name="moveUndone" label="Other undone" />
            <Box size={14} color="primary">
              Other undone
            </Box>
          </Row>
          <MsFormButton>{buttonLabel}</MsFormButton>
        </Col>
      </Row>
    </XForm>
  );
};

const persistStore = createPersistStore({
  elementToKey: () => `create-sprint`,
});

export const SprintConfigCreateForm = ({onClose}) => {
  const root = useRoot();
  const canModifyIsGlobal = hasPermissionToModifyMilestoneIsGlobal(root);

  const {state: data, setState: setData} = useStateIfLoaded(() => {
    const stored = persistStore.getStoredValue(null) || {};
    return {
      sprintConfigName: "",
      color: defaultColors[0],
      upcomingSprints: 2,
      sprintDurationWeeks: 2,
      sprintStartDate: toDateStr(new Date()),
      moveUndone: false,
      moveReview: false,
      moveBlocked: false,
      isProjectSpecific: !hasPermissionToModifyMilestoneIsGlobal(root),
      projectIds: getPreselectedProjectIds(root),
      endHour: 24,
      endHourTimezone: root.loggedInUser.timezone || "UTC",
      ...stored,
    };
  });

  persistStore.usePersistValues(null, () => {
    if (data.sprintConfigName.trim()) return data;
  });

  const history = useHistory();

  const handleSubmit = ({
    sprintConfigName,
    color,
    upcomingSprints,
    sprintDurationWeeks,
    sprintStartDate,
    moveUndone,
    moveReview,
    moveBlocked,
    projectIds,
    isProjectSpecific,
    endHour,
    endHourTimezone,
  }) => {
    return api.mutate.sprints
      .createConfig({
        name: sprintConfigName,
        color: colorCodeToName(color),
        upcomingSprints,
        sprintDurationWeeks,
        sprintStartDate,
        moveOnFinish: [
          ...(moveUndone ? ["undone"] : []),
          ...(moveReview ? ["review"] : []),
          ...(moveBlocked ? ["blocked"] : []),
        ],
        projectIds: isProjectSpecific ? projectIds : [],
        isGlobal: !isProjectSpecific,
        endHour,
        endHourTimezone,
      })
      .then(({sprints}) => {
        persistStore.clearStoredValue(null);
        onClose();
        if (sprints.length) {
          history.push(routes.sprint.getUrl(sprints[0].accountSeq));
        }
      });
  };

  const clickOutsideHandlers = useClickOutside(onClose);

  return (
    <div {...clickOutsideHandlers}>
      <Row sp="12px" py="16px" px="24px">
        <Box size={16} bold as="h2">
          Set up new Runs
        </Box>
        <Box ml="auto">
          <DSIconButton onClick={onClose} negatePadding icon={<DSIconClose />} variant="tertiary" />
        </Box>
      </Row>
      <BaseForm
        values={data}
        onChange={setData}
        onSubmit={handleSubmit}
        root={root}
        buttonLabel="Set up new Runs"
        projectPickerHint="Initial project selection is based on project visibility within Mission Control."
        canModifyIsGlobal={canModifyIsGlobal}
        isCreating
      />
    </div>
  );
};

const StopOnSection = ({sprintConfig}) => {
  const [doUpdate] = useMutation("sprints", "updateConfig");
  const handleClick = () => {
    return doUpdate({id: sprintConfig.id, stopOn: null});
  };

  return (
    <Col pb="16px" px="24px">
      <Col
        colorTheme="purple25"
        bg="foreground"
        borderWidth={1}
        borderColor="default"
        pa="12px"
        sp="12px"
        rounded={4}
        align="center"
      >
        <Box size={14} color="primary" textAlign="center">
          Runs have been stopped.
          <br />
          No more Runs will be created past <b>{superShortDate(dayToDate(sprintConfig.stopOn))}</b>.
        </Box>
        <DSButton onClick={handleClick}>Resume</DSButton>
      </Col>
    </Col>
  );
};

export const SprintConfigEditForm = ({sprintConfig, onClose}) => {
  const clickOutsideHandlers = useClickOutside(onClose);

  const root = useRoot();

  const {
    state: data,
    setState: setData,
    isLoaded,
  } = useStateIfLoaded(() => ({
    isProjectSpecific: !sprintConfig.isGlobal,
    sprintConfigName: sprintConfig.name,
    color: msMainColors[sprintConfig.color] ? msMainColors[sprintConfig.color] : sprintConfig.color,
    upcomingSprints: sprintConfig.upcomingSprints,
    sprintDurationWeeks: sprintConfig.sprintDurationWeeks,
    sprintStartWeekday: sprintConfig.sprintStartWeekday,
    moveUndone: sprintConfig.moveOnFinish.includes("undone"),
    moveReview: sprintConfig.moveOnFinish.includes("review"),
    moveBlocked: sprintConfig.moveOnFinish.includes("blocked"),
    projectIds: sprintConfig.$meta
      .find("sprintProjects", {project: {visibility: "default"}})
      .map((mp) => mp.projectId),
    endHour: sprintConfig.endHour,
    endHourTimezone: sprintConfig.endHourTimezone,
  }));

  const canModifyIsGlobal = hasPermissionToModifyMilestoneIsGlobal(root);
  const [doUpdate] = useMutation("sprints", "updateConfig");

  const handleSubmit = ({
    sprintConfigName,
    color,
    upcomingSprints,
    sprintDurationWeeks,
    sprintStartWeekday,
    moveUndone,
    moveReview,
    moveBlocked,
    isProjectSpecific,
    projectIds,
    endHour,
    endHourTimezone,
  }) => {
    if (!isLoaded) return;
    return doUpdate({
      id: sprintConfig.id,
      name: sprintConfigName,
      color: colorCodeToName(color),
      isGlobal: !isProjectSpecific,
      projectIds: isProjectSpecific ? projectIds : [],
      upcomingSprints,
      sprintDurationWeeks,
      sprintStartWeekday,
      moveOnFinish: [
        ...(moveUndone ? ["undone"] : []),
        ...(moveReview ? ["review"] : []),
        ...(moveBlocked ? ["blocked"] : []),
      ],
      endHour,
      endHourTimezone,
    }).then(onClose);
  };

  return (
    <div {...clickOutsideHandlers}>
      <Row sp="12px" py="16px" px="24px">
        <Box size={16} bold as="h2">
          Manage Run Configuration
        </Box>
        <Box ml="auto">
          <DSIconButton onClick={onClose} negatePadding icon={<DSIconClose />} variant="tertiary" />
        </Box>
      </Row>
      {sprintConfig.stopOn && <StopOnSection sprintConfig={sprintConfig} />}
      <BaseForm
        values={data}
        sprintConfig={sprintConfig}
        onChange={setData}
        onSubmit={handleSubmit}
        root={root}
        canModifyIsGlobal={canModifyIsGlobal}
        buttonLabel="Save"
        projectPickerHint={`${
          isMac() ? "Cmd" : "Ctrl"
        } + Click selects target project and deselects all others.`}
      />
    </div>
  );
};

export const SprintEditForm = ({sprint, onClose}) => {
  const clickOutsideHandlers = useClickOutside(onClose);

  const {state, setState, isLoaded} = useStateIfLoaded(() => ({
    sprintName: sprint.name || "",
  }));

  const [doUpdate] = useMutation("sprints", "updateSprint");

  const handleSubmit = () => {
    if (!isLoaded) return;
    return doUpdate({id: sprint.id, name: (state.sprintName || "").trim() || null}).then(onClose);
  };

  return (
    <div {...clickOutsideHandlers}>
      <Row sp="12px" py="16px" px="24px">
        <Box size={16} bold as="h2">
          Edit Run
        </Box>
        <Box ml="auto">
          <DSIconButton onClick={onClose} negatePadding icon={<DSIconClose />} variant="tertiary" />
        </Box>
      </Row>
      <Col px="24px" py="16px" sp="8px" styleChild>
        <XForm onSubmit={handleSubmit} values={state} onChange={setState} rules={{sprintName: []}}>
          <XForm.Field
            as={WrappedDSInput}
            name="sprintName"
            label="Custom Label"
            autoFocus
            hint={`If left empty, will be displayed as "Run ${sprint.index + 1}"`}
          />
          <MsFormButton>Update Run</MsFormButton>
        </XForm>
      </Col>
    </div>
  );
};
