import {Card, CardId} from "../../../../cdx-models/Card";
import {Resolvable, ResolvableId} from "../../../../cdx-models/Resolvable";
import {Root} from "../../../../cdx-models/Root";
import {Location} from "history";
import DSAvatar, {DSAvatarProps, avatarSizes} from "../../../../components/DSAvatar/DSAvatar";
import {Box, DSIconShield} from "@cdx/ds";
import {Dispatch, ReactElement, SetStateAction, forwardRef} from "react";
import {convoStyles as styles} from "./convo.css";
import {User, UserId} from "../../../../cdx-models/User";
import {DISCORD_USER_ID} from "../../../../lib/ids";
import {CdxCropImg} from "../../../../components/CdxImg";
import {cx} from "@cdx/common";
import {DSAvatarStyles} from "../../../../components/DSAvatar/DSAvatar.css";
import dsStyles from "@cdx/ds/css/index.css";
import {DiscordMember} from "../../../../cdx-models/DiscordMember";
import {Notifications} from "../../../notifications/useNotifications";
import {useNow} from "../../../../components/FromNow";
import {formatForHumans} from "../../../../lib/date-utils";

type AvatarDiscordInfo = {
  nick: string;
  name: string;
  discriminator: string;
  avatar: string | null;
  id: string;
};

const getAvatarInfoFromDiscordMember = (member: DiscordMember): AvatarDiscordInfo => {
  return {
    nick: member.nick,
    name: member.name,
    discriminator: member.discriminator,
    avatar: member.avatar,
    id: member.discordUserId, // or member.id??
  };
};

type GetConvoBagProps = {
  root: Root;
  card: Card;
  status: string;
  onReceiveFile: (f: any) => any;
  canModify: boolean | null;
  onReopen: (resId: ResolvableId) => void;
  onCloseThread: (resId: ResolvableId) => void;
  onCardDone?: () => void;
  location: Location;
  lastClosedResolvableId: ResolvableId | null;
  onEditCardClick?: () => void;
  onScrollToTarget: () => void;
  notifications: Notifications;
};
export const createConvoBag = (props: GetConvoBagProps) => {
  const {card} = props;
  return {
    ...props,
    project: card.deck?.project ?? null,
    cardGuardiansIdSet: card.deck?.hasGuardians
      ? new Set(card.deck.guardians.map((g) => g.user.id))
      : null,
  };
};

export type ConvoBag = ReturnType<typeof createConvoBag>;

const emptyMap = new Map<UserId, AvatarDiscordInfo>();

const getDiscordMembers = (resolvable: Resolvable, card: Card) => {
  const discordGuild = card.$meta.get("discordGuild", null);
  if (!discordGuild) return emptyMap;
  const getAvatarInfo = (discordUserId: string | null): null | AvatarDiscordInfo => {
    if (!discordUserId) return null;
    const member = discordGuild.$meta.find("members", {discordUserId})[0];
    return member && getAvatarInfoFromDiscordMember(member);
  };
  const entries = resolvable.participants
    .map((p) => [p.user.id as UserId, getAvatarInfo(p.discordUserId)!] as const)
    .filter(([, member]) => member);
  return new Map<UserId, AvatarDiscordInfo>(entries);
};
export const createResolvableBag = (
  resolvable: Resolvable,
  bag: ConvoBag,
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>> | null
) => {
  return {
    discordMembers: getDiscordMembers(resolvable, bag.card),
    resolvable,
    isOpen,
    setIsOpen,
    ...bag,
  };
};

export type ResolvableBag = ReturnType<typeof createResolvableBag>;

type ConvoAvatarProps = DSAvatarProps &
  ({resBag: ResolvableBag; bag?: undefined} | {resBag?: undefined; bag: ConvoBag});

export const ConvoAvatar = forwardRef<HTMLDivElement, ConvoAvatarProps>(
  ({resBag, bag, className, ...props}, ref) => {
    if (resBag && props.user?.id === DISCORD_USER_ID) {
      const {discordMembers} = resBag;
      const discordInfo = discordMembers.get(props.user?.id as UserId);
      if (discordInfo) {
        return (
          <DiscordAvatar className={className} {...props} ref={ref} discordInfo={discordInfo} />
        );
      }
      return <DSAvatar className={className} {...props} ref={ref} />;
    }
    const isGuardian = (resBag || bag).cardGuardiansIdSet?.has(props.user?.id as UserId) ?? false;
    if (isGuardian) {
      return (
        <GuardianContainer className={className}>
          <DSAvatar {...props} ref={ref} />
        </GuardianContainer>
      );
    }

    return <DSAvatar className={className} {...props} ref={ref} />;
  }
);

const GuardianContainer = ({
  children,
  size = 24,
  className,
}: {
  children: ReactElement;
  className?: string;
  size?: keyof (typeof DSAvatarStyles)["sizes"];
}) => (
  <Box relative className={cx(DSAvatarStyles.sizes[size], className)}>
    {children}
    <Box absolute bottom="-2px" right="-2px" pointerEvents="none">
      <DSIconShield className={styles.guardIcon} size={16} />
    </Box>
  </Box>
);

type DiscordAvatarProps = {
  discordInfo: AvatarDiscordInfo;
} & DSAvatarProps;

const DiscordAvatar = forwardRef<HTMLDivElement, DiscordAvatarProps>(
  ({discordInfo, ...props}, ref) => {
    const {name, discriminator, avatar, id, nick} = discordInfo;
    if (!discordInfo.avatar) return <DSAvatar {...props} ref={ref} />;
    const {size = 24, className, user, flat, ...rest} = props;
    const imgSize = size - 2;
    const shownName = nick || `${name}#${discriminator}`;
    const url = `https://cdn.discordapp.com/avatars/${id}/${avatar}.png`;
    return (
      <Box relative className={cx(className, DSAvatarStyles.sizes[size])}>
        <CdxCropImg
          width={imgSize}
          height={imgSize}
          pickSize={avatarSizes}
          src={url}
          alt={shownName}
          title={shownName}
          className={cx(
            !flat && dsStyles.elevation[100],
            rest.onClick && dsStyles.cursor.pointer,
            DSAvatarStyles.img
          )}
          {...rest}
          ref={ref}
        />
        <Box absolute style={{bottom: -2, right: -2}} pointerEvents="none">
          <DSAvatar user={user} size={14} />
        </Box>
      </Box>
    );
  }
);

export const DSAvatarWithDiscordInfo = DiscordAvatar;

const getDiscordCreationInfoFromCard = (card: Card): AvatarDiscordInfo | null => {
  const discordInfo = card.meta.discord;
  if (!discordInfo) return null;
  const getDiscordMember = () => {
    if (card.meta.discord?.slashCommandId) {
      const discordGuild = card.$meta.get("discordGuild", null);
      if (!discordGuild) return null;
      return discordGuild.$meta.find("members", {
        discordUserId: card.meta.discord.authorDiscordId,
      })[0];
    }
    return null;
  };
  const discordMember = getDiscordMember();
  return discordMember && getAvatarInfoFromDiscordMember(discordMember);
};

export const DSDiscordCreatedAwareAvatar = forwardRef<
  HTMLDivElement,
  DSAvatarProps & {card: Card | null}
>(({card, ...props}, ref) => {
  if (card && props.user?.id === DISCORD_USER_ID) {
    const discordInfo = getDiscordCreationInfoFromCard(card);
    if (discordInfo) return <DiscordAvatar {...props} ref={ref} discordInfo={discordInfo} />;
  }
  return <DSAvatar {...props} ref={ref} />;
});

export const DSDiscordConvoAwareAvatar = forwardRef<
  HTMLDivElement,
  DSAvatarProps & {card: Card | null; resolvable: Resolvable | null}
>(({card, resolvable, ...props}, ref) => {
  if (card && resolvable && props.user?.id === DISCORD_USER_ID) {
    const discordMembers = getDiscordMembers(resolvable, card);
    const discordInfo = discordMembers.get(props.user.id as UserId);
    if (discordInfo) return <DiscordAvatar {...props} ref={ref} discordInfo={discordInfo} />;
  }
  return <DSAvatar {...props} ref={ref} />;
});

export const getConvoUserName = (user: User | null, resBag: ResolvableBag) => {
  // user can be  null in open decks
  if (!user) return "unknown";
  const {discordMembers} = resBag;
  if (user.id === DISCORD_USER_ID) {
    const discordInfo = discordMembers.get(user.id as UserId);
    if (discordInfo) return `${discordInfo.nick || discordInfo.name} via Discord`;
  }
  return user.name;
};

export const SnoozeUntilLabel = ({snoozeUntil}: {snoozeUntil: Date}) => {
  useNow();
  return formatForHumans(snoozeUntil);
};

// relevant for bulk selection (remind me later for multiple cards)
export const getParticipatingResIdsForCards = (cardIds: CardId[], root: Root) => {
  const meId = root.loggedInUser.$meta.get("id", null);
  if (!meId) return [];
  return root.account.$meta
    .find("resolvables", {
      cardId: cardIds,
      isClosed: false,
      participants: {
        user: {id: meId},
        done: false,
      },
    })
    .map((r) => r.$meta.get("id", null)!);
};
