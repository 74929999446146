import {Suspense, forwardRef, lazy, useMemo} from "react";
import {shrinker} from "@cdx/common";
import getEmojiRegex from "emoji-regex";
import CardMention from "./CardMention";
import {MagicTag} from "./CardTags";
import {pronounceSafeSeq} from "../../lib/sequences";
import Emoji from "./Emoji";
import {DSSpinner} from "@cdx/ds";

export const emojiRe = getEmojiRegex();

const LazyCardReference = lazy(() => import("./CardReference"));
const CardReference = (props) => (
  <Suspense fallback={<DSSpinner size={16} />}>
    <LazyCardReference {...props} />
  </Suspense>
);

const LazyDeckReference = lazy(() => import("./DeckReference"));
const DeckReference = (props) => (
  <Suspense fallback={<DSSpinner size={16} />}>
    <LazyDeckReference {...props} />
  </Suspense>
);

const emojiLocator = (value, fromIndex) => {
  emojiRe.lastIndex = fromIndex;
  const m = emojiRe.exec(value);
  if (!m) return -1;
  return m.index;
};

const cdxTransformers = [
  {
    type: "mention",
    locatorString: "@[userId",
    regex: /^@\[userId:([\w-]+)\]/,
    matchToProps: (m) => ({userId: m[1]}),
    component: CardMention,
    length: () => 8,
  },
  {
    type: "tag",
    locatorString: "#",
    regex: /^#([\w-.]+)/,
    matchToProps: (m) => ({tag: m[1]}),
    docPropsToProps: ({noOnClick, projectId, onDark, onGray}) => ({
      noOnClick,
      projectId,
      onDark,
      onGray,
    }),
    component: MagicTag,
    length: (p) => p.tag.length,
  },
  {
    type: "deckReference",
    locatorString: "$",
    regex: /^\$\[deck:(\d+)\]/,
    matchToProps: (m) => ({seq: m[1]}),
    docPropsToProps: ({noOnClick}) => ({noOnClick}),
    component: DeckReference,
    length: () => 20,
  },
  {
    type: "reference",
    locatorString: "$",
    regex: new RegExp(`^\\$([${pronounceSafeSeq.letters}]{3,5})(?:\\b|$)`),
    matchToProps: (m) => ({id: m[1]}),
    docPropsToProps: ({noOnClick}) => ({noOnClick}),
    component: CardReference,
    length: () => 20,
  },
  {
    type: "emoji",
    locator: emojiLocator,
    regex: new RegExp(`^(?:${emojiRe.source})`),
    matchToProps: (m) => ({decoratedText: m[0]}),
    component: Emoji,
    length: () => 1,
    toString: (props) => props.decoratedText,
  },
];

const breakTransformer = {
  type: "break",
  locatorString: "\n",
  regex: /^\n+/,
  matchToProps: (m) => ({}),
  component: "br",
  length: () => 0,
};
const withBreakTransformers = [breakTransformer, ...cdxTransformers];

export const processCdxTokens = ({
  content: rawContent,
  maxChars = Number.POSITIVE_INFINITY,
  projectId,
  onDark,
  onGray,
  addBreaks,
  noOnClick,
  skipCardReferences,
}) => {
  const content = rawContent || "";
  const parts = [{type: "text", value: content, len: content.length}];
  const getTransformerList = () => {
    const list = addBreaks ? withBreakTransformers : cdxTransformers;
    return skipCardReferences ? list.filter((t) => t.type === "references") : list;
  };
  const transList = getTransformerList();
  for (const transformer of transList) {
    const {
      locator: transLocator,
      locatorString,
      regex,
      matchToProps,
      docPropsToProps,
      type,
      component: Comp,
      length,
    } = transformer;
    const locator = transLocator || ((value, fromIndex) => value.indexOf(locatorString, fromIndex));
    let partIdx = 0;
    while (partIdx < parts.length) {
      const part = parts[partIdx];
      partIdx += 1;
      if (part.type !== "text") continue;
      const text = part.value;
      let i = 0;
      while (i <= text.length) {
        i = locator(text, i);
        if (i === -1) break;
        const match = regex.exec(text.slice(i));
        if (match) {
          const nextParts = [];
          if (i > 0) nextParts.push({type: "text", value: text.slice(0, i), len: i});
          const props = matchToProps(match);
          nextParts.push({
            type,
            value: (
              <Comp
                key={`${partIdx}-${i}`}
                {...props}
                {...(docPropsToProps && docPropsToProps({projectId, onDark, noOnClick, onGray}))}
              />
            ),
            len: length(props),
          });
          let addedAfter = false;
          if (i + match[0].length < text.length) {
            addedAfter = true;
            const newText = text.slice(i + match[0].length);
            nextParts.push({type: "text", value: newText, len: newText.length});
          }
          parts.splice(partIdx - 1, 1, ...nextParts);
          partIdx += nextParts.length - (addedAfter ? 2 : 1);
          break;
        }
        i += 1;
      }
    }
  }
  const finalParts = [];
  let len = 0;
  let isShortened = false;
  for (const part of parts) {
    if (part.type === "text") {
      if (len + part.len > maxChars) {
        isShortened = true;
        finalParts.push(shrinker(part.value, maxChars - len));
        break;
      } else {
        finalParts.push(part.value);
        len += part.len;
      }
    } else {
      finalParts.push(part.value);
      len += part.len;
      if (len >= maxChars) {
        isShortened = true;
        break;
      }
    }
  }
  return {result: finalParts, isShortened};
};

/** @type any */
const UnMarkedown = forwardRef(
  (
    {
      children,
      projectId,
      maxChars,
      onDark,
      onGray,
      as: Comp = "div",
      addBreaks,
      skipCardReferences,
      ...other
    },
    ref
  ) => {
    const {result} = useMemo(
      () =>
        processCdxTokens({
          content: children,
          maxChars,
          projectId,
          onDark,
          onGray,
          addBreaks,
          noOnClick: true,
          skipCardReferences,
        }),
      [children, maxChars, projectId, onDark, onGray, addBreaks, skipCardReferences]
    );

    return (
      <Comp {...other} ref={ref}>
        {result}
      </Comp>
    );
  }
);

export default UnMarkedown;
