import {Card} from "../../../cdx-models/Card";
import {Query} from "../../../cdx-models/utils/MakeModel";
import {SearchCategory} from "../search-types";

const cmpMap = {gte: <>&gt;=&nbsp;</>, lte: <>&lt;=&nbsp;</>, eq: null};

type Val = {effort: number | null; cmp: "eq" | "gte" | "lte"};

const effortCmpToFilter = ({effort, cmp}: Val): Query<Card> => {
  switch (cmp) {
    case "eq":
      return {effort};
    case "gte":
      return {effort: {op: cmp, value: effort}};
    case "lte":
      return {$or: [{effort: {op: cmp, value: effort}}, {effort: null}]};
    default:
      return {};
  }
};

const effortCategory: SearchCategory<Val> = {
  label: "Effort",
  key: "effort",
  getSuggestions({input, root}) {
    if ("effort".startsWith(input)) {
      const {effortScale} = root.account;
      return [
        ...effortScale.map((e) => ({effort: e, cmp: "eq"})),
        {effort: null, cmp: "eq"},
      ] as Val[];
    }
    if (/^\d+$/.test(input)) {
      const num = parseInt(input, 10);
      return [
        {effort: num, cmp: "eq"},
        {effort: num, cmp: "gte"},
        {effort: num, cmp: "lte"},
      ];
    }
    return [];
  },
  valueToKey({effort, cmp}) {
    return `${cmp}${effort}`;
  },
  valuesToDbQuery(values) {
    if (values.length === 1) {
      return effortCmpToFilter(values[0]);
    } else if (values.every(({cmp}) => cmp === "eq")) {
      return {effort: values.map((v) => v.effort)};
    } else {
      return {$or: values.map((val) => effortCmpToFilter(val))};
    }
  },
  renderSuggestion({effort, cmp}) {
    return effort === null ? (
      "none"
    ) : (
      <>
        {cmpMap[cmp] || ""}
        {effort}
      </>
    );
  },
  renderPill({effort, cmp}) {
    return effort === null ? (
      "No effort"
    ) : (
      <>
        {cmpMap[cmp] || ""}
        {effort}
      </>
    );
  },
  savedSearchLabel({effort, cmp}) {
    const cmpToLabel = {gte: "+", lte: "-", eq: ""};
    return {
      prio: 2,
      label: effort === null ? "no effort" : `${effort}${cmpToLabel[cmp]}`,
    };
  },
};

export default effortCategory;
