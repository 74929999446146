import {Account} from "../cdx-models/Account";

export const FEATURE_FLAGS = {
  projectCardMarker: "projectCardMarker",
  visionBoard: "visionBoard",
  projectEffortIcon: "projectEffortIcon",
  msStartDate: "msStartDate",
  zoneIconOnCard: "zoneIconOnCard",
  notificationV2: "notificationV2",
  myCardsButton: "myCardsButton",
} as const;

type Flags = typeof FEATURE_FLAGS;

// use like
// const discordActive = useFeatureFlag(account, FEATURE_FLAGS.discordIntegration)

const useFeatureFlag = (account: Account, flag: Flags[keyof Flags]): boolean | null => {
  if (!account) return null;
  return account.activeFeatureFlags[flag] ?? false;
};

export const hasFeatureFlag = useFeatureFlag;

export default useFeatureFlag;

// export const isStageOrSuperAdmin = (root) => {
//   if (process.env.REACT_APP_IS_STAGE === "true") return true;
//   return root.loggedInUser && root.loggedInUser.isSuperAdmin;
// };

/*
how to use:

hand: {
  noDefaultLayout: true,
  exact: true,
  path: ["", "/", "/card/:cardId", "/metrics", "/activity", "/upvotes"],
  getUrl: () => "",
  component: SplitByFeatureRoute,
  props: {
    base: lazy(() => import( "./pages/hand")),
    alternative: lazy(() =>
      import("./features/hand-queue/HandQueueDashboard")
    ),
    isAlternativeActive: (root) => {
      const enabled = root.account.$meta.get("handV2Enabled", null);
      return enabled === null
        ? {isLoading: true, isActive: null}
        : {isLoading: false, isActive: enabled};
    },
  },
},
*/

// export const SplitByFeatureRoute = ({
//   base: BaseComp,
//   alternative: AltComp,
//   isAlternativeActive,
//   root,
//   ...rest
// }) => {
//   const {isLoading, isActive} = isAlternativeActive(root);
//   if (isLoading) return <Spinner />;
//   return isActive ? <AltComp root={root} {...rest} /> : <BaseComp root={root} {...rest} />;
// };
