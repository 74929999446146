import {Id, ModelName} from "./mate-types";

export type ChangeMap = Map<ModelName, Set<Id>>;

const mergeInto = (target: ChangeMap, from: ChangeMap) => {
  for (const [key, value] of from) {
    const exist = target.get(key);
    if (exist) {
      value.forEach((v) => exist.add(v));
    } else {
      target.set(key, value);
    }
  }
};

export const mergeChanges = (
  targetChanges: Changes["changes"],
  fromChanges: Changes["changes"]
) => {
  mergeInto(targetChanges.touched, fromChanges.touched);
  mergeInto(targetChanges.updated, fromChanges.updated);
};

export class Changes {
  changes = {
    updated: new Map() as ChangeMap,
    touched: new Map() as ChangeMap,
  };
  // 'type' can also be "touched"
  // 'touched' means that no data has been changed, but that data that has been uncertain (STATUS.INVALID)
  // is now certain again. this leads to calling _clearCache on loaded instances
  add(model: ModelName, id: Id, type: "updated" | "touched" = "updated") {
    const map = this.changes[type];
    const exist = map.get(model);
    if (exist) {
      exist.add(id);
    } else {
      map.set(model, new Set([id]));
    }
  }
}
