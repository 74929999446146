export const clamp = (val: number, min: number, max: number) => {
  return Math.min(max, Math.max(min, val));
};

export const clampButKeepRatio = (
  input: [number, number],
  frame: [number, number]
): [number, number] => {
  const [inputWidth, inputHeight] = input;
  const [frameWidth, frameHeight] = frame;
  if (inputWidth <= frameWidth && inputHeight <= frameHeight) {
    return input;
  }
  const scale = Math.min(frameWidth / inputWidth, frameHeight / inputHeight);

  return [Math.round(inputWidth * scale), Math.round(inputHeight * scale)];
};
