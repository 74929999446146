// Had to fork it due to https://github.com/facebook/lexical/issues/4214

import type {CommandPayloadType, LexicalEditor} from "lexical";
import {$getHtmlContent} from "@lexical/clipboard";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {$moveCharacter, $shouldOverrideDefaultCharacterSelection} from "@lexical/selection";
import {mergeRegister} from "@lexical/utils";
import {
  $getSelection,
  $isRangeSelection,
  RangeSelection,
  BaseSelection,
  $isNodeSelection,
  $isTextNode,
  $selectAll,
  SELECT_ALL_COMMAND,
  COMMAND_PRIORITY_EDITOR,
  CONTROLLED_TEXT_INSERTION_COMMAND,
  COPY_COMMAND,
  CUT_COMMAND,
  DELETE_CHARACTER_COMMAND,
  DELETE_LINE_COMMAND,
  DELETE_WORD_COMMAND,
  DRAGSTART_COMMAND,
  DROP_COMMAND,
  INSERT_LINE_BREAK_COMMAND,
  INSERT_PARAGRAPH_COMMAND,
  INDENT_CONTENT_COMMAND,
  OUTDENT_CONTENT_COMMAND,
  KEY_ARROW_LEFT_COMMAND,
  KEY_ARROW_RIGHT_COMMAND,
  KEY_BACKSPACE_COMMAND,
  KEY_DELETE_COMMAND,
  KEY_ENTER_COMMAND,
  PASTE_COMMAND,
  REMOVE_TEXT_COMMAND,
  $getNearestNodeFromDOMNode,
  $isDecoratorNode,
  $isLineBreakNode,
  $isParagraphNode,
  $createTextNode,
  TextPoint,
} from "lexical";
import {$canShowPlaceholderCurry} from "@lexical/text";
import useLexicalEditable from "@lexical/react/useLexicalEditable";
import {Suspense, useEffect, useLayoutEffect, useMemo, useState} from "react";
import {createPortal, flushSync} from "react-dom";
import {
  getSelectionInfo,
  indentLine,
  outdentLine,
} from "./CdxSmartPlainTextCompletionPlugin/smartIndentHelpers";
import {parseLine} from "./CdxSmartPlainTextCompletionPlugin/parseMarkdownLineAst";

function $isTargetWithinDecorator(target: HTMLElement): boolean {
  const node = $getNearestNodeFromDOMNode(target);
  return $isDecoratorNode(node);
}

export const sanitizeSelection = (selection: RangeSelection) => {
  // when the anchor or selection is a paragraph node, then `selection.insert` is doing bad stuff
  // so we move to the corresponding text node
  const sanitizePoint = (pt: TextPoint) => {
    const node = pt.getNode();
    if ($isParagraphNode(node)) {
      const child = node.getChildAtIndex(pt.offset - 1);
      if (child && $isTextNode(child)) {
        pt.set(child.__key, child.getTextContentSize(), "text");
      }
    }
  };
  sanitizePoint(selection.focus as TextPoint);
  sanitizePoint(selection.anchor as TextPoint);
};

const documentMode = "documentMode" in document ? document.documentMode : null;

const CAN_USE_BEFORE_INPUT: boolean =
  "InputEvent" in window && !documentMode
    ? "getTargetRanges" in new window.InputEvent("input")
    : false;

const IS_SAFARI: boolean = /Version\/[\d.]+.*Safari/.test(navigator.userAgent);

const IS_IOS: boolean = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;

// Keep these in case we need to use them in the future.
// const IS_WINDOWS: boolean = CAN_USE_DOM && /Win/.test(navigator.platform);
const IS_CHROME: boolean = /^(?=.*Chrome).*/i.test(navigator.userAgent);
// const canUseTextInputEvent: boolean = CAN_USE_DOM && 'TextEvent' in window && !documentMode;

const IS_APPLE_WEBKIT = /AppleWebKit\/[\d.]+/.test(navigator.userAgent) && !IS_CHROME;

function onCopyForPlainText(
  event: CommandPayloadType<typeof COPY_COMMAND>,
  editor: LexicalEditor
): void {
  editor.update(() => {
    if (!event) return;
    const clipboardData = event instanceof KeyboardEvent ? null : event.clipboardData;
    const selection = $getSelection();

    if (selection !== null && clipboardData != null) {
      event.preventDefault();
      const htmlString = $getHtmlContent(editor);

      if (htmlString !== null) {
        clipboardData.setData("text/html", htmlString);
      }

      clipboardData.setData("text/plain", selection.getTextContent());
    }
  });
}

function $insertDataTransferForPlainText(
  dataTransfer: DataTransfer,
  selection: BaseSelection
): void {
  const html = dataTransfer.getData("text/html");
  let text;
  if (html) {
    const div = document.createElement("div");
    div.innerHTML = html;
    div.querySelectorAll<HTMLAnchorElement>("a[href]").forEach((linkNode) => {
      const {textContent, href, parentNode, classList} = linkNode;
      if (!textContent) return;
      const span = document.createElement("span");
      if (classList.contains("is-cdx-reference")) {
        span.textContent = href;
      } else {
        span.textContent = textContent === href ? href : `[${textContent}](${href})`;
      }
      parentNode!.replaceChild(span, linkNode);
    });
    div.querySelectorAll<HTMLImageElement>("img[src]").forEach((imgNode) => {
      const block = document.createElement("div");
      block.textContent = `![${imgNode.alt}](${imgNode.src})`;
      imgNode.parentNode!.replaceChild(block, imgNode);
    });
    document.body.append(div);
    const sel = window.getSelection()!;
    const prevSel = sel && sel.rangeCount > 0 && sel.getRangeAt(0);
    sel.removeAllRanges();
    const r = document.createRange();
    r.selectNode(div);
    sel.addRange(r);
    text = sel.toString();

    sel.removeAllRanges();
    if (prevSel) sel.addRange(prevSel);
    document.body.removeChild(div);
  } else {
    text = dataTransfer.getData("text/plain") || dataTransfer.getData("text/uri-list");
  }
  if (text != null) {
    selection.insertRawText(text);
  }
}

function onPasteForPlainText(
  event: CommandPayloadType<typeof PASTE_COMMAND>,
  editor: LexicalEditor
): void {
  event.preventDefault();
  editor.update(
    () => {
      const selection = $getSelection();
      const clipboardData =
        event instanceof InputEvent || event instanceof KeyboardEvent ? null : event.clipboardData;

      if (clipboardData != null && $isRangeSelection(selection)) {
        $insertDataTransferForPlainText(clipboardData, selection);
      }
    },
    {
      tag: "paste",
    }
  );
}

function onCutForPlainText(
  event: CommandPayloadType<typeof CUT_COMMAND>,
  editor: LexicalEditor
): void {
  onCopyForPlainText(event, editor);
  editor.update(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      selection.removeText();
    }
  });
}

export function registerPlainText(editor: LexicalEditor): () => void {
  const removeListener = mergeRegister(
    editor.registerCommand<boolean>(
      DELETE_CHARACTER_COMMAND,
      (isBackward) => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection)) {
          return false;
        }
        sanitizeSelection(selection);

        selection.deleteCharacter(isBackward);
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    ),
    editor.registerCommand<boolean>(
      DELETE_WORD_COMMAND,
      (isBackward) => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection)) {
          return false;
        }
        sanitizeSelection(selection);

        selection.deleteWord(isBackward);
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    ),
    editor.registerCommand<boolean>(
      DELETE_LINE_COMMAND,
      (isBackward) => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection)) {
          return false;
        }
        sanitizeSelection(selection);

        selection.deleteLine(isBackward);
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    ),
    editor.registerCommand<InputEvent | string>(
      CONTROLLED_TEXT_INSERTION_COMMAND,
      (eventOrText) => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection)) {
          return false;
        }
        sanitizeSelection(selection);
        if (typeof eventOrText === "string") {
          selection.insertText(eventOrText);
        } else {
          const dataTransfer = eventOrText.dataTransfer;

          if (dataTransfer != null) {
            $insertDataTransferForPlainText(dataTransfer, selection);
          } else {
            const data = eventOrText.data;

            if (data) {
              selection.insertText(data);
            }
          }
        }

        return true;
      },
      COMMAND_PRIORITY_EDITOR
    ),
    editor.registerCommand(
      REMOVE_TEXT_COMMAND,
      () => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection)) {
          return false;
        }
        sanitizeSelection(selection);

        selection.removeText();
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    ),
    editor.registerCommand<boolean>(
      INSERT_LINE_BREAK_COMMAND,
      (selectStart) => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection)) {
          return false;
        }
        sanitizeSelection(selection);

        selection.insertLineBreak(selectStart);
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    ),
    editor.registerCommand(
      INSERT_PARAGRAPH_COMMAND,
      () => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection)) {
          return false;
        }
        sanitizeSelection(selection);

        selection.insertLineBreak();
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    ),
    editor.registerCommand(
      INDENT_CONTENT_COMMAND,
      () => {
        const selection = $getSelection();
        if (!$isRangeSelection(selection)) return false;
        sanitizeSelection(selection);
        const selectionInfo = getSelectionInfo(selection);
        if (!selectionInfo) return false;
        for (const line of selectionInfo.lines) {
          const firstChild = line[0];
          if ($isLineBreakNode(firstChild)) continue;
          let diff = 0;
          if (!$isTextNode(firstChild)) {
            firstChild.insertBefore($createTextNode("  "));
            diff = 2;
          } else {
            const prevContent = firstChild.getTextContent();
            const nextContent = indentLine(parseLine(prevContent));
            diff = nextContent.length - prevContent.length;
            firstChild.setTextContent(nextContent);
          }
          if (firstChild.__key === selection.anchor.key) {
            selection.anchor.offset += diff;
          }
          if (firstChild.__key === selection.focus.key) {
            selection.focus.offset += diff;
          }
        }
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    ),
    editor.registerCommand(
      OUTDENT_CONTENT_COMMAND,
      () => {
        const selection = $getSelection();
        if (!$isRangeSelection(selection)) return false;
        sanitizeSelection(selection);
        const selectionInfo = getSelectionInfo(selection);
        if (!selectionInfo) return false;

        for (const line of selectionInfo.lines) {
          const firstChild = line[0];
          if ($isLineBreakNode(firstChild)) continue;
          if (!$isTextNode(firstChild)) continue;
          let diff = 0;

          const prevContent = firstChild.getTextContent();
          const nextContent = outdentLine(parseLine(prevContent));
          diff = nextContent.length - prevContent.length;
          firstChild.setTextContent(nextContent);

          if (firstChild.__key === selection.anchor.key) {
            selection.anchor.offset += diff;
          }
          if (firstChild.__key === selection.focus.key) {
            selection.focus.offset += diff;
          }
        }
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    ),
    editor.registerCommand<KeyboardEvent>(
      KEY_ARROW_LEFT_COMMAND,
      (event) => {
        const selection = $getSelection();
        if ($isNodeSelection(selection)) {
          // If selection is on a node, let's try and move selection
          // back to being a range selection.
          const nodes = selection.getNodes();
          if (nodes.length > 0) {
            event.preventDefault();
            nodes[0].selectPrevious();
            return true;
          }
        }
        if (!$isRangeSelection(selection)) {
          return false;
        }
        if ($shouldOverrideDefaultCharacterSelection(selection, true)) {
          const isHoldingShift = event.shiftKey;
          event.preventDefault();
          $moveCharacter(selection, isHoldingShift, true);
          return true;
        }
        return false;
      },
      COMMAND_PRIORITY_EDITOR
    ),
    editor.registerCommand<KeyboardEvent>(
      KEY_ARROW_RIGHT_COMMAND,
      (event) => {
        const selection = $getSelection();
        if ($isNodeSelection(selection) && !$isTargetWithinDecorator(event.target as HTMLElement)) {
          // If selection is on a node, let's try and move selection
          // back to being a range selection.
          const nodes = selection.getNodes();
          if (nodes.length > 0) {
            event.preventDefault();
            nodes[0].selectNext(0, 0);
            return true;
          }
        }
        if (!$isRangeSelection(selection)) {
          return false;
        }
        if ($shouldOverrideDefaultCharacterSelection(selection, false)) {
          const isHoldingShift = event.shiftKey;
          event.preventDefault();
          $moveCharacter(selection, isHoldingShift, false);
          return true;
        }
        return false;
      },
      COMMAND_PRIORITY_EDITOR
    ),
    editor.registerCommand<KeyboardEvent>(
      KEY_BACKSPACE_COMMAND,
      (event) => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection)) {
          return false;
        }
        sanitizeSelection(selection);
        event.preventDefault();
        return editor.dispatchCommand(DELETE_CHARACTER_COMMAND, true);
      },
      COMMAND_PRIORITY_EDITOR
    ),
    editor.registerCommand<KeyboardEvent>(
      KEY_DELETE_COMMAND,
      (event) => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection)) {
          return false;
        }
        sanitizeSelection(selection);
        event.preventDefault();
        return editor.dispatchCommand(DELETE_CHARACTER_COMMAND, false);
      },
      COMMAND_PRIORITY_EDITOR
    ),
    editor.registerCommand<KeyboardEvent | null>(
      KEY_ENTER_COMMAND,
      (event) => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection)) {
          return false;
        }
        sanitizeSelection(selection);

        if (event !== null) {
          // If we have beforeinput, then we can avoid blocking
          // the default behavior. This ensures that the iOS can
          // intercept that we're actually inserting a paragraph,
          // and autocomplete, autocapitalize etc work as intended.
          // This can also cause a strange performance issue in
          // Safari, where there is a noticeable pause due to
          // preventing the key down of enter.
          if ((IS_IOS || IS_SAFARI || IS_APPLE_WEBKIT) && CAN_USE_BEFORE_INPUT) {
            return false;
          }

          event.preventDefault();
        }

        return editor.dispatchCommand(INSERT_LINE_BREAK_COMMAND, false);
      },
      COMMAND_PRIORITY_EDITOR
    ),
    editor.registerCommand(
      SELECT_ALL_COMMAND,
      () => {
        $selectAll();

        return true;
      },
      COMMAND_PRIORITY_EDITOR
    ),
    editor.registerCommand(
      COPY_COMMAND,
      (event) => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection)) {
          return false;
        }
        sanitizeSelection(selection);

        onCopyForPlainText(event, editor);
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    ),
    editor.registerCommand(
      CUT_COMMAND,
      (event) => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection)) {
          return false;
        }
        sanitizeSelection(selection);

        onCutForPlainText(event, editor);
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    ),
    editor.registerCommand(
      PASTE_COMMAND,
      (event) => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection)) {
          return false;
        }
        sanitizeSelection(selection);

        onPasteForPlainText(event, editor);
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    ),
    editor.registerCommand<DragEvent>(
      DROP_COMMAND,
      (event) => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection)) {
          return false;
        }

        // TODO: Make drag and drop work at some point.
        event.preventDefault();
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    ),
    editor.registerCommand<DragEvent>(
      DRAGSTART_COMMAND,
      (event) => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection)) {
          return false;
        }

        // TODO: Make drag and drop work at some point.
        event.preventDefault();
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    )
  );
  return removeListener;
}

export function usePlainTextSetup(editor: LexicalEditor): void {
  useLayoutEffect(() => {
    return mergeRegister(registerPlainText(editor));

    // We only do this for init
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor]);
}

function Placeholder({
  content,
}: {
  content: ((isEditable: boolean) => null | JSX.Element) | null | JSX.Element;
}): null | JSX.Element {
  const [editor] = useLexicalComposerContext();
  const showPlaceholder = useCanShowPlaceholder(editor);
  const editable = useLexicalEditable();

  if (!showPlaceholder) {
    return null;
  }

  if (typeof content === "function") {
    return content(editable);
  } else {
    return content;
  }
}

function canShowPlaceholderFromCurrentEditorState(editor: LexicalEditor): boolean {
  const currentCanShowPlaceholder = editor
    .getEditorState()
    .read($canShowPlaceholderCurry(editor.isComposing()));

  return currentCanShowPlaceholder as any;
}

export function useCanShowPlaceholder(editor: LexicalEditor): boolean {
  const [canShowPlaceholder, setCanShowPlaceholder] = useState(() =>
    canShowPlaceholderFromCurrentEditorState(editor)
  );

  useLayoutEffect(() => {
    function resetCanShowPlaceholder() {
      const currentCanShowPlaceholder = canShowPlaceholderFromCurrentEditorState(editor);
      setCanShowPlaceholder(currentCanShowPlaceholder);
    }
    resetCanShowPlaceholder();
    return mergeRegister(
      editor.registerUpdateListener(() => {
        resetCanShowPlaceholder();
      }),
      editor.registerEditableListener(() => {
        resetCanShowPlaceholder();
      })
    );
  }, [editor]);

  return canShowPlaceholder;
}

type ErrorBoundaryProps = {
  children: JSX.Element;
  onError: (error: Error) => void;
};

export type ErrorBoundaryType =
  | React.ComponentClass<ErrorBoundaryProps>
  | React.FC<ErrorBoundaryProps>;

export function useDecorators(
  editor: LexicalEditor,
  ErrorBoundary: ErrorBoundaryType
): Array<JSX.Element> {
  const [decorators, setDecorators] = useState<Record<string, JSX.Element>>(() =>
    editor.getDecorators<JSX.Element>()
  );

  // Subscribe to changes
  useLayoutEffect(() => {
    return editor.registerDecoratorListener<JSX.Element>((nextDecorators) => {
      flushSync(() => {
        setDecorators(nextDecorators);
      });
    });
  }, [editor]);

  useEffect(() => {
    // If the content editable mounts before the subscription is added, then
    // nothing will be rendered on initial pass. We can get around that by
    // ensuring that we set the value.
    setDecorators(editor.getDecorators());
  }, [editor]);

  // Return decorators defined as React Portals
  return useMemo(() => {
    const decoratedPortals = [];
    const decoratorKeys = Object.keys(decorators);

    for (let i = 0; i < decoratorKeys.length; i++) {
      const nodeKey = decoratorKeys[i];
      const reactDecorator = (
        <ErrorBoundary onError={(e) => editor._onError(e)}>
          <Suspense fallback={null}>{decorators[nodeKey]}</Suspense>
        </ErrorBoundary>
      );
      const element = editor.getElementByKey(nodeKey);

      if (element !== null) {
        decoratedPortals.push(createPortal(reactDecorator, element));
      }
    }

    return decoratedPortals;
  }, [ErrorBoundary, decorators, editor]);
}

export function CdxPlainTextPlugin({
  contentEditable,
  placeholder,
  ErrorBoundary,
}: {
  contentEditable: JSX.Element;
  placeholder: ((isEditable: boolean) => null | JSX.Element) | null | JSX.Element;
  ErrorBoundary: any;
}): JSX.Element {
  const [editor] = useLexicalComposerContext();
  const decorators = useDecorators(editor, ErrorBoundary);
  usePlainTextSetup(editor);

  return (
    <>
      {contentEditable}
      <Placeholder content={placeholder} />
      {decorators}
    </>
  );
}
