import {Card} from "../../../cdx-models/Card";
import {Query} from "../../../cdx-models/utils/MakeModel";
import {SearchCategory} from "../search-types";

type QueueVal = {type: "hand" | "notHand"};

const queueCategory: SearchCategory<QueueVal> = {
  key: "queue",
  label: "Hand",
  getSuggestions({input}) {
    if (input.length < 2) return [];
    if (!"hand".startsWith(input)) return [];
    return [{type: "hand"}, {type: "notHand"}];
  },
  valueToKey({type}) {
    return type;
  },
  valuesToDbQuery(values) {
    const toConst = ({type}: QueueVal): Query<Card> => {
      switch (type) {
        case "hand":
          return {queueEntries: {cardDoneAt: null}};
        case "notHand":
          return {"!queueEntries": {}};
      }
    };
    if (values.length === 1) {
      return toConst(values[0]);
    } else {
      return {$or: values.map(toConst)};
    }
  },
  renderSuggestion({type}) {
    switch (type) {
      case "hand":
        return "In Hand";
      case "notHand":
        return "Not in Hand";
    }
  },
  savedSearchLabel({type}) {
    return {
      prio: -2,
      label: type === "notHand" ? "not in hand" : "in hand",
    };
  },
};

export default queueCategory;
