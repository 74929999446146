import {Col, css, Row, Text} from "../Box/Box";
import {CSSProperties, ReactNode} from "react";

export const DSFieldLabel = ({children, fieldId}: {children: ReactNode; fieldId?: string}) => (
  <label
    htmlFor={fieldId}
    className={css({
      color: "secondary",
      textType: "label11Caps",
      minHeight: "24px",
      display: "flex",
      align: "center",
    })}
  >
    {children}
  </label>
);

export type DSFieldProps = {
  children: ReactNode;
  errors?: ReactNode[];
  hint?: ReactNode;
  className?: string;
  style?: CSSProperties;
} & (
  | {
      label: ReactNode;
      fieldId: string;
    }
  | {label?: undefined; fieldId?: undefined}
);

export const DSField = (props: DSFieldProps) => {
  const {children, errors = [], hint, label, fieldId, className, style} = props;
  const firstError = errors[0];
  return (
    <Col sp="8px" className={className} style={style}>
      {label && (
        <Row sp="8px" align="baseline">
          <DSFieldLabel fieldId={fieldId}>{label}</DSFieldLabel>
          {firstError && (
            <Text type="label12" colorTheme="alert25" color="primary" noOverflow>
              {firstError}
            </Text>
          )}
        </Row>
      )}
      {children}
      {!label && firstError && (
        <Text type="label12" colorTheme="alert25" color="primary" noOverflow>
          {firstError}
        </Text>
      )}
      {hint && (
        <Text type="label12light" noOverflow color="secondary">
          {hint}
        </Text>
      )}
    </Col>
  );
};
