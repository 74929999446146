import {Box} from "@cdx/ds";
import {api} from "../../../lib/api";
import {dayToDate, superShortDate, toCmpDate} from "../../../lib/date-utils";
import {useInstance} from "../../../lib/mate/mate-utils";
import {
  getActiveAndFutureSprints,
  getActiveSprintConfigs,
  sprintLabel,
} from "../../milestones/sprint-utils";
import {SprintId} from "../../../cdx-models/Sprint";
import {SearchCategory} from "../search-types";
import {QueryContent} from "../../../cdx-models/utils/MakeModel";
import {Card} from "../../../cdx-models/Card";
import {SprintConfigId} from "../../../cdx-models/SprintConfig";

const SprintOpt = ({id, short}: {id: SprintId; short?: boolean}) => {
  const sprint = useInstance("sprint", id);
  if (!sprint) return "unknown sprint";
  return (
    <div>
      {sprint.sprintConfig.name} <b>{sprintLabel(sprint)}</b>
      {short ? null : (
        <>
          <Box as="span" size={11} style={{marginLeft: "0.5em"}}>
            {superShortDate(dayToDate(sprint.startDate))} -{" "}
            {superShortDate(dayToDate(sprint.endDate))}
          </Box>
        </>
      )}
    </div>
  );
};

const sprintCategory: SearchCategory<SprintId | null> = {
  label: "Run",
  key: "sprint",
  getSuggestions({input, root}) {
    if ("level".startsWith(input) || "sprint".startsWith(input)) {
      return [
        ...getActiveAndFutureSprints({account: root.account}).map((s) => s.id as SprintId),
        null,
      ];
    }
    if (input.length < 2) return [];
    return [
      ...getActiveAndFutureSprints({account: root.account})
        .filter((s) => sprintLabel(s).toLowerCase().indexOf(input) > -1)
        .map((s) => s.id as SprintId),
      ...("no level".startsWith(input) ? [null] : []),
    ];
  },
  valueToKey(value) {
    return value;
  },
  valuesToDbQuery(values) {
    return {sprintId: values};
  },
  renderSuggestion(value) {
    return value ? <SprintOpt id={value} /> : "Not in Run";
  },
  renderPill(value) {
    return value ? <SprintOpt short id={value} /> : "Not in Run";
  },
  savedSearchLabel(value) {
    if (!value) return {prio: -2, label: "w/o level"};
    const sprint = api.getModel({modelName: "sprint", id: value});
    if (!sprint) return {prio: -2, label: "in unknown sprint"};
    return {
      prio: -2,
      label: `in '${sprintLabel(sprint).slice(0, 16)}'`,
    };
  },
  nonPublic: true,
};

type SprintConfVal = {id: SprintConfigId; cmp: "current" | "future" | "past"};

const SprintConfOpt = ({val: {id, cmp}, short}: {val: SprintConfVal; short?: boolean}) => {
  const config = useInstance("sprintConfig", id);
  return (
    <div>
      {short ? null : <>Cards in </>}
      {cmp} <b>{config?.name}</b> Run
    </div>
  );
};

const sprintConfCategory: SearchCategory<SprintConfVal> = {
  label: "Run Configurations",
  key: "sprintConf",
  getSuggestions({input, root}) {
    if (
      "run configurations".startsWith(input) ||
      "sprints".startsWith(input) ||
      "configurations".startsWith(input)
    ) {
      return getActiveSprintConfigs(root.account).flatMap((conf) => [
        {id: conf.id as SprintConfigId, cmp: "current"},
        {id: conf.id as SprintConfigId, cmp: "future"},
        {id: conf.id as SprintConfigId, cmp: "past"},
      ]);
    }
    if (input.length < 2) return [];
    return getActiveSprintConfigs(root.account)
      .filter((s) => s.$meta.get("name", "").toLowerCase().indexOf(input) > -1)
      .flatMap((conf) => [
        {id: conf.id as SprintConfigId, cmp: "current"},
        {id: conf.id as SprintConfigId, cmp: "future"},
        {id: conf.id as SprintConfigId, cmp: "past"},
      ]);
  },
  valueToKey({id, cmp}) {
    return `${id}:${cmp}`;
  },
  valuesToDbQuery(values) {
    const advCmpToFilter = ({cmp, id}: SprintConfVal): QueryContent<Card> => {
      const today = toCmpDate(new Date());
      switch (cmp) {
        case "current":
          return {
            sprint: {
              sprintConfigId: id,
              startDate: {op: "lte", value: today},
              endDate: {op: "gte", value: today},
            },
          };
        case "future":
          return {
            sprint: {
              sprintConfigId: id,
              endDate: {op: "gt", value: today},
            },
          };
        case "past":
          return {
            sprint: {
              sprintConfigId: id,
              startDate: {op: "lt", value: today},
            },
          };
        default:
          throw new Error(`unknown cmp: ${cmp}`);
      }
    };
    if (values.length === 1) {
      return advCmpToFilter(values[0]);
    } else {
      return {$or: values.map(advCmpToFilter)};
    }
  },
  renderSuggestion(val) {
    return <SprintConfOpt val={val} />;
  },
  renderPill(val) {
    return <SprintConfOpt val={val} short />;
  },
  savedSearchLabel({id, cmp}) {
    const conf = api.getModel({modelName: "sprintConfig", id});
    return {
      prio: -2,
      label: `${cmp} '${conf?.name.slice(0, 16)}'`,
    };
  },
  nonPublic: true,
};

const sprintCategories = [sprintCategory, sprintConfCategory];

export default sprintCategories;
