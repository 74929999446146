import {ModelOverview} from "../../cdx-models";
import {Root} from "../../cdx-models/Root";
import {MakeMeta} from "../../cdx-models/utils/MakeModel";
import {ChangeMap, Changes} from "./Changes";
import {getIdAsKey} from "./mate-shared";
import {
  CacheVal,
  Id,
  ModelDescriptions,
  ModelName,
  InstancePath,
  ChangeListener,
} from "./mate-types";
import {processQuery} from "./process-query";

const isDev = process.env.NODE_ENV !== "production";

type PoolParams = {
  cache: any;
  descriptions: ModelDescriptions;
  collect: (
    path: InstancePath,
    name: string,
    type: "hasMany" | "field",
    currStatus?: string
  ) => void;
  registerOnChangeListener: ChangeListener;
  getApiChangeIndex: () => number;
  notLoadedEvent: (status: CacheVal["status"]) => void;
};

export default function createPool({
  cache,
  descriptions,
  collect: rawCollect,
  registerOnChangeListener,
  getApiChangeIndex,
  notLoadedEvent,
}: PoolParams) {
  let loadedInstances: Record<string, any> = {};
  let instancesWithoutKnownId: Record<string, Record<string, any>> = {};
  let rootInstance: Root | null = null;
  let references: Record<string, InstanceTuple[]> = {};

  type InstanceTuple = [relModel: ModelName, relId: Id];

  type GetRelationFn = (relName: string, isSingleton: boolean, model: ModelName) => any;
  type GetFieldFn = (fieldName: string, defaultValue: any, opts?: {forceRelIds?: boolean}) => any;
  const createMetaMethods = (getRelation: GetRelationFn, getField: GetFieldFn, modelDesc: any) => {
    const methods: Partial<MakeMeta<any, any, any, any>> = {
      find(relName: string, query: any) {
        const hasManyDesc = modelDesc.hasMany[relName];
        if (query) {
          const {fullRelName} = processQuery({
            modelDesc,
            query,
            relName,
            descriptions,
            cache,
          });
          return getRelation(fullRelName, hasManyDesc.isSingleton, hasManyDesc.model).get();
        } else {
          return getRelation(relName, hasManyDesc.isSingleton, hasManyDesc.model).get();
        }
      },
    };
    (
      [
        {name: "exists", defaultValue: false},
        {name: "count", defaultValue: 0},
      ] as const
    ).forEach(({name, defaultValue}) => {
      methods[name] = (relName, query = null, userDefault) => {
        if (query && Object.keys(query).length) {
          const {fullRelName} = processQuery({
            modelDesc,
            query,
            relName,
            prefix: `${name}:`,
            descriptions,
            cache,
          });
          return getField(fullRelName, userDefault !== undefined ? userDefault : defaultValue);
        } else {
          return getField(
            `${name}:${relName}`,
            userDefault !== undefined ? userDefault : defaultValue
          );
        }
      };
    });

    methods.first = (relName, query) => {
      if (!query || !query.$order) {
        throw new Error("you need to pass `$order` when asking for first!");
      }
      // @ts-expect-error
      if (query.$limit) {
        throw new Error("don't pass `$limit` when asking for first!");
      }
      const {fullRelName} = processQuery({
        modelDesc,
        query: {...query, $first: true},
        relName,
        descriptions,
        cache,
      });
      return getRelation(fullRelName, true, modelDesc.hasMany[relName].model).get();
    };

    methods.firstN = (n, relName, query) => {
      if (!query || !query.$order) {
        throw new Error("you need to pass `$order` when asking for firstN!");
      }
      const {fullRelName} = processQuery({
        modelDesc,
        query: {...query, $limit: n},
        relName,
        descriptions,
        cache,
      });
      return getRelation(fullRelName, false, modelDesc.hasMany[relName].model).get();
    };

    methods.idProps = modelDesc.idPropAsArray;

    return methods as Pick<
      MakeMeta<any, any, any, any>,
      "find" | "exists" | "count" | "first" | "firstN" | "idProps"
    >;
  };

  const createInstanceViaId = ({
    modelName,
    id,
    isOptimistic,
  }: {
    modelName: ModelName;
    id: Id;
    isOptimistic?: boolean;
  }) => {
    if (cache.isDeleted(modelName, id)) {
      if (isDev)
        console.warn(
          `trying to load instance of ${modelName}(${id}) which is deleted. Returning null.`
        );
      return null;
    }
    const idKey = getIdAsKey(id);
    if (isDev && !descriptions[modelName]) {
      throw new Error(`passed unknown modelName: ${modelName} (id: ${idKey})`);
    }
    const {fields, idProp, hasMany, belongsTo, idPropAsArray} = descriptions[modelName];
    const path = [{modelName, id, isOptimistic}];

    const instance: Record<string, any> = {};
    const props: Record<string, any> = {};
    let cachedRelations: Record<string, CacheVal> = {};

    Object.keys(fields).forEach((field) => {
      const fieldDesc = fields[field];
      const initialCacheVal: CacheVal = cache.getAndTellIfLoaded({
        modelName,
        id: idKey,
        field,
        collectIfMissing: false,
      });
      if (initialCacheVal.isLoaded) {
        instance[field] = initialCacheVal.val;
        cachedRelations[field] = initialCacheVal;
      } else {
        props[field] = {
          enumerable: true,
          get() {
            const existing = cachedRelations[field];
            if (existing) {
              if (!existing.isLoaded) notLoadedEvent(existing.status);
              return existing.useDefaultVal ? fieldDesc.defaultValue : existing.val;
            }
            const cacheVal = cache.getAndTellIfLoaded({
              modelName,
              id: idKey,
              field,
              collectIfMissing: !isOptimistic && "field",
            });
            if (!cacheVal.isLoaded) notLoadedEvent(cacheVal.status);
            cachedRelations[field] = cacheVal;
            return cacheVal.useDefaultVal ? fieldDesc.defaultValue : cacheVal.val;
          },
        };
      }
    });

    const getRelation: GetRelationFn = (relName, isSingleton, relModel) => {
      return {
        enumerable: false,
        get: () => {
          const exist = cachedRelations[relName];
          if (exist !== undefined && exist.isLoaded) {
            return exist.val;
          } else {
            if ((relModel as any) === "$deprecated") return isSingleton ? null : [];
            const cacheVal = cache.getAndTellIfLoaded({
              modelName,
              id: idKey,
              field: relName,
              collectIfMissing: !isOptimistic && "hasMany",
            });
            if (!cacheVal.isLoaded) notLoadedEvent(cacheVal.status);
            const {val: relIds} = cacheVal;
            const thisInstanceKey: InstanceTuple = [modelName, id];
            let val;
            if (relIds !== undefined) {
              if (isSingleton) {
                const otherInstanceKey = `${relModel}:${getIdAsKey(relIds)}`;
                if (modelName !== "_root")
                  (references[otherInstanceKey] = references[otherInstanceKey] || []).push(
                    thisInstanceKey
                  );
                val =
                  relIds &&
                  pool.getInstanceViaId({
                    modelName: relModel,
                    id: relIds,
                    isOptimistic: cacheVal.status === "optimistic",
                  });
              } else {
                val = relIds
                  ? relIds
                      .map((relId: Id) => {
                        const otherInstanceKey = `${relModel}:${getIdAsKey(relId)}`;
                        // references["card-123"] = [[project, 4], [deck, 12]]
                        // TODO: ensure that it won't turn into 'references["card-123"] = [[project, 4], [project, 4], [project, 4]]''
                        if (modelName !== "_root")
                          (references[otherInstanceKey] = references[otherInstanceKey] || []).push(
                            thisInstanceKey
                          );
                        return pool.getInstanceViaId({
                          modelName: relModel,
                          id: relId,
                          isOptimistic: cacheVal.status === "optimistic",
                        });
                      })
                      .filter(Boolean)
                  : [];
              }
            } else {
              const relInstance = pool.getInstanceViaPath({
                modelName: relModel,
                path: [...path, relName],
              });
              val = isSingleton ? relInstance : [relInstance];
            }
            cachedRelations[relName] = {...cacheVal, val};
            return val;
          }
        },
      };
    };

    Object.keys(hasMany).forEach((relName) => {
      const {isSingleton, model} = hasMany[relName];
      props[relName] = getRelation(relName, isSingleton, model);
    });

    Object.keys(belongsTo).forEach((relName) => {
      const {model} = belongsTo[relName];
      props[relName] = getRelation(relName, true, model);
    });

    if (Object.keys(props).length) Object.defineProperties(instance, props);

    if (idPropAsArray.length === 1 && idProp !== "id") instance.id = instance[idProp as string];

    const getField: GetFieldFn = (fieldName, defaultValue, {forceRelIds = false} = {}) => {
      const cacheKey = forceRelIds ? `$raw$${fieldName}` : fieldName;
      const existing = cachedRelations[cacheKey];
      if (existing !== undefined) {
        if (!existing.isLoaded) notLoadedEvent(existing.status);
        return existing.useDefaultVal ? defaultValue : existing.val;
      } else {
        const cacheVal = cache.getAndTellIfLoaded({
          modelName,
          id: idKey,
          field: fieldName,
          collectIfMissing: !isOptimistic && (forceRelIds ? "hasMany" : "field"),
        });
        if (!cacheVal.isLoaded) notLoadedEvent(cacheVal.status);
        cachedRelations[cacheKey] = cacheVal;
        return cacheVal.useDefaultVal ? defaultValue : cacheVal.val;
      }
    };

    const $meta: MakeMeta<any, any, any, any> = {
      isLoaded: true,
      modelName,
      changeIndex: getApiChangeIndex(),
      isFieldLoaded(fieldName, collectIfNotPresent) {
        // returns false if not loaded or invalid
        const {status} = cache.getAndTellIfLoaded({
          modelName,
          id: idKey,
          field: fieldName,
          collectIfMissing: !isOptimistic && collectIfNotPresent ? "field" : false,
        });
        return status === "loaded" || status === "deleted";
      },
      isFieldPresent(fieldName, collectIfNotPresent) {
        const {val} = cache.getAndTellIfLoaded({
          modelName,
          id: idKey,
          field: fieldName,
          collectIfMissing: !isOptimistic && collectIfNotPresent ? "field" : false,
        });
        return val !== undefined;
      },
      get(fieldName, defaultValue, {forceRelIds = false} = {}) {
        if (fields[fieldName] || forceRelIds) {
          return getField(fieldName, defaultValue, {forceRelIds});
        }
        const val = instance[fieldName];
        return val && val.$meta && !val.$meta.isLoaded ? defaultValue : val;
      },
      getId(fieldName, defaultValue) {
        return getField(fieldName, defaultValue, {forceRelIds: true});
      },
      getIds(fieldName, defaultValue) {
        return getField(fieldName, defaultValue, {forceRelIds: true});
      },
      isDeleted() {
        return cache.isDeleted(modelName, id);
      },
      isFieldDeleted(fieldName) {
        const {status} = cache.getAndTellIfLoaded({
          modelName,
          id: idKey,
          field: fieldName,
          collectIfMissing: false,
        });
        return status === "deleted";
      },
      _clearCache() {
        cachedRelations = {};
      },
      ...createMetaMethods(getRelation, getField, descriptions[modelName]),
    };
    instance.$meta = $meta;

    return instance;
  };

  const createInstanceViaPath = (opts: {modelName: ModelName; path: InstancePath}) => {
    const {modelName, path} = opts;
    const {fields, idProp, hasMany, belongsTo, idPropAsArray} = descriptions[modelName];

    const instance: Record<string, any> = {};
    const props: Record<string, any> = {};
    const cachedRelations: Record<string, any> = {};
    const collectedRelations: Record<string, true> = {};

    const collect = (p: InstancePath, fieldName: string, type: "hasMany" | "field") => {
      rawCollect(p, fieldName, type);
      collectedRelations[fieldName] = true;
    };

    const getField: GetFieldFn = (fieldName, defaultValue) => {
      if (collectedRelations[fieldName]) {
        notLoadedEvent("loading");
      } else {
        collect(path, fieldName, "field");
      }
      return defaultValue;
    };

    Object.keys(fields).forEach((field) => {
      const fieldDesc = fields[field];
      props[field] = {
        enumerable: true,
        get() {
          return getField(field, fieldDesc.defaultValue);
        },
      };
    });

    if (idPropAsArray.length === 1 && idProp !== "id") {
      props.id = {
        enumerable: true,
        get() {
          return getField(idProp as string, fields[idProp as string].defaultValue);
        },
      };
    }

    const getRelation: GetRelationFn = (relName, isSingleton, relModel) => ({
      enumerable: false,
      get() {
        const exist = cachedRelations[relName];
        if (exist) {
          notLoadedEvent("loading");
          return exist;
        } else {
          if ((relModel as any) === "$deprecated") return isSingleton ? null : [];
          collect(path, relName, "hasMany");
          const relInstance = pool.getInstanceViaPath({
            modelName: relModel,
            path: [...path, relName],
          });
          const retVal = isSingleton ? relInstance : [relInstance];
          cachedRelations[relName] = retVal;
          return retVal;
        }
      },
    });

    Object.keys(hasMany).forEach((relName) => {
      const {isSingleton, model} = hasMany[relName];
      props[relName] = getRelation(relName, isSingleton, model);
    });

    Object.keys(belongsTo).forEach((relName) => {
      const {model} = belongsTo[relName];
      props[relName] = getRelation(relName, true, model);
    });

    const $meta: MakeMeta<any, any, any, any> = {
      isLoaded: false,
      modelName,
      changeIndex: getApiChangeIndex(),
      isFieldLoaded(fieldName, collectIfNotPresent) {
        if (collectIfNotPresent && !collectedRelations[fieldName]) {
          collect(path, fieldName, "field");
        }
        return false;
      },
      isFieldPresent(fieldName, collectIfNotPresent) {
        if (collectIfNotPresent && !collectedRelations[fieldName]) {
          collect(path, fieldName, "field");
        }
        return false;
      },
      get(fieldName, defaultValue, {forceRelIds = false} = {}) {
        const exist = cachedRelations[fieldName];
        if (exist) {
          notLoadedEvent("loading");
          return forceRelIds ? [] : exist;
        }
        if (fields[fieldName]) {
          return getField(fieldName, defaultValue);
        } else {
          const existAsRel = collectedRelations[fieldName];
          if (existAsRel) {
            notLoadedEvent("loading");
          } else {
            collect(path, fieldName, "hasMany");
          }
          return defaultValue;
        }
      },
      getId(fieldName, defaultValue) {
        const existAsRel = collectedRelations[fieldName];
        if (existAsRel) {
          notLoadedEvent("loading");
        } else {
          collect(path, fieldName, "hasMany");
        }
        return defaultValue;
      },
      getIds(fieldName, defaultValue) {
        const existAsRel = collectedRelations[fieldName];
        if (existAsRel) {
          notLoadedEvent("loading");
        } else {
          collect(path, fieldName, "hasMany");
        }
        return defaultValue as any;
      },
      isDeleted() {
        return false;
      },
      isFieldDeleted() {
        return false;
      },
      ...createMetaMethods(getRelation, getField, descriptions[modelName]),
    };

    instance.$meta = $meta;

    Object.defineProperties(instance, props);

    return instance;
  };

  const pool = {
    invalidateInstances(index: number, initialChanges: Changes["changes"] | "all") {
      if (initialChanges === "all") {
        loadedInstances = {};
        instancesWithoutKnownId = {};
        rootInstance = null;
        references = {};
      } else {
        const invalidated = [];
        const alreadyUpdated = new Set();
        const {updated, touched} = initialChanges;
        for (const [modelName, idSet] of touched) {
          for (const id of idSet) {
            const idAsKey = getIdAsKey(id);
            const inst = loadedInstances[modelName] && loadedInstances[modelName][idAsKey];
            if (inst) inst.$meta._clearCache();
          }
        }
        let nextChanges = updated;
        let hasMoreChanges = true;
        rootInstance = null;
        instancesWithoutKnownId = {};
        const update = (changes: ChangeMap) => {
          for (const [modelName, idSet] of changes) {
            if (modelName === "_root") continue;
            for (const id of idSet) {
              const idAsKey = getIdAsKey(id);
              const key = `${modelName}:${idAsKey}`;
              if (alreadyUpdated.has(key)) continue;
              alreadyUpdated.add(key);
              if (loadedInstances[modelName]) {
                if (isDev && loadedInstances[modelName][idAsKey]) invalidated.push(key);
                delete loadedInstances[modelName][idAsKey];
              }
              const refs = references[key];
              if (refs) {
                for (const [relModel, relId] of refs) {
                  hasMoreChanges = true;
                  const exist = nextChanges.get(relModel);
                  if (exist) {
                    exist.add(relId);
                  } else {
                    nextChanges.set(relModel, new Set([relId]));
                  }
                }
              }
            }
          }
        };
        while (hasMoreChanges) {
          const currentChanges = nextChanges!;
          nextChanges = new Map();
          hasMoreChanges = false;
          update(currentChanges);
        }
        // if (isDev) console.log(`invalidated [${invalidated.join(", ")}]`);
      }
    },
    getRoot() {
      if (rootInstance) return rootInstance;
      rootInstance = createInstanceViaId({modelName: "_root", id: null}) as Root;
      return rootInstance;
    },
    getInstanceViaId<T extends ModelName>(opts: {modelName: T; id: Id; isOptimistic?: boolean}) {
      const {modelName, id, isOptimistic} = opts;
      if (!id || id === "unknown") {
        // for cases like api.getModel({modelName: "x", id: something.id})
        return null;
      }
      loadedInstances[modelName] = loadedInstances[modelName] || {};
      const idAsKey = getIdAsKey(id);
      if (loadedInstances[modelName][idAsKey] !== undefined) {
        return loadedInstances[modelName][idAsKey] as ModelOverview[T];
      }

      const newInstance = createInstanceViaId({modelName, id, isOptimistic});
      loadedInstances[modelName][idAsKey] = newInstance;
      return newInstance as ModelOverview[T];
    },

    getInstanceViaPath(opts: {modelName: ModelName; path: InstancePath}) {
      const {modelName, path} = opts;
      instancesWithoutKnownId[modelName] = instancesWithoutKnownId[modelName] || {};

      const pathAsKey = path
        .reduce((m, p) => {
          if (typeof p === "string") {
            m.push(p);
          } else if (p.modelName === "_root") {
            m.push(p.modelName);
          } else {
            m.push(`${p.modelName}:${p.id}`);
          }
          return m;
        }, [] as string[])
        .join("-");

      if (instancesWithoutKnownId[modelName][pathAsKey]) {
        return instancesWithoutKnownId[modelName][pathAsKey];
      }
      const newInstance = createInstanceViaPath({modelName, path});
      instancesWithoutKnownId[modelName][pathAsKey] = newInstance;
      return newInstance;
    },
  };

  registerOnChangeListener(pool.invalidateInstances);

  return pool;
}
