import {Col} from "@cdx/ds";
import {CdxImgWithKnownDimensions as RawCdxImgWithKnownDimensions} from "../../../components/CdxImg";
import {CARD_HEIGHT, CARD_WIDTH} from "../../../components/Card/card.css";
import {Dispatch, forwardRef, useEffect, useRef, useState} from "react";
import {setupDragHandlers} from "../../../lib/hooks/use-drag";

const CdxImgWithKnownDimensions = RawCdxImgWithKnownDimensions as any;

const clamp0_100 = (v: number) => Math.min(100, Math.max(0, v));

const bottomHeight = {
  default: 26,
  hero: 38,
};

type CoverImageEditorProps = {
  url: string;
  width: number;
  height: number;
  offsetVals: [number, number];
  setOffsetVals: Dispatch<[number, number]>;
  withChildCards?: boolean;
};
const CoverImageEditor = forwardRef((props: CoverImageEditorProps, ref) => {
  const {url, width, height, offsetVals, setOffsetVals, withChildCards} = props;
  const [isGrabbing, setIsGrabbing] = useState(false);
  const [tmpDiff, setTmpDiff] = useState(0);
  const refs = useRef({tmpDiff, offsetVals, setOffsetVals});
  useEffect(() => {
    refs.current = {tmpDiff, offsetVals, setOffsetVals};
  });

  const bottom = bottomHeight[withChildCards ? "hero" : "default"];
  const coverAreaHeight = CARD_HEIGHT - bottomHeight.default;

  const imgRatio = width / height;
  const cardRatio = CARD_WIDTH / coverAreaHeight;

  const isVert = imgRatio > cardRatio;

  const [{handlers, unsubs}] = useState(() =>
    setupDragHandlers(
      (startPos) => {
        let d = 0;
        return {
          onMove: ([x, y]) => {
            d = isVert ? startPos[0] - x : startPos[1] - y;
            setTmpDiff(d);
          },
          onStop: (isCancelled) => {
            if (!isCancelled) {
              const nextVal = clamp0_100(refs.current.offsetVals[isVert ? 0 : 1] + d);
              refs.current.setOffsetVals(isVert ? [nextVal, 0] : [0, nextVal]);
            }
            setTmpDiff(0);
            setIsGrabbing(false);
          },
        };
      },
      () => {
        setIsGrabbing(true);
        return {
          onDone: () => null,
          onCancel: () => {
            setIsGrabbing(false);
          },
        };
      }
    )
  );
  useEffect(() => {
    return () => unsubs.forEach((fn) => fn());
  }, [unsubs]);

  const realOff = clamp0_100(offsetVals[isVert ? 0 : 1] + tmpDiff);

  return (
    <Col
      width="cardWidth"
      height="cardHeight"
      bg="foreground"
      elevation={100}
      rounded="card"
      overflow="hidden"
    >
      <Col
        flex="auto"
        relative
        cursor={isGrabbing ? "grabbing" : isVert ? "col-resize" : "row-resize"}
      >
        <CdxImgWithKnownDimensions
          src={url}
          width={width}
          height={height}
          maxWidth={500}
          maxHeight={500}
          asBackground
          imgStyle={{objectPosition: isVert ? `${realOff}% 0` : `0 ${realOff}%`}}
          {...handlers}
          ref={ref}
        />
      </Col>
      <Col style={{height: bottom}} bg="background" />
    </Col>
  );
});

export default CoverImageEditor;
