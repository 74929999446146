import {useState, useMemo, useEffect, Fragment, forwardRef, lazy, memo, useRef} from "react";
import {Link} from "react-router-dom";
import {mergeRefs} from "react-merge-refs";
import deepEqual from "deep-equal";
import {Draggable} from "@codecks/dnd";
import {XCol, XRow, XText, XPush} from "../../components/xui";
import UnMarkedown from "../../components/Markdown/UnMarkedown";
import {hoverInfoStyles as styles} from "./workflows.css";
import Avatar from "../../components/Avatar";
import xcolors from "../../components/xui/xcolors";
import {RawPriorityIcon} from "../../components/props";
import {getMilestoneState} from "../milestones/milestone-utils";
import {api} from "../../lib/api";
import {EmojiTag, usersEqual} from "../../components/Card";
import {
  AttachmentTooltip,
  HandPositionHint,
  ProjectTagHint,
  ProjectTagTooltip,
  trackedTimeToProgress,
  TrackingTooltip,
} from "../../components/Card/CardBottomHints";
import {cx, getMetaKeyLabel, TooltipForChild, WithTooltip} from "@cdx/common";
import {getStatusForCard} from "../../lib/card-status-utils";
import {IconWithMilestoneTooltipById} from "../../pages/milestones/MilestoneTooltip";
import {getShownEffort, isHeroCard} from "./workflow-utils";
import {getTotalTimeForCard} from "../time-tracking/time-tracking-utils";
import {enrichCardProjectTags} from "../../components/Markdown/CardTags";
import cardStyles from "../../components/Card/card.css";
import {ConversationHint, getConvoPreviewInfo} from "../conversations/ConversationHint";
import {cardStatusVariants} from "@cdx/ds/components/DSCard/DSCardTheme.css";
import useOriginalCopy from "../../lib/hooks/useOriginalCopy";
import {
  Box,
  DSIconAttachment,
  DSIconButton,
  DSIconClose,
  DSIconCrown,
  DSIconEffort,
  Row,
} from "@cdx/ds";
import dsStyles from "@cdx/ds/css/index.css";
import {CardPropChangeOverlayForChild} from "../../components/RichTextarea/Lexical/CardPropChangeForChild";
import {iconStyles} from "@cdx/ds/components/DSIcon/DSIcon.css";
import {ThemedSprintIconWithTooltipAndMetaClick, getSprintState} from "../milestones/sprint-utils";
import {msColorThemes} from "../../pages/milestones/milestone-theme.css";
import {getCardHandInfo} from "../hand-queue/hand-queue-utils";
import {BeastPill, getBeastLevel} from "../../components/Card/Beast";
import {AddedDuringSprintPill, getIsNewUntil} from "../../components/Card/AddedDuringSprint";
import {StopWatchIconWithProgress} from "../card-container/AnimatedStopWatchIcon";

const MilestoneHint = ({bag}) => {
  const {cardId, interactable, milestoneInfo} = bag;
  const {id, color, milestoneState} = milestoneInfo;
  return (
    <IconWithMilestoneTooltipById
      milestoneId={id}
      color={color}
      milestoneState={milestoneState}
      hidden={!bag.interactable}
      propChangeInfo={interactable && {cardId, cardContainerKey: "sidebar-milestone"}}
      openMsOnMetaClick={process.env.REACT_APP_MODE !== "open" ? {cardId} : undefined}
      tooltip={
        process.env.REACT_APP_MODE !== "open"
          ? `${getMetaKeyLabel()} + click to open card within milestone view`
          : undefined
      }
    />
  );
};

const SprintHint = ({bag}) => {
  const {cardId, interactable, sprintInfo} = bag;
  const {id, color, sprintState} = sprintInfo;
  return (
    <ThemedSprintIconWithTooltipAndMetaClick
      theme={msColorThemes[color] || msColorThemes.blue}
      cardId={bag.cardId}
      sprintId={id}
      sprintState={sprintState}
      propChangeInfo={interactable && {cardId, cardContainerKey: "sidebar-milestone"}}
    />
  );
};

const ProjectTagHintPart = ({enrichedTags, interactable}) => {
  const tagColors = enrichedTags.map((t) => t.color).filter(Boolean);
  return tagColors.length > 0 ? (
    <WithTooltip
      tooltip={interactable && <ProjectTagTooltip tags={enrichedTags} />}
      options={{bg: "gray600"}}
      as={ProjectTagHint}
      tagColors={tagColors}
      isSmall
    />
  ) : null;
};

const HeroCardBadge = () => (
  <XCol rounded="sm" style={{padding: 2}} bg="gray200" border="gray300">
    <DSIconCrown size={16} className={iconStyles.setSize["10px"]} />
  </XCol>
);

const LazyWorkflowHoverInfo = lazy(
  () => import(/* webpackChunkName: "WorkflowOverInfo" */ "./WorkflowHoverInfo")
);

const ParentCardBadge = ({parentCardInfo}) => (
  <WithTooltip
    tooltip={() => (
      <LazyWorkflowHoverInfo card={api.getModel({id: parentCardInfo.cardId, modelName: "card"})} />
    )}
    options={{placement: "left", bg: "gray800", distanceFromAnchor: 10, delayed: true}}
    as={UnMarkedown}
    className={styles.cardLane.parentCard}
    maxChars={16}
  >
    {parentCardInfo.shownTitle}
  </WithTooltip>
);

const useBag = (props) => {
  const {
    arenaCtxType,
    card,
    root,
    notifications,
    hideParent,
    interactable,
    removeLabel,
    isShown,
    isHighlighted,
    ref2,
  } = props;
  const isCardLoaded = card.$meta.isLoaded;
  const status = getStatusForCard(card);
  const effort = getShownEffort(card, root.account);
  const enrichedProjectTags = useOriginalCopy(enrichCardProjectTags(card), deepEqual);
  const totalTrackingTime =
    process.env.REACT_APP_MODE !== "open" &&
    root.account.timeTrackingMode !== "none" &&
    getTotalTimeForCard(card);
  const convoInfo = useOriginalCopy(getConvoPreviewInfo({notifications, card, root}), deepEqual);
  const projectId = card.deck?.project.id ?? null;
  const hasAttachments = card.$meta.count("attachments") > 0;
  const {priority, title, milestone, parentCard: rawParentCard, sprint} = card;
  const isArchived = card.visibility === "archived";
  const assignee = useOriginalCopy(card.assignee, usersEqual);
  const msColor = milestone?.$meta.get("color", null) ?? null;
  const milestoneState = milestone && getMilestoneState(root.account, milestone);
  const to = useOriginalCopy(props.to);
  const milestoneInfo = useOriginalCopy(
    milestone
      ? {
          id: milestone.id,
          color: msColor,
          milestoneState,
        }
      : null
  );

  const sprintColor = sprint?.sprintConfig.$meta.get("color", null) ?? null;
  const sprintInfo = useOriginalCopy(
    sprint
      ? {
          id: sprint.id,
          color: sprintColor,
          sprintState: getSprintState(sprint),
        }
      : null
  );

  const parentCard = !hideParent && rawParentCard;

  let shownTitle = null;
  if (parentCard) {
    const emojis = enrichCardProjectTags(parentCard)
      .map((t) => t.emoji)
      .filter(Boolean)
      .join("");
    shownTitle = emojis ? `${emojis} ${parentCard.title}` : parentCard.title;
  }

  const parentCardInfo = useOriginalCopy(
    parentCard
      ? {
          shownTitle,
          cardId: parentCard.$meta.get("cardId", null),
        }
      : null
  );

  const handInfo = getCardHandInfo({card, root});

  const beastLevel = getBeastLevel(root.account, card);
  const isNewUntil = getIsNewUntil(root.account, card);
  const isHero = isHeroCard(card);

  const propRefs = useRef(props);
  useEffect(() => {
    propRefs.current = props;
  });

  const actions = useMemo(
    () => ({
      onRemove: propRefs.current.onRemove
        ? (...args) => propRefs.current.onRemove(...args)
        : undefined,
      onClick: propRefs.current.onClick
        ? (...args) => propRefs.current.onClick(...args)
        : undefined,
      goToCard: propRefs.current.goToCard
        ? (...args) => propRefs.current.goToCard(...args)
        : undefined,
    }),
    []
  );

  return useMemo(
    () => ({
      arenaCtxType,
      cardId: card.id,
      isCardLoaded,
      status,
      effort,
      enrichedProjectTags,
      totalTrackingTime,
      convoInfo,
      projectId,
      hasAttachments,
      priority,
      isArchived,
      title,
      assignee,
      milestoneInfo,
      sprintInfo,
      parentCardInfo,
      interactable,
      to,
      goToCard: actions.goToCard,
      onRemove: actions.onRemove,
      onClick: actions.onClick,
      removeLabel,
      isShown,
      isHighlighted,
      handInfo,
      ref2,
      beastLevel,
      isNewUntil,
      isHero,
    }),
    [
      arenaCtxType,
      card.id,
      isCardLoaded,
      status,
      effort,
      enrichedProjectTags,
      totalTrackingTime,
      convoInfo,
      projectId,
      hasAttachments,
      priority,
      isArchived,
      title,
      assignee,
      milestoneInfo,
      sprintInfo,
      parentCardInfo,
      interactable,
      to,
      actions,
      removeLabel,
      isShown,
      isHighlighted,
      handInfo,
      ref2,
      beastLevel,
      isNewUntil,
      isHero,
    ]
  );
};

const BaseCardLane = ({bag, forwardedRef, dragProps}) => {
  const {
    status,
    isHero,
    enrichedProjectTags,
    totalTrackingTime,
    convoInfo,
    interactable,
    to,
    goToCard,
    onRemove,
    onClick,
    isHighlighted,
    ref2,
    beastLevel,
    isNewUntil,
  } = bag;
  const [node, setNode] = useState();
  const ref = useMemo(() => mergeRefs([setNode, forwardedRef, ref2]), [forwardedRef, ref2]);
  useEffect(() => {
    if (node && isHighlighted) {
      node.scrollIntoView && node.scrollIntoView({behavior: "smooth", block: "nearest"});
    }
  }, [node, isHighlighted]);

  const gotoPanel = (targetPanel) => goToCard && goToCard({cardId: bag.cardId, targetPanel});

  return (
    <XCol
      bg="white"
      elevation={1}
      className={cx(
        cardStatusVariants[status],
        styles.cardLane.container.base,
        bag.isShown && styles.cardLane.container.isShown,
        isHighlighted && styles.cardLane.container.isHighlighted,
        onClick && styles.cardLane.container.isClickable
      )}
      {...(to && {as: Link, to})}
      {...dragProps}
      onClick={onClick}
      ref={ref}
    >
      {convoInfo && (
        <div className={styles.cardLane.convoHintContainer}>
          <ConversationHint
            convoInfo={convoInfo}
            cardId={bag.cardId}
            gotoPanel={gotoPanel}
            size="sm"
          />
        </div>
      )}
      <div
        className={cx(
          styles.cardLane.statusBorder.base,
          bag.isArchived && styles.cardLane.statusBorder.striped
        )}
      />
      <XCol px={2} py={1} sp={0}>
        <XRow sp={0} align="start">
          <XRow sp={0} align="center" minWidth>
            {isHero && <HeroCardBadge />}
            {bag.parentCardInfo && <ParentCardBadge parentCardInfo={bag.parentCardInfo} />}
            <XText color="gray700" size={1} noOverflow>
              <EmojiTag enrichedProjectTags={enrichedProjectTags} />
              <UnMarkedown as={Fragment} projectId={bag.projectId}>
                {bag.title}
              </UnMarkedown>
            </XText>
          </XRow>
          <XPush />
          {onRemove && (
            <TooltipForChild tooltip={bag.removeLabel || "Remove from Hero card"}>
              <DSIconButton
                icon={<DSIconClose />}
                size="sm"
                negatePadding
                variant="tertiary"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onRemove(bag.cardId);
                }}
              />
            </TooltipForChild>
          )}
        </XRow>
        <XRow sp={0} align="center" style={{color: xcolors.gray600}}>
          {bag.assignee && (
            <CardPropChangeOverlayForChild
              cardId={bag.cardId}
              cardContainerKey="sidebar-owner"
              disabled={!bag.interactable}
              initialTab="owner"
            >
              <Avatar size={14} user={bag.assignee} />
            </CardPropChangeOverlayForChild>
          )}
          {bag.interactable || bag.effort !== null ? (
            <CardPropChangeOverlayForChild
              cardId={bag.cardId}
              cardContainerKey="sidebar-effort"
              disabled={!bag.interactable}
              initialTab="effort"
            >
              <Row align="center" sp="2px">
                <DSIconEffort size={16} className={dsStyles.color.secondary} />
                <Box bold size={12} lineHeight="none">
                  {bag.effort ?? "-"}
                </Box>
              </Row>
            </CardPropChangeOverlayForChild>
          ) : null}
          {bag.priority && (
            <CardPropChangeOverlayForChild
              cardId={bag.cardId}
              cardContainerKey="sidebar-priority"
              disabled={!bag.interactable}
              initialTab="priority"
            >
              <RawPriorityIcon
                className={dsStyles.color.secondary}
                size={16}
                priority={bag.priority}
              />
            </CardPropChangeOverlayForChild>
          )}
          {totalTrackingTime ? (
            <TooltipForChild tooltip={<TrackingTooltip cardId={bag.cardId} />}>
              <StopWatchIconWithProgress
                size={20}
                progress={trackedTimeToProgress(totalTrackingTime)}
              />
            </TooltipForChild>
          ) : null}
          {bag.hasAttachments && (
            <TooltipForChild
              tooltip={() => <AttachmentTooltip cardId={bag.cardId} />}
              delayed
              bg="gray600"
              placement="left"
            >
              <DSIconAttachment
                className={cardStyles.bottomHintIcons.md}
                size={16}
                onClick={(e) => {
                  gotoPanel("attachments");
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            </TooltipForChild>
          )}
          {bag.handInfo && (
            <HandPositionHint
              position={bag.handInfo.position}
              type={bag.handInfo.type}
              cardId={bag.cardId}
            />
          )}

          <XPush />

          {bag.milestoneInfo && <MilestoneHint bag={bag} />}
          {bag.sprintInfo && <SprintHint bag={bag} />}
          <ProjectTagHintPart enrichedTags={enrichedProjectTags} interactable={interactable} />
          {beastLevel > 0 ? (
            <BeastPill level={beastLevel} isDone={status === "done"} isNewUntil={isNewUntil} />
          ) : isNewUntil ? (
            <AddedDuringSprintPill isDone={status === "done"} isNewUntil={isNewUntil} />
          ) : null}
        </XRow>
      </XCol>
    </XCol>
  );
};

const InteractiveCardLane = ({forwardedRef, bag}) => {
  const getData = () => {
    const cardIds = [bag.cardId];
    const item = {
      cardInfos: cardIds.map((id) => ({
        cardId: id,
        getCard: () => api.getModel({modelName: "card", id}),
      })),
      dragFrom: bag.arenaCtxType,
    };
    return item;
  };

  return (
    <Draggable
      type="CARD"
      id={bag.cardId}
      itemData={getData}
      disabled={!bag.isCardLoaded}
      mergeRef={forwardedRef}
    >
      {({handlers: dragHandlers, ref}) => (
        <BaseCardLane bag={bag} forwardedRef={ref} dragProps={dragHandlers} />
      )}
    </Draggable>
  );
};

export const MaybeInaccessibleChildCardLane = forwardRef((props, ref) => {
  const {card} = props;
  if (!card || card.$meta.isDeleted()) {
    return (
      <XCol align="center" justify="center" ref={ref} className={styles.cardLane.inaccessbile}>
        <XText color="gray600" preset="bold" size={1}>
          Inaccessible card
        </XText>
      </XCol>
    );
  } else {
    return <ChildCardLane ref={ref} {...props} />;
  }
});

const ChildCardLaneWithBag = memo((props) => {
  if (props.bag.interactable) {
    return <InteractiveCardLane {...props} />;
  } else {
    return <BaseCardLane {...props} />;
  }
});

const ChildCardLane = forwardRef((props, ref) => {
  const bag = useBag(props);
  return <ChildCardLaneWithBag bag={bag} forwardedRef={ref} />;
});

export default ChildCardLane;
