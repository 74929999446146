import {forwardRef, ReactNode, Ref} from "react";
import XTextButton from "../../components/xui/XTextButton";
import {
  useGlobalKeyPress,
  TooltipForChild,
  DropDownForChild,
  GlobalKeyPressOpts,
  DropDownRenderOverlay,
} from "@cdx/common";
import {DSShortcutKey, Row, css} from "@cdx/ds";

type SharedMenuButtonProps = {
  tooltip?: string | (() => string);
  hidden?: boolean;
  children: ReactNode;
  shortcut?: string;
  showShortcutInTooltip?: string;
  description?: string;
  specialShortcut?: Partial<GlobalKeyPressOpts>;
};

type MenuButtonGlobalKeyPressParams = {
  shortcut?: string;
  fn: () => void;
  description?: string;
  specialShortcut?: Partial<GlobalKeyPressOpts>;
};

const MenuButtonGlobalKeyPress = (props: MenuButtonGlobalKeyPressParams) => {
  const {shortcut, fn, description, specialShortcut} = props;
  useGlobalKeyPress({
    key: shortcut,
    fn,
    description,
    category: description ? "Card Selection" : undefined,
    disabled: false,
    // to ensure that hitting `p` doesn't trigger shortcut for just opened card
    prio: "high",
    ...specialShortcut,
  } as GlobalKeyPressOpts);

  return null;
};

type MenuButtonProps = SharedMenuButtonProps & {
  onClick: () => void;
};
export const MenuButton = forwardRef<HTMLButtonElement, MenuButtonProps>(
  (props, ref: Ref<HTMLButtonElement>) => {
    const {
      tooltip,
      onClick,
      hidden,
      children,
      shortcut,
      showShortcutInTooltip,
      description,
      specialShortcut,
    } = props;
    const getDescription = () => {
      if (description) return description;
      return typeof tooltip === "function" ? tooltip() : tooltip;
    };
    const realDescription = getDescription();
    const shownShortcut = showShortcutInTooltip || shortcut;
    return (
      <>
        {(shortcut || specialShortcut) && (
          <MenuButtonGlobalKeyPress
            shortcut={shortcut}
            fn={onClick}
            description={realDescription}
            specialShortcut={specialShortcut}
          />
        )}
        <TooltipForChild
          tooltip={() => {
            const val = typeof tooltip === "function" ? tooltip() : tooltip;
            return (
              <Row sp="8px" px="8px" py="4px" align="center">
                <div>{val}</div>
                {shownShortcut && <DSShortcutKey size="md">{shownShortcut}</DSShortcutKey>}
              </Row>
            );
          }}
          hidden={hidden}
          delayed
          placement="bottom"
          bg="gray700"
          outerClassName={css({colorTheme: "gray550"})}
          ref={ref}
        >
          <XTextButton size="lg" square onClick={onClick} color="white">
            {children}
          </XTextButton>
        </TooltipForChild>
      </>
    );
  }
);

type MenuButtonWithDropDownProps = SharedMenuButtonProps & {
  overlayFn: DropDownRenderOverlay;
};
export const MenuButtonWithDropDown = (props: MenuButtonWithDropDownProps) => {
  const {overlayFn, ...rest} = props;
  return (
    <DropDownForChild
      overlayProps={{placement: "bottom", distanceFromAnchor: 5}}
      renderOverlay={overlayFn}
      setChildProps={({isOpen}) => ({hidden: !isOpen})}
    >
      <MenuButton onClick={() => {}} {...rest} />
    </DropDownForChild>
  );
};
