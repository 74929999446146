import CdxTypeaheadPlugin, {createNodeHandler} from "./CdxTypeAheadLexicalPlugin";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {
  $applyNodeReplacement,
  DOMConversionMap,
  DOMExportOutput,
  DecoratorNode,
  LexicalEditor,
  NodeKey,
  TextNode,
} from "lexical";
import {useInstance} from "../../../lib/mate/mate-utils";
import RawAvatar from "../../Avatar";
import {Box, Row} from "@cdx/ds";
import dsStyles from "@cdx/ds/css/index.css";
import {waitForResultPromise} from "../../../lib/wait-for-result";
import {api} from "../../../lib/api";
import {useEffect} from "react";
import {
  addTextTransformer,
  useSlashCommand,
} from "@cdx/ds/components/DSTextEditor/CdxSlashCommandLexicalPlugin";
import useLexicalDecoratorNode from "@cdx/ds/components/DSTextEditor/useLexicalDecoratorNode";
import {searchAndFilter} from "../../../lib/search-utils";

const Avatar = RawAvatar as any;

export class MentionNode extends DecoratorNode<JSX.Element> {
  __userId: string;

  static getType() {
    return "cdxMention";
  }

  static clone(node: MentionNode) {
    return new MentionNode(node.__userId, node.__key);
  }

  static importJSON(serializedNode: any): MentionNode {
    const node = $createMentionNode(serializedNode.userId);
    return node;
  }

  constructor(userId: string, key?: NodeKey) {
    super(key);
    this.__userId = userId;
  }

  exportJSON() {
    return {
      type: "cdxMention",
      userId: this.__userId,
      version: 1,
    };
  }

  exportDOM(editor: LexicalEditor): DOMExportOutput {
    const element = document.createElement("span");
    element.setAttribute("data-lexical-cdx-mention-user-id", this.__userId);
    element.innerText = this.getTextContent();
    return {element};
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute("data-lexical-cdx-mention-user-id")) {
          return null;
        }
        return {
          conversion: (innerNode: HTMLElement) => {
            const userId = innerNode.getAttribute("data-lexical-cdx-mention-user-id");
            if (userId) {
              const node = $createMentionNode(userId);
              return {node};
            } else {
              return null;
            }
          },
          priority: 1,
        };
      },
    };
  }

  createDOM(): HTMLElement {
    const el = document.createElement("span");
    el.className = dsStyles.userSelect.none;
    return el;
  }

  updateDOM(): false {
    return false;
  }

  getTextContent(): string {
    const sib = this.getPreviousSibling();
    const hasSpace = sib && sib.getTextContent().match(/\s$/);
    return `${!sib || hasSpace ? "" : " "}@[userId:${this.__userId}]`;
  }

  decorate(): JSX.Element {
    return <Mention userId={this.__userId} nodeKey={this.__key} />;
  }
}

export function $isMentionNode(node: any) {
  return node instanceof MentionNode;
}

export function $createMentionNode(userId: string) {
  return $applyNodeReplacement<MentionNode>(new MentionNode(userId));
}

const Option = ({userId}: {userId: string}) => {
  const user = useInstance("user", userId);
  return (
    <Row sp="8px">
      <Avatar user={user} size={20} />
      <Box color="primary" bold size={14}>
        {user?.name}
      </Box>
    </Row>
  );
};
const Mention = ({userId, nodeKey}: {userId: string; nodeKey: string}) => {
  const user = useInstance("user", userId);
  const {selectionClassName} = useLexicalDecoratorNode(nodeKey);
  return (
    <Box as="span" bold color="primary" colorTheme="active50" className={selectionClassName}>
      @{user?.name}
    </Box>
  );
};

const getOptions =
  (projectId: any) =>
  async (word: string): Promise<string[]> =>
    waitForResultPromise(() => {
      const root = api.getRoot();
      const users = projectId
        ? root.account.$meta
            .find("roles", {
              $or: [
                {role: ["admin", "owner"]},
                {
                  role: ["guest", "observer"],
                  user: {withProjectAccess: {projectId}},
                },
              ],
            })
            .map((r: any) => r.user)
        : [root.loggedInUser]; // deck-less card, only current user has access;
      return searchAndFilter(users, (u) => u.name || "", word).map((u: any) => u.id);
    });

const findAndTransformMention = (node: TextNode) => {
  const text = node.getTextContent();
  const mentionRegex = /@\[userId:([\w-]+)\]/;
  const m = text.match(mentionRegex);
  if (!m) return null;
  const i = m.index!;
  let targetNode;
  if (i === 0) {
    [targetNode] = node.splitText(i + m[0].length);
  } else {
    [, targetNode] = node.splitText(i, i + m[0].length);
  }

  const mentionNode = $createMentionNode(m[1]);
  targetNode.replace(mentionNode);
  return mentionNode;
};

type MentionPluginProps = {project: any};
const MentionPlugin = ({project}: MentionPluginProps) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerNodeTransform(TextNode, (node) => {
      if (!node.isSimpleText()) return;
      findAndTransformMention(node);
    });
  }, [editor]);

  useSlashCommand({
    key: `mention`,
    label: `Mention User`,
    keywords: "user mention owner assignee @",
    handleSelect: addTextTransformer("@", ""),
    groupKey: "references",
  });

  return (
    <CdxTypeaheadPlugin<string>
      triggerFn={(text: string) => {
        const m = text.match(/(\s|\(|^)(@)(\S*)$/);
        if (!m) return null;
        const offset = m.index! + m[1].length;
        return {
          offset,
          match: m[3],
          triggerChar: m[2],
        };
      }}
      getOptions={getOptions(project?.$meta.get("id", null))}
      handleOptionSelect={createNodeHandler((opt) => $createMentionNode(opt))}
      optionToKey={(opt) => opt}
      renderOption={(opt) => <Option userId={opt} />}
      debounceMs={0}
    />
  );
};

export default MentionPlugin;
