import {ComponentProps, forwardRef, memo} from "react";
import cx from "../../utils/cx";
import {ReactComponent as RobotSvg} from "./assets/robot_24.svg";
import {ReactComponent as CloseSvg} from "./assets/close_24.svg";
import {ReactComponent as ClockSvg} from "./assets/clock_24.svg";
import {ReactComponent as SelectionEmptySvg} from "./assets/selection_empty_24.svg";
import {ReactComponent as SelectionCloseSvg} from "./assets/selection_close_24.svg";
import {ReactComponent as CheckSvg} from "./assets/check_24.svg";
import {ReactComponent as EffortSvg} from "./assets/effort_24.svg";
import {ReactComponent as PrioASvg} from "./assets/prio_a_24.svg";
import {ReactComponent as PrioBSvg} from "./assets/prio_b_24.svg";
import {ReactComponent as PrioCSvg} from "./assets/prio_c_24.svg";
import {ReactComponent as LockOpenSvg} from "./assets/lock_open_24.svg";
import {ReactComponent as LockClosedSvg} from "./assets/lock_closed_24.svg";
import {ReactComponent as DocSvg} from "./assets/doc_24.svg";
import {ReactComponent as CalendarMilestoneSvg} from "./assets/calendar_milestone_24.svg";
import {ReactComponent as CalendarBellSvg} from "./assets/calendar_bell_24.svg";
import {ReactComponent as BellSvg} from "./assets/bell_24.svg";
import {ReactComponent as PropertiesSvg} from "./assets/properties_24.svg";
import {ReactComponent as AttachmentSvg} from "./assets/attachment_24.svg";
import {ReactComponent as HistorySvg} from "./assets/history_24.svg";
import {ReactComponent as CrownSvg} from "./assets/crown_24.svg";
import {ReactComponent as ArchiveSvg} from "./assets/archive_24.svg";
import {ReactComponent as DeckInboxSvg} from "./assets/deck_inbox_24.svg";
import {ReactComponent as GhostSvg} from "./assets/ghost_24.svg";
import {ReactComponent as MilestoneSvg} from "./assets/milestone_24.svg";
import {ReactComponent as PlusSvg} from "./assets/plus_24.svg";
import {ReactComponent as MinusSvg} from "./assets/minus_24.svg";
import {ReactComponent as TrashSvg} from "./assets/trash_24.svg";
import {ReactComponent as MailSvg} from "./assets/mail_24.svg";
import {ReactComponent as CalendarSvg} from "./assets/calendar_24.svg";
import {ReactComponent as QuestionSvg} from "./assets/question_24.svg";
import {ReactComponent as BookmarkSvg} from "./assets/bookmark_24.svg";
import {ReactComponent as HandSvg} from "./assets/hand_24.svg";
import {ReactComponent as PlaySvg} from "./assets/play_24.svg";
import {ReactComponent as SearchSvg} from "./assets/search_24.svg";
import {ReactComponent as DeckSvg} from "./assets/deck_24.svg";
import {ReactComponent as ChevronUpSvg} from "./assets/chevron_up_24.svg";
import {ReactComponent as ChevronDownSvg} from "./assets/chevron_down_24.svg";
import {ReactComponent as ChevronLeftSvg} from "./assets/chevron_left_24.svg";
import {ReactComponent as ChevronRightSvg} from "./assets/chevron_right_24.svg";
import {ReactComponent as YoutubeSvg} from "./assets/youtube_24.svg";
import {ReactComponent as DiscordSvg} from "./assets/discord_24.svg";
import {ReactComponent as TwitterSvg} from "./assets/twitter_24.svg";
import {ReactComponent as GoogleSvg} from "./assets/google_24.svg";
import {ReactComponent as BracketsSvg} from "./assets/brackets_24.svg";
import {ReactComponent as CodeSvg} from "./assets/code_24.svg";
import {ReactComponent as HeadingsSvg} from "./assets/headings_24.svg";
import {ReactComponent as LinkSvg} from "./assets/link_24.svg";
import {ReactComponent as ListSvg} from "./assets/list_24.svg";
import {ReactComponent as MiscSvg} from "./assets/misc_24.svg";
import {ReactComponent as ReferenceSvg} from "./assets/reference_24.svg";
import {ReactComponent as BoldSvg} from "./assets/bold_24.svg";
import {ReactComponent as ItalicSvg} from "./assets/italic_24.svg";
import {ReactComponent as StrikeThroughSvg} from "./assets/strikethrough_24.svg";
import {ReactComponent as HandSingleSvg} from "./assets/hand_single_24.svg";
import {ReactComponent as HandSingleDashedSvg} from "./assets/hand_single_dashed_24.svg";
import {ReactComponent as FocusModeSvg} from "./assets/focus_mode_24.svg";
import {ReactComponent as FullscreenSvg} from "./assets/fullscreen_24.svg";
import {ReactComponent as EyeSvg} from "./assets/eye_24.svg";
import {ReactComponent as EyeSlashSvg} from "./assets/eye_slash_24.svg";
import {ReactComponent as PencilSvg} from "./assets/pencil_24.svg";
import {ReactComponent as UserSvg} from "./assets/user_24.svg";
import {ReactComponent as SortSvg} from "./assets/sort_24.svg";
import {ReactComponent as SortAscSvg} from "./assets/sort_asc_24.svg";
import {ReactComponent as SortDescSvg} from "./assets/sort_desc_24.svg";
import {ReactComponent as StarSvg} from "./assets/star_24.svg";
import {ReactComponent as ReviewSvg} from "./assets/review_24.svg";
import {ReactComponent as BlockedSvg} from "./assets/blocked_24.svg";
import {ReactComponent as SnoozingSvg} from "./assets/snoozing_24.svg";
import {ReactComponent as FilterSvg} from "./assets/filter_24.svg";
import {ReactComponent as ExternalSvg} from "./assets/external_24.svg";
import {ReactComponent as CardSvg} from "./assets/card_24.svg";
import {ReactComponent as InfoSvg} from "./assets/info_24.svg";
import {ReactComponent as ShieldSvg} from "./assets/shield_24.svg";
import {ReactComponent as CogSvg} from "./assets/cog_24.svg";
import {ReactComponent as DragSvg} from "./assets/drag_24.svg";
import {ReactComponent as GddSvg} from "./assets/gdd_24.svg";
import {ReactComponent as TasksSvg} from "./assets/tasks_24.svg";
import {ReactComponent as BookSvg} from "./assets/book_24.svg";
import {ReactComponent as QaSvg} from "./assets/qa_24.svg";
import {ReactComponent as FlashSvg} from "./assets/flash_24.svg";
import {ReactComponent as PinSvg} from "./assets/pin_24.svg";
import {ReactComponent as ConversationSvg} from "./assets/conversation_24.svg";
import {ReactComponent as TagSvg} from "./assets/tag_24.svg";
import {ReactComponent as ExpandSvg} from "./assets/expand_24.svg";
import {ReactComponent as CollapseSvg} from "./assets/collapse_24.svg";
import {ReactComponent as DuplicateCardSvg} from "./assets/duplicate_card_24.svg";
import {ReactComponent as KeyboardSvg} from "./assets/keyboard_24.svg";
import {ReactComponent as MediaSvg} from "./assets/media_24.svg";
import {ReactComponent as MentionSvg} from "./assets/mention_24.svg";
import {ReactComponent as StopSvg} from "./assets/stop_24.svg";
import {ReactComponent as BarChartSvg} from "./assets/barchart_24.svg";
import {ReactComponent as ArrowCurvedUpSvg} from "./assets/arrow_curved_up_24.svg";
import {ReactComponent as DownloadSvg} from "./assets/download_24.svg";
import {ReactComponent as SignoutSvg} from "./assets/signout_24.svg";
import {ReactComponent as RevenueSvg} from "./assets/revenue_24.svg";
import {ReactComponent as MilestoneNextSvg} from "./assets/milestone_next_24.svg";
import {ReactComponent as SprintSvg} from "./assets/sprint_24.svg";
import {ReactComponent as SprintNextSvg} from "./assets/sprint_next_24.svg";
import {ReactComponent as PyramidSvg} from "./assets/pyramid_24.svg";
import {ReactComponent as BanSvg} from "./assets/ban_24.svg";
import {ReactComponent as AddCardSvg} from "./assets/add_card_24.svg";
import {ReactComponent as AverageSvg} from "./assets/average_24.svg";
import {ReactComponent as StopWatchPlaySvg} from "./assets/stop_watch_play_24.svg";
import {ReactComponent as StopWatchSvg} from "./assets/stop_watch_24.svg";
import {ReactComponent as JourneySvg} from "./assets/journey_24.svg";
import {ReactComponent as PaletteSvg} from "./assets/palette_24.svg";
import {ReactComponent as SmileSvg} from "./assets/smile_24.svg";

// not final
import {ReactComponent as GlobeSvg} from "./assets/globe_24.svg";
import {ReactComponent as ShareSvg} from "./assets/share_24.svg";
import {ReactComponent as BeastSvg} from "./assets/beast_24.svg";
import {ReactComponent as BeastSlainSvg} from "./assets/beast_slain_24.svg";

import {iconStyles} from "./DSIcon.css";

export type IconProps = {
  alt?: string;
  size?: keyof (typeof iconStyles)["sizes"] | null;
  inline?: boolean;
} & ComponentProps<"svg">;

const makeComponent = (SvgComp: any, opts: {fallbackAlt: string}) =>
  memo(
    forwardRef<SVGElement, IconProps>(({className, alt, size = null, inline, ...rest}, ref) => (
      <SvgComp
        ref={ref}
        className={cx(
          className,
          size && iconStyles.sizes[size],
          inline ? iconStyles.mode.inline : iconStyles.mode.block,
          iconStyles.base
        )}
        alt={alt || opts.fallbackAlt}
        {...rest}
      />
    ))
  );

export const DSIconRobot = makeComponent(RobotSvg, {fallbackAlt: "Robot"});
export const DSIconClose = makeComponent(CloseSvg, {fallbackAlt: "Close"});
export const DSIconClock = makeComponent(ClockSvg, {fallbackAlt: "Clock"});
export const DSIconSelectionEmpty = makeComponent(SelectionEmptySvg, {
  fallbackAlt: "Make Selection",
});
export const DSIconSelectionClose = makeComponent(SelectionCloseSvg, {
  fallbackAlt: "Make Selection",
});
export const DSIconCheck = makeComponent(CheckSvg, {fallbackAlt: "Check mark"});
export const DSIconEffort = makeComponent(EffortSvg, {fallbackAlt: "Effort"});
export const DSIconPrioA = makeComponent(PrioASvg, {fallbackAlt: "Prio A"});
export const DSIconPrioB = makeComponent(PrioBSvg, {fallbackAlt: "Prio B"});
export const DSIconPrioC = makeComponent(PrioCSvg, {fallbackAlt: "Prio C"});
export const DSIconLockClosed = makeComponent(LockClosedSvg, {fallbackAlt: "Closed Lock"});
export const DSIconLockOpen = makeComponent(LockOpenSvg, {fallbackAlt: "Open Lock"});
export const DSIconDoc = makeComponent(DocSvg, {fallbackAlt: "Doc"});

export const DSIconCalendarMilestone = makeComponent(CalendarMilestoneSvg, {
  fallbackAlt: "Milestone",
});
export const DSIconCalendarBell = makeComponent(CalendarBellSvg, {fallbackAlt: "Due Date"});
export const DSIconBell = makeComponent(BellSvg, {fallbackAlt: "Bell"});
export const DSIconProperties = makeComponent(PropertiesSvg, {fallbackAlt: "Properties"});
export const DSIconAttachment = makeComponent(AttachmentSvg, {fallbackAlt: "Attachment"});
export const DSIconHistory = makeComponent(HistorySvg, {fallbackAlt: "History"});
export const DSIconCrown = makeComponent(CrownSvg, {fallbackAlt: "Crown"});
export const DSIconArchive = makeComponent(ArchiveSvg, {fallbackAlt: "Archive"});
export const DSIconDeckInbox = makeComponent(DeckInboxSvg, {fallbackAlt: "Inbox"});
export const DSIconGhost = makeComponent(GhostSvg, {fallbackAlt: "Ghost"});
export const DSIconMilestone = makeComponent(MilestoneSvg, {fallbackAlt: "Milestone"});
export const DSIconPlus = makeComponent(PlusSvg, {fallbackAlt: "Plus"});
export const DSIconMinus = makeComponent(MinusSvg, {fallbackAlt: "Minus"});
export const DSIconTrash = makeComponent(TrashSvg, {fallbackAlt: "Delete"});
export const DSIconMail = makeComponent(MailSvg, {fallbackAlt: "Mail"});
export const DSIconCalendar = makeComponent(CalendarSvg, {fallbackAlt: "Calendar"});
export const DSIconQuestion = makeComponent(QuestionSvg, {fallbackAlt: "Question"});
export const DSIconBookmark = makeComponent(BookmarkSvg, {fallbackAlt: "Bookmark"});
export const DSIconHand = makeComponent(HandSvg, {fallbackAlt: "Hand"});
export const DSIconPlay = makeComponent(PlaySvg, {fallbackAlt: "Play"});
export const DSIconSearch = makeComponent(SearchSvg, {fallbackAlt: "Search"});
export const DSIconDeck = makeComponent(DeckSvg, {fallbackAlt: "Deck"});

export const DSIconChevronUp = makeComponent(ChevronUpSvg, {fallbackAlt: "Up"});
export const DSIconChevronDown = makeComponent(ChevronDownSvg, {fallbackAlt: "Up"});
export const DSIconChevronLeft = makeComponent(ChevronLeftSvg, {fallbackAlt: "Up"});
export const DSIconChevronRight = makeComponent(ChevronRightSvg, {fallbackAlt: "Up"});
export const DSIconYoutube = makeComponent(YoutubeSvg, {fallbackAlt: "Youtube"});
export const DSIconDiscord = makeComponent(DiscordSvg, {fallbackAlt: "Discord"});
export const DSIconTwitter = makeComponent(TwitterSvg, {fallbackAlt: "Twitter"});
export const DSIconGoogle = makeComponent(GoogleSvg, {fallbackAlt: "Google"});

export const DSIconBrackets = makeComponent(BracketsSvg, {fallbackAlt: "Brackets"});
export const DSIconCode = makeComponent(CodeSvg, {fallbackAlt: "Code"});
export const DSIconHeadings = makeComponent(HeadingsSvg, {fallbackAlt: "Headings"});
export const DSIconLink = makeComponent(LinkSvg, {fallbackAlt: "Link"});
export const DSIconList = makeComponent(ListSvg, {fallbackAlt: "List"});
export const DSIconMisc = makeComponent(MiscSvg, {fallbackAlt: "Misc"});
export const DSIconReference = makeComponent(ReferenceSvg, {fallbackAlt: "Reference"});
export const DSIconBold = makeComponent(BoldSvg, {fallbackAlt: "Bold"});
export const DSIconItalic = makeComponent(ItalicSvg, {fallbackAlt: "Italic"});
export const DSIconStrikeThrough = makeComponent(StrikeThroughSvg, {fallbackAlt: "Strike Through"});

export const DSIconHandSingle = makeComponent(HandSingleSvg, {fallbackAlt: "In Hand"});
export const DSIconHandSingleDashed = makeComponent(HandSingleDashedSvg, {
  fallbackAlt: "Not in Hand",
});
export const DSIconFocusMode = makeComponent(FocusModeSvg, {fallbackAlt: "Focus Mode"});
export const DSIconFullscreen = makeComponent(FullscreenSvg, {fallbackAlt: "Fullscreen"});

export const DSIconJourney = makeComponent(JourneySvg, {fallbackAlt: "Journey"});
export const DSIconGlobe = makeComponent(GlobeSvg, {fallbackAlt: "Globe"});

export const DSIconEye = makeComponent(EyeSvg, {fallbackAlt: "Subscribe"});
export const DSIconEyeSlash = makeComponent(EyeSlashSvg, {fallbackAlt: "Eye with Slash"});
export const DSIconPencil = makeComponent(PencilSvg, {fallbackAlt: "Edit"});
export const DSIconUser = makeComponent(UserSvg, {fallbackAlt: "User"});
export const DSIconSort = makeComponent(SortSvg, {fallbackAlt: "Sort"});
export const DSIconSortAsc = makeComponent(SortAscSvg, {fallbackAlt: "Sort Ascending"});
export const DSIconSortDesc = makeComponent(SortDescSvg, {fallbackAlt: "Sort Descending"});
export const DSIconStar = makeComponent(StarSvg, {fallbackAlt: "Star"});
export const DSIconReview = makeComponent(ReviewSvg, {fallbackAlt: "Review"});
export const DSIconBlocked = makeComponent(BlockedSvg, {fallbackAlt: "Blocked"});
export const DSIconSnoozing = makeComponent(SnoozingSvg, {fallbackAlt: "Snoozing"});
export const DSIconFilter = makeComponent(FilterSvg, {fallbackAlt: "Filter"});
export const DSIconExternal = makeComponent(ExternalSvg, {fallbackAlt: "External"});
export const DSIconCard = makeComponent(CardSvg, {fallbackAlt: "Card"});
export const DSIconInfo = makeComponent(InfoSvg, {fallbackAlt: "Info"});
export const DSIconShield = makeComponent(ShieldSvg, {fallbackAlt: "Shield"});
export const DSIconCog = makeComponent(CogSvg, {fallbackAlt: "Settings"});
export const DSIconDrag = makeComponent(DragSvg, {fallbackAlt: "Drag"});

export const DSIconGdd = makeComponent(GddSvg, {fallbackAlt: "GDD"});
export const DSIconTasks = makeComponent(TasksSvg, {fallbackAlt: "Tasks"});
export const DSIconBook = makeComponent(BookSvg, {fallbackAlt: "Knowledge"});
export const DSIconQa = makeComponent(QaSvg, {fallbackAlt: "QA"});
export const DSIconFlash = makeComponent(FlashSvg, {fallbackAlt: "Flash"});
export const DSIconPin = makeComponent(PinSvg, {fallbackAlt: "Pin"});
export const DSIconConversation = makeComponent(ConversationSvg, {fallbackAlt: "Conversation"});
export const DSIconTag = makeComponent(TagSvg, {fallbackAlt: "Tag"});
export const DSIconExpand = makeComponent(ExpandSvg, {fallbackAlt: "Expand"});
export const DSIconCollapse = makeComponent(CollapseSvg, {fallbackAlt: "Collapse"});
export const DSIconDuplicateCard = makeComponent(DuplicateCardSvg, {fallbackAlt: "Duplicate"});
export const DSIconKeyboard = makeComponent(KeyboardSvg, {fallbackAlt: "Keyboard"});
export const DSIconMedia = makeComponent(MediaSvg, {fallbackAlt: "Media"});
export const DSIconMention = makeComponent(MentionSvg, {fallbackAlt: "Mention"});
export const DSIconStop = makeComponent(StopSvg, {fallbackAlt: "Stop"});
export const DSIconBarChart = makeComponent(BarChartSvg, {fallbackAlt: "Bar Chart"});
export const DSIconArrowCurvedUp = makeComponent(ArrowCurvedUpSvg, {fallbackAlt: "ArrowCurvedUp"});
export const DSIconDownload = makeComponent(DownloadSvg, {fallbackAlt: "Download"});
export const DSIconShare = makeComponent(ShareSvg, {fallbackAlt: "Share"});
export const DSIconSignout = makeComponent(SignoutSvg, {fallbackAlt: "Signout"});
export const DSIconRevenue = makeComponent(RevenueSvg, {fallbackAlt: "Revenue"});
export const DSIconMilestoneNext = makeComponent(MilestoneNextSvg, {fallbackAlt: "Next Milestone"});
export const DSIconSprint = makeComponent(SprintSvg, {fallbackAlt: "Run"});
export const DSIconSprintNext = makeComponent(SprintNextSvg, {fallbackAlt: "Next Run"});
export const DSIconStopWatchPlay = makeComponent(StopWatchPlaySvg, {fallbackAlt: "Start Tracking"});
export const DSIconStopWatch = makeComponent(StopWatchSvg, {fallbackAlt: "Time Tracking"});
export const DSIconPalette = makeComponent(PaletteSvg, {fallbackAlt: "Color Picker"});
export const DSIconSmile = makeComponent(SmileSvg, {fallbackAlt: "Pick Emoji"});

export const DSIconBeast = makeComponent(BeastSvg, {fallbackAlt: "Beast"});
export const DSIconBeastSlain = makeComponent(BeastSlainSvg, {fallbackAlt: "Slained Beast"});
export const DSIconPyramid = makeComponent(PyramidSvg, {fallbackAlt: "Zone"});
export const DSIconBan = makeComponent(BanSvg, {fallbackAlt: "Opt out"});
export const DSIconAddCard = makeComponent(AddCardSvg, {fallbackAlt: "Add Card"});
export const DSIconAverage = makeComponent(AverageSvg, {fallbackAlt: "Average"});
