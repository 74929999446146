import {useState} from "react";
import {History} from "history";
import {useDropZone, useDragItem} from "@codecks/dnd";
import {api} from "../../lib/api";
import DeckSelect, {DECK_SELECT_MODAL_WIDTH} from "../../components/DeckSelect";
import {useModalWithData} from "../../components/Modals";
import messenger from "../../lib/messenger";
import routes from "../../routes";
import {moveCardsToDeck} from "../../lib/cross-project-cards-or-decks";
import Tab from "./Tab";
import {searchActions, useSearchStore} from "../../features/search/useSearch";
import useBling from "../../lib/hooks/useBling";
import {cx, useGlobalKeyPress, useToggle} from "@cdx/common";
import MilestonePicker from "../../features/milestones/MilestonePicker";
import useMutation from "../../lib/hooks/useMutation";
import {
  canAddHandToQueueErrors,
  canDiscardHandFromQueue,
  isCardInSomeHandQueue,
} from "../../features/hand-queue/hand-queue-utils";
import {mainNavigationStyles as styles} from "./arena.css";
import {toDateStr} from "../../lib/date-utils";
import {getMySelectedProjectIds} from "../../lib/hooks/useSelectedProjects";
import {HelpCenterButton} from "../../features/help-center/HelpCenter";
import {Col, DSIconCheck, Row} from "@cdx/ds";
import {useCardResizeArea} from "../../components/Card/dragged-card-resize";
import useFeatureFlag, {FEATURE_FLAGS} from "../../components/useFeatureFlag";
import {hasPermissionToGuardCard} from "../../lib/permissions";
import {useLocalStorageState} from "../../lib/storage";
import {Root} from "../../cdx-models/Root";
import {Milestone, MilestoneId} from "../../cdx-models/Milestone";
import {Card, CardId} from "../../cdx-models/Card";
import {DeckId} from "../../cdx-models/Deck";
import {Sprint} from "../../cdx-models/Sprint";

const DecksButton = ({root, isActive}: {root: Root; isActive: boolean}) => {
  const [pickDeckForCardIds, setPickDeckForCardIds] = useState<null | CardId[]>(null);
  const [showDeckSelector, {on, off}] = useToggle();

  const handleDeckPick = (deckId: DeckId) => {
    if (!pickDeckForCardIds) return;
    return moveCardsToDeck(pickDeckForCardIds, deckId).then((ok) => {
      if (ok) {
        messenger.send(
          (
            <span>
              {pickDeckForCardIds.length === 1 ? "Card was" : "Cards were"} moved to{" "}
              <b>{api.getModel({modelName: "deck", id: deckId})?.title}</b>
            </span>
          ) as unknown as string
        );
      }
    });
  };

  const closeDeckSelector = () => {
    setPickDeckForCardIds(null);
    off();
  };

  const handleCardDrop = ({item}: any) => {
    if (!item) return;
    if (item.data.cardInfos.some(({getCard}: any) => !hasPermissionToGuardCard(root, getCard())))
      return;
    on();
    setPickDeckForCardIds(item.data.cardInfos.map((ci: any) => ci.cardId));
  };

  const renderDeckPicker = () => {
    const deckIds = new Set();
    if (pickDeckForCardIds) {
      pickDeckForCardIds.forEach((id) => {
        const card = api.getModel({modelName: "card", id});
        if (card?.deck) deckIds.add(card.deck.id);
      });
    }
    return (
      <DeckSelect
        root={root}
        onChange={handleDeckPick}
        value={deckIds.size === 1 ? [...deckIds][0] : null}
        onClose={closeDeckSelector}
      />
    );
  };

  const {isOver, dragItem, ref: dropRef} = useDropZone({type: "CARD", onDrop: handleCardDrop});
  const renderModal = useModalWithData(showDeckSelector, {
    onClose: closeDeckSelector,
    hideClose: true,
    width: DECK_SELECT_MODAL_WIDTH,
  });

  const validDragItems =
    dragItem &&
    dragItem.data.cardInfos.some(({getCard}: any) => hasPermissionToGuardCard(root, getCard()));
  const dragCardsHaveDeck =
    validDragItems && dragItem.data.cardInfos.some(({getCard}: any) => getCard().deck);
  return (
    <>
      {renderModal(renderDeckPicker)}
      <Tab
        to={routes.decks.getUrl()}
        active={isActive}
        index={1}
        canDrop={Boolean(validDragItems)}
        isDropOver={validDragItems && isOver}
        ref={dropRef}
      >
        {validDragItems ? (dragCardsHaveDeck ? "Change deck" : "Add to Deck") : "Decks"}
      </Tab>
    </>
  );
};

const HandQueueButton = ({isActive, root}: {isActive: boolean; root: Root}) => {
  const [blingRef, setMsg] = useBling();
  const handleDropSuccess = () => setMsg(<DSIconCheck size={16} />);
  const meId = root.loggedInUser?.$meta.get("id", null);

  const onDropCard = () => {
    const cardIds = dragItem.data.cardInfos.map((c: any) => c.cardId);
    if (allInSomeQueue) {
      if (canDiscardSome && meId) {
        api.mutate.handQueue.removeCards({cardIds}).then(handleDropSuccess);
      }
    } else if (canAddSome && meId) {
      api.mutate.handQueue
        .addCardsToOwner({cardIds, accountId: root.account.id})
        .then(handleDropSuccess);
    }
  };

  const {dragItem, isOver, ref} = useDropZone({
    type: "CARD",
    onDrop: onDropCard,
  });

  const cards = dragItem
    ? (dragItem.data.cardInfos.map((ci: any) => ci.getCard()) as Card[])
    : null;
  const allInSomeQueue =
    cards &&
    cards.every((card) =>
      isCardInSomeHandQueue({cardId: card.cardId as CardId, account: root.account})
    );

  const canDiscardSome = allInSomeQueue && cards.some(canDiscardHandFromQueue);
  const canAddSome = cards && cards.some((card) => !canAddHandToQueueErrors(card, meId, root));

  const getText = () => {
    if (allInSomeQueue) {
      if (canDiscardSome) {
        return "Discard from hand";
      }
    } else if (canAddSome) {
      return "Add to hand";
    }
    return "Hand";
  };

  const handleClick = () => {
    if (isActive) searchActions.setFilters([]);
  };
  const text = getText();
  return (
    <Tab
      to={routes.hand.getUrl()}
      handActive={isActive}
      index={0}
      canDrop={dragItem && text !== "Hand"}
      isDropOver={isOver && text !== "Hand"}
      isRemoveDrop={allInSomeQueue}
      smallText={text.length > 5}
      ref={ref}
      onClick={handleClick}
    >
      <div ref={blingRef}>{text}</div>
    </Tab>
  );
};

const MilestoneButton = ({isActive, to, root}: {isActive: boolean; to: string; root: Root}) => {
  const [pickMsForCardIds, setPickMsForCardIds] = useState<null | MilestoneId[]>(null);
  const [showMsSelector, {on, off}] = useToggle();
  const [updateCards] = useMutation("cards", "bulkUpdate");

  const closeMsSelector = () => {
    setPickMsForCardIds(null);
    off();
  };

  const handleMsPick = (milestoneId: MilestoneId) => {
    return updateCards({milestoneId, ids: pickMsForCardIds}).then(() => {
      messenger.send(
        `${pickMsForCardIds?.length === 1 ? "Card was" : "Cards were"} moved to milestone.`
      );
      closeMsSelector();
    });
  };

  const handleCardDrop = ({item}: {item: any}) => {
    on();
    setPickMsForCardIds(item.data.cardInfos.map((ci: any) => ci.cardId));
  };

  const renderMsPicker = () => {
    const count = pickMsForCardIds ? pickMsForCardIds.length : 0;

    return (
      <MilestonePicker
        account={root.account}
        onlySelectedProjects
        onChange={handleMsPick}
        title={`Pick milestone${count > 1 ? ` for ${count} cards` : ""}`}
        allowNone
        root={root}
      />
    );
  };

  const {isOver, dragItem, ref: dropRef} = useDropZone({type: "CARD", onDrop: handleCardDrop});
  const renderModal = useModalWithData(showMsSelector, {
    onClose: closeMsSelector,
    hideClose: true,
    width: 400,
  });

  return (
    <>
      {renderModal(renderMsPicker)}

      <Tab active={isActive} index={2} to={to} canDrop={dragItem} isDropOver={isOver} ref={dropRef}>
        Timeline
      </Tab>
    </>
  );
};

const useTimelineLink = (
  root: Root
): {type: "ms"; milestone: Milestone} | {type: "sprint"; sprint: Sprint} | {type: "none"} => {
  const {loggedInUser, account} = root;
  const meId = root.loggedInUser?.$meta.get("id", null);
  const accountId = root.account?.$meta.get("id", null);
  const [lastClick] = useLocalStorageState("navigation-timeline-last-type");
  if (!meId || !accountId || !account.milestonesEnabled) {
    return {type: "none"};
  }
  const todayStr = toDateStr(new Date());

  if (lastClick?.type === "milestone") {
    const ms = api.getModel({modelName: "milestone", id: lastClick.id});
    if (ms && !ms.$meta.isDeleted()) {
      return {type: "ms", milestone: ms};
    }
  } else if (lastClick?.type === "sprintConfig") {
    const sprint = account.$meta.find("sprints", {
      sprintConfigId: lastClick.id,
      isDeleted: false,
      endDate: {op: "gte", value: todayStr},
      $order: ["endDate"],
      $limit: 1,
    });
    if (sprint.length === 1) {
      return {type: "sprint", sprint: sprint[0]};
    }
  }

  const maybePinned = accountId
    ? loggedInUser.$meta.find("pinnedMilestoneNext", {
        accountId,
        milestone: {isDeleted: false},
      })
    : null;
  if (maybePinned?.milestone) return {type: "ms", milestone: maybePinned?.milestone};
  const selProjIds = getMySelectedProjectIds(root);

  const nextSprint = account.$meta.first("sprints", {
    ...(selProjIds.length ? {sprintConfig: {sprintProjects: {projectId: selProjIds}}} : {}),
    endDate: {op: "gte", value: todayStr},
    isDeleted: false,
    $order: ["endDate", "sprintConfigId"],
  });
  if (nextSprint) return {type: "sprint", sprint: nextSprint};

  const nextMilestone = account.$meta.first("milestones", {
    ...(selProjIds.length ? {milestoneProjects: {projectId: selProjIds}} : {}),
    date: {op: "gte", value: todayStr},
    isDeleted: false,
    $order: ["date", "id"],
  });
  if (nextMilestone) return {type: "ms", milestone: nextMilestone};
  return {type: "none"};
};

const MilestoneArea = ({root, activeTab, history}: MainNavigationProps) => {
  const nextEl = useTimelineLink(root);
  const getTo = () => {
    switch (nextEl.type) {
      case "ms":
        return routes.milestone.getUrl(nextEl.milestone.accountSeq);
      case "sprint":
        return routes.sprint.getUrl(nextEl.sprint.accountSeq);
      case "none":
        return routes.milestones.getUrl();
    }
  };
  const to = getTo();
  useGlobalKeyPress({
    code: "Digit3",
    fn: () => history.push(to),
    description: "Go to Timeline",
    withShift: true,
    category: null,
  });
  return <MilestoneButton isActive={activeTab === "milestones"} to={to} root={root} />;
};

const VisionBoardButton = ({isActive}: {isActive?: boolean}) => (
  <Tab active={isActive} index={3} to={routes.visionBoard.getUrl()}>
    Vision
  </Tab>
);

type MainNavigationProps = {
  root: Root;
  activeTab: string;
  history: History;
};

const MainNavigation = ({root, activeTab, history}: MainNavigationProps) => {
  const searchOpen = useSearchStore((s) => s.isFocussed);

  useGlobalKeyPress({
    code: "Digit1",
    fn: () => history.push(routes.hand.getUrl()),
    description: "Go to your hand",
    withShift: true,
    category: null,
  });
  useGlobalKeyPress({
    code: "Digit2",
    fn: () => history.push(routes.decks.getUrl()),
    description: "Go to decks",
    withShift: true,
    category: null,
  });

  const withVisionBoard = useFeatureFlag(root.account, FEATURE_FLAGS.visionBoard);
  const isSuperAdmin = root.loggedInUser.cdxRole !== "none";

  const draggedCard = useDragItem("CARD");

  const nodeRef = useCardResizeArea({cardScale: 0.25, reach: 100, disabled: !draggedCard});

  return (
    <Row
      className={cx(styles.default, searchOpen && styles.focussedSearch)}
      align="end"
      ref={nodeRef}
    >
      <HandQueueButton isActive={activeTab === "hand"} root={root} />
      <DecksButton root={root} isActive={activeTab === "decks"} />
      {(root.account.milestonesEnabled || root.account.sprintsEnabled) && (
        <MilestoneArea activeTab={activeTab} history={history} root={root} />
      )}
      {withVisionBoard && isSuperAdmin && (
        <VisionBoardButton isActive={activeTab === "visionBoard"} />
      )}
      <Col pl={2} justify="center" alignSelf="stretch">
        <HelpCenterButton root={root} />
      </Col>
    </Row>
  );
};
export default MainNavigation;
