import {isEventWithinInteractiveNode} from "@cdx/common/dom-helpers";
import React, {useEffect, useRef, useState} from "react";

type State = {
  state: "idle" | "pending" | "pendingTouch" | "started";
  actions: Actions;
};

type Actions = {
  onMouseDown?: (e: React.MouseEvent<HTMLElement>) => void;
  onMouseUp?: (e: React.MouseEvent<HTMLElement>) => void;
  onMouseMove?: (e: React.MouseEvent<HTMLElement>) => void;
  onClickCapture?: (e: React.MouseEvent<HTMLElement>) => void;
  onContextMenu?: (e: React.MouseEvent<HTMLElement>) => void;
  onTouchStart?: (e: React.TouchEvent<HTMLElement>) => void;
  onTouchMove?: (e: React.TouchEvent<HTMLElement>) => void;
  onTouchEnd?: (e: React.TouchEvent<HTMLElement>) => void;
  onTouchCancel?: (e: React.TouchEvent<HTMLElement>) => void;
};

type Point = [x: number, y: number];

export type DragHandler = {
  onMove: (pos: Point) => void;
  onStop: (isCancelled: boolean) => void;
  onUnmount?: () => void;
};

type PendingHandler<T> = {
  onDone: () => T;
  onCancel: () => void;
};

type DragHandlerCreator<T> = (startPos: Point, ctx: T) => DragHandler | null;

const primaryButton = 0;
const secondaryButton = 2;
const sloppyClickThreshold = 5;
const isSloppyClickThresholdExceeded = ([orgX, orgY]: Point, [currX, currY]: Point) =>
  Math.abs(currX - orgX) >= sloppyClickThreshold || Math.abs(currY - orgY) >= sloppyClickThreshold;

export const setupDragHandlers = <T = undefined>(
  createDragHandler: DragHandlerCreator<T>,
  createPendingHandler?: (e: React.MouseEvent | React.TouchEvent) => PendingHandler<T>,
  opts: {allowSecondaryButton?: boolean} = {}
) => {
  let justReleasedFromDrag = false;
  const {allowSecondaryButton} = opts;
  const allowedButtons = new Set([primaryButton]);
  if (allowSecondaryButton) allowedButtons.add(secondaryButton);
  const unsubs: (() => void)[] = [];
  const getIdleState = (): State => ({
    state: "idle",
    actions: {
      onClickCapture: (e) => {
        if (justReleasedFromDrag) {
          e.preventDefault();
          e.stopPropagation();
        }
      },
      onMouseDown: (e) => {
        if (isEventWithinInteractiveNode(e)) return;
        justReleasedFromDrag = false;
        if (e.defaultPrevented) return;
        if (!allowedButtons.has(e.button)) return;
        if (e.ctrlKey || e.metaKey || e.shiftKey || e.altKey) return;
        e.stopPropagation();
        e.preventDefault();
        state = getPendingState({startPos: [e.clientX, e.clientY]}, e);
      },
      onTouchStart: (e) => {
        if (isEventWithinInteractiveNode(e)) return;
        if (e.defaultPrevented) return;
        const handler = createPendingHandler?.(e);
        const t = e.touches[0];
        const startPos: Point = [t.clientX, t.clientY];
        const timeoutId = setTimeout(() => {
          unsubs.splice(0, unsubs.length);
          const result = handler?.onDone();
          state = getStartedState({startPos, isTouch: true}, result as T);
        }, 100);
        unsubs.push(() => clearTimeout(timeoutId));
        return (state = getPendingTouchState({
          startPos,
          onCancel: () => {
            if (handler) handler.onCancel();
          },
        }));
      },
      ...(allowSecondaryButton
        ? {
            onContextMenu: (e) => {
              e.preventDefault();
            },
          }
        : {}),
    },
  });

  const getPendingState = (
    {startPos}: {startPos: Point},
    initialEvent: React.MouseEvent
  ): State => {
    const handler = createPendingHandler?.(initialEvent);
    const clearUnsubs = () => {
      for (const fn of unsubs.splice(0, unsubs.length)) fn();
    };
    const cancel = () => {
      clearUnsubs();
      state = getIdleState();
      if (handler) handler.onCancel();
    };
    const onMouseMove = (e: MouseEvent) => {
      const point: Point = [e.clientX, e.clientY];
      if (isSloppyClickThresholdExceeded(startPos, point)) {
        clearUnsubs();
        const result = handler?.onDone();
        state = getStartedState({startPos, isTouch: false}, result as T);
      }
    };

    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", cancel);
    unsubs.push(() => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", cancel);
    });
    return {
      state: "pending",
      actions: allowSecondaryButton
        ? {
            onContextMenu: (e) => {
              e.preventDefault();
            },
          }
        : {},
    };
  };

  const getPendingTouchState = ({
    startPos,
    onCancel,
  }: {
    startPos: Point;
    onCancel: () => void;
  }): State => {
    const cancel = () => {
      for (const fn of unsubs.splice(0, unsubs.length)) fn();
      state = getIdleState();
      onCancel();
    };
    return {
      state: "pendingTouch",
      actions: {
        onTouchMove: (e: React.TouchEvent) => {
          const t = e.touches[0];
          const point: Point = [t.clientX, t.clientY];
          if (isSloppyClickThresholdExceeded(startPos, point)) {
            cancel();
          } else {
            e.preventDefault();
          }
        },
        onTouchStart: cancel,
        onTouchEnd: cancel,
        onTouchCancel: cancel,
      },
    };
  };

  const getStartedState = (args: {startPos: Point; isTouch: boolean}, pendingResult: T): State => {
    const {isTouch, startPos} = args;
    const handler = createDragHandler(startPos, pendingResult);
    if (!handler) return getIdleState();
    if (handler.onUnmount) unsubs.push(handler.onUnmount);
    const onMove = (pos: Point) => handler.onMove(pos);
    const endDrag = (e: MouseEvent | TouchEvent) => {
      justReleasedFromDrag = true;
      requestAnimationFrame(() => {
        justReleasedFromDrag = false;
      });
      stopDrag(false);
      e.preventDefault();
      e.stopPropagation();
    };
    const cancelDrag = () => stopDrag(true);
    const stopDrag = (isCancelled: boolean) => {
      state = getIdleState();
      handler.onStop(isCancelled);
      for (const fn of unsubs.splice(0, unsubs.length)) fn();
    };
    if (isTouch) {
      const onTouchMove = (e: TouchEvent) => {
        const t = e.touches[0];
        const point: Point = [t.clientX, t.clientY];
        onMove(point);
      };
      window.addEventListener("touchmove", onTouchMove);
      window.addEventListener("touchend", endDrag);
      window.addEventListener("touchcancel", cancelDrag);
      unsubs.push(() => {
        window.removeEventListener("touchmove", onTouchMove);
        window.removeEventListener("touchend", endDrag);
        window.removeEventListener("touchcancel", cancelDrag);
      });
    } else {
      const onMouseMove = (e: MouseEvent) => {
        const point: Point = [e.clientX, e.clientY];
        onMove(point);
      };

      window.addEventListener("mousemove", onMouseMove);
      window.addEventListener("mouseup", endDrag);
      unsubs.push(() => {
        window.removeEventListener("mousemove", onMouseMove);
        window.removeEventListener("mouseup", endDrag);
      });
    }
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") cancelDrag();
    };
    window.addEventListener("keydown", handleKeyDown);
    unsubs.push(() => {
      window.removeEventListener("keydown", handleKeyDown);
    });
    return {state: "started", actions: {}};
  };

  let state = getIdleState();

  return {
    handlers: Object.fromEntries(
      (
        [
          "onMouseDown",
          "onMouseUp",
          "onMouseMove",
          "onTouchStart",
          "onTouchMove",
          "onTouchEnd",
          "onTouchCancel",
          "onClickCapture",
          "onContextMenu",
        ] as const
      ).map((key) => [
        key,
        (e: any) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          state.actions[key]?.(e);
        },
      ])
    ),
    unsubs,
  };
};

export type DragCb = (
  action: "start" | "move" | "end" | "cancel",
  movement: [number, number]
) => void;

const setupSimpleDragHandler = (dragCb: DragCb) => {
  return setupDragHandlers((startPos) => {
    dragCb("start", [0, 0]);
    let lastPos = startPos;
    return {
      onMove: (pos) => {
        dragCb("move", [pos[0] - startPos[0], pos[1] - startPos[1]]);
        lastPos = pos;
      },
      onStop: (isCancelled) => {
        dragCb(isCancelled ? "cancel" : "end", [
          lastPos[0] - startPos[0],
          lastPos[1] - startPos[1],
        ]);
      },
    };
  });
};

export const useSimpleDrag = (onDragCb: DragCb) => {
  const dragCbRef = useRef(onDragCb);
  dragCbRef.current = onDragCb;

  const [{handlers, unsubs}] = useState(() =>
    setupSimpleDragHandler((dragCb, movement) => dragCbRef.current(dragCb, movement))
  );
  useEffect(() => {
    return () => {
      unsubs.forEach((fn) => fn());
    };
  }, [unsubs]);
  return handlers;
};
