import {Account} from "../../cdx-models/Account";
import {AccountCoupon} from "../../cdx-models/utils/extended-fields";
import cdxEnv from "../../env";
import {paidRoles} from "../../lib/permissions";

export const getBalance = (account: Account) => {
  if (!account.stripeAccountSync) {
    return account.netGiftAmount || 0;
  }
  const {grossActualBalance, grossBonusBalance, netGiftBalance, vatTaxPercentage} =
    account.stripeAccountSync;
  const f = 100 / (100 + (vatTaxPercentage || 0));
  return (grossActualBalance || 0) * f + (grossBonusBalance || 0) * f + (netGiftBalance || 0);
};

export type ProductLimits = {
  // strings to avoid int overflow issues
  storageBytesBase: string;
  storageBytesPerUser: string;
  fileSizeByteLimit: string;

  userLimit: number | null;
  projectLimit: number | null;

  withDependencies: "true" | "false";
  withProDecky: "true" | "false";
  withDueDates: "true" | "false";
  withTimeTracking: "true" | "false";
  withObservers: "true" | "false";
  withProducers: "true" | "false";
  withLimitedProjectAccess: "true" | "false";
  withGuardians: "true" | "false";
  withCapacityTracking: "true" | "false";
  withBeastMode: "true" | "false";
};

export const planLimits = {
  pro: {
    storageBytesBase: "0",
    storageBytesPerUser: (10 * 1024 ** 3).toString(),
    fileSizeByteLimit: (250 * 1024 ** 2).toString(),
    userLimit: null,
    projectLimit: null,
    withDependencies: "true",
    withProDecky: "true",
    withDueDates: "true",
    withTimeTracking: "true",
    withObservers: "true",
    withProducers: "true",
    withLimitedProjectAccess: "true",
    withGuardians: "true",
    withCapacityTracking: "true",
    withBeastMode: "true",
  },
  plus: {
    storageBytesBase: "0",
    storageBytesPerUser: (1 * 1024 ** 3).toString(),
    fileSizeByteLimit: (250 * 1024 ** 2).toString(),
    userLimit: null,
    projectLimit: null,
    withDependencies: "false",
    withProDecky: "false",
    withDueDates: "false",
    withTimeTracking: "false",
    withObservers: "true",
    withProducers: "false",
    withLimitedProjectAccess: "false",
    withGuardians: "false",
    withCapacityTracking: "false",
    withBeastMode: "false",
  },
  free: {
    storageBytesBase: (1024 ** 3).toString(),
    storageBytesPerUser: "0",
    fileSizeByteLimit: (10 * 1024 ** 2).toString(),
    userLimit: 5,
    projectLimit: 5,
    withDependencies: "false",
    withProDecky: "false",
    withDueDates: "false",
    withTimeTracking: "false",
    withObservers: "false",
    withProducers: "false",
    withLimitedProjectAccess: "false",
    withGuardians: "false",
    withCapacityTracking: "false",
    withBeastMode: "false",
  },
} satisfies {[key: string]: ProductLimits};

export const pricingOptions = {
  plus: {
    priceName: "plus-5€",
    centsPerSeat: 500,
    currency: "EUR",
    planType: "plus",
  },
  pro: {
    priceName: "pro-9€",
    centsPerSeat: 900,
    currency: "EUR",
    planType: "pro",
  },
  education: {
    priceName: "pro-education",
    centsPerSeat: 0,
    currency: "EUR",
    planType: "pro",
  },
};

const june2022 = Date.UTC(2022, 6 - 1, 1);
const oct2022 = Date.UTC(2022, 10 - 1, 18);
const aug2024 = Date.UTC(2024, 8 - 1, 22);

export const getLimits = (account: Account): ProductLimits => {
  if (cdxEnv.ON_PREMISE) return planLimits.pro;
  const {stripeAccountSync, billingType} = account;
  const getRawLimits = () => {
    if (billingType === "education") return planLimits.pro;
    const planType = stripeAccountSync?.$meta.get("planType", null);
    if (planType) return planLimits[planType] || planLimits.free;
    return planLimits.free;
  };
  const limits = getRawLimits();
  const getGrandfatheredFeatures = () => {
    const t = account.createdAt.getTime();
    return {
      ...(t < june2022 && ({withTimeTracking: "true", withObservers: "true"} as const)),
      ...(t < oct2022 && ({withLimitedProjectAccess: "true"} as const)),
      ...(t < aug2024 && ({withCapacityTracking: "true"} as const)),
    };
  };
  return {...limits, ...getGrandfatheredFeatures()};
};

export const getFileSizeLimit = (account: Account) => {
  const {fileSizeByteLimit} = getLimits(account);
  return Number(fileSizeByteLimit);
};

export const getIncludedStorageInKB = (account: Account) => {
  const {storageBytesBase, storageBytesPerUser} = getLimits(account);
  if (cdxEnv.MAX_STORAGE_MBS) {
    return Number(cdxEnv.MAX_STORAGE_MBS) * 1024;
  } else {
    return (
      Number(storageBytesBase) / 1024 + (Number(storageBytesPerUser) / 1024) * (account.seats || 1)
    );
  }
};

export const remainingSpaceInKB = (account: Account) => {
  const storage = getIncludedStorageInKB(account);
  return storage - Number(account.totalMediaByteUsage / 1024n);
};

export const hasFreeSeats = (account: Account) => {
  const {userLimit} = getLimits(account);
  if (!userLimit) return true;
  const inviteCount = account.$meta.count("invitations", {role: paidRoles});
  const userCount = account.$meta.count("roles", {role: paidRoles});
  return userLimit - (userCount + inviteCount) > 0;
};

export const canAddProject = (account: Account) => {
  const {projectLimit} = getLimits(account);
  if (!projectLimit) return true;
  const projectCount = account.$meta.count("anyProjects", {visibility: ["default", "archived"]});
  return projectLimit - projectCount > 0;
};

type ProcessPaymentIntentByPaymentMethodTypeArgs = {
  stripe: any;
  paymentMethodType: "card" | "sepa_debit";
  paymentMethodId: string;
  clientSecret: string;
};
export const processPaymentIntentByPaymentMethodType = ({
  stripe,
  paymentMethodType,
  paymentMethodId,
  clientSecret,
}: ProcessPaymentIntentByPaymentMethodTypeArgs) => {
  if (paymentMethodType === "card") {
    return stripe.confirmCardPayment(clientSecret, {payment_method: paymentMethodId});
  } else if (paymentMethodType === "sepa_debit") {
    return stripe.confirmSepaDebitPayment(clientSecret, {payment_method: paymentMethodId});
  } else {
    throw new Error(`Can't deal with ${paymentMethodType} payments yet`);
  }
};

export const validCoupon = (coupon: AccountCoupon, planType: "pro" | "plus" | "free") => {
  if (coupon.validUntil && new Date(coupon.validUntil) < new Date()) return false;
  if (planType === "pro") return true;
  return coupon.applyTo === "all";
};

export const applyPotentialCoupon = (
  amount: number,
  {account, planType}: {account: Account; planType: "pro" | "plus" | "free"}
) => {
  const coupon = account.$meta.get("coupon", null);
  const percentOff = coupon && validCoupon(coupon, planType) ? coupon.percentOff : 0;
  return (amount * (100 - percentOff)) / 100;
};
