import {forwardRef, useMemo} from "react";
import {XGrid, XText, XRow, cx, XCol, XHorLine, XPush, XPlainButton} from "@cdx/common";
import {toCmpDate, monthNames, countWorkdaysForAccount} from "../../lib/date-utils";
import {msToDate, isMilestoneInPast, setMsThemeColor} from "./milestone-utils";
import {milestonePickerStyles as styles} from "./milestones.css";
import {sortBy} from "lodash";
import {MilestoneTooltipForChild} from "../../pages/milestones/MilestoneTooltip";
import {ordinalSuffix} from "../../lib/utils";
import {getMetaKeyLabel, isWithMetaKey} from "@cdx/common/device-utils";
import routes from "../../routes";
import {useHistory} from "react-router";
import {getMyProjects, getMySelectedProjectIds} from "../../lib/hooks/useSelectedProjects";
import {DSButton} from "@cdx/ds";

const MiniMs = ({milestone: ms, account, active, absoluteDate, checkIfPast, onDark}) => {
  const isPast = checkIfPast && isMilestoneInPast(ms);
  const getDays = () => {
    if (absoluteDate) {
      return [ms.date.day, ordinalSuffix(ms.date.day)];
    } else {
      const workDays = countWorkdaysForAccount({account, targetDay: msToDate(ms)});
      return [workDays, "d"];
    }
  };
  const [day, suffix] = getDays();
  return (
    <XCol align="center" sp={1} pt={0}>
      <div
        className={cx(
          setMsThemeColor(ms),
          styles.miniMs.base,
          active && styles.miniMs.isSelected,
          isPast && styles.miniMs.isPast
        )}
      />
      <XCol align="center">
        <XRow align="baseline">
          <XText
            preset="label"
            size={1}
            color={active ? "active800" : isPast ? "gray500" : onDark ? "gray100" : "gray700"}
          >
            {day}
          </XText>
          <XText
            preset="bold"
            size={0}
            color={active ? "active700" : isPast ? "gray400" : onDark ? "gray300" : "gray500"}
          >
            {suffix}
          </XText>
        </XRow>
        <XText
          align="center"
          size={0}
          color={active ? "active700" : isPast ? "gray400" : onDark ? "gray300" : "gray500"}
        >
          {ms.name.slice(0, 4)}
        </XText>
      </XCol>
    </XCol>
  );
};

export const MiniMsButton = forwardRef(
  ({milestone, onClick, account, active, absoluteDate, checkIfPast, tooltip, onDark}, ref) => (
    <MilestoneTooltipForChild
      tooltip={tooltip}
      milestone={milestone}
      delayed
      placement="bottom"
      ref={ref}
    >
      <DSButton
        variant="tertiary"
        onClick={onClick}
        className={styles.outerMsButton}
        active={active}
      >
        <MiniMs
          milestone={milestone}
          account={account}
          active={active}
          absoluteDate={absoluteDate}
          checkIfPast={checkIfPast}
          onDark={onDark}
        />
      </DSButton>
    </MilestoneTooltipForChild>
  )
);

const startDate = ({onlyFuture, pastMonthCount = 1}) => {
  const today = new Date();
  if (onlyFuture) {
    return toCmpDate(today);
  } else {
    const past = new Date(today.getFullYear(), today.getMonth() - pastMonthCount, 1);
    return toCmpDate(past);
  }
};

const getMonthList = (msList) => {
  const groups = {};
  const today = new Date();
  const current = {
    key: `${today.getFullYear()}-${today.getMonth() + 1}`,
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    msList: [],
    isCurrent: true,
  };
  const monthList = [current];
  groups[current.key] = current;
  for (const ms of msList) {
    const asDate = msToDate(ms);
    const key = `${asDate.getFullYear()}-${asDate.getMonth() + 1}`;
    let group = groups[key];
    if (!group) {
      group = {
        key,
        month: asDate.getMonth() + 1,
        year: asDate.getFullYear(),
        msList: [],
        isCurrent: false,
      };
      groups[key] = group;
      monthList.push(group);
    }
    group.msList.push(ms);
  }
  return sortBy(monthList, ["year", "month"]);
};

const MsButton = forwardRef(({ms, onChange, account, active, history}, ref) => {
  const msSeq = ms.$meta.get("accountSeq", null);
  const handleClick = (e) => {
    if (isWithMetaKey(e)) {
      if (msSeq) history.push(routes.milestone.getUrl(msSeq));
    } else {
      onChange(ms.id);
    }
  };
  return (
    <MiniMsButton
      milestone={ms}
      onClick={handleClick}
      ref={ref}
      account={account}
      active={active}
      tooltip={`${getMetaKeyLabel()} + click to view milestone`}
      absoluteDate
      checkIfPast
    />
  );
});

/**
 * @type any
 */
const MilestonePicker = ({
  currentMs,
  account,
  title,
  onChange,
  onlyFuture,
  allowNone,
  onlyProjectId,
  onlySelectedProjects,
  root,
}) => {
  const history = useHistory();
  const projectIds = onlyProjectId
    ? [onlyProjectId]
    : onlySelectedProjects
      ? getMySelectedProjectIds(root)
      : getMyProjects(root)
          .map((p) => p.$meta.get("id", null))
          .filter(Boolean);
  const nextMs = account.$meta.find("milestones", {
    date: {op: "gte", value: startDate({onlyFuture})},
    isDeleted: false,
    milestoneProjects: {projectId: projectIds},
    $order: "date",
  });
  const msByMonths = useMemo(() => getMonthList(nextMs), [nextMs]);

  return (
    <XCol minHeight>
      <XCol sp={1} pt={3}>
        <XRow px={4} sp={2}>
          <XText preset="bold" size={3}>
            {title}
          </XText>
          {allowNone && (
            <>
              <XPush />
              <XPlainButton size="sm" onClick={() => onChange(null)} active={currentMs === null}>
                None
              </XPlainButton>
            </>
          )}
        </XRow>
        <XHorLine />
      </XCol>
      <XCol sp={4} scrollable px={4} py={3}>
        {msByMonths.map(({key, year, month, msList, isCurrent}) => (
          <XCol key={key} sp={1}>
            <XRow align="baseline" sp={0}>
              <XText lineHeight="tight" size={4} color={isCurrent ? "blue600" : "gray700"}>
                {monthNames[month - 1]}
              </XText>
              <XText preset="bold" size={0} color="gray400">
                {year}
              </XText>
              <XHorLine fillParent color="gray100" style={{minWidth: 10}} />
            </XRow>
            <XGrid sp={3}>
              {msList.length > 0 ? (
                msList.map((ms) => (
                  <div key={ms.id}>
                    <MsButton
                      ms={ms}
                      onChange={onChange}
                      account={account}
                      active={currentMs === ms}
                      history={history}
                    />
                  </div>
                ))
              ) : (
                <XText size={1} color="gray600" lineHeight="tight">
                  {onlyFuture ? "No upcoming milestones" : "No milestones"}
                </XText>
              )}
            </XGrid>
          </XCol>
        ))}
      </XCol>
    </XCol>
  );
};

export default MilestonePicker;
