import type {Location, History} from "history";
import {searchCategoriesByKey} from "./search-categories";
import shallowEqual from "shallowequal";
import getSelectedProjects from "../../lib/hooks/useSelectedProjects";
import {create} from "zustand";
import {useEffect, useRef, useState} from "react";
import {getLocalStorageMap} from "../../lib/storage";
import {completeOnboardingStep, ONBOARDING_STEPS} from "../onboarding/onboarding-utils";
import {SearchFilter, SearchStore} from "./search-types";
import {Root} from "../../cdx-models/Root";
import {Query} from "../../cdx-models/utils/MakeModel";
import {Card} from "../../cdx-models/Card";

const forceOrCats = new Set(["prjct-tags", "prsnl-tags", "tags", "cardTitle", "content"]);

export const needsForceOrDisplay = (filters: SearchFilter[]) => {
  const present = new Set();
  return filters.some(({category}) => {
    if (!forceOrCats.has(category)) return false; // ignore non-tag, non-content categories

    if (present.has(category)) return true;
    present.add(category);
    return false;
  });
};

const saneArchived = (state: SearchStore) => {
  if (state.includeArchivedCards) return state;
  if (!state.filters.some((f) => f.category === "status" && f.value === "archived")) return state;
  return {...state, includeArchivedCards: true};
};

const saneForceOr = (state: SearchStore) => {
  if (!state.forceOr) return state;
  if (needsForceOrDisplay(state.filters)) return state;
  return {...state, forceOr: false};
};

const sanityCheck = (inState: SearchStore) => {
  let outState = inState;
  outState = saneArchived(outState);
  outState = saneForceOr(outState);
  return outState;
};

export const useSearchStore = create<SearchStore>((set) => ({
  set: (nextState) => set((prev) => sanityCheck({...prev, ...nextState})),

  filters: [
    // {category: "cardTitle", value: "Hello"},
    // {category: "tags", value: "spiderman"},
    // {category: "status", value: "assigned"},
  ],
  includeArchivedCards: false,
  isFocussed: false,
  forceOr: false, // when adding two or more tags (or title, content searches) should these be ANDed or ORed?
  defaultArchiveStorageKey: null,
}));

const {storageGet, storageSet} = getLocalStorageMap("default-archived-");

export const searchActions = {
  onChangeArena: ({defaultArchiveStorageKey}: {defaultArchiveStorageKey: string | null}) => {
    const {set, ...prev} = useSearchStore.getState();
    // if arena has no defaultArchiveStorageKey, just assume `includeArchivedCards: false`
    const storedValue = Boolean(defaultArchiveStorageKey && storageGet(defaultArchiveStorageKey));
    return set({
      forceOr: false,
      includeArchivedCards: storedValue,
      defaultArchiveStorageKey,
      ...(prev.filters.length && {filters: []}),
    });
  },
  setDefaultArchiveStorageKey: (storageKey: string | null) => {
    const {set, ...prev} = useSearchStore.getState();
    if (prev.defaultArchiveStorageKey === storageKey) return;
    const storedValue = Boolean(storageKey && storageGet(storageKey));
    return set({
      includeArchivedCards: storedValue,
      defaultArchiveStorageKey: storageKey,
    });
  },
  setFilters: (filters: SearchFilter[], {forceOr}: {forceOr?: boolean} = {}) =>
    useSearchStore.getState().set({filters, ...(forceOr !== undefined ? {forceOr} : {})}),
  addFilter: (filter: SearchFilter) => {
    const {set, ...prev} = useSearchStore.getState();
    const {category, value} = filter;
    if (
      !prev.filters.some(
        (t) => t.category === category && (t.value === value || shallowEqual(t.value, value))
      )
    ) {
      if (category === "priority" && value.priority === "a" && value.cmp === "eq") {
        completeOnboardingStep(ONBOARDING_STEPS.searchForPriority);
      }
      set({filters: [...prev.filters, filter]});
    }
  },
  removeFilter: (filter: SearchFilter) => {
    const {set, ...prev} = useSearchStore.getState();
    set({filters: prev.filters.filter((f) => f !== filter)});
  },
  removeLast: () => {
    const {set, ...prev} = useSearchStore.getState();
    if (prev.filters.length > 0) set({filters: prev.filters.slice(0, -1)});
  },
  open: () => useSearchStore.getState().set({isFocussed: true}),
  close: () => useSearchStore.getState().set({isFocussed: false}),
  setArchived: (value: boolean) => {
    const {set, defaultArchiveStorageKey} = useSearchStore.getState();
    set({includeArchivedCards: value});
    if (defaultArchiveStorageKey) storageSet(defaultArchiveStorageKey, value);
  },
  toggleArchived: () => {
    const {set, defaultArchiveStorageKey, ...prev} = useSearchStore.getState();
    set({includeArchivedCards: !prev.includeArchivedCards});
    if (defaultArchiveStorageKey) storageSet(defaultArchiveStorageKey, !prev.includeArchivedCards);
  },
  toggleForceOr: () => {
    const {set, ...prev} = useSearchStore.getState();
    set({forceOr: !prev.forceOr});
  },
};

// use global variable to remember the last arena name across unmounts
let lastArenaName: string | null = null;

const useArenaSwitchBehaviour = ({
  defaultArchiveStorageKey,
  searchArenaName,
}: {
  defaultArchiveStorageKey: string | null;
  searchArenaName: string | null;
}) => {
  const currStorageKeyRef = useRef(defaultArchiveStorageKey);
  useEffect(() => {
    currStorageKeyRef.current = defaultArchiveStorageKey;
  });
  useEffect(() => {
    if (lastArenaName && lastArenaName !== searchArenaName) {
      searchActions.onChangeArena({defaultArchiveStorageKey: currStorageKeyRef.current});
    }
    lastArenaName = searchArenaName;
  }, [searchArenaName]);
  useEffect(() => {
    // shuold only matter if lastArenaName is null, as otherwise `onChangeArena` already sets the storage key
    searchActions.setDefaultArchiveStorageKey(defaultArchiveStorageKey);
  }, [defaultArchiveStorageKey]);
};

const useApplyLocationSearchState = ({
  location,
  history,
}: {
  location: Location<{searchTokens?: SearchFilter[]; searchIncludeArchived?: boolean}>;
  history: History;
}) => {
  const [appliedLocationFilter, setAppliedLocationFilter] = useState(false);
  const locationRef = useRef(location);
  useEffect(() => {
    locationRef.current = location;
  });
  const searchTokens = location.state && location.state.searchTokens;
  const searchIncludeArchived = location.state && location.state.searchIncludeArchived;

  useEffect(() => {
    if (searchTokens) {
      searchActions.setFilters(searchTokens);
      setAppliedLocationFilter(true);
    }
  }, [searchTokens]);
  useEffect(() => {
    if (searchIncludeArchived) {
      searchActions.setArchived(true);
      setAppliedLocationFilter(true);
    }
  }, [searchIncludeArchived]);

  useEffect(() => {
    if (appliedLocationFilter) {
      const {searchTokens: _1, searchIncludeArchived: _2, ...restState} = locationRef.current.state;
      history.replace({...locationRef.current, state: restState});
      setAppliedLocationFilter(false);
    }
  }, [appliedLocationFilter, history]);
};

type SearchBehaviourParams = {
  location: Location<{searchTokens?: SearchFilter[]; searchIncludeArchived?: boolean}>;
  defaultArchiveStorageKey: string | null;
  searchArenaName: string | null;
  history: History;
};

export const useSearchBehaviour = ({
  location,
  defaultArchiveStorageKey,
  searchArenaName,
  history,
}: SearchBehaviourParams) => {
  useArenaSwitchBehaviour({defaultArchiveStorageKey, searchArenaName});
  useApplyLocationSearchState({location, history});
};

export const useSearchFilters = () => useSearchStore((s) => s.filters);

const createDbFilters = (
  filters: SearchFilter[],
  {
    includeArchivedCards = false,
    forceOr = false,
  }: {includeArchivedCards?: boolean; forceOr?: boolean}
): Query<Card> => {
  if (!filters.length) return {};
  const byCat = filters.reduce(
    (m, {category, value}) => {
      (m[category] = m[category] || []).push(value);
      return m;
    },
    {} as Record<string, unknown[]>
  );
  return {
    $and: Object.entries(byCat).map(([category, values]) =>
      searchCategoriesByKey[category].valuesToDbQuery(values, {includeArchivedCards, forceOr})
    ),
  };
};

export const useFilteredCards = (
  root: Root,
  defaultFilter: Query<Card> | false,
  opts: {forceFilter?: Query<Card>; onlyCount?: boolean; forceIncludeArchivedCards?: boolean} = {}
) => {
  const {forceFilter, onlyCount, forceIncludeArchivedCards} = opts;
  const {
    searchFilters,
    includeArchivedCards: searchArchived,
    forceOr,
  } = useSearchStore((s) => ({
    searchFilters: s.filters,
    includeArchivedCards: s.includeArchivedCards,
    forceOr: s.forceOr,
  }));
  const includeArchivedCards = searchArchived || forceIncludeArchivedCards;
  const projectIds = getSelectedProjects(root)
    .map((p) => p.$meta.get("id", null)!)
    .filter(Boolean);
  if (defaultFilter === false) return {cards: [], getCardCount: () => 0};
  const baseFilter: Query<Card> = {
    ...(defaultFilter.deckId
      ? {}
      : {
          $or: [{deck: {projectId: projectIds}}, {deckId: null}],
        }),
    visibility: includeArchivedCards ? ["default", "archived"] : "default",
    ...defaultFilter,
  };
  const finalFilter: Query<Card> = {
    ...baseFilter,
    ...createDbFilters(searchFilters, {includeArchivedCards, forceOr}),
    ...forceFilter,
  };

  return {
    cards: onlyCount ? null : root.account.$meta.find("cards", finalFilter),
    getCardCount: () => root.account.$meta.count("cards", baseFilter),
    isFiltering: forceFilter || searchFilters.length > 0,
  };
};
