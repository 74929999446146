import CdxTypeaheadPlugin, {ResultProps, createNodeHandler} from "./CdxTypeAheadLexicalPlugin";
import {
  KEY_ARROW_DOWN_COMMAND,
  KEY_ARROW_UP_COMMAND,
  KEY_ARROW_LEFT_COMMAND,
  KEY_ARROW_RIGHT_COMMAND,
  KEY_TAB_COMMAND,
  KEY_ENTER_COMMAND,
  COMMAND_PRIORITY_NORMAL,
} from "lexical";
import {mergeRegister} from "@lexical/utils";
import {Box, Col, css, DSIconPlay} from "@cdx/ds";
import {waitForResultPromise} from "../../../lib/wait-for-result";
import {api} from "../../../lib/api";
import {canDisplayAsImage, getVideoMimeTypeFromUrl} from "../../../lib/file-utils";
import {CdxImgForExternalUrl, CdxImgWithKnownDimensions as RawImg} from "../../CdxImg";
import {useMarkdownToPreview} from "../../Markdown/markdownToPreview";
import {forwardRef, useEffect, useRef, useState} from "react";
import {$createImageNode} from "./CdxImagePlugin";
import {
  addTextTransformer,
  useSlashCommand,
} from "@cdx/ds/components/DSTextEditor/CdxSlashCommandLexicalPlugin";
import {User} from "../../../cdx-models/User";
import {searchAndFilter} from "../../../lib/search-utils";
import {Card, CardId} from "../../../cdx-models/Card";
import {File} from "../../../cdx-models/File";

const CdxImgWithKnownDimensions = RawImg as any;

const ShowResultsInGrid = forwardRef<HTMLDivElement, ResultProps<any>>((props, ref) => {
  const {data, editor, optRef} = props;
  const [selIdx, setSelIdx] = useState(0);
  const refs = useRef({...props, selIdx});
  useEffect(() => {
    refs.current = {...props, selIdx};
  });

  useEffect(() => {
    const select = (event: KeyboardEvent) => {
      const opt = refs.current.data[refs.current.selIdx];
      if (!opt) return false;

      event.preventDefault();
      event.stopImmediatePropagation();
      refs.current.onSelect(opt);
      return true;
    };
    return mergeRegister(
      editor.registerCommand<KeyboardEvent>(
        KEY_ARROW_RIGHT_COMMAND,
        (event) => {
          const dataLen = refs.current.data.length;
          setSelIdx((prev) => Math.min(prev + 1, dataLen - 1));
          event.preventDefault();
          event.stopImmediatePropagation();
          return true;
        },
        COMMAND_PRIORITY_NORMAL
      ),
      editor.registerCommand<KeyboardEvent>(
        KEY_ARROW_LEFT_COMMAND,
        (event) => {
          setSelIdx((prev) => Math.max(prev - 1, 0));
          event.preventDefault();
          event.stopImmediatePropagation();
          return true;
        },
        COMMAND_PRIORITY_NORMAL
      ),
      editor.registerCommand<KeyboardEvent>(
        KEY_ARROW_DOWN_COMMAND,
        (event) => {
          const dataLen = refs.current.data.length;
          setSelIdx((prev) => Math.min(prev + 4, dataLen - 1));
          event.preventDefault();
          event.stopImmediatePropagation();
          return true;
        },
        COMMAND_PRIORITY_NORMAL
      ),
      editor.registerCommand<KeyboardEvent>(
        KEY_ARROW_UP_COMMAND,
        (event) => {
          setSelIdx((prev) => Math.max(prev - 4, 0));
          event.preventDefault();
          event.stopImmediatePropagation();
          return true;
        },
        COMMAND_PRIORITY_NORMAL
      ),
      editor.registerCommand<KeyboardEvent>(KEY_TAB_COMMAND, select, COMMAND_PRIORITY_NORMAL),
      editor.registerCommand<KeyboardEvent>(KEY_ENTER_COMMAND, select, COMMAND_PRIORITY_NORMAL)
    );
  }, [editor, setSelIdx]);

  return (
    <Box
      display="grid"
      style={{gridTemplateColumns: `repeat(${Math.min(data.length, 4)}, 106px)`, gridGap: "0.5rem"}}
      ref={ref}
    >
      {data.map((el, idx) => (
        <div
          key={optRef.current.optionToKey(el)}
          data-cdx-clickable
          onClick={() => refs.current.onSelect(el)}
          className={css({
            display: "flex",
            flexDir: "column",
            px: "16px",
            py: "8px",
            cursor: "pointer",
            useHoverBg: "true",
            ...(idx === selIdx && {bg: "foreground", colorTheme: "active50", rounded: 4}),
          })}
        >
          {optRef.current.renderOption(el)}
        </div>
      ))}
    </Box>
  );
});

const Option = ({imgInfo}: {imgInfo: ImgInfo}) => {
  const {height, name, src, width, type} = imgInfo;
  const content = useMarkdownToPreview(name, 32);
  return (
    <Col sp="4px" flex="auto">
      <Box flex="auto" />
      {type === "video" ? (
        <Col align="center" flex="auto" justify="center">
          <Col
            align="center"
            justify="center"
            width="32px"
            height="32px"
            bg="foreground"
            colorTheme="gray550"
            rounded="full"
            color="primary"
          >
            <DSIconPlay size={16} />
          </Col>
        </Col>
      ) : width && height ? (
        <CdxImgWithKnownDimensions
          maxHeight={60}
          maxWidth={80}
          src={src}
          width={width}
          height={height}
          alt={name}
        />
      ) : (
        <CdxImgForExternalUrl src={src} alt={name} maxWidth={80} maxHeight={60} />
      )}
      <Box flex="auto" />
      <Box size={12} bold color="secondary" overflow="hidden" whiteSpace="nowrap">
        {content}
      </Box>
    </Col>
  );
};

type ImgInfo = {
  id: string;
  src: string;
  name: string;
  width?: number;
  height?: number;
  type: "img" | "video";
};

const getType = (file: File): null | "img" | "video" => {
  if (canDisplayAsImage(file)) return "img";
  if (getVideoMimeTypeFromUrl(file.url)) return "video";
  return null;
};

const getOptions =
  (cardId: CardId) =>
  async (word: string): Promise<ImgInfo[]> =>
    waitForResultPromise(() => {
      if (!cardId) return [];
      const card: Card = api.getModel({modelName: "card", id: cardId});
      if (!card) return [];
      const media = (card.attachments || [])
        .map((a) => ({
          type: getType(a.file)!,
          att: a,
        }))
        .filter((m) => m.type);

      return searchAndFilter(media, (m) => m.att.title, word).map((m) => ({
        id: m.att.id,
        src: m.att.file.url,
        name: m.att.title,
        width: (m.att.file.meta as any)?.width,
        height: (m.att.file.meta as any)?.height,
        type: m.type,
      }));
    });

type CardCreationAttachment = {
  id: string;
  title: string;
  content: string;
  createdAt: Date;
  creator: User;
  file: {
    id: string;
    meta: {type?: string};
    name: string;
    url: string;
    size: number;
  };
};

const getOptionsDuringCardCreation =
  (attachments: CardCreationAttachment[]) =>
  async (word: string): Promise<ImgInfo[]> =>
    attachments
      .map((a) => ({
        type: getType(a.file as any)!,
        att: a,
      }))
      .filter((m) => m.type)
      .filter((m) => !word || m.att.title.toLowerCase().indexOf(word.toLowerCase()) >= 0)
      .map((m) => ({
        id: m.att.id,
        src: m.att.file.url,
        name: m.att.title,
        type: m.type,
      }));

type AttachmentPluginProps = {card: any};
const AttachmentPlugin = ({card}: AttachmentPluginProps) => {
  useSlashCommand({
    key: `attachment`,
    label: `Embed attached image`,
    keywords: "image attachment url embed",
    handleSelect: addTextTransformer("!", ""),
    groupKey: "links",
  });

  return (
    <CdxTypeaheadPlugin<ImgInfo>
      triggerFn={(text: string) => {
        const m = text.match(/(\s|\(|^)(!)([A-Za-z0-9][\w\-.]*)?$/);
        if (!m) return null;
        const offset = m.index! + m[1].length;
        return {
          offset,
          match: m[3],
          triggerChar: m[2],
        };
      }}
      getOptions={
        card?.$meta.isCardCreationPlaceholder
          ? getOptionsDuringCardCreation(card.attachments)
          : getOptions(card?.$meta.get("cardId", null))
      }
      handleOptionSelect={createNodeHandler(({height, name, src, width}) =>
        $createImageNode({altText: name, src, height, width})
      )}
      optionToKey={(opt) => opt.id}
      renderOption={(opt) => <Option imgInfo={opt} />}
      debounceMs={0}
      resultComp={ShowResultsInGrid as any}
    />
  );
};

export default AttachmentPlugin;
