import sortBy from "lodash/sortBy";
import {hasPermissionToAccessAllProjects} from "../permissions";
import {Root} from "../../cdx-models/Root";
import {Project, ProjectId} from "../../cdx-models/Project";
import {
  ProjectSpace,
  ProjectSpaceId,
  UserProjectRole,
} from "../../cdx-models/utils/extended-fields";
import {Deck} from "../../cdx-models/Deck";
import {groupByFn} from "../collection-utils";
import {sortedProjectDecks} from "../utils";

export const getProjectOrderIdx = (root: Root): Record<ProjectId, number | null> => {
  const {loggedInUser, account} = root;
  const accountId = account.$meta.get("id", null);
  const projectOrders =
    loggedInUser && accountId ? loggedInUser.$meta.find("projectOrders", {accountId}) : [];
  return projectOrders.reduce(
    (m, o) => {
      m[(o as unknown as {projectId: ProjectId}).projectId] = o.$meta.get("sortIndex", null);
      return m;
    },
    {} as Record<ProjectId, number | null>
  );
};

/**
 * Only returns projects a user has explicit access. I.e no public projects for guests
 */
export const getMyProjects = (root: Root, projectRole?: UserProjectRole): Project[] => {
  const {account, loggedInUser: user} = root;
  if (!user) {
    // return public projects if not logged in
    return account.projects;
  }
  const accessToAll = hasPermissionToAccessAllProjects(root, {fallback: null});
  if (accessToAll === null) return [];
  if (accessToAll) {
    return account.projects;
  } else {
    const accountId = account.$meta.get("id", null);
    return accountId
      ? user.$meta
          .find("withProjectAccess", {
            ...(projectRole && {projectRole}),
            project: {visibility: "default", accountId},
          })
          .map((pa) => pa.project)
      : [];
  }
};

export const getMySelectedProjectIds = (root: Root): ProjectId[] => {
  const explicitSelections = getRawSelectedProjects(root);
  const selProjects = explicitSelections.length ? explicitSelections : getMyProjects(root);
  return selProjects.map((p) => p.$meta.get("id", null)!).filter(Boolean);
};

export const orderProjects = (root: Root, projects: Project[]): Project[] => {
  const projectIdToOrder = getProjectOrderIdx(root);
  return sortBy(projects, [(p) => p && projectIdToOrder[p.id as ProjectId], "id"]);
};

export const useOrderedSelectedProjects = (root: Root): Project[] => {
  return orderProjects(root, getSelectedProjects(root));
};

export const getOrderedSelectedProjects = useOrderedSelectedProjects;

/**
 * Note: when using this one, make sure to exclude archived/deleted projects!
 * This one also returns public projects wheras `getMySelectedProjectIds` or `getMyProjects` does not!
 */
export const getRawSelectedProjects = (root: Root): Project[] => {
  const {account, loggedInUser} = root;
  if (loggedInUser === null || !account.$meta.isLoaded) return [];
  const accountId = account.$meta.get("id", null);
  return accountId
    ? loggedInUser.$meta
        .find("projectSelections", {accountId})
        .map((ps) => ps.project)
        .filter(Boolean)
    : [];
};

export const getSelectedProjects = (root: Root): Project[] => {
  const explicitSelections = getRawSelectedProjects(root);
  return explicitSelections.length ? explicitSelections : getMyProjects(root);
};

export default getSelectedProjects;

export const getSpacesAndDecks = (projects: Project[], filterFn?: (deck: Deck) => boolean) => {
  const spacesAndDecksByProjectId: Record<ProjectId, {space: ProjectSpace; decks: Deck[]}[]> = {};
  for (const project of projects) {
    const spacesAndDecks: {space: ProjectSpace; decks: Deck[]}[] = [];
    let allDecks = sortedProjectDecks(project);
    allDecks = filterFn ? allDecks.filter(filterFn) : allDecks;
    const presentSpaceIds = new Set(project.spaces.map((s) => s.id));
    const defaultSpaceId = project.spaces.length > 0 ? project.spaces[0].id : (0 as ProjectSpaceId);
    const bySpaceId = groupByFn(
      allDecks,
      (d) =>
        (d.spaceId && presentSpaceIds.has(d.spaceId)
          ? d.spaceId
          : defaultSpaceId) as unknown as string
    );
    for (const space of project.spaces) {
      const decks = bySpaceId[space.id];
      if (decks) spacesAndDecks.push({space, decks});
    }
    spacesAndDecksByProjectId[project.id as ProjectId] = spacesAndDecks;
  }
  return spacesAndDecksByProjectId;
};
