import {useTransition, animated, UseTransitionProps, SpringValue} from "react-spring";
import {ReactNode} from "react";
import {css, CSSProps} from "@cdx/ds";

export const springConfigs = {
  quick: {tension: 500, friction: 30},
  quickNoBounce: {tension: 600, friction: 44},
};

type Config = UseTransitionProps;
export const useReveal = <T,>(
  show: T | null | undefined,
  {from = {value: 0}, enter = {value: 1}, ...opts}: Config = {}
) => {
  const transition = useTransition(show, {
    key: (i: T) => !!i,
    from,
    enter,
    leave: opts.leave || {value: 0.001},
    ...opts,
  });
  return (renderFn: (props: {value: SpringValue<number>}, item: NonNullable<T>) => JSX.Element) =>
    transition((props, item) => (item ? renderFn(props as any, item) : null));
};

type RevealContainerProps = {
  show: boolean;
  pl?: CSSProps["pl"];
  children: ReactNode;
};

export const RevealContainer = ({show, children, pl = 2}: RevealContainerProps) => {
  const reveal = useReveal(show);
  return reveal((props) => (
    <animated.div
      className={css({
        top: "0",
        bottom: "0",
        left: "100%",
        position: "absolute",
        display: "flex",
        align: "center",
        pl,
      })}
      style={{
        opacity: props.value,
        transform: props.value.to((v) => `scale(${v / 2 + 0.5})`),
      }}
    >
      {children}
    </animated.div>
  ));
};
