import {SearchCategory} from "../search-types";

type Prio = "a" | "b" | "c" | null;
type PrioKey = "a" | "b" | "c" | "null";

type Val = {
  priority: Prio;
  cmp: "eq" | "gte" | "lte";
};

const cmpMap = {gte: <>&gt;=&nbsp;</>, lte: <>&lt;=&nbsp;</>, eq: null};
const prioOrder: Record<PrioKey, number> = {null: 0, c: 1, b: 2, a: 3};
const prios: Prio[] = [null, "c", "b", "a"];
const prioCmpToFilter = ({priority: prio, cmp}: Val): Prio | Prio[] => {
  switch (cmp) {
    case "eq":
      return prio;
    case "gte":
      return prios.filter((p) => prioOrder[p as PrioKey] >= prioOrder[prio as PrioKey]);
    case "lte":
      return prios.filter((p) => prioOrder[p as PrioKey] <= prioOrder[prio as PrioKey]);
    default:
      return [];
  }
};

const priorityCategory: SearchCategory<Val> = {
  label: "Priority",
  key: "priority",
  getSuggestions({input, root}) {
    const {priorityLabels} = root.account;
    if ("priority".startsWith(input)) {
      return [
        ...Object.keys(priorityLabels).map((p) => ({priority: p, cmp: "eq"}) as Val),
        {priority: null, cmp: "eq"},
      ];
    } else {
      return Object.keys(priorityLabels).reduce((results, rawKey) => {
        const key = rawKey as "a";
        if (priorityLabels[key].toLowerCase().startsWith(input)) {
          results.push(
            {priority: key, cmp: "eq"},
            {priority: key, cmp: "gte"},
            {priority: key, cmp: "lte"}
          );
        }
        return results;
      }, [] as Val[]);
    }
  },
  valueToKey({priority, cmp}) {
    return `${cmp}${priority}`;
  },
  valuesToDbQuery(values) {
    if (values.length === 1) {
      return {priority: prioCmpToFilter(values[0])};
    } else if (values.every(({cmp}) => cmp === "eq")) {
      return {priority: values.map((v) => v.priority)};
    } else {
      return {$or: values.map((val) => ({priority: prioCmpToFilter(val)}))};
    }
  },
  renderSuggestion({priority, cmp}, {root}) {
    const {priorityLabels} = root.account;
    return priority === null ? (
      "none"
    ) : (
      <>
        {cmpMap[cmp] || ""}
        {priorityLabels[priority] || priority}
      </>
    );
  },
  renderPill({priority, cmp}, {root}) {
    const {priorityLabels} = root.account;
    return priority === null ? (
      "No priority"
    ) : (
      <>
        {cmpMap[cmp] || ""}
        {priorityLabels[priority] || priority}
      </>
    );
  },
  savedSearchLabel({priority, cmp}, {root}) {
    const {priorityLabels} = root.account;
    const cmpToLabel = {gte: "+", lte: "-", eq: ""};
    return {
      prio: 2,
      label:
        priority === null ? "no prio" : `${priorityLabels[priority] || priority}${cmpToLabel[cmp]}`,
    };
  },
};

export default priorityCategory;
