import {Changes} from "./Changes";
import {getIdAsKey} from "./mate-shared";
import {
  CacheEntry as Entry,
  ModelCache,
  ModelDescriptions,
  ModelName,
  MutationDesc,
} from "./mate-types";

const invalidateField = ({cacheEntry, fieldName}: {cacheEntry: Entry; fieldName: string}) => {
  cacheEntry.status[fieldName] = "invalid";
};

type InvalidateParams = {
  desc: MutationDesc & {type: "update" | "delete" | "create"};
  data: Record<string, any>;
  retVal: any;
  isImplicit: boolean;
  changes: Changes;
  descriptions: ModelDescriptions;
  modelCache: ModelCache;
  fieldsAffectingRelations: Partial<
    Record<ModelName, Record<string, Partial<Record<ModelName, Set<string>>>>>
  >;
  fksToFields: Record<ModelName, Record<string, string>>;
  fieldDeps: Record<
    ModelName,
    Record<string, {fields: string[]; rels: {modelName: ModelName; relName: string; fk: string}[]}>
  >;
  belongsToModelDeps: Record<ModelName, {model: ModelName; relName: string; fk: string}[]>;
};

const invalidateEntryViaId = (
  id: string,
  affectedFields: any,
  modelEntries: Record<string, Entry<any> | null>,
  bag: InvalidateParams,
  changedFields: Record<string, any>
) => {
  const {data, changes, fksToFields, fieldDeps, modelCache, descriptions, desc} = bag;
  const {model: modelName} = desc;
  let cacheEntry = modelEntries[id];
  if (!cacheEntry) {
    // we're using a fake entry here, to evaluate whether stuff like `deckId` affects already loaded models
    cacheEntry = {value: data, status: {}};
  } else {
    changes.add(modelName, id);
  }
  for (const [field, val] of Object.entries(changedFields)) {
    const fieldName = fksToFields[modelName][field] || field;
    invalidateField({cacheEntry, fieldName});

    const fieldDep = fieldDeps[modelName][field];
    if (fieldDep) {
      for (const dep of fieldDep.fields) {
        invalidateField({cacheEntry, fieldName: dep});
      }
      for (const {modelName: relModelName, relName, fk} of fieldDep.rels) {
        const relCacheEntry = modelCache[relModelName];
        if (!relCacheEntry) continue;

        // if we know who owned the element before, we now can tell which relation to change
        let relIds = Object.keys(relCacheEntry).filter((relId) => relCacheEntry[relId] !== null);
        let filterIds: null | string[] = null;
        let hasFkInValue = fk in cacheEntry.value;
        let sourceFkVal: string | null | undefined;
        if (!hasFkInValue) {
          // maybe `deckId` is not present as a field, but the belongsTo-value `deck`!?
          const alternativeFk = Object.keys(descriptions[modelName].belongsTo).find(
            (belongsToRelName) => descriptions[modelName].belongsTo[belongsToRelName].fk === fk
          );
          if (alternativeFk) {
            hasFkInValue = alternativeFk in cacheEntry.value;
            if (hasFkInValue) sourceFkVal = cacheEntry.value[alternativeFk];
          }
        } else {
          sourceFkVal = cacheEntry.value[fk];
        }
        if (hasFkInValue) {
          filterIds = [];
          if (sourceFkVal) filterIds.push(sourceFkVal);
          if (data[fk] && data[fk] !== sourceFkVal) filterIds.push(data[fk]);
          const relDesc = descriptions[relModelName];
          relIds = relIds.filter((relId) =>
            filterIds!.includes(relCacheEntry[relId]!.value[relDesc.idProp as string])
          );
        }

        const fieldVariantsRegex = new RegExp(`^(count:|exists:)?${relName}($|\\()`);
        for (const relId of relIds) {
          const entry = relCacheEntry[relId];
          if (!entry) continue;
          for (const relField of Object.keys(entry.value)) {
            if (fieldVariantsRegex.test(relField)) {
              entry.status[relField] = "invalid";
              changes.add(relModelName, relId);
            }
          }
        }
      }
    }

    const affectedRels = affectedFields && affectedFields[field];
    if (affectedRels) {
      for (const [parentModel, relNames] of Object.entries(affectedRels) as [
        ModelName,
        Set<string>,
      ][]) {
        const parentEntries = modelCache[parentModel];
        if (!parentEntries) continue;
        for (const [parentId, entry] of Object.entries(parentEntries)) {
          if (!entry) continue;
          for (const relName of relNames) {
            if (relName in entry.value || relName in entry.status) {
              entry.status[relName] = "invalid";
              // it's a change because $isLoaded now returns a different value and the model's cache isn't valid anymore
              changes.add(parentModel, parentId);
            }
          }
        }
      }
    }

    if (val !== null && val !== undefined) {
      // change it last so that e.g. previous deckId can be accessed by rel-invalidations above
      cacheEntry.value[fieldName] = val;
    }
  }
};

export const invalidateAfterUpdate = (bag: InvalidateParams) => {
  const {desc, data, descriptions, modelCache, fieldsAffectingRelations} = bag;
  let {ids, ...changedFields} = data;
  const {model: modelName} = desc;
  const modelDesc = descriptions[modelName];
  const maybeIds = modelDesc.idPropAsArray.map((prop) => {
    const val = changedFields[prop];
    delete changedFields[prop];
    return val;
  });
  let entryId = maybeIds.every((val) => val !== undefined) ? getIdAsKey(maybeIds) : null;
  if (!entryId && changedFields.id && modelDesc.idPropAsArray.length === 1) {
    entryId = changedFields.id;
    delete changedFields.id;
  }

  const modelEntries = modelCache[modelName] || {};

  if (process.env.NODE_ENV !== "production" && !entryId && !ids) {
    // eslint-disable-next-line no-console
    console.info(
      `updating all ${
        Object.keys(modelEntries).length
      } instances of ${modelName} since no id was given`
    );
  }
  ids = entryId ? [entryId] : ids || Object.keys(modelEntries);
  const affectedFields = fieldsAffectingRelations[modelName];
  for (const id of ids) {
    invalidateEntryViaId(id, affectedFields, modelEntries, bag, changedFields);
  }
};

export const invalidateAfterDelete = (bag: InvalidateParams) => {
  const {desc, changes, modelCache, descriptions, data, belongsToModelDeps} = bag;
  const modelDesc = descriptions[desc.model];
  const maybeIds = modelDesc.idPropAsArray.map((prop) => data[prop]);
  let idKey = maybeIds.every((val) => val !== undefined) ? getIdAsKey(maybeIds) : null;
  if (!idKey && data.id && modelDesc.idPropAsArray.length === 1) idKey = data.id;
  if (!idKey) {
    console.warn(
      `deleting a resource without passing '${modelDesc.idPropAsArray.join(", ")}'`,
      data,
      desc
    );
  } else {
    const cacheEntry = (modelCache[desc.model] || {})[idKey];
    if (cacheEntry) {
      changes.add(desc.model, idKey);
      Object.keys(cacheEntry.value).forEach((key) => {
        cacheEntry.status[key] = "invalid";
      });
    }
    for (const {model: parentModelName, relName, fk} of belongsToModelDeps[desc.model]) {
      const modelEntries = modelCache[parentModelName];
      if (!modelEntries) continue;
      for (const [id, parentCacheEntry] of Object.entries(modelEntries)) {
        if (!parentCacheEntry) continue;
        if (parentCacheEntry.value[relName] === idKey) {
          parentCacheEntry.status[relName] = "invalid";
          changes.add(parentModelName, id);
        }
        if (parentCacheEntry.value[fk] === idKey) {
          parentCacheEntry.status[fk] = "invalid";
          changes.add(parentModelName, id);
        }
      }
    }
  }
};
