import {Account} from "../cdx-models/Account";
import {formatNumber} from "./utils";

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
] as const;

export const weekdayNames = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
] as const;

const mutateNow = new Date();
export const toUTCHourToLocalHour = (hour: number) => {
  mutateNow.setUTCHours(hour);
  return mutateNow.getHours();
};

const workdayIdxs = [0, 1, 2, 3, 4, 5, 6] as const;
const workdayKeys = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
] as const;

export const prettyWorkdaysForAccount = ({
  account,
  now,
  targetDay,
}: {
  account: Account;
  now?: Date;
  targetDay: Date;
}) => prettyWorkdays({workdays: account.workdays, now, targetDay});

export const countWordaysForWithWorkdays = ({
  workdays,
  todayMidnight,
  targetDay,
}: {
  workdays: Account["workdays"];
  todayMidnight?: Date;
  targetDay: Date;
}) => countWordaysAndRealDays({workdays, todayMidnight, targetDay}).workdays;

export const countWorkdaysForAccount = ({
  account,
  todayMidnight,
  targetDay,
}: {
  account: Account;
  todayMidnight?: Date;
  targetDay: Date;
}) => countWordaysAndRealDays({workdays: account.workdays, todayMidnight, targetDay}).workdays;

export const countWorkdaysAndRealDaysForAccount = ({
  account,
  todayMidnight,
  targetDay,
}: {
  account: Account;
  todayMidnight?: Date;
  targetDay: Date;
}) => countWordaysAndRealDays({workdays: account.workdays, todayMidnight, targetDay});

export const startOfDay = (date = new Date()) => {
  const next = new Date(date);
  next.setHours(0, 0, 0, 0);
  return next;
};

export const dateToMonthKey = (date: Date) => monthYearToKey(date.getMonth(), date.getFullYear());

let _monthKeyCache: Date[] = [];

export const monthKeyToDate = (monthKey: number) => {
  let exist = _monthKeyCache[monthKey];
  if (exist) {
    return exist;
  } else {
    const month = monthKey % 12;
    const year = (monthKey - month) / 12;
    const date = new Date(year + 2010, month, 1);
    _monthKeyCache[monthKey] = date;
    return date;
  }
};

export const monthYearToKey = (month: number, year: number) => (year - 2010) * 12 + month;
export const startOfMonth = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

let _monthLengthCache: Record<number, number> = {};
export const getMonthLength = (date: Date) => {
  const month = date.getMonth();
  const year = date.getFullYear();
  const key = monthYearToKey(month, year);
  const exist = _monthLengthCache[key];
  if (exist) return exist;
  const lastDay = new Date(year, month + 1, 0);
  return (_monthLengthCache[key] = lastDay.getDate());
};

export const startOfHour = (date = new Date()) => {
  const next = new Date(date);
  next.setHours(date.getHours(), 0, 0, 0);
  return next;
};

export const startOfUTCDay = (date = new Date()) => {
  const next = new Date(date);
  next.setUTCHours(0, 0, 0, 0);
  return next;
};

export const prettyDayDiff = (d: Date) => {
  const today = startOfDay(new Date());
  const dDay = startOfDay(d);
  const diff = diffDays(today, dDay);
  const label = (() => {
    switch (diff) {
      case 0:
        return "today";
      case 1:
        return "yesterday";
      default:
        if (diff <= 31) {
          return `${diff} days ago`;
        } else {
          return "over a month ago";
        }
    }
  })();
  return {label, dayDiff: diff};
};

const prettyWorkdays = ({
  workdays,
  now = new Date(),
  targetDay,
}: {
  workdays: Account["workdays"];
  now?: Date;
  targetDay: Date;
}) => {
  const todayMidnight = startOfDay(now);
  var diff = Math.round((targetDay.getTime() - todayMidnight.getTime()) / (24 * 3600 * 1000));
  switch (diff) {
    case 0:
      return "today";
    case 1:
      return "tomorrow";
    case -1:
      return "yesterday";
    default: {
      const days = rawCountWorkdays(workdays, todayMidnight, diff);
      if (diff < 0) {
        return `${-days} workday${-days === 1 ? "" : "s"} ago`;
      } else {
        return `in ${days} workday${days === 1 ? "" : "s"}`;
      }
    }
  }
};

export const prettyShortWorkdays = ({
  workdays,
  now = new Date(),
  targetDay,
}: {
  workdays: Account["workdays"];
  now?: Date;
  targetDay: Date;
}) => {
  const todayMidnight = startOfDay(now);
  var diff = Math.round((targetDay.getTime() - todayMidnight.getTime()) / (24 * 3600 * 1000));
  const days = rawCountWorkdays(workdays, todayMidnight, diff);
  if (diff === 0) {
    return "today";
  } else if (Math.abs(days) <= 10) {
    return `${days}d`;
  } else {
    return superShortDate(targetDay);
  }
};

const countWordaysAndRealDays = ({
  workdays,
  todayMidnight = setToMidnight(new Date()),
  targetDay,
}: {
  workdays: Account["workdays"];
  todayMidnight?: Date;
  targetDay: Date;
}) => {
  var diff = Math.round((targetDay.getTime() - todayMidnight?.getTime()) / (24 * 3600 * 1000));
  return {workdays: rawCountWorkdays(workdays, todayMidnight, diff), realDays: diff};
};

const rawCountWorkdays = (workdays: Account["workdays"], todayMidnight: Date, diff: number) => {
  const todayWeekday = todayMidnight.getDay();
  const weekDays = workdayIdxs.filter((idx) => workdays[workdayKeys[idx]]);
  return (weekDays as number[]).reduce(
    (carry, w) => carry + Math.floor((diff + ((todayWeekday + 6 - w) % 7)) / 7),
    0
  );
};

export const isToday = (someDate: Date) => {
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};

export const isYesterday = (someDate: Date) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return (
    someDate.getDate() === yesterday.getDate() &&
    someDate.getMonth() === yesterday.getMonth() &&
    someDate.getFullYear() === yesterday.getFullYear()
  );
};

export const setToMidnight = (date: Date) => {
  date.setHours(0, 0, 0, 0);
  return date;
};

export const pad2 = (num: number) => (num < 10 ? `0${num}` : `${num}`);

export const beginningOfDateStr = (date: Date) => {
  const d = new Date(date);
  d.setHours(0, 0, 0, 0);
  return d.toISOString();
};

export const endOfDateStr = (date: Date) => {
  const d = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
  d.setHours(0, 0, 0, 0);
  return d.toISOString();
};

const locale = ["en-GB", "en-US"];

export const titleFormatter = new Intl.DateTimeFormat(locale, {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
});
export const shortFormatter = new Intl.DateTimeFormat(locale, {
  year: "numeric",
  month: "short",
  day: "numeric",
});
export const superShortFormatter = new Intl.DateTimeFormat(locale, {
  month: "short",
  day: "numeric",
});

export const superShortFormatterWithWeekday = new Intl.DateTimeFormat(locale, {
  weekday: "long",
  month: "short",
  day: "numeric",
});

export const shortFormatterWithTime = new Intl.DateTimeFormat(locale, {
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
});

export const shortDate = (date: Date) => {
  const now = new Date();
  const thisYear = now.getFullYear();
  const showYear =
    date.getFullYear() !== thisYear && now.getTime() - date.getTime() > 6 * 30 * 24 * 3600 * 1000;
  return showYear ? shortFormatter.format(date) : shortFormatterWithTime.format(date);
};

export const superShortDate = (date: Date) => {
  const thisYear = new Date().getFullYear();
  return date.getFullYear() === thisYear
    ? superShortFormatter.format(date)
    : shortFormatter.format(date);
};

export const superShortDateWithWeekdayIfThisYear = (date: Date) => {
  const thisYear = new Date().getFullYear();
  return date.getFullYear() === thisYear
    ? superShortFormatterWithWeekday.format(date)
    : shortFormatter.format(date);
};

export const toCmpDate = (date: Date) =>
  `${date.getFullYear()}-${pad2(date.getMonth() + 1)}-${pad2(date.getDate())}`;
export const toDateStr = toCmpDate;

export const addDays = (day: Date, amount: number) => {
  const next = new Date(day);
  next.setDate(next.getDate() + amount);
  return next;
};

export const MILLISECONDS_IN_DAY = 86400000;
// assumes start of days
export const diffDays = (leftDay: Date, rightDay: Date) => {
  const timestampLeft = leftDay.getTime(); // - getTimezoneOffsetInMilliseconds(leftDay)
  const timestampRight = rightDay.getTime(); // - getTimezoneOffsetInMilliseconds(rightDay)
  return Math.round((timestampLeft - timestampRight) / MILLISECONDS_IN_DAY);
};

export type Day = {day: number; month: number; year: number};

export const dayToDateStr = (day: Day) => `${day.year}-${pad2(day.month)}-${pad2(day.day)}`;

export function isDayAfter(date1: Day, date2: Day) {
  return (
    date1.year > date2.year ||
    (date1.year === date2.year && date1.month > date2.month) ||
    (date1.year === date2.year && date1.month === date2.month && date1.day > date2.day)
  );
}
export function isDayBefore(date1: Day, date2: Day) {
  return (
    date1.year < date2.year ||
    (date1.year === date2.year && date1.month < date2.month) ||
    (date1.year === date2.year && date1.month === date2.month && date1.day < date2.day)
  );
}

export function dateToDay(date: Date): Day {
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
  };
}
export function dayToDate(date: Day): Date {
  return new Date(date.year, date.month - 1, date.day);
}

export function isDayInPast(day: Day) {
  return isDayBefore(day, dateToDay(new Date()));
}
export function isDayInFuture(day: Day) {
  return isDayBefore(dateToDay(new Date()), day);
}

export function dateStrToDay(dateAsStr: string): Day {
  const parts = dateAsStr.split("-");
  return {
    year: parseInt(parts[0], 10),
    month: parseInt(parts[1], 10),
    day: parseInt(parts[2], 10),
  };
}

export function getWeekAndYear(d: Date) {
  const copyDate = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  copyDate.setUTCDate(copyDate.getUTCDate() + 4 - (copyDate.getUTCDay() || 7));
  // Get first day of year
  var yearStart = new Date(Date.UTC(copyDate.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(((copyDate.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
  // Return array of year and week number
  return [copyDate.getUTCFullYear(), weekNo];
}

// startOfWeek
export function getStartOfMonday(d: Date) {
  const copyDate = new Date(d);
  const day = copyDate.getDay();
  const diff = copyDate.getDate() - day + (day === 0 ? -6 : 1);
  copyDate.setDate(diff);
  return startOfDay(copyDate);
}

type NextMorningOpts = {unit: "days"} | {unit: "workDays"; account: Account};
export function getNextMorning(days: number, opts: NextMorningOpts): Date {
  const now = new Date();
  // before 3am we assume that tomorrow implies the day after waking up
  if (now.getHours() < 3) {
    now.setDate(now.getDate() - 1);
  }
  if (opts.unit === "days") {
    const d = new Date();
    d.setHours(8, 0, 0, 0);
    d.setDate(now.getDate() + days);
    return d;
  } else {
    now.setHours(8, 0, 0, 0);
    return addWorkdays(days, opts.account.workdays, now);
  }
}

export const addWorkdays = (
  addDayNum: number,
  workdays: Account["workdays"],
  start: Date = new Date()
): Date => {
  const workDayArr = [
    workdays.sunday ? 1 : 0,
    workdays.monday ? 1 : 0,
    workdays.tuesday ? 1 : 0,
    workdays.wednesday ? 1 : 0,
    workdays.thursday ? 1 : 0,
    workdays.friday ? 1 : 0,
    workdays.saturday ? 1 : 0,
  ];

  let daysLeft = addDayNum;
  const numWorkDays = workDayArr.reduce((sum, val) => sum + val, 0);
  let addRealDays = 0;
  const startDayOfWeek = start.getDay(); // 0 for Sunday, 1 for Monday, etc.

  // If all weekdays are false, simply add calendar days
  if (numWorkDays === 0) {
    const result = new Date(start);
    result.setDate(result.getDate() + addDayNum);
    return result;
  } else {
    // If the start day is not a business day, go forward until we hit one
    while (workDayArr[(startDayOfWeek + addRealDays) % 7] === 0) {
      addRealDays += 1;
    }

    // Jump to the final week
    addRealDays += Math.floor(daysLeft / numWorkDays) * 7;
    daysLeft = daysLeft % numWorkDays;

    // Go ahead with the remaining days until we hit a business day
    while (daysLeft > 0 || workDayArr[(startDayOfWeek + addRealDays) % 7] === 0) {
      addRealDays += 1;
      daysLeft -= workDayArr[(startDayOfWeek + addRealDays - 1) % 7];
    }

    const result = new Date(start);
    result.setDate(result.getDate() + addRealDays);
    return result;
  }
};

export const formatForHumans = (date: Date) => {
  const now = new Date();

  const today = setToMidnight(new Date());
  const diff = diffDays(today, setToMidnight(new Date(date)));
  if (diff === 0) {
    const diffMins = Math.round((now.getTime() - date.getTime()) / 60000);
    const diffHours = diffMins / 60;
    if (now < date) {
      if (Math.abs(diffHours) > 1) {
        return `in ${formatNumber(-diffHours)}h`;
      } else {
        return `in ${-diffMins}m`;
      }
    } else {
      if (Math.abs(diffHours) > 1) {
        return `${formatNumber(diffHours)}h ago`;
      } else {
        return `${diffMins}m ago`;
      }
    }
  } else if (diff === -1) {
    return "tomorrow";
  } else if (diff === 1) {
    return "yesterday";
  } else if (diff < 1) {
    return `in ${-diff} days`;
  } else {
    return `${diff} days ago`;
  }
};
