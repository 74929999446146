import {ModelOverview} from "../../cdx-models";
import {Query} from "../../cdx-models/utils/MakeModel";
import {JsonModel} from "../../cdx-models/utils/model-type-utils";

const isDev = process.env.NODE_ENV !== "production";

type ModelName = keyof ModelOverview;
type ModelDescriptions = Record<ModelName, JsonModel>;

type RegisterQueryInCacheParams = {
  parentModel: ModelName;
  relName: string;
  query: Query<any>;
  targetModelDesc: JsonModel;
  cache: any;
  descriptions: ModelDescriptions;
};
const registerQueryInCache = (params: RegisterQueryInCacheParams) => {
  const {parentModel, relName, query, targetModelDesc, descriptions, cache} = params;
  // account.$meta.find("cards", {effort: 2})
  // targetModelDesc: "card"
  // parentModel: "account" + relName

  Object.entries(query).forEach(([key, val]) => {
    if (key[0] === "$") {
      if (key === "$or" || key === "$and") {
        (val as any[]).forEach((q) =>
          registerQueryInCache({
            parentModel,
            relName,
            query: q,
            targetModelDesc,
            descriptions,
            cache,
          })
        );
      } else if (key === "$order") {
        const fields = Array.isArray(val) ? val : [val];
        fields.forEach((rawField) => {
          const field = rawField[0] === "-" ? rawField.slice(1) : rawField;
          return cache.addConstraintBasedRelationField({
            parentModel,
            relName,
            targetModel: targetModelDesc.name,
            field,
          });
        });
      }
    } else {
      if (key[0] === "!") key = key.slice(1);
      const hasManyDesc = targetModelDesc.hasMany[key];
      if (
        targetModelDesc.fields[key] ||
        targetModelDesc.fkToBelongsTo[key] ||
        (hasManyDesc && hasManyDesc.fkAsArray)
      ) {
        return cache.addConstraintBasedRelationField({
          parentModel,
          relName,
          targetModel: targetModelDesc.name,
          field: key,
        });
      }
      if (hasManyDesc) {
        cache.addConstraintBasedRelationRelation({
          parentModel,
          relName,
          targetModel: hasManyDesc.model,
          fk: hasManyDesc.fk,
        });
        if (isDev && (hasManyDesc as any).deps.length) {
          throw new Error(
            `'modern' queries may not rely on relations with deps, specify the deps in the query instead! (rel: ${targetModelDesc.name}'s ${key})`
          );
        }
        if (isDev && (hasManyDesc as any).via) {
          throw new Error(
            `'modern' queries may not rely on relations with via, specify the via in the query instead! (rel: ${targetModelDesc.name}'s ${key})`
          );
        }
        return registerQueryInCache({
          parentModel,
          relName,
          query: val,
          targetModelDesc: descriptions[hasManyDesc.model],
          descriptions,
          cache,
        });
      }
      const belongsToDesc = targetModelDesc.belongsTo[key];
      if (belongsToDesc) {
        cache.addConstraintBasedRelationField({
          parentModel: parentModel,
          relName,
          targetModel: targetModelDesc.name,
          field: belongsToDesc.fk,
        });
        return registerQueryInCache({
          parentModel,
          relName,
          query: val,
          targetModelDesc: descriptions[belongsToDesc.model],
          descriptions,
          cache,
        });
      }
    }
  });
};

type CreateQueryStringParams = {
  modelDesc: JsonModel;
  query: Query<any>;
  relName: string;
  prefix?: string;
  descriptions: ModelDescriptions;
  cache: any;
};

/**
 * creates stable string representation for better caching,
 * also analyses query dependencies to be able invalidate query
 * if dependencies are updated
 */
export const processQuery = ({
  modelDesc,
  query,
  relName,
  prefix = "",
  descriptions,
  cache,
}: CreateQueryStringParams) => {
  const sortedQueryKeys = Object.keys(query).sort();
  const hasManyDesc = modelDesc.hasMany[relName];
  if (isDev) {
    if (!hasManyDesc) throw new Error(`Unknown rel '${relName}' for '${modelDesc.name}'`);
  }
  const targetModelDesc = descriptions[hasManyDesc.model];

  const queryObj = sortedQueryKeys.reduce(
    (memo, key) => {
      const entry = (query as any)[key];
      if (entry && entry.$meta)
        throw new Error(
          "Does not support passing instance as constraint anymore, please use its id instead!"
        );
      memo[key] = entry;
      return memo;
    },
    {} as Record<string, any>
  );

  const queryString = JSON.stringify(queryObj);
  const fullRelName = `${prefix}${relName}(${queryString})`;
  if (queryString && (hasManyDesc as any).model !== "$deprecated") {
    registerQueryInCache({
      parentModel: modelDesc.name,
      relName: fullRelName,
      query,
      targetModelDesc,
      descriptions,
      cache,
    });
  }

  return {isSingleton: hasManyDesc.isSingleton, fullRelName};
};
